import * as _ from 'lodash-es'
export default {
    //params: ['target'],
    params: [
        {
            name: 'target',
            isTarget: true
        },
        {
            name: 'values'
        }
    ],
    validate(value, { target, values }) {
        let required;
        if (_.isArray(values)) {
            required = _.indexOf(values, target) == -1;
        } else {
            required = target != values;
        }

        return {
            required: required,
            valid: !required || ['', null, undefined].indexOf(value) === -1
        };
    },
    //message: 'Password confirmation does not match',
    computesRequired: true

};;
