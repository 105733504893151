<template>
  <div v-if="section.cnpj.show">
    <form-field-switch
      name="curriculum.cnpj.has_cnpj"
      test-id="admissional.cnpj.has_cnpj"
      v-model="curriculum.cnpj.has_cnpj"
      v-bind="section.cnpj.fields.has_cnpj"
      autocomplete="off"
    />

    <form-set-generic
      v-show="curriculum.cnpj.has_cnpj"
      v-model="curriculum.cnpj"
      label="curriculum"
      form="admissional"
      section="cnpj"
      :fields="fields"
      class="profile-subsection"
    />

  </div>
</template>

<script>
import * as _ from 'lodash-es'
import { useCurriculum } from '@state/user/curriculum'
import FormSetGeneric from '@components/forms/set/Generic.vue'
import { FormFieldSwitch } from '@components/forms'
import { useCompanyForm } from '@state/company/form'

export default {
    components: {
        FormSetGeneric,
        FormFieldSwitch
    },
    setup() {
        const curriculum = useCurriculum()
        const forms = useCompanyForm()
        return {
            curriculum,
            forms
        }
    },
    created() {
        this.forms.fields(
          this.form,
          true,
          ...this.section.cnpj.fields
        ).then((fields) => {
            for (let i in fields[this.section]) {
                this.$set(this.internalFields, i, fields[this.section][i])
            }
            this.is_loaded = true;
        });
    },
    props : {
        section : {
            type : Object,
            required: true
        }
    },
    data() {
        return {
            internalValue: this.value,
            internalFields : {},
        }
    },
    computed : {
        fields() {
            return _.filter(this.section.cnpj.fields, (item) => item != 'cnpj.has_cnpj')
        }
    }
};
</script>
