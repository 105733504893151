<template>
  <div class="vaga vacancy-full" :class="{ scrollable: !noScroll, 'is-focused': forceFocus}">

    <!-- MOBILE HEADER -->
    <div class="d-flex align-items-center justify-content-between sticky-top bg-white p-4" v-if="!forceFocus && isMobile()">
      <a class="mr-4 text-default cursor-pointer" @click="$emit('close')">
        <XIcon size="1.5x" />
      </a>
    </div>

    <!-- Content -->
    <div class="h-100" v-if="currentMail">
      
      <!-- LATEST MESSAGE -->
      <message-content :mailContent="currentMail" />

      <!-- REPLY MESSAGE -->
      <div class="row m-3 p-3 pt-0 bg-light rounded" v-if="currentMail.enable_reply && currentMail.replies <= 0">
        <div class="col-12">
          <validation-observer ref="replyRules">
            <b-form @submit.prevent="handleSubmitReply()">
              <b-form-group>
                <label for="reply">
                  {{ $t('messages.reply_message') }}
                </label>
                <validation-provider #default="{ errors }" name="mensagem" rules="required">
                  <b-form-textarea v-model="reply.message" cols="10" rows="5"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="d-flex justify-end">
                <b-button type="submit" variant="primary" size="sm" pill>{{ $t('messages.reply') }}</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>

      <!-- ALL MESSAGES - THREAD -->
      <template v-if="currentMail.replies">
        <div class="row" v-for="threadMail in currentMail.replies" :key="threadMail.id">
          <div class="col-12">
            <message-content :mailContent="threadMail" class="border-top"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { useTheme } from "@state/system/theme";
import { XIcon } from "@vue-hero-icons/outline";
import companyMixin from "@state/company";

import MessageContent from "@blocks/message/MessageContent.vue";
import LetterAvatar from "@components/avatar/LetterAvatar.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {

  components: {
    XIcon,
    MessageContent,
    LetterAvatar,
    ValidationObserver,
    ValidationProvider,
  },

  mixins: [companyMixin],

  props: {
    item: {
      required: true,
      type: Object,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    forceFocus: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      reply: {
        parent_message_id: null,
        message: null,
      },
    };
  },

  setup() {
    const theme = useTheme();
    return {
      isMobile: theme.isMobile,
    };
  },

  computed: {
    currentMail() {
      return this.item;
    },
  },

  methods: {
    handleSubmitReply() {
      this.$refs.replyRules.validate().then((success) => {
        if (success) {
          const payload = {
            ...this.reply,
            parent_message_id: this.currentMail.application_message_id,
          };
          this.$emit("reply", payload);
        }
      });
    },
  },
};
</script>
