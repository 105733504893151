<template>
    <div class="vacancy-card card cursor-pointer border-top-0 rounded-0 p-3" v-on="$listeners">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex flex-column justify-content-center" :class="{'font-semibold': !mail.read}">
                <p class="subtitle">{{ mail.from }}</p>
                <span class="text-sm">{{ mail.subject || $t('messages.noSubject') }}</span>
            </div>
            <div class="text-sm">
                {{ getDateFormatted(mail.created) }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
  props: {
    mail: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isSelectedMail: this.isSelected
    }
  },
  watch: {
    isSelected (value) {
      this.isSelectedMail = value
    },
    isSelectedMail (val) {
      val ? this.$emit('addToSelected', this.mail.id) : this.$emit('removeSelected', this.mail.id)
    }
  },
  computed: {
    labelColor () {
      return (label) => {
        const tags = this.$store.state.email.mailTags
        return tags.find((tag) => {
          return tag.value === label
        }).color
      }
    },
    messageText() {
      return this.mail.message_content.substr(0, 400);
    }
  },
  methods: {
    toggleIsStarred () {
      const payload = { mailId: this.mail.id, value: !this.mail.isStarred }
      this.$store.dispatch('email/toggleIsMailStarred', payload)
    },

    getDateFormatted(date) {
       return new Date(date).toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })
    }
  }
}

</script>

