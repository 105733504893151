<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :vid="vid"
    :rules="rules"
    v-if="is_loaded && hasFields"
    slim
  >
    <div class="" header-class="d-flex w-100 justify-content-between">
      <div
        class="card border-radius-base mt-2"
        v-for="(s, index) in computedValue"
        :key="index"
      >
        <div class="card-body">
          <div class="flex-row">
            <span
              class="title text-lg font-weight-semibold flex justify-content-between"
            >
              <span style="max-width: 85%" v-if="s.institution">{{
                s.institution
              }}</span>
              <span v-else>
                {{ getDomainLabel("education_level", s.education_level_id) }}
              </span>
              <span>
                <PencilAltIcon
                  v-on:click="edit(s, index)"
                  class="text-primary cursor-pointer"
                />
                <TrashIcon
                  v-on:click="remove(s, index)"
                  class="text-primary cursor-pointer"
                />
              </span>
            </span>
          </div>
          <p class="text-sm" v-if="s.education_level_id && s.institution">
            {{ getDomainLabel("education_level", s.education_level_id) }}

            <span v-if="s.course_id && s.education_level_id >= 3">
              {{ " - " + getDomainLabel("education_course", s.course_id) }}
            </span>
          </p>
          <span class="text-sm font-weight-medium" v-if="s.status">{{
            $t("curriculum.education.academic.status.label")
          }}</span>
          <p class="text-sm" v-if="s.status">
            {{ getDomainLabel("education_situation", s.status) }}
          </p>
          <span
            class="text-sm font-weight-medium"
            v-if="s.period && s.status == 's'"
            >{{ $t("curriculum.education.academic.period.label") }}</span
          >
          <p class="text-sm" v-if="s.period && s.status == 's'">
            {{
              $tc("curriculum.texts.education.academic.period.period", s.period)
            }}
          </p>
          <span
            class="text-sm font-weight-medium"
            v-if="s.time && s.status == 's'"
            >{{ $t("curriculum.education.academic.time.label") }}</span
          >
          <p class="text-sm" v-if="s.time && s.status == 's'">
            {{ getDomainLabel("education_time", s.time) }}
          </p>
          <span
            class="text-sm font-weight-medium"
            v-if="s.start_date || s.conclusion_date"
            >{{ $t("common.start_end") }}</span
          >
          <p class="font-weight-medium">
            {{ getConcatenateDates(s.start_date, s.conclusion_date, s.status) }}
          </p>
        </div>
      </div>

      <giga-button
        :label="$t('curriculum.actions.education.add_academic')"
        @click="add"
      />
    </div>

    <b-modal
      ref="modal"
      id="education-academic-modal"
      size="lg"
      centered
      :title="$t('curriculum.actions.education.add_academic')"
      @ok="append($event)"
      @cancel="clear"
      scrollable
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button
          class="border"
          variant="outline-primary"
          pill
          size="sm"
          @click="cancel()"
        >
          {{ $t("common.cancel") }}
        </b-button>
        <b-button variant="primary" pill size="sm" @click="ok()">
          {{ $t("common.save") }}
        </b-button>
      </template>

      <validation-observer ref="entityRules" tag="div" class="">
        <form-set-generic
          section="education.academic"
          v-model="new_entity"
          :fields="internalFields"
          :field-props="{ untracked: true }"
          autocomplete="off"
        >
          <template
            #institution="{ model, field, componentName, testId, component }"
          >
            <span>
              <component
                ref="field-institution"
                v-if="model['education_level_id'] >= 3"
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                :reduce="(option) => option.label || option"
                v-model="model[field]"
                v-bind="component"
                autocomplete="off"
              >
                <template #no-options="{ search, searching }">
                  <span v-if="searching">
                    {{ `${$t("common.institution_not_found")} "${search}"` }}.
                    <a
                      href="#"
                      @click.prevent="
                        addEducationInstitution(search, 'field-institution')
                      "
                      >{{ $t("common.click") }}</a
                    >
                    {{ $t("common.to_add") }}
                  </span>
                  <span v-else>
                    {{ $t("common.no-results-found") }}
                  </span>
                </template>
              </component>
            </span>
          </template>

          <template
            #course_id="{ model, field, componentName, testId, component }"
          >
            <span>
              <component
                ref="field-course"
                v-if="model['education_level_id'] >= 3"
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                v-model="model[field]"
                v-bind="component"
                @search="searchCourse"
                :options="courseOptions"
                autocomplete="off"
              >
                <template #no-options="{ search, searching }">
                  <span v-if="searching">
                    {{ `${$t("common.course_not_found")} "${search}"` }}.
                    <a
                      href="#"
                      @click.prevent="
                        addEducationCourse(search, 'field-course')
                      "
                      >{{ $t("common.click") }}</a
                    >
                    {{ $t("common.to_add") }}
                  </span>
                  <span v-else>
                    {{ $t("common.no-results-found") }}
                  </span>
                </template>
              </component>
            </span>
          </template>

          <template
            #period="{ model, field, componentName, testId, component }"
          >
            <span>
              <component
                v-if="
                  model['status'] == 's' &&
                  model['education_level_id'] !== 1 &&
                  model['education_level_id'] !== 2
                "
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                v-model="model[field]"
                v-bind="component"
                autocomplete="off"
              />
            </span>
          </template>

          <template #time="{ model, field, componentName, testId, component }">
            <span>
              <component
                v-if="model['status'] == 's'"
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                v-model="model[field]"
                v-bind="component"
                autocomplete="off"
              />
            </span>
          </template>

          <template
            #conclusion_date="{
              model,
              field,
              componentName,
              testId,
              component,
            }"
          >
            <span>
              <component
                v-if="model['status'] == 'c'"
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                v-model="model[field]"
                v-bind="component"
                autocomplete="off"
              />
            </span>
          </template>
        </form-set-generic>
      </validation-observer>
    </b-modal>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";

import FormFieldSet from "../mixins/Set";
import FormFieldBase from "../mixins/Base";
import { isoToLocal } from "@/plugins/i18n";
import * as FormComponents from "@components/forms";
import GigaButton from "@components/buttons/GigaButton.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { PencilAltIcon, TrashIcon } from "@vue-hero-icons/outline";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [FormFieldBase, FormFieldSet],
  components: {
    TrashIcon,
    GigaButton,
    PencilAltIcon,
    FormSetGeneric,
    ...FormComponents,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    return {
      formatDate: isoToLocal,
    };
  },
  data() {
    return {
      courseOptions: [],
    };
  },
  created() {
    this.courseOptions = this.domain.education_course;
  },
  methods: {
    searchCourse: _.debounce(function (data) {
      if (data.length > 2) {
        this.domain.getCourses({ param: data }).then((response) => {
          this.courseOptions = [...this.domain.education_course, response.data];
        });
      }
    }, 100),
    addEducationInstitution(name, refName) {
      this.domain
        .create_record("education_institution", { label: name })
        .then((record) => {
          this.new_entity.institution = record.label;
        });
    },
    addEducationCourse(name, refName) {
      this.domain
        .create_record("education_course", { label: name })
        .then((record) => {
          this.courseOptions.push(record);
        });
    },
    getConcatenateDates(startDate, conclusionDate, progressStatus) {
      var dateString = "";

      if (startDate) {
        dateString += this.formatDate(startDate);
      }

      if (conclusionDate && ["c", "p"].indexOf(progressStatus) >= 0) {
        dateString += " - " + this.formatDate(conclusionDate);
      } else if (progressStatus == "s") {
        dateString += " - até o momento";
      }

      return dateString;
    },
  },
};
</script>
