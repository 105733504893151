import Vue from "vue";
import * as _ from "lodash-es";
import Http from "@/plugins/axios";
import { defineStore } from "pinia";

const COUNT_VACANCIES = "/vacancy/count";
const SUGGESTIONS = "/vacancy/suggestions";
const FAVORITE = "/profile/vacancy/favorite";
const FAVORITE_LIST = "/profile/vacancy/favorite-list";

export const useVacancies = defineStore("vacancies", {
    // other options...
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            filters: {},
            conditions: {
                text: "",
                location: "",
                interval: [],
                contract_type: [],
                workplace_type: [],
                professional_area: [],
                limit: "",
            },
            search_results: {},
            paginated_list: [],
            page: 1,
            limit: 30,
            qtd_results: 0,
            loadMoreItems: true,
            list: [],
            favorites: [],
            cardItems: [],
            groupItems: [],
            vacancy: {},
            suggestions: {
                text: [],
                location: [],
            },
            locationTextController: null,
            locationSuggestionController: null,
        };
    },
    actions: {
        countVacancies(conditions = {}) {
            const postConditions = _.merge({}, this.conditions, conditions);
            return Http.post(COUNT_VACANCIES, postConditions).then(
                ({ data }) => {
                    return data.qtd_vacancies;
                }
            );
        },
        read(id, force = false) {
            if (!force && _.has(this.vacancy, id)) {
                return Promise.resolve(this.vacancy[id]);
            }

            return Http.get("/vacancy/read", {
                params: {
                    id: id,
                },
            }).then((response) => {
                this.vacancy[id] = response;
                return response;
            });
        },

        vacancyHomeSectionsSearch(conditions) {
            return Http.post(`/vacancy/home-sections-list`, conditions).then(
                ({ data }) => {
                    return data;
                }
            );
        },
        setLimit(limit) {
            this.limit = limit;

            return this;
        },
        clearSearchPaginated() {
            this.page = 1;
            this.loadMoreItems = true;
            this.qtd_results = 0;
            this.paginated_list = [];
        },
        searchPaginated(conditions, clear = false) {
            if (clear) {
                this.clearSearchPaginated();
            }
            if (this.loadMoreItems) {
                const postConditions = _.merge(
                    {},
                    this.conditions,
                    conditions,
                    {
                        page: this.page,
                        limit: this.limit,
                    }
                );
                return Http.post(`/vacancy`, postConditions).then(
                    ({ data }) => {
                        if (data.items.length > 0) {
                            this.loadMoreVacancies = true;
                            this.page++;
                        } else {
                            this.loadMoreVacancies = false;
                        }
                        this.qtd_results = data.qtd_result;
                        this.$patch((state) => {
                            for (let i in data.items) {
                                state.paginated_list.push(data.items[i]);
                            }
                        });
                        return data.items;
                    }
                );
            } else {
                return Promise.resolve([]);
            }
        },
        search(conditions, index = "") {
            const postConditions = _.merge({}, this.conditions, conditions);
            return Http.post(`/vacancy`, postConditions).then(({ data }) => {
                this.qtd_results = data.qtd_result;

                this.$patch((state) => {
                    if (_.isEmpty(index)) {
                        state.list = {};
                    } else {
                        state.search_results[index] = {};
                    }

                    for (let i in data.items) {
                        if (_.isEmpty(index)) {
                            state.list = data.items;
                        } else {
                            state.search_results[index] = data.items;
                        }
                    }
                });
                return this.list;
            });
        },
        favoriteListLoad() {
            return Http.get(FAVORITE_LIST).then(({ data }) => {
                this.$patch((state) => {
                    state.favorites = [];
                    for (let i in data) {
                        //Vue.set(state.favorites, i, data[i]);
                        state.favorites = data;
                    }
                });
                return this.favorites;
            });
        },
        favorite_vacancy(vacancy_id) {
            document.body.classList.add("waiting");
            return Http.post(`${FAVORITE}/${vacancy_id}`)
                .then(({ data }) => {
                    this.$patch((state) => {
                        console.info("favorite", data);
                        if (data.favorited) {
                            state.favorites.push({ id: vacancy_id });
                        } else {
                            // REMOVE DA LISTA
                            //_.remove(state.favorites, { id: vacancy_id });
                            let index = _.findIndex(state.favorites, {
                                id: vacancy_id,
                            });
                            state.favorites.splice(index, 1);
                        }
                    });
                    return data;
                })
                .finally(() => {
                    document.body.classList.remove("waiting");
                });
        },
        async getSearchTextSuggestions(query) {
            if (this.locationTextController) {
                this.locationTextController.abort();
            }

            this.locationTextController = new AbortController();

            return Http.post(
                `${SUGGESTIONS}/text`,
                {
                    query: query,
                },
                {
                    signal: this.locationTextController.signal,
                }
            ).then(({ data }) => {
                this.suggestions.text = data;
                return data;
            });
            /*.then(({ data }) => {
                return data;
                this.$patch((state) => {
                    state.list = {};
                    for (let i in data) {
                        Vue.set(state.list, i, data[i]);
                        state.list = data;
                    }
                });
                return this.list;
            });
            */
        },
        async getSearchLocationSuggestions(query) {
            if (this.locationSuggestionController) {
                this.locationSuggestionController.abort();
            }

            this.locationSuggestionController = new AbortController();

            return Http.post(
                `${SUGGESTIONS}/location`,
                {
                    query: query,
                },
                {
                    signal: this.locationSuggestionController.signal,
                }
            ).then(({ data }) => {
                //this.locationSuggestionController = null;
                this.suggestions.location = data;
                return data;
            });
        },
        resetConditions() {
            this.conditions = {
                text: "",
                location: "",
                interval: [],
                contract_type: [],
                workplace_type: [],
                professional_area: [],
                limit: "",
            };
            Vue.set(this.conditions, "text", "");
        },
    },
});
