import { defineStore } from 'pinia'
import Vue from 'vue';
import { differenceInSeconds, parseISO } from 'date-fns'
import * as _ from 'lodash-es'

export const useChangeable = defineStore('changeable', {
    // other options...
    state: () => {
        return {
            data: {},
            indexed: {},
            lastChanged: new Date(),
            lastPooled: new Date()
        }
    },
    getters: {
        /*
        const pollChangesFunc = _.throttle(
            _.bind(changeable.pollForChanges, changeable, 'curriculum'),
            30
        )
        */
        poolChanges(state) {
            return _.throttle((name) => {
                let counter = 0;
                for (let i in state.indexed) {
                    if (i.search(name) == 0) {
                        counter++;
                    }
                }
                const changes = _.get(state.data, name, {});

                //state.data = {};

                if (typeof state.lastPooled == 'string') {
                    state.lastPooled = parseISO(state.lastPooled);

                }

                return {
                    counter,
                    changes,
                    time: differenceInSeconds(new Date(), state.lastPooled)
                }
            }, 1)
        }
    },
    actions: {
        placeChange(name, value, add_data = {}) {
            return new Promise((resolve, reject) => {
                const oldValue = _.get(this.indexed[name], 'new', null);
                if (oldValue != value) {
                    this.$patch((state) => {
                        _.set(state.data, name, value);

                        for (let i in add_data) {
                            _.set(state.data, i, add_data[i]);
                        }

                        Vue.set(state.indexed, name, {
                            old: oldValue,
                            new: value
                        })

                        state.lastChanged = new Date();

                        resolve(name, value)
                    })
                } else {
                    reject('no-changes')
                }
            });
        },
        cleanUpAfterSave() {
            this.$state.data = {};
            this.$state.indexed = {};
            this.lastPooled = new Date();
        },
        localPersist(name) {
            return localStorage.setItem(
                name,
                JSON.stringify(this.$state)
            );
        },
        loadChanges(name) {
            const storageJson = localStorage.getItem(
                name
            );
            try {
                const storage = storageJson ? JSON.parse(storageJson) : false;

                if (storage) {
                    this.$patch(storage);

                    return true
                }
            } catch {
                return false
            }
        },
        clearLocalStorage() {
            return localStorage.removeItem('changeable-express-form');
        }


    }
});
