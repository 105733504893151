<template>
  <div v-if="!$root.loading" style="height: 700px">
    <div class="d-flex justify-between align-items-center mb-8">
      <h2 class="text-3xl font-weight-bold">{{ $t("application.title") }}</h2>
    </div>

    <b-card
      header-class="with-tabs sticky-top"
      body-class="p-0"
      class="overflow-auto h-75"
    >
      <template #header>
        <b-nav card-header tabs class="justify-content-evenly">
          <b-nav-item
            href="#candidaturas"
            class="flex-grow text-center"
            :class="{ active: selectedTab == 'candidaturas' }"
            @click="selectedTab = 'candidaturas'"
          >
          {{ $t("application.applications") }}
          </b-nav-item>
          <b-nav-item
            href="#favoritas"
            class="flex-grow text-center"
            :class="{ active: selectedTab == 'favoritas' }"
            @click="selectedTab = 'favoritas'"
          >
          {{ $t("application.favorites") }}
          </b-nav-item>
        </b-nav>
      </template>

      <template v-if="selectedTab == 'candidaturas'">
        <div
          v-if="applications.list.length == 0"
          class="border rounded-bottom bg-white d-flex gap-4 flex-column align-items-center p-8"
        >
          <div class="rounded-pill p-5 bg-surface-informational mb-2">
            <SearchIcon />
          </div>
          <h4 class="text-lg font-weight-semibold">
            {{ $t("application.none_application_yet") }}
          </h4>
          <p class="m-0">{{ $t("application.keep_searching") }}</p>

          <b-button
            pill
            variant="primary"
            class="mt-2"
            :to="{ name: 'vacancy-search' }"
            >{{ $t("common.search") }} {{ $t("common.jobs") }}</b-button
          >
        </div>
        <vacancy-card
          v-else
          v-for="(application, index) in applications.list"
          :key="application.id"
          :item="application.vacancy"
          :application="application.application"
          class=""
          type="stacked"
          :rounded="false"
          no-bookmark
          no-location
          show-status
          @click="openApplication(application)"
        >
        </vacancy-card>
      </template>
      <template v-if="selectedTab == 'favoritas'">
        <div
          class="border rounded-bottom bg-white d-flex gap-4 flex-column align-items-center p-8"
          v-if="!vacancies.favorites.length"
        >
          <div class="rounded-pill p-5 bg-surface-informational mb-2">
            <SearchIcon class="text-primary" />
          </div>
          <h4 class="text-lg font-weight-semibold">
            {{ $t("vacancy.actions.none_favorite_vacancy") }}
          </h4>
          <p class="m-0">{{ $t("vacancy.actions.keep_looking_vacancies") }}</p>

          <b-button
            pill
            variant="primary"
            class="mt-2"
            :to="{ name: 'vacancy-search' }"
            >{{ $t("common.search") }} {{ $t("common.jobs") }}</b-button
          >
        </div>
        <vacancy-card
          v-else
          v-for="item in vacancies.favorites"
          :key="item.id"
          :item="item"
          type="stacked"
          :rounded="false"
          no-bookmark
          no-location
          @click="openVacancy(item.id)"
        >
        </vacancy-card>
      </template>
    </b-card>
  </div>
</template>

<script>
import * as _ from "lodash-es";

import companyMixin from "@state/company";

import { useApplications } from "@state/user/applications";
import { useVacancies } from "@state/vacancy/vacancies";

import VacancyCard from "@blocks/vacancy/VacancyCard.vue";

import { SearchIcon } from "@vue-hero-icons/solid";

export default {
  mixins: [companyMixin],
  components: {
    VacancyCard,
    SearchIcon,
  },
  setup() {
    const applications = useApplications();
    const vacancies = useVacancies();

    vacancies.favoriteListLoad();
    applications.load();

    return {
      // you can return the whole store instance to use it in the template
      applications,
      vacancies,
    };
  },
  created() {
    //this.vacancies.search()
  },
  data() {
    return {
      selectedTab: "candidaturas",
    };
  },
  methods: {
    openSearch() {
      this.$router.push({ name: "vacancy-search" });
    },
    openApplication(application) {
      if (
        _.indexOf(
          ["l", "e", "w", "n"],
          application.application.candidate_status
        ) >= 0
      ) {
        return;
      }

      if (this.company.config.sj) {
        this.$router.push({
          name: "application-progress",
          params: {
            application_id: application.application.id,
            sj_id: application.sj_id,
          },
        });
      } else {
        this.$router.push({
          name: "application-progress",
          params: { application_id: application.application.id },
        });
      }
    },
    openVacancy(id) {
      this.$router.push({
        name: "vacancy-show",
        params: { vacancy_id: id },
      });
    },
  },
};
</script>
