<template>
  <div v-if="section.forms.show">
    <form-set-generic
      v-model="curriculum.forms"
      label="curriculum"
      form="admissional"
      section="forms"
      :fields="fields"
      class="profile-subsection"
    >
      <template #item="{ model, field, componentName, testId, component }">
        <component
          v-show="component.is && component.show && fieldVisible(field)"
          :key="componentName"
          :is="component.is"
          :name="componentName"
          :test-id="testId"
          v-model="model[field]"
          v-bind="component"
        />
      </template>

      <template
        #syndicate_contribution_file_id="{
          model,
          field,
          componentName,
          testId,
          component,
        }"
      >
        <span>
          <component
            v-if="model['has_contribution_sindical']"
            :is="component.is"
            :name="componentName"
            :test-id="testId"
            v-model="model[field]"
            v-bind="component"
          />
        </span>
      </template>
    </form-set-generic>

    <!--
                                    'forms.transport_form_file_id',
                                    'forms.transport_file_id',
                                    'forms.chartered_file_id',
                                    'forms.salary_family_file_id',
                                    'forms.impost_file_id',
                                    'forms.has_contribution_sindical',
                                    'forms.syndicate_contribution_file_id',
                                    'forms.vaccine_file_id',
                                    'forms.unemployment_insurance_file_id',
                                    'forms.contribution_inss_file_id',
                                    'forms.other_file_id',
                                    'forms.other_file2_id',
                                    'forms.other_file3_id',
                                    'forms.other_file4_id'
-->
  </div>
</template>

<script>
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import * as FormComponents from "@components/forms";

export default {
  components: {
    ...FormComponents,
    FormSetGeneric,
  },
  setup() {
    const curriculum = useCurriculum();
    return {
      // you can return the whole store instance to use it in the template
      curriculum,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fields() {
      return this.section.forms.fields;
    },
  },
  methods: {
    fieldVisible(field) {
      return (
        field != "syndicate_contribution_file_id" ||
        this.curriculum.forms.has_contribution_sindical === true
      );
    },
  },
};
</script>
