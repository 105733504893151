<template>
    <div class="container mt-3">
        <b-row vs-type="flex" vs-justify="center">
            <b-col vs-xs="12" vs-lg="5">
            <div class="rounded-xl text-center d-flex align-items-center justify-content-center vh-90">
                <div class="text-center text-lg">
                    <img :src="illustCheckImg" alt="check" class="mb-5 w-40" />
                    <div class="font-bold text-dark mb-2">
                        {{ $t('disc.form.hello_user', [$auth.user().name]) }}
                    </div>
                    <p>{{ $t("disc.form.disc_denied_message_intro") }}</p>
                    <p>{{ $t("disc.form.disc_denied_message_body") }}</p>
                    <p>{{ $t("disc.form.disc_denied_message_conclusion") }}</p>
                    <div class="row my-3 text-center">
                        <div class="col-sm-6">
                            <strong>{{ $t("disc.form.execution_date") }}:</strong>
                            <span v-if="evaluation.execution_date" class="ml-2">{{ evaluation.execution_date }}</span>
                        </div>
                        <div class="col-sm-6">
                            <strong>{{ $t("disc.form.valid_until") }}:</strong>
                            <span v-if="evaluation.valid_until" class="ml-2">{{ evaluation.valid_until }}</span>
                        </div>
                    </div>
                </div>
            </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import illustCheckImg from '@assets/images/survey/illust-check.svg';
import EvaluationMixin from "@mixins/evaluation";
import { useDisc } from '@state/evaluation/disc';

export default {
    mixins: [EvaluationMixin],
    data() {
        return {
            evaluation: {
                execution_date: "",
                valid_until: "",
            },
        };
    },
    setup() {
        const discState = useDisc();
        return {
            discState,
            illustCheckImg,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.discState.getLastDiscDone()
                .then(({ data }) => (this.evaluation = data))
        },
    },
};
</script>