<template>
  <div>
    <b-navbar
      type="dark"
      variant="primary"
      v-if="selectiveProcessId && finished"
    >
      <b-navbar-brand href="#"
        >{{ $t("disc.results.selection_process") }} #{{ selectiveProcessId }}</b-navbar-brand
      >

      <b-button
        variant="light"
        class="my-2 my-sm-0"
        @click="returnToCaller"
        type="button"
        >{{ $t("disc.questions.return") }}</b-button
      >
    </b-navbar>
    <vs-row
      vs-type="flex"
      vs-justify="center"
      class="video-interview"
      style="margin: 0 auto"
    >
      <vs-col vs-xs="12" vs-lg="5">
        <div
          class="rounded-xl text-center d-flex align-items-center justify-content-center vh-90"
        >
          <div class="text-center text-lg">
            <img :src="surveyExpiredSvg" alt="check" class="mb-5 w-40" />
            <!-- <img src="/images/illust-check.svg" class="mb-5 w-40" alt=""> -->
            <div class="font-bold text-dark mb-2">
              {{ $t("evaluation.expired.title") }}
            </div>
            <div class="mb-4">
              <div>{{ $t("evaluation.expired.description") }}</div>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import Vue from "vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import surveyExpiredSvg from "@assets/images/survey/survey-expired.svg";
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
  components: {
    FormWizard,
    TabContent,
  },
  setup() {
    return {
      surveyExpiredSvg,
    };
  },
  computed: {
    selectiveProcessId() {
      return this.$route.params.selectiveProcessId;
    },
  },
};
</script>