<template>
  <div v-if="section.cnh.show">
    <form-field-switch
      name="curriculum.cnh.has_cnh"
      test-id="admissional.cnh.has_cnh"
      v-model="curriculum.cnh.has_cnh"
      v-bind="section.cnh.fields.has_cnh"
      autocomplete="off"
    />

    <form-set-generic
      v-if="curriculum.cnh.has_cnh"
      v-model="curriculum.cnh"
      label="curriculum"
      form="admissional"
      section="cnh"
      :fields="cnhFields"
      class="profile-subsection"
    />

  </div>
</template>

<script>
import * as _ from 'lodash-es'
import { useCurriculum } from '@state/user/curriculum'
import FormSetGeneric from '@components/forms/set/Generic.vue'
import { FormFieldSwitch } from '@components/forms'

export default {
  components: {
    FormSetGeneric,
    FormFieldSwitch
  },
  setup() {
    const curriculum = useCurriculum()
    return {
      // you can return the whole store instance to use it in the template
      curriculum
    }
  },
  props : {
    section : {
      type : Object,
      required: true
    }
  },
  computed : {
    cnhFields() {
      return _.filter(this.section.cnh.fields, (item) => item != 'cnh.has_cnh')
    }
  }
};
</script>
