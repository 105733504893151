<template>
  <section id="banner-cta" data-scroll-to="banner-cta" class="d-flex flex-column gap-4">

    <!-- Section title -->
    <h3 v-if="section_title" class="text-center mb-3">
      {{ section_title }}
    </h3>

    <!-- Section content -->
    <div class="row">

        <!-- Image on left side -->
        <div v-if="image_side === 'left'" class="col-md py-md-0 d-flex align-content-center justify-content-center">
          <img v-if="image_url" class="img-fluid" :src="getUrlSrc(image_url)" />
        </div>

        <!-- Content on left or right side -->
        <div class="col-md py-md-0 py-4 d-flex flex-column" :class="button_link == '' ? 'text-center justify-content-center' : 'justify-content-around'">
            <h5 v-if="title" :class="{'h2': button_link == ''}">{{ title }}</h5>
            <p v-if="subtitle">{{ subtitle }}</p>
            <ul class="list-unstyled" v-if="Object.keys(items).length">
                <li class="mb-3 text-sm" v-for="(item, index) in items" :key="index">
                    <CheckIcon /> 
                    <span class="ml-2">{{ item.text }}</span>
                </li>
            </ul>
            <div class="w-100 text-center" v-if="isValidURL(button_link)">
                <b-button pill variant="primary" :href="button_link" target="_blank" :style="'min-width: 75%;'">
                    {{ button_label }}
                </b-button>
            </div>
        </div>

        <!-- Image on right side -->
        <div v-if="image_side === 'right'" class="col-md py-md-0 d-flex align-content-center justify-content-center" :class="{'order-first': this.isMobile()}">
          <img v-if="image_url" class="img-fluid" :src="getUrlSrc(image_url)" />
        </div>
    </div>
  </section>
</template>

<script>
import images from "@mixins/images";
import { useTheme } from "@state/system/theme";
import { CheckIcon } from "@vue-hero-icons/solid";
import companyMixin from "@state/company";

export default {
  props: {
    section_title: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
    button_label: {
      type: String,
      default: "",
    },
    button_link: {
      type: String,
      default: "",
    },
    image_side: {
      type: String,
      default: "left",
    },
    image_url: {
      type: String,
      default: "",
    },
  },
  mixins: [companyMixin, images],
  components: {
    CheckIcon,
  },
  setup() {
    const theme = useTheme();
    return {
      isMobile: theme.isMobile,
    };
  },
  computed: {
    isSJ() {
      return this.company.config.sj;
    },
  },
  methods: {
    getUrlSrc(src) {
      if (this.isSJ) {
        return this.imagesList[src];
      }

      return src;
    },
    isValidURL(string = '') {
      try {
        return new URL(string);
      } catch (e) {
        return false;
      }
    }
  }
};
</script>
