import { defineStore } from "pinia";
import Vue from "vue";
import DomainService from "@services/domain/Domain";
import * as _ from "lodash-es";
import { i18n } from "@/plugins/i18n";
import Http from "@/plugins/axios"; // não precisa de .js

const CREATE_RECORD = "/domains/record";
const GET_COURSES = "/domains/get-courses";
const GET_TRAINING = "/domains/get-training";

export const useDomain = defineStore("domain", {
    // other options...
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            domains: {
                logo_position: [
                    { label: i18n.t('domains.logo_position.normal'), value: "default" },
                    { label: i18n.t('domains.logo_position.centralized'), value: "centralized" },
                    { label: i18n.t('domains.logo_position.top_square'), value: "normal-square" },
                ],
                gender: [
                    { label: i18n.t('domains.gender.male_abb'), value: "M" },
                    { label: i18n.t('domains.gender.female_abb'), value: "F" },
                    { label: i18n.t('domains.gender.do_not_inform'), value: "u" },
                ],
                admissional_gender: [
                    { label: "Masc", value: "M" },
                    { label: "Fem", value: "F" },
                ],
                nationalities: [
                    { label: i18n.t('domains.nationalities.brazilian_native'), value: 1 },
                    { label: i18n.t('domains.nationalities.naturalized'), value: 2 },
                    { label: i18n.t('domains.nationalities.foreigner'), value: 3 },
                ],
                has_letter_attribute: [
                    { label: i18n.t('common.yes'), value: "y" },
                    { label: i18n.t('common.no'), value: "n" },
                ],
                professional_history: [
                    { label: i18n.t('common.yes'), value: "y" },
                    { label: i18n.t('common.no'), value: "n" },
                ],
                option_yes: [{ label:  i18n.t('common.yes'), value: true }],
                radio_boolean_options: [
                    { label: i18n.t('common.yes'), value: true },
                    { label: i18n.t('common.no'), value: false },
                ],
                data_holder: [
                    { label: i18n.t('domains.data_holder.I_am_data_holder'), value: true },
                    { label: i18n.t('domains.data_holder.I_am_attorney_or_representative'), value: false },
                ],
                data_holder_category: [
                    { label: i18n.t('domains.data_holder_category.client'), value: "Cliente" },
                    { label: i18n.t('domains.data_holder_category.collaborator'), value: "Colaborador" },
                    { label: i18n.t('domains.data_holder_category.lead_prospect'), value: "Lead/Prospect" },
                    { label: i18n.t('domains.data_holder_category.former_collaborator'), value: "Ex-colaborador" },
                    { label: i18n.t('domains.data_holder_category.supplier'), value: "Fornecedor" },
                ],
                exercise_rights: [
                    {
                        label: i18n.t('domains.exercise_rights.correct_data_incomplete_inaccurate_outdated'),
                        value: "cd",
                    },
                    {
                        label: i18n.t('domains.exercise_rights.allow_data_portability'),
                        value: "pp",
                    },
                    {
                        label: i18n.t('domains.exercise_rights.receive_information_shared_entities'),
                        value: "ri",
                    },
                    { label: i18n.t('domains.exercise_rights.revoke_consent'), value: "rc" },
                    {
                        label: i18n.t('domains.exercise_rights.review_automated_decisions'),
                        value: "rd",
                    },
                    {
                        label: i18n.t('domains.exercise_rights.confirm_data_processing_and_access'),
                        value: "sc",
                    },
                ],
                result_request: [
                    {
                        label: i18n.t('domains.result_request.electronic_contact_email'),
                        value: "email",
                    },
                    {
                        label: i18n.t('domains.result_request.printed_format_address'),
                        value: "letter",
                    },
                ],
                countries: [],
                document_type: [],
                states: [],
                cities: [],
                cities_rg: [],
                cities_born: [],
                cities_voter_registration: [],
                occupations: [],
                interest_levels: [],
                interest_areas: [],
                pcd: [],
                admissional_education_level: [],
                education_level: [],
                education_institution: [],
                education_course: [],
                education_situation: [],
                education_period: [],
                education_time: [],
                additional_training: [],
                languages: [],
                computing: [],
                colleges: [],
                civil_status: [],
                admissional_civil_status: [],
                kinship: [],
                cnh_type: [],
                street_type: [],
                acting_areas: [],
                company_sizes: [],
                racial: [],
                admissional_racial: [],
                gender_identity: [],
                gender_orientation: [],
                vacancy_interval: [],
                vacancy_contract_type: [],
                vacancy_workplace_type: [],
                vacancy_professional_area: [],
                companies: [],
                armed_forces_service_status: [
                    { label: i18n.t('domains.armed_forces_service_status.armed_forces_enlisted'), value: "Alistado" },
                    { label: i18n.t('domains.armed_forces_service_status.armed_forces_reservist'), value: "Reservista" },
                    { label: i18n.t('domains.armed_forces_service_status.armed_forces_exempt'), value: "Dispensado" },
                ],
                banks: [],
                bank_types: [
                    { label: i18n.t('domains.bank_types.bank_type_current_account'), value: 1 },
                    { label: i18n.t('domains.bank_types.bank_type_savings_account'), value: 2 },
                    { label: i18n.t('domains.bank_types.bank_type_salary_account'), value: 3 },
                ],
                bank_holder_types: [
                    { label: i18n.t('domains.bank_holder_types.bank_holder_individual'), value: 1 },
                    { label: i18n.t('domains.bank_holder_types.bank_holder_legal_entity'), value: 2 },
                ],
                bank_pix_types: [
                    { label: i18n.t('domains.bank_pix_types.pix_type_not_informed'), value: '0' },
                    { label: i18n.t('domains.bank_pix_types.pix_type_phone'), value: '1' },
                    { label: i18n.t('domains.bank_pix_types.pix_type_email'), value: '2' },
                    { label: i18n.t('domains.bank_pix_types.pix_type_cpf_cnpj'), value: '3' },
                    { label: i18n.t('domains.bank_pix_types.pix_type_random_key'), value: '4' },
                ],
                tshirt: [
                    { label: i18n.t('domains.tshirt.tshirt_size_small'), value: "P" },
                    { label: i18n.t('domains.tshirt.tshirt_size_medium'), value: "M" },
                    { label: i18n.t('domains.tshirt.tshirt_size_large'), value: "G" },
                    { label: i18n.t('domains.tshirt.tshirt_size_extra_large'), value: "GG" },
                    { label: i18n.t('domains.tshirt.tshirt_size_extra_extra_large'), value: "EG" },
                ],
                trousers: _.map([...Array(27).keys()], (item) => ({
                    label: item + 34,
                    value: item + 34,
                })),
                shirt: _.map([...Array(10).keys()], (item) => ({
                    label: item + 1,
                    value: item + 1,
                })),
                benefits: [
                    { label: i18n.t('domains.benefits.benefit_meal_voucher'), value: "vr" },
                    { label: i18n.t('domains.benefits.benefit_food_voucher'), value: "va" },
                    {
                        html: i18n.t('domains.benefits.benefit_medical_assistance'),
                        value: "am",
                    },
                    { label: i18n.t('domains.benefits.benefit_transport_voucher'), value: "vt" },
                ],
                transports: [],
                way_transport: [],
                yes_or_no: [
                    { label: i18n.t('common.yes'), value: "y" },
                    { label: i18n.t('common.no'), value: "n" },
                ],
                ctps_types: [
                    { label: i18n.t('common.physical'), value: "f" },
                    { label: i18n.t('common.digital'), value: "d" },
                ],
            },
            labelMap: new Map(),
        };
    },
    getters: {
        /**
         * @todo Plurarize all domain names
         */

        gender() {
            return this.domains["gender"];
        },
        admissional_gender() {
            return this.domains[`admissional_gender`];
        },
        nationalities() {
            return this.domains["nationalities"];
        },
        countries() {
            return this.domains["countries"];
        },
        document_type() {
            return this.domains["document_type"];
        },
        states() {
            return this.domains["states"];
        },
        cities() {
            return this.domains[`cities`];
        },
        cities_rg() {
            return this.domains[`cities_rg`];
        },
        cities_born() {
            return this.domains[`cities_born`];
        },
        cities_voter_registration() {
            return this.domains[`cities_voter_registration`];
        },
        occupations() {
            return this.domains[`occupations`];
        },
        interest_levels() {
            return this.domains[`interest_levels`];
        },
        interest_areas() {
            return this.domains[`interest_areas`];
        },
        pcd() {
            return this.domains[`pcd`];
        },
        admissional_education_level() {
            return this.domains[`admissional_education_level`];
        },
        education_level() {
            return this.domains[`education_level`];
        },
        education_institution() {
            return this.domains[`education_institution`];
        },
        education_course() {
            return this.domains[`education_course`];
        },
        education_situation() {
            return this.domains[`education_situation`];
        },
        education_period() {
            return this.domains[`education_period`];
        },
        education_time() {
            return this.domains[`education_time`];
        },
        additional_training() {
            return this.domains[`additional_training`];
        },
        languages() {
            return this.domains[`languages`];
        },
        computing() {
            return this.domains[`computing`];
        },
        civil_status() {
            return this.domains[`civil_status`];
        },
        admissional_civil_status() {
            return this.domains[`admissional_civil_status`];
        },
        kinship() {
            return this.domains[`kinship`];
        },
        cnh_type() {
            return this.domains[`cnh_type`];
        },
        street_type() {
            return this.domains[`street_type`];
        },
        acting_areas() {
            return this.domains[`acting_areas`];
        },
        company_sizes() {
            return this.domains[`company_sizes`];
        },
        racial() {
            return this.domains[`racial`];
        },
        admissional_racial() {
            return this.domains[`admissional_racial`];
        },
        gender_identity() {
            return this.domains[`gender_identity`];
        },
        gender_orientation() {
            return this.domains[`gender_orientation`];
        },
        vacancy_interval() {
            return this.domains[`vacancy_interval`];
        },
        vacancy_contract_type() {
            return this.domains[`vacancy_contract_type`];
        },
        vacancy_workplace_type() {
            return this.domains[`vacancy_workplace_type`];
        },
        vacancy_professional_area() {
            return this.domains[`vacancy_professional_area`];
        },
        armed_forces_service_status() {
            return this.domains[`armed_forces_service_status`];
        },
        banks() {
            return this.domains[`banks`];
        },
        bank_types() {
            return this.domains[`bank_types`];
        },
        bank_types_without_saving_account() {
            return this.domains[`bank_types`].filter(i => i.value != 2);
        },
        bank_holder_types() {
            return this.domains[`bank_holder_types`];
        },
        bank_pix_types() {
            return this.domains[`bank_pix_types`];
        },
        tshirt() {
            return this.domains[`tshirt`];
        },
        trousers() {
            return this.domains[`trousers`];
        },
        shirt() {
            return this.domains[`shirt`];
        },
        benefits() {
            return this.domains[`benefits`];
        },
        transports() {
            return this.domains[`transports`];
        },
        way_transport() {
            return this.domains[`way_transport`];
        },
        companies() {
            return this.domains[`companies`];
        },
        yes_or_no() {
            return this.domains[`yes_or_no`];
        },
        ctps_types() {
            return this.domains[`ctps_types`];
        },
    },
    actions: {
        async load(path, key, subdomain = null) {
            if (_.isUndefined(key)) {
                key = path;
            }

            if (_.has(this.domains, path) && !_.isEmpty(this.domains[path])) {
                return Promise.resolve(this.domains[path]);
            }

            return DomainService.build()
                .read(path)
                .then(({ data }) => {
                    let keys = path.replace("/", "_");
                    this.$patch((state) => {
                        if (!_.has(state.domains, key)) {
                            state.domains[key] = [];
                        }

                        state.domains[key].splice(data.length);

                        for (let i in data) {
                            if (!_.isNull(subdomain)) {
                                key = subdomain;
                            }
                            Vue.set(
                                state.domains[key],
                                i,
                                this.parseItem(data[i])
                            );
                        }
                    });
                    return this.domains[path];
                });
        },
        parseItem(item) {
            if (_.has(item, "token")) {
                item.label = i18n.tc(item.token, item.value);
            }
            return item;
        },
        getLabel(domain, id, defaultValue = "") {
            const key = domain + ":" + id;
            if (this.labelMap.has(key)) {
                return this.labelMap.get(key);
            } else {
                if (this[domain]) {
                    let item = _.find(this[domain], { value: id });
                    if (item) {
                        this.labelMap.set(key, item.label);

                        return item.label;
                    }
                }
                return defaultValue;
            }
        },
        create_record(domain, value) {
            // DO THE REMOTE REQUEST, AND REFRESH THE DOMAIN (OR APPEND)

            return Http.post(`${CREATE_RECORD}/${domain}`, value).then(
                ({ data }) => {
                    this.domains[domain].push(data);

                    return data;
                }
            );
        },
        getCourses(data) {
            if (this.locationTextController) {
                this.locationTextController.abort();
            }

            this.locationTextController = new AbortController();

            return Http.post(GET_COURSES, data, {
                signal: this.locationTextController.signal,
            });
        },
        getTraining(data) {
            if (this.locationTextController) {
                this.locationTextController.abort();
            }

            this.locationTextController = new AbortController();

            return Http.post(GET_TRAINING, data, {
                signal: this.locationTextController.signal,
            });
        },
    },
});
