<template>
    <div>
        <span v-if="step.status == 2">
            <!-- {{ step.data.pattern.name }}
            {{ step.data.change.d }}-{{ step.data.change.i }}-{{ step.data.change.s }}-{{ step.data.change.c }}
            <br />
            @todo INJETAR COMPONENTE DE VISUALIZAÇÂO DISC, CONFORME CURRICULO -->

                <!-- :candidate-name="form.personalData[0].name" -->
            <disc-card
                v-b-modal.modal-disc-details
                :pattern="step.data.pattern"
                :factor="step.data.change"
            />
        </span>
        <span v-else-if="step.status == 1">
            <router-link :to="{ name: step.link.name, params : step.link.params }" v-slot="{ navigate, href, isExactActive }">
                <a :href="href" @click="navigate" :class="{ 'active': isExactActive }">
                    <span>{{ $t(step.link.text) }}</span>
                </a>
            </router-link>
        </span>
        <span v-else-if="step.status == 0">
            {{ step.content }}
        </span>
    </div>
</template>
<script>

import DiscCard  from '@components/disc/DiscCard.vue';

export default {
    components : {
        DiscCard,
    },
    props : {
        step : {
            type: Object,
            required: true
        }
    },
}
</script>
