<template>
  <section id="portal-banner-mosaic" data-scroll-to="portal-banner-mosaic">
    <h4 class="mb-4" v-if="title">{{ title }}</h4>

    <div v-for="(value, index) in images" :key="index">
      <a :href="value.redirect_url ? value.redirect_url : null" target="_blank">
        <img
          class="img-fluid"
          :src="getUrlSrc(value.src)"
          :alt="alt"
          style="height: 100%; width: 100%; object-fit: contain"
          @error="renderPlaceholder($event)"
        />
      </a>
    </div>
  </section>
</template>

<script>
import images from "@mixins/images";
import companyMixin from "@state/company";
import bannerPlaceholder from "./assets/banner_moisaco_example.png";

export default {
  mixins: [companyMixin, images],
  props: ["images", "title", "alt"],
  computed: {
    isSJ() {
      return this.company.config.sj;
    },
  },
  data() {
    return {
      placeholderImage: bannerPlaceholder,
    };
  },
  methods: {
    getUrlSrc(src) {
      if (this.isSJ) {
        return this.imagesList[src];
      } else {
        return src;
      }
    },
    renderPlaceholder(ev) {
      ev.target.src = this.placeholderImage;
    },
  },
};
</script>