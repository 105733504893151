<template>
  <div class="" v-if="!isLoading" style="max-width: 740px">
    <!-- Page title -->
    <div class="d-flex justify-between align-items-center mb-8">
      <h2 class="text-3xl font-weight-bold">{{ $t("esocial.title") }}</h2>
    </div>

    <!-- Page content -->
    <validation-observer ref="esocialRules" tag="div" class="">
      <b-card class="" body-class="p-6">
        <validation-observer
          v-for="section in formSections"
          ref="esocialInnerRules"
          :key="section.name"
        >
          <component
            :section="section"
            v-bind:is="section.component"
            :ref="section.name"
            :candidate_id="candidate_id"
          />
        </validation-observer>
        <b-button variant="primary" class="w-100 my-4" @click="submitForm" pill>
          {{ $t("common.save") }}
        </b-button>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useCompanyForm } from "@state/company/form";
import { useCurriculum } from "@state/user/curriculum";
import { useChangeable } from "@state/behaviours/changeable";
import * as FormComponents from "@components/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Personal from "./esocial/Personal.vue";
import Ctps from "./esocial/Ctps.vue";

export default {
  mixins: [companyMixin],
  components: {
    ...FormComponents,
    ValidationProvider,
    ValidationObserver,
    Personal,
    Ctps,
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
    };
  },

  props: {
    candidate_id: {
      required: true,
    },
  },

  setup() {
    const formState = useCompanyForm();
    const curriculum = useCurriculum();
    const changeable = useChangeable();
    formState.load("esocial");

    return {
      formState,
      curriculum,
      changeable,
    };
  },

  created() {
    // Redirect when in SJ context
    if (this.company.config.sj) {
      this.$router.push({ name: "home" });
    }

    // Validate request
    if (this.$auth.user().need_to_change_password) {
      this.$router.push({
        name: "register4",
        params: { change_password: true },
      });
    }

    // Load cv data
    this.isLoading = true;
    this.$root.loading = true;
    this.curriculum
      .read()
      .then(() => {
        this.isLoading = false;
        if (this.curriculum.ctps.has_pis === "") {
          this.curriculum.ctps.has_pis = true;
        }
      })
      .finally(() => (this.$root.loading = false));
  },

  computed: {
    formSections() {
      return _.pickBy(this.formState.esocial.sections, "show");
    },
  },

  methods: {
    submitForm() {
      this.$refs.esocialRules.validate().then((success) => {
        // Validate
        this.$root.loading = true;
        if (!success) {
          this.inputFocus();
          this.$root.loading = false;
          return;
        }

        // SAVE PARTIAL CURRICULUM
        const poll_changes = this.changeable.poolChanges("curriculum");
        this.changeCounter = poll_changes.counter;

        this.isSaving = true;
        this.curriculum
          .persist(poll_changes.changes)
          .then(({ data }) => {
            if (data.success) {
              this.changeable.cleanUpAfterSave();
              this.changeCounter = 0;
            }

            this.isSaving = false;
            this.$router.push({ name: "profile" });
          })
          .finally(() => (this.$root.loading = false));
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },
  },
};
</script>