<template>
  <section v-if="company.config.rodape && company.config.rodape.show" id="rodape" class="d-flex flex-column justify-content-between align-items-center">
    <!-- SJ footer -->
    <div v-if="this.company.config.sj" class="w-100 h-100 d-flex text-left bg-primary text-white py-12" :class="{'mt-6': !this.isMobile()}">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <img :src="getSjFooterLogo()" alt="selecty_jobs_footer_logo" width="250"/> 
            <div class="d-flex flex-column justify-content-between mt-4 gap-3">
              <p class="mb-0 font-weight-bold">{{ $t('footer.sj.fastest_way_to_hire') }}</p>
              <div>
                <div class="d-flex align-items-center">
                  <LocationMarkerIcon class="m-0 p-0"/>
                  <span class="ml-1">{{ $t('footer.sj.office_location') }}</span>
                </div>
                <div class="mt-0">
                  {{ $t('footer.sj.office_address') }}
                </div>
              </div>
              <p class="mb-0">{{ $t('footer.sj.welcome') }}</p>
            </div>
          </div>
          <div class="col-lg-3 col-sm-12 mt-5">
            <p class="h6 font-weight-bold">{{ $t('footer.sj.for_companies') }}</p>
            <ul class="list-unstyled mt-4 mb-0">
              <li class="mb-2">
                <a href="https://jobs.selecty.app/jobpost/register" target="_blank" class="text-white">{{ $t('footer.sj.post_your_jobs') }}</a>
              </li>
              <li class="mb-2">
                <a href="https://selecty.com.br" target="_blank" class="text-white">{{ $t('footer.sj.full_recruitment_management') }}</a>
              </li>
              <li class="mb-2">
                <a href="https://materiais.selecty.com.br/para-consultorias" target="_blank" class="text-white">{{ $t('footer.sj.outsource_your_job') }}</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-sm-12 mt-5">
            <p class="h6 font-weight-bold">{{ $t('footer.sj.for_candidates') }}</p>
            <ul class="list-unstyled mt-4 mb-0">
              <li class="mb-2">
                <router-link :to="{name: 'vacancy-search'}" class="text-white">{{ $t('footer.sj.search_jobs') }}</router-link>
              </li>
              <li class="mb-2">
                <router-link :to="{name: 'create-account'}" class="text-white">{{ $t('footer.sj.create_profile') }}</router-link>
              </li>
              <li class="mb-2">
                <a href="https://selecty.com.br/politica-privacidade" class="text-white" target="_blank">{{ $t("footer.sj.privacy_policy") }}</a>
              </li>
              <li class="mb-2">
                <router-link :to="{name: 'faq'}" class="text-white">{{ $t('footer.sj.faq') }}</router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="col-lg-2 col-sm-12 mt-5">
            <p class="h6 text-nowrap">{{ $t('footer.sj.follow_us') }}</p>
            <div class="d-flex mt-3 gap-3" :style="!this.isMobile() ? 'justify-content: end; margin-right: -40px;' : ''">
              <a class="btn rounded-circle p-0" target="_blank">
                <img :src="imageSocialMedia('in', 'white')" alt="name" width="40" />
              </a>
              <a class="btn rounded-circle p-0" href="https://www.linkedin.com/company/selecty-jobs" target="_blank">
                <img :src="imageSocialMedia('lk', 'white')" alt="name" width="40" />
              </a>
            </div>
          </div> -->
        </div>
        <div class="row text-center mt-8">
          <div class="col-12">
            <p class="m-0">{{ $t('footer.sj.copyright', {year: this.getCurrentYear}) }}</p>
            <p class="m-0">{{ $t('footer.sj.company') }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Customer footer -->
    <div v-else class="w-100 d-flex flex-column justify-content-between align-items-center">
      <div class="p-4">
        <img :src="imageRodape()" alt="" height="30" />
        <div class="social-media-container d-flex gap-4 mt-3">
          <a v-for="(link, name) in links" :key="name" class="btn p-3 btn-dt-secondary rounded-circle" :href="link" target="_blank">
            <img :src="imageSocialMedia(name)" :alt="name" width="25" height="25" @error="renderPlaceholder($event)" v-if="!imageError"/>
          </a>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center align-items-center bg-white" :style="'min-height: 70px; border-top: 1px solid #efefef;'">
        <a href="https://selecty.com.br" target="_blank">
          <img :src="imagesList['/resources/assets/images/logo/powered_by_sj.png']" alt="" width="230"/>
        </a>
      </div>
    </div>
  </section>
</template>

<script>

import * as _ from "lodash-es";
import { useTheme } from "@state/system/theme";
import companyMixin from "@state/company";
import images from "@mixins/images";
import { LocationMarkerIcon } from "@vue-hero-icons/outline"

export default {
  components: {LocationMarkerIcon},
  mixins: [companyMixin, images],
  setup() {
    const theme = useTheme();
    return {
      isMobile: theme.isMobile,
    };
  },
  data() {
    return {
      imageError: false,
    };
  },
  computed: {
    links() {
      return _.omitBy(this.company.config.rodape.links, (item) => {
        return _.isNull(item);
      });
    },
    getCurrentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    imageRodape() {
      if (this.company.config.sj) {
        return new URL(this.company.config.rodape.logo, import.meta.url).href;
      } else {
        return this.company.config.rodape.logo;
      }
    },
    imageSocialMedia(name, color = 'grey') {
      return this.imagesList[`/resources/assets/images/social/${name}_${color}.png`];
    },
    renderPlaceholder(ev) {
      this.imageError = true;
    },
    getSjFooterLogo() {
      return this.imagesList[`/resources/assets/images/sj/rodape_branco_sj.png`];
    }
  },
};
</script>
