<template>
  <b-avatar rounded="sm" variant="neutral-subdued" size="2.5rem">
    <template #default="{}">
      <img
        :src="item.customer_square_logo"
        alt="avatar"
        @error="renderAvatar"
        v-if="
          !imageError &&
          item.customer_square_logo &&
          item.customer_square_logo.length > 0
        "
        style="height: 100%; width: 100%; object-fit: contain"
      />

      <OfficeBuildingIcon class="icon-subdued" v-else />
    </template>
  </b-avatar>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import { OfficeBuildingIcon } from "@vue-hero-icons/solid";

export default {
  components: {
    BAvatar,
    OfficeBuildingIcon,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data: () => {
    return {
      imageError: false,
    };
  },
  methods: {
    renderAvatar() {
      this.imageError = true;
    },
  },
  mounted() {},
};
</script>
