<template>
  <section id="accordion-with-image" data-scroll-to="accordion-with-image" class="d-flex flex-column gap-4">
    <div
      class="text-center"
      v-if="title"
    >
      <h3>{{ title }}</h3>
    </div>
    <div class="d-flex row no-gutters">
      <div class="col-md">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item" v-for="(item, index) in items" :key="index">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                aria-expanded="true"
                aria-controls="collapseOne"
                v-b-toggle="collapaseBaseId + '-' + index"
              >
                {{ item.title }}
              </button>
            </h2>
            <b-collapse
              :id="collapaseBaseId + '-' + index"
              role="tabpanel"
              v-bind="collapseBindings"
            >
              <div class="accordion-body">
                {{ item.text }}
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <div class="col-md">
        <img class="img-fluid w-100" :src="imagesList[imageUrl]" />
      </div>
    </div>
  </section>
</template>

<script>
import images from '@mixins/images';

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: "",
    },
    imageUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
  mixins : [
    images
  ],
  computed: {
    collapaseBaseId() {
      return "accordion-" + this._uid;
    },
    collapseBindings() {
      if (this.mode == "accordion") {
        return {
          accordion: "accordion-" + this._uid,
        };
      }
      return {};
    },
  },
};
</script>
