import { defineStore } from 'pinia'
//import { changeLanguage } from '@/plugins/i18n'
import { i18n } from '@/plugins/i18n'
import axios from 'axios'
import br from '@assets/images/flags/br.svg'
import us from '@assets/images/flags/us.svg'
import es from '@assets/images/flags/es.svg'


export const useLocale = defineStore('locale', {
    state: () => {
        return {
            available : {
                'pt-BR' : {
                    flag: br,
                    lang: 'Português'
                },
                'en-US' : {
                    flag: us,
                    lang: 'English'
                },
                'es' : {
                    flag: es,
                    lang: 'Español'
                }
            },
            selected : '',
            loadedLanguages : [] // our default language that is preloaded
        }
    },
    getters : {
        current() {
            return this.available[this.selected];
        }
    },
    actions : {
        async detect(defaultLang) {
            let currentLanguage = window.localStorage.getItem('language')
            if (Object.keys(this.available).indexOf(currentLanguage) == -1) {
                if (navigator.languages) {
                    for(let i in navigator.languages) {
                        if (Object.keys(this.available).indexOf(navigator.languages[i]) != -1) {
                            currentLanguage = navigator.languages[i];
                            break;
                        }
                    }
                }
            }
            // SMALL HACK TO FORCE pt-br
            // currentLanguage = 'pt-BR';
            this.change(currentLanguage)
        },
        async change(lang) {

            this.selected = lang;

            if (Object.keys(this.available).indexOf(lang) != -1) {
                i18n.locale = lang
                axios.defaults.headers.common['Accept-Language'] = lang
                document.querySelector('html').setAttribute('lang', lang)

                window.localStorage.setItem('language', lang);
            }
            return lang
           /*
            const { t, locale } = useI18n({
                inheritLocale: true,
                useScope: 'local'
              })
              return { t, locale }

            this.loadLanguage(lang).then((newValue) => {
                this.selected = newValue
            })
            */
        },
        loadLanguage(lang) {
            // If the same language
            if (i18n.locale === lang) {
                return Promise.resolve(this.setI18nLanguage(lang))
            }

            // If the language was already loaded
            if (this.loadedLanguages.includes(lang)) {
                return Promise.resolve(this.setI18nLanguage(lang))
            }

            i18n.setLocaleMessage(lang, messages.default[lang])
            this.loadedLanguages.push(lang)

            return Promise.resolve(this.setI18nLanguage(lang))
            /*
            // If the language hasn't been loaded yet
            //return import(`@lang/${lang}.js`).then(
                messages => {
                    i18n.setLocaleMessage(lang, messages.default[lang])
                    this.loadedLanguages.push(lang)
                    return this.setI18nLanguage(lang)
                }
            )
            */
        },
        async setI18nLanguage (lang) {
            if (Object.keys(this.available).indexOf(lang) != -1) {
                i18n.locale = lang
                axios.defaults.headers.common['Accept-Language'] = lang
                document.querySelector('html').setAttribute('lang', lang)

                //let messages = i18n.getLocaleMessage(lang);

                window.localStorage.setItem('language', lang);

                return lang
            }
            return i18n.locale;
        }


    }

});
