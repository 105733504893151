<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="internalRules"
    >
        <b-form-group
            :name="internalVid"
            :id="fieldsetId"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot
                    name="label"
                    :inputId="inputId"
                    :label="internalLabel"
                >
                    <label
                        :id="fieldsetId + '__BV_label_'"
                        :for="inputId"
                        class="d-block"
                        v-if="internalLabel"
                        :class="labelClass"
                    >
                        {{ internalLabel }}
                        <span
                            v-if="required"
                            class="text-danger"
                        >*</span>
                    </label>
                </slot>
            </template>

            <b-input-group>
                <b-form-input
                    ref="input"
                    :id="inputId"
                    :class="{'is-invalid' : errors.length > 0}"
                    :label="internalLabel"
                    v-model="internalValue"
                    @change="dispatchEvent('change', internalValue)"
                    :placeholder="internalPlaceholder"
                    :data-cy="internalTestId"
                    v-bind="$attrs"
                    autocomplete="off"
                    :type="showPassword ? 'text' : 'password'"
                />

                <b-input-group-append>
                    <b-button
                        variant="default"
                        @click="showPassword = !showPassword"
                        size="sm"
                        :class="{'is-invalid' : errors.length > 0}"
                    >
                        <EyeOffIcon
                            v-if="showPassword"
                            size="1.5x"
                        />
                        <EyeIcon
                            v-if="!showPassword"
                            size="1.5x"
                        />
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>

    </validation-provider>
</template>

<script>
import * as _ from 'lodash-es'

import companyMixin from "@state/company";
import FormFieldBase from "../mixins/Base";
import { ValidationProvider } from "vee-validate";

import { EyeIcon, EyeOffIcon } from "@vue-hero-icons/solid";

import { format, parse } from "@/plugins/i18n";

/**
 * Componente que encapsula o b-form-datepicker.
 * @see https://bootstrap-vue.org/docs/components/form-datepicker
 */
export default {
    name: "form-field-password",
    mixins: [companyMixin, FormFieldBase],
    components: {
        ValidationProvider,
        EyeIcon,
        EyeOffIcon,
    },
    data() {
        return {
            showPassword: false,
        };
    },
};
</script>
<!--
<docs>
```vue
  <template>
    <form-field-password v-model="inputValue" name="field-name" />
  </template>

  <script>
    export default {
      data() {
        return { inputValue: '' };
      }
    }
  </script>
```
</docs>
-->
