<template>
  <section id="list" data-scroll-to="list">
    <h4 class="mb-4" v-if="title">{{ title }}</h4>
    <div class="card-list">
      <h5 class="mb-4 text-center" v-if="subtitle">{{ subtitle }}</h5>

      <ul class="ml-5" v-if="items">

        <li v-for="(item, index) in items" :key="index">{{ item.text }}</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
props: [
  'title',
  'subtitle',
  'items'
],
data() {
  return {
  };
},
methods: {},
};
</script>