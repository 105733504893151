<template>
  <section id="vagas-agrupadas" data-scroll-to="grouped-vacancies">
    <div class="mb-6" v-if="searchable">
      <vacancy-search-bar @search="search" />
    </div>
    <div class="d-flex justify-content-between mt-6">
      <h4 v-if="title">
        {{ title }}
      </h4>
      <a class="float-right d-none d-md-block" href="/search">{{
        $t("vacancy.actions.seeAllVacancies")
      }}</a>
    </div>
    <div
      class="d-flex flex-column flex-md-row flex-wrap justify-content-between no-gutters"
      v-if="groupedList"
    >
      <div
        class="col-md-4 py-2 card-group pr-md-2"
        v-for="(items, index) in groupedList"
        :key="index"
      >
        <vacancy-group :items="items" :title="index" />
      </div>
    </div>
    <div v-else>
      <div
        class="vacancy-card card d-flex align-items-center justify-content-center"
      >
        <div class="card-body">
          <h6 class="text-subdued">{{ $t("vacancy.no-vacancy") }}</h6>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useVacancies } from "@state/vacancy/vacancies";
import VacancyGroup from "@blocks/vacancy/VacancyGroup.vue";
import VacancySearchBar from "@blocks/vacancy/VacancySearchBar.vue";

export default {
  mixins: [companyMixin],
  components: {
    VacancyGroup,
    VacancySearchBar,
  },
  setup() {
    const vacancies = useVacancies();

    return {
      vacancies,
    };
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      groupedList: [],
    };
  },
  mounted() {
    this.vacancies
      .vacancyHomeSectionsSearch({})
      .then((response) => {
        if (_.size(response) > 1) {
          this.groupedList = response;
        } else {
          this.groupedList = null;
        }
      });
  },
  methods: {
    openVacancy(id) {
      this.$router.push({
        name: "vacancy-show",
        params: { vacancy_id: id },
      });
    },
    search() {
      this.$router.push({
        name: "vacancy-search",
        query: {
          text: this.vacancies.conditions.text.trim(),
          location: this.vacancies.conditions.location.trim(),
        },
      });
    },
  },
};
</script>
