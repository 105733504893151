<template>
  <b-card no-body :data-cy="testId" v-bind="$attrs">
    <!-- :body-class="bodyClass" -->
    <slot
      name="header"
      :append="append"
      :label="label"
      :test-id="testId + '-append'"
      v-if="!noHeader"
    >
      <b-card-header
        header-tag="header"
        class="d-flex w-100 justify-content-between align-items-center"
      >
        <h5 class="mb-1">
          {{ label }}
        </h5>
        <b-button-group size="sm" v-if="!flat">
          <b-button
            variant="primary"
            @click="append()"
            :data-cy="testId + '-append'"
          >
            <b-icon icon="plus" />
          </b-button>
        </b-button-group>
      </b-card-header>
    </slot>
    <b-list-group flush>
      <b-list-group-item
        v-for="(item, counter) in internalValue"
        :key="item.index"
        class="flex-column align-items-start"
      >
        <div
          class="d-flex w-100 justify-content-between flex-row-reverse"
          v-if="!flat"
        >
          <b-button-group size="sm">
            <b-button variant="outline-danger" @click="remove(counter)">
              <b-icon icon="trash" />
            </b-button>
          </b-button-group>
          <h5 class="mb-1" v-if="showTitle">Item #{{ counter + 1 }}</h5>
        </div>
        <template v-if="!flat">
          <slot
            name="loop"
            :inputId="inputId"
            :fields="parsedFields"
            :model="item"
            :counter="counter"
            :test-id="testId"
          >
            <div
              v-for="(component, component_name) in parsedFields"
              :key="inputId + '-' + counter + '-' + component_name"
            >
              <slot
                name="item"
                :vid="vid"
                :testId="testId"
                :counter="counter"
                :item="item"
                :component="component"
                :component_name="component_name"
                :attrs="componentAttrs(component, component_name)"
              >
                <component
                  :is="component.is"
                  :item="item"
                  :name="testId + '.' + component_name"
                  :test-id="testId + '.' + component_name + '-' + counter"
                  v-model="item[component_name]"
                  v-bind="componentAttrs(component, component_name)"
                />
              </slot>
            </div>
          </slot>
        </template>
        <template v-else>
          <!-- <div
                        v-for="(component, name) in fields"
                         :key="inputId + '-' + index + '-' + name"
                    >
                    {{ internalValue }}
                    {{ item }}
                    {{ internalValue[index] }}
                    </div> -->

          <component
            v-for="(component, component_name) in parsedFields"
            :is="component.is"
            :key="inputId + '-' + component_name"
            :test-id="testId + '-' + component_name"
            :vid="vid + '-' + component_name"
            v-model="internalValue[counter]"
            v-bind="componentAttrs(component, component_name)"
            autocomplete="off"
          />
        </template>
      </b-list-group-item>
    </b-list-group>
    <slot name="footer" :append="append" :test-id="testId + '-append'"> </slot>
  </b-card>
</template>

<script>
//import { ValidationProvider } from 'vee-validate'

import FormFieldBase from "../mixins/Base";

import {
  FormFieldInput,
  FormFieldUploadAvatar,
  FormFieldSelect,
  FormFieldTextarea,
  FormFieldSwitch,
  FormFieldRadio,
  FormFieldDate,
  FormFieldPhone,
  FormFieldCurrency,
  FormFieldSpinbutton,
  FormFieldBarpicker,
  FormFieldUpload,
  //FormFieldList,
  FormFieldImage,
  FormFieldImages,
} from "@components/forms";

import * as _ from "lodash-es";
import { BButtonGroup } from "bootstrap-vue";

export default {
  mixins: [FormFieldBase],
  components: {
    BButtonGroup,
    FormFieldUploadAvatar,
    FormFieldInput,
    FormFieldSelect,
    FormFieldTextarea,
    FormFieldSwitch,
    FormFieldRadio,
    FormFieldDate,
    FormFieldPhone,
    FormFieldCurrency,
    FormFieldSpinbutton,
    FormFieldBarpicker,
    FormFieldUpload,
    //FormFieldList,
    //FormFieldImage,
    //FormFieldImages
  },
  props: {
    bodyClass: {
      required: false,
    },
    fields: {
      type: Array | Object,
      required: true,
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    insertMode: {
      type: String,
      required: false,
      default: "append",
      validator: (value) => ["prepend", "append"].includes(value),
    },
    noHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    textResolver: {
      type: Function,
      required: false,
      default: function (name, type) {
        return this.$t(`${name}.${type}`);
      },
    },
  },
  computed: {
    parsedFields() {
      return _.mapValues(this.fields, (item, name) =>
        this.componentAttrs(item, name)
      );
    },
    componentAttrs() {
      return (component, name) => {
        if (this.textResolver) {
          /*
                    _each(
                        ['label', 'placeholder', 'description'],
                        (field) => component[field] = component[field] || this.textResolver(name, field)
                    );
                    */
        }
        //component['test-id'] = [this.testId, name].join('-');
        return component;
      };
    },
  },
  /* NEED TO SET A INTERNAL INDEX, TO USE AS :key for v-for */
  created() {
    for (let i in this.internalValue) {
      this.$set(this.internalValue[i], "index", i);
    }
  },

  methods: {
    append() {
      let skel = _.mapValues(this.fields, (value, key, object) => {
        if (_.has(value, "default")) {
          return value.default;
        }
        return "";
      });

      skel.index = this.internalValue.length;

      if (this.insertMode == "prepend") {
        this.internalValue.unshift(skel);
      } else {
        this.internalValue.push(skel);
      }
    },
    remove(index) {
      this.internalValue.splice(index, 1);
    },
  },
};
</script>
