<template>
  <section data-scroll-to="placeholder">
    <h4 v-if="title">{{ title }}</h4>

    placeholder
  </section>
</template>

<script>

export default {
  props : {
    title : {
      type : String,
      required: false,
    }
  }
};
</script>