import { defineStore } from 'pinia'
//import CompanyService from '@services/company/Company'
import Http from '@/plugins/axios';
import { useDomain } from '@state/domain/domain'
import * as _ from 'lodash-es'

const FORM = '/company/form';

export const useCompanyForm = defineStore('company.form', {
    // other options...
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            domain: useDomain(),
            forms: {
                curriculum: {},
                express: {},
                admissional: {},
                application: {},
                esocial: {}
            },
            promises: {

            },
        }
    },
    getters: {
        curriculum() {
            return this.forms['curriculum']
        },
        express() {
            return this.forms['express']
        },
        admissional() {
            return this.forms['admissional']
        },
        application() {
            return this.forms['application']
        },
        esocial() {
            return this.forms['esocial']
        },
    },
    actions: {
        async load(form_name, load_domains = false, cache = true) {
            if (cache && this.promises[form_name]) {
                return this.promises[form_name];
            } else if (cache && !_.has(this.forms, form_name) && !_.isEmpty(this.forms[form_name])) {
                return Promise.resolve({
                    data: this.forms[form_name]
                });
            } else {
                return this.promises[form_name] = Http.get(`${FORM}/${form_name}`).then(({ data }) => {
                    this.forms[form_name] = data;

                    if (load_domains) {
                        this.domains(form_name)
                    }

                    return data;
                })
            }
        },
        async domains(form_name) {
            const domainStore = useDomain()
            let innerDomains = _.reduce(this.forms[form_name], (reducer, section, section_key) => {

                let domains2 = _.reduce(section, (reducer2, field, field_key) => {
                    if ((_.has(field, 'options') && _.isString(field.options))) {
                        reducer2[field_key] = field.options.replace('domain.', '');
                    }
                    return reducer2;
                }, {});
                _.assign(reducer, domains2);
                return reducer;
            }, {})

            for (let i in innerDomains) {

                domainStore.load(innerDomains[i]);
            }
        },
        async fields(form_name, load_domains = true, ...fields) {
            await this.load(form_name);

            return new Promise((resolve, reject) => {
                const domainStore = useDomain()

                if (_.isEmpty(fields)) {
                    let base_sections = _.omit(this.forms[form_name], 'section')

                    for (let section in base_sections) {

                        let base_fields = _.keys(base_sections[section]);
                        for (let i in base_fields) {
                            fields.push(section + '.' + base_fields[i]);
                        }
                    }
                }

                // TRANSFORM WILDCARDS
                const new_fields = _.transform(fields, (result, value, key) => {
                    if (value.endsWith('.*')) {
                        let section_key = value.replace('.*', '');
                        _.each(_.keys(_.get(this.forms[form_name], section_key)), (value) => {
                            result.push([section_key, value].join('.'))
                        });
                    } else {
                        result.push(value)
                    }

                }, []);

                const result = _.transform(new_fields, (result, field_name) => {
                    let field = _.get(this.forms[form_name], field_name);

                    if (!field) {
                        return true;
                    }
                    if (field.calculated) {
                        _.set(result, field_name, field);
                        return true;
                    }

                    if (_.has(field, 'show') && !field.show) {
                        return true;
                    }
                    if (_.has(field, 'calculated') && field.calculated) {
                        _.set(result, field_name, field);
                        return true;
                    }

                    // PARSING COMPONENT NAME
                    field.is = _.has(field, 'is') ? field.is : null

                    // PARSING RULES
                    if (!_.has(field, 'rules')) {
                        field.rules = {};
                    }

                    if (field.required) {
                        field.rules.required = true;
                    }

                    // PARSING OPTIONS
                    if (_.has(field, 'options') && _.isString(field.options)) {
                        if (load_domains) {
                            domainStore.load(field.options.replace('domain.', ''));
                        }
                        field.options = _.get(this, field.options);
                    }

                    field.calculated = true;
                    _.set(result, field_name, field);

                    return true;
                }, {});

                resolve(result)
            });
        }
    }
});
