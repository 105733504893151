<template>
    <div>
        <form-field-radio
            name="curriculum.education.has_schooling_level"
            v-model="curriculum.education.has_schooling_level"
            :options="domain.domains.has_letter_attribute"
            v-bind="section.fields.has_schooling_level"
            autocomplete="off"
            class="mt-4"
          />
        <form-set-academic
            v-if="section.academic.show && curriculum.education.has_schooling_level == 'y'"
            name="curriculum.academic"
            v-model="curriculum.academic"
            :fields="section.academic.fields"
            section="academic"
            autocomplete="off"
            :title="$t('curriculum.sections.education.academic')"
            class="profile-subsection"
        >
            <template #header="{ label }">
                <h4 v-if="label">{{ label }}</h4>
            </template>
        </form-set-academic>

        <form-field-radio
            name="curriculum.education.has_additional_training"
            v-model="curriculum.education.has_additional_training"
            :options="domain.domains.has_letter_attribute"
            v-bind="section.fields.has_additional_training"
            autocomplete="off"
            class="mt-4"
          />

        <form-set-training
            v-if="section.training.show && curriculum.education.has_additional_training == 'y'"
            name="curriculum.training"
            v-model="curriculum.training"
            :fields="section.training.fields"
            section="training"
            :title="$t('curriculum.sections.education.training')"
            class="profile-subsection"
        >
            <template #header="{ label }">
                <h4 v-if="label">{{ label }}</h4>
            </template>
        </form-set-training>

        <form-field-radio
            name="curriculum.education.has_languages"
            v-model="curriculum.education.has_languages"
            :options="domain.domains.has_letter_attribute"
            v-bind="section.fields.has_languages"
            autocomplete="off"
            class="mt-4"
          />

        <form-set-languages
            v-if="section.languages.show && curriculum.education.has_languages == 'y'"
            name="curriculum.languages"
            v-model="curriculum.languages"
            :fields="section.languages.fields"
            section="languages"
            :title="$t('curriculum.sections.education.languages')"
            class="profile-subsection"
        >
            <template #header="{ label }">
                <h4 v-if="label">{{ label }}</h4>
            </template>
        </form-set-languages>

        <form-field-radio
            name="curriculum.education.has_computer_knowledge"
            v-model="curriculum.education.has_computer_knowledge"
            :options="domain.domains.has_letter_attribute"
            v-bind="section.fields.has_computer_knowledge"
            class="mt-4"
          />
        <form-set-computing
            v-if="section.computing.show && curriculum.education.has_computer_knowledge == 'y'"
            name="curriculum.computing"
            v-model="curriculum.computing"
            :fields="section.computing.fields"
            section="computing"

            :title="$t('curriculum.sections.education.computing')"
            class="profile-subsection"
        >
            <template #header="{ label }">
                <h4 v-if="label">{{ label }}</h4>
            </template>
        </form-set-computing>
    </div>
</template>

<script>
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { useCurriculum } from '@state/user/curriculum';
import FormSetAcademic from '@components/forms/set/Academic.vue';
import FormSetTraining from '@components/forms/set/Training.vue';
import FormSetLanguages from '@components/forms/set/Languages.vue';
import FormSetComputing from '@components/forms/set/Computing.vue';

export default {
  components: {
    FormSetAcademic,
    FormSetTraining,
    FormSetLanguages,
    FormSetComputing,
    ...FormComponents
  },
  setup() {
    const curriculum = useCurriculum()
    const domain = useDomain();

    return {
      // you can return the whole store instance to use it in the template
      domain,
      curriculum
    }
  },
  props : {
    section : {
      type : Object,
      required: true
    }
  },

};
</script>
