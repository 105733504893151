export default {
    "BR" : {
        cellphone: "(##) #####-####",
        homephone: "(##) #####-####",
        currency : {
            currency: 'BRL',
            symbol: 'R$ ',
            autoDecimalDigits : true,
            hideCurrencySymbolOnFocus : false,
            hideGroupingSeparatorOnFocus : false
        }
    },
}
