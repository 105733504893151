import { useVacancies } from '@state/vacancy/vacancies'

export default {
    data() {
      return {
        vacancies : useVacancies(),
      }
    },
}

export { useVacancies }
