<template>
  <validation-provider
    ref="validator"
    #default="{ errors, required }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
    slim
  >
    <b-form-group
      :id="fieldsetId"
      :name="internalVid"
      :description="internalDescription"
      :label-for="inputId"
      :state="errors.length == 0"
    >
      <!-- :label="internalLabel" -->
      <template #label>
        <slot name="label" :inputId="inputId" :label="internalLabel">
          <label
            :id="fieldsetId + '__BV_label_'"
            :for="inputId"
            class="d-block"
            :class="labelClass"
            v-if="internalLabel"
          >
            {{ internalLabel }}
            <span v-if="required" class="text-danger">*</span>
          </label>
        </slot>
      </template>

      <input
        :data-cy="internalTestId + '-proxy'"
        type="hidden"
        v-model="internalValue"
      />

      <span :data-cy="internalTestId">
        <!-- @input="$emit('input', internalValue)"
                    @change="$emit('input', internalValue)" -->
        <b-form-checkbox
          :id="inputId"
          class="w-full"
          :class="{ 'is-invalid': errors.length > 0 }"
          :label="internalLabel"
          v-model="internalValue"
          @input="dispatchEvent('change', internalValue)"
          :placeholder="internalPlaceholder"
          v-bind="$attrs"
          :value="checkedValue"
          switch
          autocomplete="off"
        >
          <!-- <template #default>
                        <slot name="default">{{ internalLabel }}</slot>
                    </template> -->
        </b-form-checkbox>
      </span>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormFieldBase from "../mixins/Base";
/**
 * @todo Mesclar componentes checkbox / Switch, pois são muito semelhantes
 * @see https://bootstrap-vue.org/docs/components/form-checkbox#switch-style-checkboxes
 */

/**
 * Componente que encapsula o b-form-switch.
 * @see https://bootstrap-vue.org/docs/components/form-switch
 */
export default {
  name: "form-field-switch",
  mixins: [FormFieldBase],
  components: {
    ValidationProvider,
  },
  props: {
    /**
     * Usado caso queria aplicar outros valores no campo de switch
     */
    checkedValue: {
      required: false,
      default: true,
    },
  },
};
</script>
<!--
<docs>
```vue
  <template>
    <form-field-switch v-model="inputValue" name="teste" />
  </template>

  <script>
    export default {
      data() {
        return { inputValue: false };
      }
    }
  </script>
```
</docs>
-->
