<template>
  <validation-provider
    :name="label"
    :vid="vid"
    :rules="rules"
    v-if="is_loaded && hasFields"
    slim
  >
    <div class="" header-class="d-flex w-100 justify-content-between">
      <div
        class="card border-radius-base mt-2"
        v-for="(s, index) in computedValue"
        :key="index"
      >
        <div class="card-body">
          <div class="flex-row">
            <span
              class="title text-lg font-weight-semibold flex justify-content-between"
            >
              <p style="max-width: 85%">
                {{ getDomainLabel("languages", s.language_id) }}
              </p>
              <span>
                <PencilAltIcon
                  v-on:click="edit(s, index)"
                  class="text-primary cursor-pointer"
                />
                <TrashIcon
                  v-on:click="remove(s, index)"
                  class="text-primary cursor-pointer"
                />
              </span>
            </span>
            <div
              :class="!isMobile() ? 'd-flex' : null"
              class="justify-content-between align-items-start"
            >
              <bullet-list
                v-model="s.reading"
                :label="$t('curriculum.education.languages.reading.label')"
                :max="4"
                :editable="false"
                autocomplete="off"
              >
              </bullet-list>
              <bullet-list
                v-model="s.writing"
                :label="$t('curriculum.education.languages.writing.label')"
                :max="4"
                :editable="false"
                autocomplete="off"
              >
              </bullet-list>
              <bullet-list
                v-model="s.speaking"
                :label="$t('curriculum.education.languages.speaking.label')"
                :max="4"
                :editable="false"
                autocomplete="off"
              >
              </bullet-list>
              <bullet-list
                v-model="s.understanding"
                :label="
                  $t('curriculum.education.languages.understanding.label')
                "
                :max="4"
                :editable="false"
                autocomplete="off"
              >
              </bullet-list>
            </div>
          </div>
        </div>
      </div>
      <giga-button
        :label="$t('curriculum.actions.education.add_language')"
        @click="add"
      />
    </div>

    <b-modal
      ref="modal"
      id="education-academic-modal"
      size="lg"
      centered
      :title="$t('curriculum.actions.education.add_language')"
      @ok="append($event)"
      @cancel="clear"
      scrollable
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button
          class="border"
          variant="outline-primary"
          pill
          size="sm"
          @click="cancel()"
        >
          {{ $t("common.cancel") }}
        </b-button>
        <b-button variant="primary" pill size="sm" @click="ok()">
          {{ $t("common.save") }}
        </b-button>
      </template>
      <validation-observer ref="entityRules" tag="div" class="">
        <form-set-generic
          section="education.languages"
          v-model="new_entity"
          :fields="internalFields"
          :field-props="{
            untracked: true,
          }"
        >
        </form-set-generic>
      </validation-observer>
    </b-modal>
  </validation-provider>
</template>

<script>
import FormFieldSet from "../mixins/Set";
import FormFieldBase from "../mixins/Base";
import { useTheme } from "@state/system/theme";
import * as FormComponents from "@components/forms";
import BulletList from "@components/ui/BulletList.vue";
import TimelineItem from "@components/ui/TimelineItem.vue";
import GigaButton from "@components/buttons/GigaButton.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { PencilAltIcon, TrashIcon } from "@vue-hero-icons/outline";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [FormFieldBase, FormFieldSet],
  components: {
    TrashIcon,
    GigaButton,
    BulletList,
    TimelineItem,
    PencilAltIcon,
    FormSetGeneric,
    ...FormComponents,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const theme = useTheme();

    return {
      isMobile: theme.isMobile,
    };
  },
};
</script>
