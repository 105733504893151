import Vue from 'vue'
import { defineStore } from 'pinia'
import Http from '@/plugins/axios'; 

const READ   = '/profile/applications';
const LIST   = '/profile/applications';
const ENROLL =  '/profile/applications/enroll';

export const useApplications = defineStore('application', {
    // other options...
    state: () => {
        return {
            list : [],
            full : {}
        }
    },
    actions: {
        load(conditions) {
            return Http.post(LIST, conditions).then((response) => {
                this.$patch((state) => {
                    state.list.splice(response.data.length)

                    for (let i in response.data) {
                        Vue.set(state.list, i, response.data[i]);
                    }
                });
                return this.list;
            })
        },
        read(application_id) {
            return Http.get(`${READ}/${application_id}`).then((response) => {
                this.$patch((state) => {
                    Vue.set(state.full, application_id, response.data);
                });
                return this.full[application_id];
            })
        },
        enroll(vacancy_id){
            return Http.post(`${ENROLL}/${vacancy_id}`)
        }        

    }
});