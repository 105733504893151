<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <label :id="fieldsetId + '__BV_label_'" :for="inputId" class="d-block" v-if="internalLabel">
                        {{ internalLabel }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </slot>
            </template>


            <b-form-spinbutton
                :id="inputId"
                class="w-full"
                :label="internalLabel"
                v-model="internalValue"
                @change="dispatchEvent('change', internalValue)"
                :placeholder="internalPlaceholder"
                :data-cy="internalTestId"
                 v-bind="$attrs"
                 autocomplete="off"
            >

            </b-form-spinbutton>

            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>

    </validation-provider>
</template>

<script>
import FormFieldBase from '../mixins/Base'

import { ValidationProvider } from 'vee-validate'

/**
 * Componente que encapsula o componente form-spinbutton
 *
 * @see https://bootstrap-vue.org/docs/components/form-spinbutton
 */
export default {
    name: 'form-field-spinbutton',
    mixins : [
        FormFieldBase
    ],
    components: {
        ValidationProvider
    },
}
</script>
<!--
<docs>
```vue
<template>
    <div class="container">
        <form-field-spinbutton v-model="inputValue" name="styleguide.spinbutton.field" :step="step" :min="min" :max="max" />
        <span>Valor Selecionado: {{ inputValue }}</span>

        <div style="display: flex; justify-content: space-between;">
            <form-field-spinbutton v-model="step" name="styleguide.spinbutton.step" style="width: 30%" />
            <form-field-spinbutton v-model="min" name="styleguide.spinbutton.min" style="width: 30%" />
            <form-field-spinbutton v-model="max" name="styleguide.spinbutton.max"  style="width: 30%" max="200" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
    return {
        inputValue: 1,
        step : 1,
        min : 1,
        max : 100
    };
    }
}
</script>
```
</docs>
-->
