<template>
  <validation-observer ref="completeEnrollRules">
    <form-set-generic
      v-model="curriculum.personal"
      form="curriculum"
      section="personal"
      :fields="fields"
    />

    <form-set-generic
      v-model="curriculum.address"
      form="curriculum"
      section="address"
      :fields="fields"
    />

    <form-set-generic
      v-model="curriculum.professional"
      form="curriculum"
      section="professional"
      :fields="fields"
    />
  </validation-observer>
</template>
<script>
import * as _ from "lodash-es";
import { useCompanyForm } from "@state/company/form";
import { useCurriculum } from "@state/user/curriculum";
import { ValidationObserver } from "vee-validate";

import FormSetPcd from "@components/forms/set/Pcd.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";

import Personal from "@views/profile/curriculum/personal/Personal.vue";
import Education from "@views/profile/curriculum/education/Education.vue";
import Professional from "@views/profile/curriculum/professional/Professional.vue";
import OtherInformation from "@views/profile/curriculum/additional/OtherInformation.vue";

export default {
  components: {
    ValidationObserver,
    FormSetPcd,
    FormSetGeneric,
    Personal,
    Education,
    Professional,
    OtherInformation,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    fields: {
      type: Array | Object,
      required: true,
    },
  },
  data() {
    return {
      internalFields: null,
    };
  },
  setup(props) {
    const forms = useCompanyForm();
    const curriculum = useCurriculum();

    return {
      forms,
      curriculum,
    };
  },
  created() {
    // this.forms.fields(this.name, false, ...this.fields).then((fields) => {
    //     this.internalFields = fields
    // })
  },
  computed: {
    formSections() {
      /*
            return {
                'personal' : {
                    'name' : 'personal',
                    'component' : 'Personal',
                    'show' : true,
                    'pcd' : {
                        'show' : true,
                        'fields' : [
                            'personal.special_needs',
                            'personal.special_needs_item',
                            'personal.special_needs_details',
                            'personal.special_needs_file',
                        ],
                    },
                }
            };
*/
      return _.pickBy(this.forms[this.name].sections, "show");
    },
  },
};
</script>
