<template>
    <!-- O componente disc-factors-bar recebe o resultado dos fatores disc e retorna porcentagens e larguras das 4 barras -->
    <div class="disc-factors-bar--container">
        <div class="disc-factors-bar--wrap"
            v-b-toggle="UseCollapse == true ? 'collapse-' + letter : ''"
            v-for="(perc, letter) in percents" :style="{ width: perc + '%'}" >
            <div :class="['disc-factors-bar', 'disc-factors-bar--' + letter, perc <= minLabel ? 'min-label' : '']" :id="'disc-popover-' + letter"><span>{{ Math.round(perc) + "%" }}</span></div>
            <disc-factor-icon v-if="ShowFactorIcon == true" :factor="letter" />
        </div>
        <!-- POPOVERS -->
        <b-popover target="disc-popover-d" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-d">
                    <span v-if="percents.d >= highFactor">
                        {{ $t('disc.disc_card.high_dominance') }}
                    </span>
                    <span v-else>
                        {{ $t('disc.disc_card.low_dominance') }}
                    </span>
                </div>
            </template>
            <div v-if="percents.d >= highFactor">
                <div class="badge badge-disc-d rounded">{{ $t('disc.disc_card.competitive') }}</div>
                <div class="badge badge-disc-d rounded">{{ $t('disc.disc_card.direct') }}</div>
                <div class="badge badge-disc-d rounded">{{ $t('disc.disc_card.assertive') }}</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-d rounded">{{ $t('disc.disc_card.moderate') }}</div>
                <div class="badge badge-disc-d rounded">{{ $t('disc.disc_card.diplomatic') }}</div>
                <div class="badge badge-disc-d rounded">{{ $t('disc.disc_card.careful') }}</div>
            </div>
        </b-popover>

        <b-popover target="disc-popover-i" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-i">
                    <span v-if="percents.i >= highFactor">
                        {{ $t('disc.disc_card.high_influence') }}
                    </span>
                    <span v-else>
                        {{ $t('disc.disc_card.low_influence') }}
                    </span>
                </div>
            </template>
            <div v-if="percents.i >= highFactor">
                <div class="badge badge-disc-i rounded">{{ $t('disc.disc_card.confident') }}</div>
                <div class="badge badge-disc-i rounded">{{ $t('disc.disc_card.sociable') }}</div>
                <div class="badge badge-disc-i rounded">{{ $t('disc.disc_card.persuasive') }}</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-i rounded">{{ $t('disc.disc_card.reflective') }}</div>
                <div class="badge badge-disc-i rounded">{{ $t('disc.disc_card.reserved') }}</div>
                <div class="badge badge-disc-i rounded">{{ $t('disc.disc_card.skeptical') }}</div>
            </div>
        </b-popover>

        <b-popover target="disc-popover-s" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-s">
                    <span v-if="percents.s >= highFactor">
                        {{ $t('disc.disc_card.high_stability') }}
                    </span>
                    <span v-else>
                        {{ $t('disc.disc_card.low_stability') }}
                    </span>
                </div>
            </template>
            <div v-if="percents.s >= highFactor">
                <div class="badge badge-disc-s rounded">{{ $t('disc.disc_factors_bar.empathetic') }}</div>
                <div class="badge badge-disc-s rounded">{{ $t('disc.disc_card.patient') }}</div>
                <div class="badge badge-disc-s rounded">{{ $t('disc.disc_card.thoughtful') }}</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-s rounded">{{ $t('disc.disc_card.active') }}</div>
                <div class="badge badge-disc-s rounded">{{ $t('disc.disc_card.restless') }}</div>
                <div class="badge badge-disc-s rounded">{{ $t('disc.disc_card.flexible') }}</div>
            </div>
        </b-popover>

        <b-popover target="disc-popover-c" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-c">
                    <span v-if="percents.c >= highFactor">
                        {{ $t('disc.disc_card.high_conformity') }}
                    </span>
                    <span v-else>
                        {{ $t('disc.disc_card.low_conformity') }}
                    </span>
                </div>
            </template>
            <div v-if="percents.c >= highFactor">
                <div class="badge badge-disc-c rounded">{{ $t('disc.disc_card.detail_oriented') }}</div>
                <div class="badge badge-disc-c rounded">{{ $t('disc.disc_card.precise') }}</div>
                <div class="badge badge-disc-c rounded">{{ $t('disc.disc_card.prudent') }}</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-c rounded">{{ $t('disc.disc_card.bold') }}</div>
                <div class="badge badge-disc-c rounded">{{ $t('disc.disc_card.firm') }}</div>
                <div class="badge badge-disc-c rounded">{{ $t('disc.disc_card.impulsive') }}</div>
            </div>
        </b-popover>
    </div>

</template>

<script>
import DiscFactorIcon from './DiscFactorIcon.vue';
import FactorCalculation from './mixins/FactorCalculation.js';

export default {
    components: {
        DiscFactorIcon,
    },

    mixins: [
        FactorCalculation
    ],

    props: {
        UseCollapse: {
            type: Boolean,
            default: false
        },
        ShowFactorIcon: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            minLabel: 10
        }
    },
}
</script>

<style lang="scss">

    .min-label {
        span {
            visibility: hidden;
        }
    }

    $disc-color-d: #F73B3B;
    $disc-color-i: #F5B800;
    $disc-color-s: #52C01B;
    $disc-color-c: #5197E6;

    .disc-color-d {
        color: darken($disc-color-d, 10%);
    }

    .disc-color-i {
        color: darken($disc-color-i, 13%);
    }

    .disc-color-s {
        color: darken($disc-color-s, 10%);
    }

    .disc-color-c {
        color: darken($disc-color-c, 10%);
    }

    .disc-factors-bar--container {
        min-width: 210px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .disc-factors-bar--wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.not-collapsed {
            .disc-factors-bar {
                border: 2px solid rgb(0 0 0 / 25%);
                box-shadow: 1px 1px 3px rgb(0 0 0 / 12%);
            }
        }

        &.collapsed {
            .disc-factors-bar {
                cursor: pointer;
            }
        }
    }

    .disc-factors-bar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0 5px 0;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: bold;
    }

    .disc-factors-bar--d {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        color: #fff;
        background-color: $disc-color-d;
    }

    .disc-factors-bar--i {
        color: #fff;
        background-color: $disc-color-i;
    }

    .disc-factors-bar--s {
        color: #fff;
        background-color: $disc-color-s;
    }

    .disc-factors-bar--c {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        color: #fff;
        background-color: $disc-color-c;
    }

    .disc-popover--header {
        .disc-tag {
            margin-right: 2px;
        }
    }

    .badge {
        text-transform: uppercase;
    }

    .badge-disc-d {
        border: 1px solid $disc-color-d;
        background-color: lighten($disc-color-d, 37%);
        color: darken($disc-color-d, 10%)
    }

    .badge-disc-i {
        border: 1px solid $disc-color-i;
        background-color: lighten($disc-color-i, 51%);
        color: darken($disc-color-i, 10%)
    }

    .badge-disc-s {
        border: 1px solid $disc-color-s;
        background-color: lighten($disc-color-s, 51%);
        color: darken($disc-color-s, 10%)
    }

    .badge-disc-c {
        border: 1px solid $disc-color-c;
        background-color: lighten($disc-color-c, 35%);
        color: darken($disc-color-c, 10%)
    }
</style>
