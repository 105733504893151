import { defineStore } from "pinia";
import Http from "@/plugins/axios";

const CREATE_LGPD = "/profile/create-lgpd";
const READ_LGPD = "/profile/read-lgpd";

export const useLgpd = defineStore("lgpd", {
    // other options...
    state: () => {
        return {
            declaration_of_authenticity: true,
            data_holder: true,
            legal_agent_name: "",
            legal_agent_cpf: "",
            legal_agent_address: "",
            legal_agent_phone: "",
            legal_agent_email: "",
            legal_agent_document: "",
            legal_agent_representative_document: "",
            data_holder_name: "",
            data_holder_cpf: "",
            data_holder_phone: "",
            data_holder_email: "",
            data_holder_category: [],
            data_holder_additional_info: "",
            data_holder_document: "",
            confirm_treatment_data: null,
            confirm_access_data: null,
            hide_data: null,
            exercise_rights: [],
            data_request: "",
            result_request: [],
        };
    },
    actions: {
        async read(data) {
            return Http.get(READ_LGPD).then(({ data }) => {
                this.$state = Object.assign({}, data);
            });
        },
        async create() {
            return Http.post(CREATE_LGPD, this.$state);
        },
    },
});
