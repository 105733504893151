<template>
  <div
    class="d-flex align-items-center justify-content-center px-4 py-10 h-100"
  >
    <div
      v-if="!confirmated && !invalid_token"
      class="rounded card d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6 col-xl-3 col-lg-6 col-md-7 col-sm-9"
    >
      <b-spinner class="text-black" style="opacity: 0.5" />

      <h3 class="text-2xl font-weight-bolder m-0">
        {{ $t("confirm_communication.loading.title") }}
      </h3>
      <p class="text-subdued m-0">
        {{ $t("confirm_communication.loading.text") }}
      </p>
    </div>
    <div
      class="rounded card d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6 col-xl-3 col-lg-6 col-md-7 col-sm-9"
      v-else-if="confirmated"
    >
      <h3 class="text-2xl font-weight-bolder m-0">
        {{ $t("communication.request_sent.title") }}
      </h3>
      <p class="text-success m-0">
        {{ $t("communication.request_sent.text") }}
      </p>
    </div>
    <div
      class="rounded card d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6 col-xl-3 col-lg-6 col-md-7 col-sm-9"
      v-else-if="invalid_token"
    >
    <h3 class="text-2xl font-weight-bolder m-0">
        {{ $t("communication.invalid_token.title") }}
      </h3>
      <p class="text-danger m-0">
        {{ $t("communication.invalid_token.text") }}
      </p>
    </div>
  </div>
</template>

<script>
import Http from "axios";
import * as _ from "lodash-es";
import { useCurriculum } from "@state/user/curriculum";
import { ExclamationCircleIcon } from "@vue-hero-icons/solid";

export default {
  mixins: [],
  components: {
    ExclamationCircleIcon,
  },
  setup() {
    const curriculum = useCurriculum();

    return {
      curriculum,
    };
  },
  data() {
    return {
      confirmated: false,
      invalid_token: false,
    };
  },
  mounted() {
    if (!_.isEmpty(this.$route.params.hash)) {
      Http.post("/backend/communication/confirm-email", {hash: this.$route.params.hash})
        .then((response) => {
            if(response.data.success){

              this.confirmated = true;
            } else {
              this.invalid_token = true;
            }
        })
        .catch(() => {
            this.invalid_token = true;
        });
    }
  },
};
</script>
