import { defineStore } from "pinia";
import Http from "@/plugins/axios";

const LIST = "/profile/messages";
const MARK_AS_READ = "/profile/messages/read";
const REPLY = "/profile/messages/reply";

export const useMessages = defineStore("message", {
    state: () => {
        return {
            list: [],
        };
    },

    actions: {
        list(conditions) {
            return new Promise((resolve, reject) => {
                return Http.post(LIST, conditions)
                    .then(({ data }) => {
                        this.$patch((state) => state.list = data);
                        resolve(data);
                    })
                    .catch((err) => reject(err));
            });
        },

        markAsRead(id) {
            return Http.post(`${MARK_AS_READ}/${id}`);
        },

        reply(payload) {
            return Http.post(REPLY, payload);
        },
    },
});
