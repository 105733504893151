<template>
  <div
    class="d-flex align-items-center justify-content-center px-4 py-10 h-100"
  >
    <div
      class="rounded card d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6 col-xl-3 col-lg-6 col-md-7 col-sm-9"
    >
      <template>
        <b-spinner style="opacity: 0.5" variant="primary" />

        <h3 class="text-2xl font-weight-bolder m-0">
          {{ $t("redirecting.redirecting") }}
        </h3>
        <p class="text-subdued m-0">
          {{ $t("redirecting.please_wait") }}
        </p>
        <p>{{ $tc("redirecting.enroll_portal") + " " + publisherCompany }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { useCurriculum } from "@state/user/curriculum";

import { ExclamationCircleIcon } from "@vue-hero-icons/solid";

export default {
  mixins: [],
  components: {
    ExclamationCircleIcon,
  },
  props: {
    enrollUrl: {
      required: false,
      type: String,
    },
    publisherCompany: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const curriculum = useCurriculum();

    return {
      curriculum,
    };
  },
  mounted() {
    setTimeout(() => {
      window.location.href = this.enrollUrl;
    }, 1800);
  },
};
</script>
