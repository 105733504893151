<template>
  <b-form-input ref="inputRef" type="text" v-model="formattedValue" />
</template>

<script>
import { watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";

export default {
  props: {
    value: Number | String, // Vue 2: value
    options: Object,
  },
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput(
      props.options
    );

    watch(
      () => props.value,
      (value) => {
        setValue(value);
      }
    );

    return { inputRef, formattedValue };
  },
};
</script>