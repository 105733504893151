<template>
  <div>
    <form-field-select
      v-if="internalFields.special_needs && internalFields.special_needs.show"
      name="curriculum.personal.special_needs"
      v-model="internalValue['special_needs']"
      v-bind="internalFields.special_needs"
      autocomplete="off"
    />

    <form-field-textarea
      name="curriculum.personal.special_needs_details"
      v-if="hasPCD"
      v-model="internalValue.special_needs_details"
      v-bind="internalFields.special_needs_details"
      rules="max:200"
      autocomplete="off"
    />

    <form-field-upload
      v-if="hasPCD"
      name="curriculum.personal.special_needs_file"
      v-model="internalValue.special_needs_file"
      v-bind="internalFields.special_needs_file"
    />
  </div>
</template>
<script>
import * as _ from "lodash-es";
import FormFieldSet from "../mixins/Set";

export default {
  mixins: [FormFieldSet],
  props: {
    section: {
      type: String,
      required: false,
      default: "personal",
    },
  },
  computed: {
    hasPCD() {
      return (
        !_.isEmpty(this.value.special_needs) &&
        _.has(this.internalFields, "special_needs") &&
        this.internalFields.special_needs.show &&
        _.has(this.value, "special_needs") &&
        ["Não informado", "Nenhuma"].indexOf(this.value.special_needs) == -1
      );
    },
  },
};
</script>
