<template>
    <li class="d-flex mb-3" ref="timelineVerticalItem">
        <!-- <div class="mr-4"> -->
        
        <slot name="date"></slot>
        
        <slot name="circle">
            <div class="flex-shrink-1 mr-3 line-wrapper">
                <div class="rounded-circle" :class="getCircleClass" style="width:16px;height:16px">
                </div>
            </div>
        </slot>
        <!--
        <div v-if="typeBadge == 'evolution'" style="background: #D2E7F7; !important; border-radius: 20px; !important; height: max-content" class="flex-shrink-1 mr-3 line-wrapper">
            <span class="badge"  :style="{ width: minWidth + 'px' }">
                <slot name="badge"></slot>
            </span>
        </div>
        <div v-else class="flex-shrink-1 mr-3 line-wrapper">
            <span class="badge" :class="badgeClass" :style="{ width: minWidth + 'px' }">
                <slot name="badge"></slot>
            </span>
        </div>
        -->
        <div class="flex-fill content-wrapper">
            <slot name="content"></slot>
        </div>
    </li>
</template>

<script>
import * as _ from 'lodash-es'
export default {
    props:['label', 'badgeClass', 'circleClass', 'minWidth', 'typeBadge', 'status'],
    data() {
        return {
            statusMap : {
                'c' : 'bg-success',
                's' : 'bg-primary',
                'p' : 'bg-danger'
            }
        }
    },
    computed: {
        getCircleClass() {
            if (this.circleClass) {
                return this.circleClass
            }
            if (this.status) {
                return _.get(this.statusMap, this.status, 'bg-secondary');
            }

        }
    }
}

</script>
