<template>
  <validation-provider
    :name="label"
    :vid="vid"
    :rules="rules"
    v-if="is_loaded && hasFields"
    slim
  >
    <div
      class=""
      header-class="d-flex w-100 justify-content-between align-items-center"
    >
      <div
        class="card border-radius-base mt-2"
        v-for="(dp, index) in computedValue"
        :key="index"
      >
        <div class="card-body">
          <div class="flex-row">
            <span
              class="title text-lg font-weight-semibold flex justify-content-between"
            >
              <span v-if="dp.name">
                {{ $t("curriculum.dependents.name.label") + ": " + dp.name }}
              </span>
              <span v-else>{{ $t("common.dependent") }}</span>
              <span>
                <PencilAltIcon
                  v-on:click="edit(dp, index)"
                  class="text-primary cursor-pointer"
                />
                <TrashIcon
                  v-on:click="remove(dp, index)"
                  class="text-primary cursor-pointer"
                />
              </span>
            </span>
          </div>
          <span class="text-sm font-weight-medium" v-if="dp.kinship">
            {{
              $t("curriculum.dependents.kinship.label") +
              ": " +
              getDomainLabel("kinship", dp.kinship)
            }}
          </span>
          <br v-if="dp.kinship" />
          <span class="text-sm font-weight-medium" v-if="dp.birth_date">
            {{
              $t("curriculum.dependents.birth_date.label") +
              ": " +
              formatDate(dp.birth_date)
            }}
          </span>
          <br v-if="dp.birth_date" />
          <span class="text-sm font-weight-medium" v-if="dp.cpf">
            {{ $t("curriculum.dependents.cpf.label") + ": " + dp.cpf }}
          </span>
        </div>
      </div>

      <giga-button
        :label="$t('curriculum.actions.dependents.add_dependent')"
        @click="addNewDependent()"
      />
    </div>

    <b-modal
      ref="modal"
      id="admissional-dependents-modal"
      size="lg"
      centered
      :title="$t('curriculum.actions.dependents.add_dependent')"
      @ok="append($event)"
      @cancel="clear"
      scrollable
      no-close-on-backdrop
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button
          class="border"
          variant="outline-primary"
          pill
          size="sm"
          @click="cancel()"
        >
          {{ $t("common.cancel") }}
        </b-button>
        <b-button variant="primary" pill size="sm" @click="ok()">
          {{ $t("common.save") }}
        </b-button>
      </template>

      <validation-observer ref="entityRules" tag="div" class="">
        <form-set-generic
          section="dependents"
          v-model="new_entity"
          :fields="internalFields"
          autocomplete="off"
          :field-props="{
            untracked: true,
          }"
        />
      </validation-observer>
    </b-modal>
  </validation-provider>
</template>

<script>
import FormFieldSet from "../mixins/Set";
import FormFieldBase from "../mixins/Base";
import { isoToLocal } from "@/plugins/i18n";
import * as FormComponents from "@components/forms";
import GigaButton from "@components/buttons/GigaButton.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { PencilAltIcon, TrashIcon } from "@vue-hero-icons/outline";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [FormFieldBase, FormFieldSet],
  components: {
    ValidationObserver,
    ValidationProvider,
    FormSetGeneric,
    ...FormComponents,
    GigaButton,
    PencilAltIcon,
    TrashIcon,
  },
  setup() {
    return {
      formatDate: isoToLocal,
    };
  },

  methods: {
    addNewDependent() {
      this.clear();
      this.add();
    }
  }
};
</script>
