import { validate } from 'vee-validate';

import CpfValidate from './rules/cpf'

import * as _ from 'lodash-es'


export default async function (value, args) {
  const rules = {
    email: true,
    cpf: true
  };
  const result = await validate(value, rules, {
    bails: false
  });

  if (_size(result.errors) < _.size(rules)) {
    return true;
  }
  return false
}
