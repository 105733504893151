import Http from "@/plugins/axios";
import { defineStore } from "pinia";

const READ = "/company/config";
const SAVE_HOME_SECTIONS = "/company/save-home-sections";
const SAVE_THEME = "/company/save-theme";
const SAVE_LOGO_POSITION = "/company/save-logo-position";

export const useCompany = defineStore("company", {
    // other options...
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            config: {},
        };
    },
    getters: {
        currentCountry() {
            // GRAB FROM PORTAL CONFIG, OR FROM LOCALE
            return "BR";
        },
    },
    actions: {
        async loadData() {
            return Http.get(READ).then(({ data }) => {
                this.config = data;

                return data;
            });
        },
        async saveHomeSections(data) {
            return Http.post(SAVE_HOME_SECTIONS, data).then(({ response }) => {
                console.log(response);
            });
        },
        async saveTheme(theme) {
            return Http.post(SAVE_THEME, { theme: theme }).then(
                ({ response }) => {
                    console.log(response);
                }
            );
        },
        async saveLogoPosition(logo_position) {
            return Http.post(SAVE_LOGO_POSITION, {
                logo_position: logo_position,
            }).then(({ response }) => {
                console.log(response);
            });
        },
    },
});
