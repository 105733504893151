<template>
  <validation-provider
    ref="validator"
    class="form-field-input-dropdown"
    :class="{
      'is-mobile d-flex flex-column justify-content-between': isMobile(),
      'is-focused': isFocused,
    }"
    #default="{ errors, required }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
    slim
  >
    <div
      class="d-flex align-items-center"
      :class="{ 'px-4': isMobile() }"
      ref="inputContainer"
    >
      <a
        v-if="isMobile() && isFocused"
        class="mr-4 text-default"
        @click="looseFocus"
      >
        <ArrowLeftIcon size="1.5x" />
      </a>

      <b-input-group>
        <b-input-group-prepend is-text>
          <SearchIcon size="1x" />
        </b-input-group-prepend>

        <b-form-input
          ref="inputText"
          :placeholder="internalPlaceholder"
          v-model="internalValue"
          @keyup="onKeyUp"
          @change="dispatchEvent('change', internalValue)"
          autocomplete="off"
          @focus="focusDropdown"
          @blur="blurDropdown"
          :data-cy="internalTestId"
        />

        <b-input-group-append is-text>
          <a @click="internalValue = ''" v-if="!loadingState && internalValue">
            <XIcon size="1x" />
          </a>
          <!-- <span aria-hidden="true" class="multiselect-spinner"></span> -->
          <b-spinner
            label="Loading..."
            small
            variant="info"
            :class="{ hidden: !loadingState }"
          >
          </b-spinner>
        </b-input-group-append>
      </b-input-group>
    </div>

    <ul
      class="search-dropdown flex-column gap-2 px-4"
      :class="{ 'd-flex': showDropdown, 'd-none': !showDropdown }"
      ref="inputDropdown"
      v-if="!grouped"
    >
      <li
        v-for="(item, index) in options"
        :key="index"
        class="line"
        @click="selectOption(item.value)"
      >
        {{ item.label }}
      </li>
    </ul>

    <ul
      class="search-dropdown flex-column gap-2 px-4"
      :class="{ 'd-flex': showDropdown, 'd-none': !showDropdown }"
      ref="inputDropdown"
      v-if="grouped"
    >
      <li
        v-for="(items, group_name) in options"
        :key="group_name"
        class="border-bottom py-2 line"
      >
        <span
          class="font-semibold"
          @click="selectOption(group_name)"
          v-if="group_selectable"
        >
          {{ $t(group_name) }}
        </span>
        <span class="font-semibold" v-else>
          {{ $t(group_name) }}
        </span>
        <ul class="list-unstyled d-flex flex-column gap-2 px-2">
          <li
            class="line"
            v-for="(item, index) in items"
            :key="group_name + index"
            @click="selectOption(item.value)"
          >
            {{ item.label }}
          </li>
        </ul>
      </li>
    </ul>

    <div class="p-4 pb-0 border-top" v-if="isFocused && isMobile()">
      <slot name="footer" :close="looseFocus" />
    </div>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";
import FormFieldBase from "../mixins/Base";
import { ValidationProvider } from "vee-validate";
import { useTheme } from "@state/system/theme";
import { SearchIcon, XIcon } from "@vue-hero-icons/outline";
import { ArrowLeftIcon } from "@vue-hero-icons/solid";

/**
 * Componente que encapsula o b-form-input.
 * @see https://bootstrap-vue.org/docs/components/form-input
 */
export default {
  name: "form-field-input-dropdown",
  mixins: [FormFieldBase],
  components: {
    ValidationProvider,
    ArrowLeftIcon,
    SearchIcon,
    XIcon,
  },
  props: {
    search: {
      required: true,
    },
    options: {
      required: true,
    },
    grouped: {
      required: false,
      type: Boolean,
      default: false,
    },
    group_selectable: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forceHidden: true,
      isFocused: false,
      loadingState: false,
    };
  },
  computed: {
    computedOptions() {
      if (this.grouped) {
      } else {
        return this.options;
      }
    },
    showDropdown() {
      //if (this.$refs.inputText) {
      //}
      const status =
        !this.forceHidden &&
        _.size(this.options) > 0 &&
        this.$refs.inputText &&
        this.$refs.inputText.$el == document.activeElement;
      if (status) {
        this._updateDropdownSize();
        //this.$refs.inputDropdown.$el.clientWidth = this.$refs.inputContainer.$el.clientWidth;
      }
      return status;
    },
  },
  setup() {
    const theme = useTheme();

    return {
      isMobile: theme.isMobile,
    };
  },
  watch: {
    isFocused(value) {
      if (value) {
        document.body.classList.add("input-dropdown-focused");
      } else {
        document.body.classList.remove("input-dropdown-focused");
      }
    },
  },
  created() {
    window.addEventListener("click", this.validateDropdownFocus);
    window.addEventListener("resize", this._updateDropdownSize);
  },
  destroyed() {
    window.removeEventListener("click", this.validateDropdownFocus);
    window.removeEventListener("resize", this._updateDropdownSize);
  },
  methods: {
    validateDropdownFocus(ev) {
      if (_.indexOf(["line", "form-control"], ev.target.className) === -1) {
        //this.looseFocus();
      }
    },
    _updateDropdownSize() {
      this.$refs.inputDropdown.style.width =
        this.$refs.inputContainer.clientWidth + "px";
    },
    looseFocus() {
      this.isFocused = false;
      this.forceHidden = true;
    },
    onKeyUp($ev) {
      if (_.indexOf([27, 13, 37, 38, 39, 40], $ev.keyCode) === -1) {
        this.forceHidden = false;
        this.loadingState = true;

        this.search(this.internalValue).finally(() => {
          this.loadingState = false;
        });
      } else {
        this.looseFocus();
      }
    },
    focusDropdown() {
      this.forceHidden = false;
      this.isFocused = true;
    },

    blurDropdown($ev) {
      this.isFocused = false;
      if (
        _.isNull($ev.relatedTarget) ||
        $ev.relatedTarget == this.$refs.inputContainer.$el
      ) {
      } else {
        this.forceHidden = true;
        this.dispatchEvent("change", this.internalValue);
      }
    },
    selectOption(value) {
      this.internalValue = value;
      this.dispatchEvent("change", this.internalValue);
      //this.$refs.inputText.$el.blur();
      this.forceHidden = true;
    },
  },
};
</script>
<!--
<docs>
```vue
  <template>
    <form-field-input v-model="inputValue" name="field-name">Default Example Usage</form-field-input>
  </template>

  <script>
    export default {
      data() {
        return { inputValue: '' };
      }
    }
  </script>
```
</docs>
-->
