<template>
    <div v-if="avatarUrl || name" :style="containerStyle">
        <img
            :src="avatarUrl"
            :style="imgStyle"
            @load="renderLoaded"
            @error="renderAvatar"
            v-show="!imageError && avatarUrl" />

        <div style="position:relative;">
            <svg v-if="imageError || !avatarUrl" :style='svgStyle'>
                <text x='50%' y='50%' text-anchor='middle' dominant-baseline='central' :style='textStyle'>
                    {{ avatarLetter }}
                </text>
            </svg>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'selecty-letter-avatar',
        inheritAttrs: false,
        props: {
            size: {
                type: String,
                default: '40px'
            },
            rounded: {
                type: Boolean,
                default: true
            },
            name: {
                type: String,
                required: true
            },
            sizeFontPercent:{
                required: false,
                default: 0.65
            },
            iconClass : {
                required: false
            },
            avatarUrl : {
                type: String,
                required: false,
                default: ''
            }
        },
        data : () => {
            return {
                imageError: false
            }
        },
        computed: {
            imgStyle() {
                return {
                    'border-radius': '100%',
                    'background-repeat': 'no-repeat',
                    'background-size': '60%',
                    'background-position': 'center',
                    // 'width': 'fit-content',
                    'width': `${this.size}`,
                    'height': `${this.size}`,
                    'opacity' : this.backgroundImage ? 0.5 : 1.0
                }
            },
            containerStyle() {
                var style = {
                    'border-radius': '100%',
                    'background-repeat': 'no-repeat',
                    'background-size': '60%',
                    'background-position': 'center',
                    'width': 'fit-content'
                };
                if (this.backgroundImage) {
                    style['background-image'] = "url(" + this.backgroundImage +")";
                }
                return style;
            },


            avatarLetter() {

                let name = this.name != null && this.name != null && this.name != '' ? this.name.split(' ') : '';
                if(name.length > 1){
                    let lastName = name[name.length - 1].trim() != '' ? name[name.length - 1] : name.length > 2 ? name[2] : name[1];
                    return this.name[0].toUpperCase() + lastName[0].toUpperCase();
                }
                else{
                    return this.name && this.name.length > 0 ? this.name[0].toUpperCase(): '';
                }
            },
            svgStyle() {
                // use hsl to define the background color.
                const letterIndex = this.avatarLetter.charCodeAt() - 64;
                const hue = (360 / 26) * letterIndex;
                const backgroundColor = `hsl(${hue}, 68%, 48%)`;

                if(this.cvAvatar){
                    return {
                        width: `${this.size}`,
                        height: `${this.size}`,
                        borderRadius: this.rounded ? '100%' : '0',
                        background: backgroundColor,
                        opacity: this.backgroundImage != '' ? 0.2 : 1,
                        border: '7px solid #f4fbff'
                    };
                }
                else{
                    return {
                        width: `${this.size}`,
                        height: `${this.size}`,
                        borderRadius: this.rounded ? '100%' : '0',
                        background: backgroundColor,
                        opacity: this.backgroundImage != '' ? 0.2 : 1,
                    };
                }
            },
            textStyle() {
                let medida = this.size.indexOf('rem') != -1 ? 'rem' : 'px';
                let fontSize = parseFloat(this.size) * this.sizeFontPercent + medida;
                return {
                    fill: 'rgba(255, 255, 255, .7)',
                    fontFamily: "'Lucida Console', Monaco, monospace",
                    fontSize: `${fontSize}`
                };
            }
        },
        methods : {
            renderLoaded(e) {
                this.imageError = false;
            },
            renderAvatar(e) {
                this.imageError = true;
            }
        }
    };
</script>
