import { defineStore } from 'pinia'
import Vue from 'vue'
import Http from '@/plugins/axios'; // não precisa de .js

const READ = '/profile/dashboard';
const ACCEPT_TERMS ='/profile/accept-terms';
const TERMS_BY_USER = '/profile/terms-and-conditions-by-user';

export const useDashboard = defineStore('dashboard', {
    // other options...
    state: () => {
        return {
            data : {}
        }
    },
    actions: {
        read(application_id) {
            return Http.get(`${READ}`).then((response) => {
                this.$patch((state) => {
                    for (let i in response.data) {
                        Vue.set(this.data, i, response.data[i]);
                    }
                });
                return this.$state;
            })
        },
        async termsAndConditionsByUser() {
            return Http.post(TERMS_BY_USER);
        },       
        async acceptTerms(){
            return Http.post(ACCEPT_TERMS);
        } 
    }
});