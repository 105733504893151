<template>
  <div class="card-body p-0" v-if="filteredList.length > 0">
    <vacancy-card
      class="p-2 my-2"
      v-for="vacancy in filteredList"
      :key="vacancy.id"
      @click="openVacancy(vacancy)"
      :item="vacancy"
      type="list"
    />
  </div>
  <div v-else>
    <div
      class="vacancy-card card d-flex align-items-center justify-content-center"
    >
      <div class="card-body">
        <h6 class="text-subdued">{{ $t("vacancy.no-vacancy") }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useVacancies } from "@state/vacancy/vacancies";
import { useTheme } from "@state/system/theme";
import VacancyCard from "@blocks/vacancy/VacancyCard.vue";

import { ClockIcon, UsersIcon } from "@vue-hero-icons/outline";

export default {
  mixins: [companyMixin],
  components: {
    VacancyCard,
    ClockIcon,
    UsersIcon,
  },
  setup() {
    const vacancies = useVacancies();
    const theme = useTheme();

    return {
      vacancies,
      isMobile: theme.isMobile,
    };
  },
  props: {
    vacanciesList: {
      required: true,
      type: Array,
    },
  },
  computed: {
    filteredList() {
      return _.map(this.vacanciesList, function (vacancy) {
        var str = "";

        if (vacancy.total_vacancies) {
          if (vacancy.total_vacancies == 1) {
            str += vacancy.total_vacancies + " vaga para ";
          } else if (vacancy.total_vacancies > 1) {
            str += vacancy.total_vacancies + " vagas para ";
          }
        } else {
          str += "Vaga para ";
        }

        str += vacancy.occupation;

        var location = "";
        if (vacancy.city) {
          location += vacancy.city + (vacancy.state ? ", " : "");
        }

        if (vacancy.state) {
          location += vacancy.state + (vacancy.country ? " - " : "");
        }

        if (location.length > 0) {
          str += " em " + location;
        }

        var publication_date = false;
        if (vacancy.published_date) {
          var date = new Date(vacancy.published_date).getTime();

          var today = new Date().getTime();
          var difference = today - date;

          var final_string =
            Math.ceil(difference / (1000 * 3600 * 24)) > 1 ? " dias" : " dia";

          publication_date =
            Math.ceil(difference / (1000 * 3600 * 24)) + final_string;
        }

        return _.merge(vacancy, {
          id: vacancy.id,
          text: str,
          pcd: vacancy.is_special_needs_job,
          publication_date: publication_date,
        });
      });
    },
  },
  methods: {
    openVacancy(item) {
      this.$router.push({
        name: "vacancy-show",
        params: {
          vacancy_id: item.id,
          slug: item.slug
        },
      });
    },
  },
};
</script>
