<template>
  <validation-provider
    ref="validator"
    class="form-field-button-dropdown"
    :class="{ 'is-mobile': isMobile(), 'is-focused': isFocused }"
    #default="{ errors, required }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
    slim
  >
    <!-- :class="{'is-mobile d-flex flex-column justify-content-between': isMobile(), 'is-focused': isFocused}" -->
    <b-form-group
      :id="fieldsetId"
      :name="internalVid"
      :description="internalDescription"
      :label="internalLabel"
      :label-for="inputId"
      :state="errors.length == 0"
      class="search-bar-item"
    >
      <!-- <input :data-cy="internalTestId + '-proxy'" type="hidden" v-model="internalValue" /> -->

      <template #label>
        <slot name="label" :inputId="inputId" :label="internalLabel">
          <label
            :id="fieldsetId + '__BV_label_'"
            :for="inputId"
            class="d-block"
            v-if="internalLabel"
          >
            {{ internalLabel }}
            <span v-if="required" class="text-danger">*</span>
          </label>
        </slot>
      </template>

      <!-- @ficou bem horroroso mesmo, separando mobile de desktop. Refactorar e melhorar este componente a posterior -->
      <template v-if="isMobile()">
        <div tabindex="0" ref="inputContainer">
          <b-button variant="chip" size="xs" @click="focusDropdown">
            <CheckIcon size="1.25x" v-if="!noOptionSelected" />
            <span class="flex-grow" :class="{ 'text-muted': noOptionSelected }">
              {{ selectedValue }}
            </span>
            <ChevronDownIcon size="1.25x" v-if="options" />
          </b-button>

          <div
            class="search-dropdown flex-column gap-2 px-2"
            :class="{ 'd-flex': showDropdown, 'd-none': !showDropdown }"
            ref="inputDropdown"
          >
            <div class="d-flex align-items-center justify-content-between mx-2">
              <span class="text-xl text-semibold">
                {{ internalPlaceholder }}
              </span>
              <a @click="looseFocus" class="text-default">
                <XIcon size="1.25x" />
              </a>
            </div>

            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="internalValue"
              name="flavour-2"
              stacked
              autocomplete="off"
              class="dropdown-options overflow-y-scroll px-2"
              @change="onChangeValue()"
            >
              <template v-for="(option, index) in options">
                <div :key="index" class="d-flex justify-content-between py-2">
                  <label :for="_uid + '-option-' + index">
                    {{ option.label }}
                  </label>
                  <b-form-checkbox
                    :id="_uid + '-option-' + index"
                    :value="option"
                  />
                </div>
              </template>
            </b-form-checkbox-group>
            <b-dropdown-divider />
            <div class="d-flex flex-column mx-2">
              <b-button variant="primary" size="sm" @click="applySearch()">
                {{$t("vacancy.filters.interval.action_buttons.see_results")}}
              </b-button>
              <b-button variant="link" size="sm" @click="clearField()">
                {{$t("vacancy.filters.interval.action_buttons.redefine")}}
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div tabindex="0" ref="inputContainer">
          <!-- {{ $attrs.placeholder }} -->

          <b-dropdown
            ref="dropdown"
            variant="chip"
            size="xs"
            class="button-dropdown scrollable-options"
            :toggle-class="{
              'text-left d-flex align-items-center gap-1': true,
              active: !noOptionSelected,
            }"
            no-caret
            autocomplete="off"
          >
            <template #button-content>
              <CheckIcon size="1.25x" v-if="!noOptionSelected" />
              <span
                class="flex-grow"
                :class="{ 'text-muted': noOptionSelected }"
              >
                {{ selectedValue }}
              </span>
              <ChevronDownIcon size="1.25x" v-if="options" />
            </template>
            <template #default="{ hide }">
              <b-dropdown-text>
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="internalValue"
                  name="flavour-2"
                  stacked
                  autocomplete="off"
                  @change="onChangeValue()"
                >
                  <!-- @change="dispatchEvent('change', internalValue)" -->
                  <template v-for="(option, index) in options">
                    <label
                      :for="_uid + '-option-' + index"
                      :key="index"
                      class="d-flex justify-content-between py-2 cursor-pointer"
                    >
                      <span>{{ option.label }}</span>
                      <b-form-checkbox
                        :id="_uid + '-option-' + index"
                        :value="option"
                      />
                    </label>
                  </template>
                </b-form-checkbox-group>
              </b-dropdown-text>
              <b-dropdown-divider />
              <b-dropdown-text
                text-class="d-flex justify-content-between"
                tag="div"
              >
                <b-button
                  variant="link"
                  size="xxs"
                  @click="
                    clearField();
                    hide();
                  "
                >
                  {{$t("vacancy.filters.interval.action_buttons.redefine")}}
                </b-button>
                <b-button
                  variant="primary"
                  size="xxs"
                  pill
                  @click="applySearch(hide)"
                >
                  {{$t("vacancy.filters.interval.action_buttons.see_results")}}
                </b-button>
              </b-dropdown-text>
            </template>
          </b-dropdown>

          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </template>
    </b-form-group>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";

import FormFieldBase from "../mixins/Base";
import { useTheme } from "@state/system/theme";
import { ValidationProvider } from "vee-validate";

import { CheckIcon, ChevronDownIcon } from "@vue-hero-icons/solid";
import { XIcon } from "@vue-hero-icons/outline";

/**
 * Componente usado para filtros em barras de pesquisa, ou pequenos seleções.
 */
export default {
  name: "form-field-button-dropdown",
  mixins: [FormFieldBase],
  components: {
    ValidationProvider,
    CheckIcon,
    ChevronDownIcon,
    XIcon,
  },
  props: {
    /**
     * Define se multiplas opções são permitidas. Neste caso o valor de v-model deve ser um vetor
     */
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },

    /**
     * Vetor com as opções de escolha disponíveis, no formato {label : 'label', value : 'value'}
     */
    options: {
      type: Array | Object,
      required: false,
    },
  },
  data() {
    return {
      forceHidden: true,
      isFocused: false,
      loadingState: false,
    };
  },
  setup() {
    const theme = useTheme();

    return {
      isMobile: theme.isMobile,
    };
  },
  created() {
    this.internalPlaceholder = this.placeholder ? this.placeholder : this.label;

    //window.addEventListener("resize", this._updateDropdownSize);
  },
  mounted() {
    this.$refs.inputContainer.addEventListener(
      "blur",
      this.hideIfUnfocused,
      true
    );
    //this.$refs.inputContainer.addEventListener('focus',this.showIfFocused, true);

    //window.addEventListener("resize", this._updateDropdownSize);
  },
  destroyed() {
    //window.removeEventListener("resize", this._updateDropdownSize);
    if(this.$refs.inputContainer) {
      this.$refs.inputContainer.removeEventListener(
        "blur",
        this.hideIfUnfocused,
        true
      );
    }
    //this.$refs.inputContainer.removeEventListener('focus',this.showIfFocused, true);
  },

  computed: {
    showDropdown() {
      const status = !this.forceHidden && this.options.length > 0;
      if (status) {
        //this._updateDropdownSize();
        //this.$refs.inputDropdown.$el.clientWidth = this.$refs.inputContainer.$el.clientWidth;
      }
      return status;
    },
    noOptionSelected() {
      return _.size(this.internalValue) == 0;
    },
    selectedValue() {
      const labels = _.map(this.internalValue, (item) => item.label);
      if (_.isEmpty(labels)) {
        return this.internalPlaceholder;
      }
      if (_.size(labels) == 1) {
        return labels.join("/");
      } else {
        return _.first(labels) + " (+" + (_.size(labels) - 1) + ")";
      }
    },
  },
  methods: {
    onChangeValue() {
      this.dispatchEvent("change", this.internalValue);
    },
    applySearch(callback) {
      this.looseFocus();
      this.$nextTick(() => {
        this.$emit("search");
      });
      if (callback) {
        callback();
      }
    },
    _updateDropdownSize() {
      this.$refs.inputDropdown.style.width =
        this.$refs.inputContainer.clientWidth + "px";
    },
    looseFocus() {
      this.isFocused = false;
      this.forceHidden = true;
      //this.$refs.inputContainer.blur()
    },
    hideIfUnfocused(ev) {
      if (
        (ev.relatedTarget || ev.target) &&
        (this.$refs.inputContainer.contains(ev.relatedTarget) ||
          this.$refs.inputContainer.contains(document.activeElement))
      ) {
        // CONTINUE
      } else {
        this.looseFocus();
      }
    },
    clear() {
      this.$refs.dropdown.hide(false);
    },
    focusDropdown() {
      this.forceHidden = false;
      this.isFocused = true;
      //this.$refs.inputContainer.focus()
    },
    blurDropdown($ev) {
      this.forceHidden = true;
      this.dispatchEvent("change", this.internalValue);
    },
    clearField() {
      if (this.multiple) {
        this.internalValue = [];
      } else {
        this.internalValue = -1;
      }

      this.dispatchEvent("change", this.internalValue);
      this.looseFocus();
    },

    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.input1);
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      this.focusRef(this.$refs.button);
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus();
        });
      });
    },
  },
};
</script>
<!--
<docs>
```vue
<template>
    <div class="container">
        <form-field-button-dropdown
            v-model="inputValue"
            name="field-name"
            :options="options"
            :multiple="multiple"
            label="Label do campo"
            description="Descrição do campo"
            placeholder="Placeholder do campo"
        >
        </form-field-button-dropdown>
        <small>Selecionado: {{ inputValue }}</small>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            inputValue: [],
            multiple : true,
            options : [
                {
                    label: 'Opção 1',
                    value: 'Value1'
                },
                {
                    label: 'Opção 2',
                    value: 'Value2'
                },
            ]
        };
      }
    }
</script>
```
</docs>
-->
