<template>
  <div v-if="section.ctps.show || section.pis.show">
    <template v-if="section.ctps.show">
      <form-field-switch
        name="curriculum.ctps.has_ctps"
        test-id="esocial.ctps.has_ctps"
        v-model="curriculum.ctps.has_ctps"
        v-bind="section.ctps.fields.has_ctps"
        autocomplete="off"
      />

      <form-set-generic
        v-if="curriculum.ctps.has_ctps"
        v-model="curriculum.ctps"
        label="curriculum"
        form="esocial"
        section="ctps"
        :fields="ctpsFields"
        class="profile-subsection"
      >
        <template
          #ctps_file2_id="{ model, field, componentName, testId, component }"
        >
          <span>
            <component
              v-if="model['ctps_type'] == 'f'"
              :is="component.is"
              :name="componentName"
              :test-id="testId"
              v-model="model[field]"
              v-bind="component"
            />
          </span>
        </template>
      </form-set-generic>
    </template>

    <template v-if="section.pis.show">
      <form-field-switch
        name="curriculum.ctps.has_pis"
        test-id="esocial.ctps.has_pis"
        v-model="curriculum.ctps.has_pis"
        v-bind="section.pis.fields.has_pis"
        autocomplete="off"
      />

      <form-set-generic
        v-if="curriculum.ctps.has_pis"
        v-model="curriculum.ctps"
        label="curriculum"
        form="esocial"
        section="ctps"
        :fields="pisFields"
      />
    </template>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { FormFieldSwitch, FormFieldUpload } from "@components/forms";

export default {
  components: {
    FormSetGeneric,
    FormFieldSwitch,
    FormFieldUpload,
  },
  setup() {
    const curriculum = useCurriculum();
    return {
      // you can return the whole store instance to use it in the template
      curriculum,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ctpsFields() {
      return _.filter(
        this.section.ctps.fields,
        (item) => item != "ctps.has_ctps"
      );
    },
    pisFields() {
      return _.filter(
        this.section.pis.fields,
        (item) => item != "ctps.has_pis"
      );
    },
  }
};
</script>
