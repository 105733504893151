import "vanilla-cookieconsent";
import { watchEffect } from "vue";
import CookieConsentConfiguration from "./CookieConsentConfiguration";


const CookieConsentComponent = () => {
    watchEffect(() => {
        /**
         * useEffect is executed twice (React 18+),
         * make sure the plugin is initialized and executed once
         */
        if (!document.getElementById("cc--main")) {
            window.CookieConsentApi = window.initCookieConsent();
            window.CookieConsentApi.run(CookieConsentConfiguration);
        }
    }, []);

    return null;
};

export default CookieConsentComponent;
