export default (value, args) => {
    const [day, month, year] = value.split("/");
    var birthday = new Date([year, month, day].join('-'));

    //Subtrai 14 anos da data atual e compara com a data de nascimento
    var now = new Date();
    var m =  now.getMonth();
    now.setFullYear(now.getFullYear() - 14);
    if (m != now.getMonth()) now.setDate(0); //Fix para 29 de fevereiro
    return now > birthday;
};
