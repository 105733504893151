<template>
  <div class="card rounded-0 border-none">
    <div class="card-body pb-0">

      <!-- MAIL HEADER -->
      <div class="d-flex w-100 align-items-center">
        <letter-avatar :avatar-url="mailContent.img" :name="mailContent.from" class="avatar mr-3"/>
        <div class="flex-column">
          <h6 class="title mb-0">{{ mailContent.from }}</h6>
          <span class="text-sm">{{ getDateFormatted(mailContent.created) }}</span>
        </div>
      </div>

      <!-- MAIL SUBJECT -->
      <h6 class="my-3">{{mailContent.subject}}</h6>

      <!-- MAIL CONTENT -->
      <div class="text-sm" v-html="mailContent.message_content"></div>

    </div>
  </div>
</template>

<script>
import { useTheme } from "@state/system/theme";
import LetterAvatar from '@components/avatar/LetterAvatar.vue'

export default {

  components : {
    LetterAvatar
  },

  props: {
    mailContent: {
      type: Object,
      required: true
    }
  },

    setup() {
        const theme = useTheme();
        return {
            isMobile: theme.isMobile,
        };
    },

  methods: {
    getDateFormatted(date) {
       return new Date(date).toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        })
    }
  }
}
</script>
