<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :vid="vid"
    :rules="rules"
  >
    <b-form-group
      :id="fieldsetId"
      :description="description"
      :label="label"
      :label-for="inputId"
      :state="errors.length == 0"
    >
      <template #label>
        <slot name="label" :inputId="inputId"></slot>
      </template>

      <!-- 
     <div class="example-avatar">
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
          <h3>Drop files to upload</h3>
        </div>
        <div class="avatar-upload"  v-show="!edit">
          <div class="text-center p-2">
            <label for="avatar">
              <img :src="files.length ? files[0].url : activeUserInfo.avatar"  class="rounded-circle" />
              <h4 class="pt-2">or<br/>Drop files anywhere to upload</h4>
            </label>
          </div>
          <div class="text-center p-2">
            <file-upload
              extensions="gif,jpg,jpeg,png,webp"
              accept="image/png,image/gif,image/jpeg,image/webp"
              name="avatar"
              class="btn btn-primary"
              post-action="/backend/profile/change-avatar"
              :drop="!edit"
              v-model="files"
              @input-filter="inputFilter"
              @input-file="inputFile"
              :custom-action="changeAvatar"
              ref="upload">
              Upload avatar
            </file-upload>
          </div>
        </div>

        <div class="avatar-edit" v-show="files.length && edit">
          <div class="avatar-edit-image" v-if="files.length">
            <img ref="editImage" :src="files[0].url" />
          </div>
          <div class="text-center p-4">
            <button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear">Cancel</button>
            <button type="submit" class="btn btn-primary" @click.prevent="editSave">Save</button>
          </div>
        </div>
      </div> -->
    </b-form-group>

    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";

import FileUpload from "vue-upload-component";

export default {
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    vid: {
      type: String,
      required: false,
    },
    rules: {
      type: String | Object,
      required: false,
    },
    testId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      fieldsetId: "f-fieldset-" + this._uid,
      inputId: "f-field-" + this._uid,
    };
  },
  computed: {
    state() {},
  },
};
</script>
