<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :name="internalVid"
            :id="fieldsetId"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <div class="s-barpicker-header">
                        <b-icon class="s-barpicker-icon" :icon="icon" />
                        <span v-if="internalLabel">{{ $t(internalLabel) }} <span v-if="required" class="text-danger">*</span> </span>
                        <b>{{ info }}</b>
                    </div>
                </slot>
            </template>

<!--
<font-awesome-icon v-if="icon" class="s-barpicker-icon" :icon="icon" /> -->

            <div class="form-field-barpicker">
                <div class="form-field-barpicker-editor">
                    <div
                        v-for="level in levels"
                        :key="internalTestId + '-' + level[0]"
                        @click="setLevel(level)"
                        class="form-field-barpicker-editor-item border border-default" :class="{ 'form-field-barpicker-editor-item-active border-primary bg-primary' : internalValue >= level[0]}"

                        >
                    </div>
                </div>
                <div class="form-field-barpicker-footer">
                    <div>{{ $t(firstLevel[1]) }}</div>
                    <div>{{ $t(lastLevel[1]) }}</div>
                </div>
            </div>

            <small class="text-danger">{{ errors[0] }}</small>

        </b-form-group>


    </validation-provider>
</template>

<script>
import * as _ from 'lodash-es'
import FormFieldBase from '../mixins/Base'
import { ValidationProvider } from 'vee-validate'
import companyMixin from '@state/company'

export default {
    mixins : [
        companyMixin,
        FormFieldBase
    ],
    components: {
        ValidationProvider
    },
    props : {
        levels : {
            type: Array,
            default: () => {
            return [
                [1, 'curriculum.texts.education.basic'],
                [2, 'curriculum.texts.education.intermediate'],
                [3, 'curriculum.texts.education.advanced'],
                [4, 'curriculum.texts.education.fluent']
            ]
            }
        },
        icon : {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            info : ''
        }
    },
    computed: {
      firstLevel() {
        return _.first(this.levels);
      },
      lastLevel() {
        return _.last(this.levels);
      },
    },
    methods: {
      setLevel(level) {
        if (this.internalValue === level[0]) {
          this.internalValue = 0;
          this.info = this.$t('curriculum.texts.education.none');
        } else {
          this.internalValue = level[0];
          this.info = this.$t(level[1]);
        }
        this.dispatchEvent('change', this.internalValue)

        //this.$emit('input', this.internalValue)



      },
    },
}
</script>
<!--
    @todo Documentar componente com a forma de uso
-->
