import Vue from "vue";


//import { BootstrapVue, ButtonPlugin, IconsPlugin, VBTooltipPlugin } from "bootstrap-vue";
import {
    BootstrapVueIcons,

    AlertPlugin,
    AspectPlugin,
    BadgePlugin,
    ButtonPlugin,
    CardPlugin,
    CollapsePlugin,
    FormCheckboxPlugin,
    FormDatepickerPlugin,
    FormGroupPlugin,
    FormInputPlugin,
    FormPlugin,
    FormRadioPlugin,
    FormRatingPlugin,
    FormSpinbuttonPlugin,
    FormTextareaPlugin,
    //IconsPlugin,
    InputGroupPlugin,
    LayoutPlugin,
    ListGroupPlugin,
    NavbarPlugin,
    OverlayPlugin,
    PopoverPlugin,
    ProgressPlugin,
    SidebarPlugin,
    SkeletonPlugin,
    SpinnerPlugin,
    TooltipPlugin,
    VBPopoverPlugin,
    VBTooltipPlugin,
    VBVisiblePlugin
} from "bootstrap-vue";

Vue.use(BootstrapVueIcons);

Vue.use(AlertPlugin);
Vue.use(AspectPlugin)
Vue.use(BadgePlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormRatingPlugin)
Vue.use(FormSpinbuttonPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(ListGroupPlugin)
Vue.use(NavbarPlugin)
Vue.use(OverlayPlugin)
Vue.use(PopoverPlugin)
Vue.use(ProgressPlugin)
Vue.use(SidebarPlugin)
Vue.use(SkeletonPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TooltipPlugin)
Vue.use(VBPopoverPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(VBVisiblePlugin);
