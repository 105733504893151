
{
    "pt_BR": {
        "achiever": "Realizador",
        "agent": "Representante",
        "appraiser": "Avaliador",
        "counselor": "Conselheiro",
        "creative": "Criador",
        "developer": "Desbravador",
        "inspirational": "Inspirador",
        "investigator": "Investigador",
        "objective thinker": "Pensador Objetivo",
        "overshift": "Expandido",
        "perfectionist": "Perfeccionista",
        "persuader": "Convincente",
        "pratitioner": "Praticante",
        "promoter": "Promotor",
        "result-oriented": "Orientado a Resultados",
        "specialist": "Especialista",
        "tight": "tight",
        "undershift": "Comprimido"
    },
    "en" : {
        "achiever": "Achiever",
        "agent": "Agent",
        "appraiser": "Appraiser",
        "counselor": "Counselor",
        "creative": "Creator",
        "developer": "Developer",
        "inspirational": "Inspirational",
        "investigator": "Investigator",
        "objective thinker": "Objective Thinker",
        "overshift": "Overshift",
        "perfectionist": "Perfectionist",
        "persuader": "Persuader",
        "pratitioner": "Pratitioner",
        "promoter": "Promoter",
        "result-oriented": "Result Oriented",
        "specialist": "Specialist",
        "tight": "Tight",
        "undershift": "Undershift"
    }
}
