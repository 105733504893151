<template>
  <div v-if="section.bank.show">
    <form-field-switch
      name="curriculum.bank.has_bank"
      test-id="admissional.bank.has_bank"
      v-model="curriculum.bank.has_bank"
      v-bind="section.bank.fields.has_bank"
      autocomplete="off"
    />

    <div class="divider" v-if="!curriculum.bank.has_bank">
      <a href="#" @click="generateLetter">
        <span>{{ $t("admissional.sections.bank.generate_bank_letter") }}</span>
      </a>
    </div>

    <form-set-generic
      v-if="curriculum.bank.has_bank"
      v-model="curriculum.bank"
      label="curriculum"
      form="admissional"
      section="bank"
      :fields="bankFields"
      class="profile-subsection"
    />
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { FormFieldSwitch } from "@components/forms";

export default {
  components: {
    FormSetGeneric,
    FormFieldSwitch,
  },
  setup() {
    const curriculum = useCurriculum();
    return {
      // you can return the whole store instance to use it in the template
      curriculum,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    candidate_id: {
      required: true,
    },
    // selective_process_id: {
    //   required: true,
    // },
  },
  computed: {
    bankFields() {
      return _.filter(
        this.section.bank.fields,
        (item) => item != "bank.has_bank"
      );
    },
  },
  // data() {
  //   return {
  //     create_account_letter: "",
  //     letter_data: {},
  //   };
  // },
  methods: {
    generateLetter() {
      this.$root.loading = true;

      const payload = localStorage.getItem("bank_account_selective_process");

      const selective_process_id = payload
        ? JSON.parse(payload).selective_process_id
        : null;

      this.curriculum
        .generateBankAccountLetter({
          candidate_id: this.candidate_id,
          selective_process_id: selective_process_id,
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const objectUrl = window.URL.createObjectURL(blob);
          window.open(objectUrl, "_blank");
          this.$root.loading = false;
        })
        .catch((error) => {
          this.$root.loading = false;
        });
    },
  },
};
</script>
