<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10">
        <h1 class="h4 text-center">{{ $t("privacy_policy.sj.title") }}</h1>
        <h2 class="h5 text-center">{{ $t("privacy_policy.sj.subtitle") }}</h2>
        <div class="my-5" v-if="privacyPolicy" v-html="privacyPolicy"></div>
      </div>
    </div>
  </div>
</template>

<script>
import companyMixin from "@state/company";
export default {
  mixins: [companyMixin],
  created() {
    // Only Sj context
    if (!this.company.config.sj) {
      this.$router.push({ name: "home" });
    }
  },
  computed: {
    privacyPolicy() {
      return this.$t("privacy_policy.sj.content");
    },
  },
};
</script>