import { defineStore } from 'pinia'

import * as _ from "lodash-es";

export const useTheme = defineStore('theme', {
    state: () => {
        return {
            cssFiles : {},
            selectedTheme: '',
            themes: {},
            windowSize : null,
            // all these properties will have their type inferred automatically
            customize : false,
            slim : false,
            colors : {
                background_color: '#FF0000',
                fundo_menu: '#FFFFFF',
                background_color_search_bar: "#2A2A81",
                primary: '#FF0000',
                headings_color : '#FF0000',

            },
            domains : {
                colors : []
            }
        }
    },
    actions : {
        isMobile() {
            return this.windowSize <= 768;
        },
        load(initalTheme, themeOptions) {
            this.selectedTheme = initalTheme;

            this.themes = themeOptions;

            // this.cssFiles = import.meta.glob('/resources/sass/themes/*.css', {
            //     as: 'url',
            // });
            this.change();
        },
        change() {
            if (!_.has(this.themes, this.selectedTheme)) {
                this.selectedTheme = 'default';
            }

            const themeFile = this.themes[this.selectedTheme].file;

            document.getElementById("page-theme-css").href = themeFile;
        },
        async override(company) {

            //TODO Validar forma de trocar o nome da empresa no title do navegador
            this.domains.colors = company.config.domains.primary_colors;
            this.colors.primary = company.config.colors['botao-ativo'];
            this.colors.headings_color = company.config.colors['botao-link'];
            this.colors.background_color_nav_bar = company.config.colors['fundo-menu'] == '#003E04' ? company.config.colors['fundo-menu'] : '';
            this.colors.background_color_search_bar = company.config.colors['fundo-barra-pesquisa'] ? company.config.colors['fundo-barra-pesquisa'] : company.config.colors['fundo-menu'];

            this.update();
        },
        async update() {
            document.querySelector(':root').style.setProperty('--primary', this.colors.primary);
            document.querySelector(':root').style.setProperty('--headings-color', this.colors.headings_color);
            document.querySelector(':root').style.setProperty('--background-color-nav-bar', this.colors.background_color_nav_bar);
            document.querySelector(':root').style.setProperty('--background-color-search-bar', this.colors.background_color_search_bar);
        }
    }
});
