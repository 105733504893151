import Vue from "vue";
import VueGtag from "vue-gtag";
import * as _ from "lodash-es";
import router from "./../router";

const environment = BACKEND.env;

export default function (config) {
    if (environment == "master" && !_.isEmpty(config.analytics_id)) {
        Vue.use(
            VueGtag,
            {
                config: { id: config.analytics_id },
            },
            router
        );
    } else if (environment == "local" || environment == "develop") {
        Vue.use(
            VueGtag,
            {
                config: { id: "G-KWME8WVJ6G" },
            },
            router
        );
    }
}
