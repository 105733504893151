import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";
import * as _ from "lodash-es";
import router from "../router";

const environment = BACKEND.env;

export default function (config) {
    if (environment == "master" && !_.isEmpty(config.equalweb_sitekey) && !_.isEmpty(BACKEND.nonce)) {
        window.interdeal = {
            "sitekey": config.equalweb_sitekey, //"caab2d106eedc5b1cd73d93742b676a8"
            "Position": "left",
            "domains": {
                "js": "https://cdn.equalweb.com/",
                "acc": "https://access.equalweb.com/"
            },
            "nonce" : BACKEND.nonce,
            "Menulang": "PT",
            "btnStyle": {
                "vPosition": [
                    "20%",
                    "80%"
                ],
                "scale": [
                    "0.5",
                    "0.5"
                ],
                "color": {
                    "main": "#1c4bb6",
                    "second": "#ffffff"
                },
                "icon": {
                    "type": 1,
                    "shape": "circle"
                }
            }
        };
        (function(doc, head, body){
            var coreCall             = doc.createElement('script');
            coreCall.src             = interdeal.domains.js + 'core/5.0.0/accessibility.js';
            coreCall.defer           = true;
            coreCall.integrity       = 'sha512-IxU4Ri4fE2rR9dl7NnxGj3DQGj5T21YWeqIVzymflYXFCrzXicaPBWRz2+p31fMI+vby7RG/3tqnu6etUOSQ6w==';
            coreCall.crossOrigin     = 'anonymous';
            coreCall.setAttribute('nonce', BACKEND.nonce);
            coreCall.setAttribute('data-cfasync', true );
            body? body.appendChild(coreCall) : head.appendChild(coreCall);
        })(document, document.head, document.body);
    } else if (environment == "local" || environment == "develop") {

    }
}
