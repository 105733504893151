<template>
    <div
        class="resume-panel"
        :class="banner.className"
    >
        <h1>{{ $t("home.hi") }}, {{ $auth.user().name }}!</h1>

        <h5 v-html="banner.text" />

        <div
            v-if="banner.progress"
            class="w-1/2"
        >
            <b-progress
                :max="100"
                :precision="0"
                class="mb-3"
                variant="info"
                style="overflow: visible; border-radius: 30px"
            >
                <b-progress-bar
                    :value="progress"
                    class="progres-bar"
                    style="border-radius: 30px"
                >
                </b-progress-bar>
                <span
                    class="py-3 px-3 bg-white border"
                    style="margin-top: -9px; margin-left: -9px; border-radius: 30px"
                >
                    <strong>{{ Math.round(progress / 100 * 100) }}%</strong>
                </span>
            </b-progress>
        </div>

        <h6>
            <router-link :to="{ name: banner.follow.name }">
                {{ banner.follow.text }}
            </router-link>
        </h6>
        <img
            :src="banner.image"
            alt=""
            class="d-none d-md-block"
        />
    </div>
</template>
<script>
import { BProgress, BProgressBar, BBadge } from "bootstrap-vue";

export default {
    components: {
        BBadge,
        BProgress,
        BProgressBar,
    },
    props: {
        progress: {
            type: Number,
            default: 0,
        },
        numJobs: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            banners: {
                default: {
                    className: "painel-default",
                    text: "",
                    follow: {
                        name: "profile-edit",
                        text: this.$t("home.curriculum.follow"),
                    },
                    image: "/images/painel/vagas1.svg",
                    progress: false,
                },
                curriculo0: {
                    className: "painel-curriculo0",
                    text: this.$t("home.curriculum.empty"),
                    follow: {
                        name: "profile-edit",
                        text: this.$t("home.curriculum.complete"),
                    },
                    image: "/images/painel/curriculo0.svg",
                    progress: true,
                },
                curriculo1: {
                    className: "painel-curriculo1",
                    text: this.$t("home.curriculum.half"),
                    follow: {
                        name: "profile-edit",
                        text: this.$t("home.curriculum.complete"),
                    },
                    image: "/images/painel/curriculo1.svg",
                    progress: true,
                },
                curriculo2: {
                    className: "painel-curriculo2",
                    text: this.$t("home.curriculum.full"),
                    follow: {
                        name: "profile-edit",
                        text: this.$t("home.curriculum.review"),
                    },
                    image: "/images/painel/curriculo2.svg",
                    progress: true,
                },
                vagas1: {
                    className: "painel-vagas1",
                    text:
                        this.$t("home.currentJobs") +
                        " <b>" +
                        this.numJobs +
                        " " +
                        this.$t("home.jobs") +
                        "</b>",
                    follow: {
                        name: "profile-applications",
                        text: this.$t("home.curriculum.follow"),
                    },
                    image: "/images/painel/vagas1.svg",
                    progress: false,
                },
            },
        };
    },
    computed: {
        banner() {
            let banner = "default";
            if (this.progress < 100) {
                banner = this.progress < 50 ? "curriculo0" : "curriculo1";
                // SHOW curriculo2
            } else {
                if (this.numJobs > 0) {
                    // SHOW vagas1
                    banner = "vagas1";
                } else {
                    banner = "curriculo2";
                }
            }
            return this.banners[banner];
        },
    },
};
</script>
