export default {
    data: {
        imagesList : {}
    },
    created() {
        this.imagesList = import.meta.glob("/resources/assets/images/**", {
            import: "default",
            eager: true
        });
        //return { imagesList };
    },
};