export default {
    props : {
        height : {
            type : String,
            default: '24px'
        },
        width : {
            type : String,
            default: '24px'
        },
        fill: {
            type: String,
            default: 'none'
        }

    }
}
