<template>
    <b-button
        size="giga"
        variant="dt-subdued"
        block
        class="d-flex justify-content-between align-items-center p-6 mt-4"
        v-on="$listeners"
        v-bind="$attrs"
    >
        <slot name="default">
            {{ label }}
        </slot>
        <slot name="icon">
            <PlusCircleIcon />
        </slot>
    </b-button>
</template>

<script>
import * as _ from 'lodash-es'
import { PlusCircleIcon } from "@vue-hero-icons/outline";
/**
 * Componente usado para filtros em barras de pesquisa, ou pequenos seleções.
 */
export default {
    name: "GigaButton",
    components: {
        PlusCircleIcon,
    },
    props: {
        /**
         * Define se multiplas opções são permitidas. Neste caso o valor de v-model deve ser um vetor
         */
        label: {
            type: String,
            required: false,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            internalValue: this.value,
            internalPlaceholder: this.placeholder,
        };
    },
    created() {
        this.internalPlaceholder = this.placeholder
            ? this.placeholder
            : this.label;
    },
    computed: {
        noOptionSelected() {
            return _.size(this.internalValue) == 0;
        },
        selectedValue() {
            const labels = _.map(this.internalValue, (item) => item.label);
            if (_.isEmpty(labels)) {
                return this.internalPlaceholder;
            }
            return labels.join("/");
        },
    },
};
</script>
<!--
<docs>
```vue
<template>
    <div class="d-flex flex-column gap-2">
        <giga-button label="Giga Button Label" />
        <giga-button>
            <template #default>Label via slot</template>
        </giga-button>
        <giga-button>
            <template #default>Label e ícone via slot</template>
            <template #icon> <AcademicCapIcon /> </template>
        </giga-button>
    </div>
</template>

<script>
    import { AcademicCapIcon } from "@vue-hero-icons/solid";

    export default {
        components : {
            AcademicCapIcon
        }
    }
</script>
```
</docs>
-->
