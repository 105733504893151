import { defineStore } from "pinia";
import Http from "@/plugins/axios";
const FORGOT_PASSWORD = "/auth/forgot-password";
const RESET_PASSWORD = "/auth/reset-password";
const VALIDATE_INFO = "/auth/validate-info";
const UPDATE_EMAIL = "/auth/update-email";

export const usePasswords = defineStore("passwords", {
    // other options...
    state: () => {
        return {
            cpf: null,
            cellphone: null,
            birth_date: null,
            primary_email: null,
            sensitive: {
                new_email: null,
                new_email_confirmation: null,
            },
        };
    },
    actions: {
        async forgot(conditions) {
            return Http.post(FORGOT_PASSWORD, conditions);
        },
        async resetPassword(conditions) {
            return Http.post(RESET_PASSWORD, conditions);
        },
        async validateInfo(data) {
            return Http.post(VALIDATE_INFO, this.$state);
        },
        async changeEmail(data) {
            return Http.post(UPDATE_EMAIL, data);
        },
    },
});
