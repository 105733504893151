<template>
    <validation-provider
        :name="label"
        :vid="vid"
        :rules="rules"
        v-if="is_loaded && hasFields"
        slim
    >
        <b-card
            class="my-2"
            header-class="d-flex w-100 justify-content-between align-items-center"
        >
            <template #header>
                <h6 class="mb-1">
                    {{ title }}
                </h6>
            </template>
            <!-- <template #footer>
                <b-button variant="outline-primary" @click="add">
                    {{ $t('curriculum.actions.computing.add_computing') }} <b-icon icon="plus" />
                </b-button>
            </template> -->

            <form-set-generic
                v-model="internalValue"
                name="curriculum.computing"
                form="curriculum"
                section="computing"
                :fields="internalFields"
                :domains="['computing']"
                v-if="hasFields"
                autocomplete="off"
            >
                <template #basic="{model, field, componentName, testId, component}">
                    <component
                        ref="field-basic"
                        :is="component.is"
                        :name="componentName"
                        :test-id="testId"
                        v-model="model[field]"
                        v-bind="component"
                        :selectable="selectableBasic"
                        autocomplete="off"
                    />
                </template>
                <template #intermediate="{model, field, componentName, testId, component}">
                    <component
                        ref="field-intermediate"
                        :is="component.is"
                        :name="componentName"
                        :test-id="testId"
                        v-model="model[field]"
                        v-bind="component"
                        :selectable="selectableIntermediate"
                        autocomplete="off"
                    />
                </template>
                <template #advanced="{model, field, componentName, testId, component}">
                    <component
                        ref="field-Advanced"
                        :is="component.is"
                        :name="componentName"
                        :test-id="testId"
                        v-model="model[field]"
                        v-bind="component"
                        :selectable="selectableAdvanced"
                        autocomplete="off"
                    />
                </template>



            </form-set-generic>
        </b-card>
    </validation-provider>
</template>

<script>
//import * as FormComponents from "@components/forms";
import * as _ from 'lodash-es'
import FormSetGeneric from '@components/forms/set/Generic.vue'
import FormFieldBase from "./../mixins/Base.js";
import FormFieldSet from "./../mixins/Set.js";
import { ValidationProvider } from "vee-validate";


export default {
  /*
  mixins: [FormFieldBase, FormFieldSet],
  components: {
      ValidationObserver,
      ValidationProvider,
      FormSetGeneric,
      ...FormComponents,
      TimelineItem,
      BulletList
  }
  */
    mixins : [FormFieldBase, FormFieldSet],
    components: {
        ValidationProvider,
        FormSetGeneric
    },
    methods : {
        selectableBasic(option) {
            return this.selectableByType(['intermediate', 'advanced'], option);
        },
        selectableIntermediate(option) {
            return this.selectableByType(['basic', 'advanced'], option);
        },
        selectableAdvanced(option) {
            return this.selectableByType(['basic', 'intermediate'], option);
        },
        selectableByType(types, option) {
            return _.every(types, (type) => {
                return (!_.has(this.internalValue, type) || this.internalValue[type].indexOf(option.value) == -1)
            });
        },
    }
  // setup() {
  //   const curriculum = useCurriculum()

  //   const computingFields = [
  //     'computing.basic',
  //     'computing.intermediate',
  //     'computing.advanced'
  //   ];

  //   //const domain = useDomain()
  //   return {
  //     // you can return the whole store instance to use it in the template
  //     computingFields,
  //     curriculum
  //   }
  // },
};
</script>
