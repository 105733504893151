<template>
  <validation-provider
    ref="validator"
    #default="{ errors }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
  >
    <giga-button
      :label="$t('customize.banner.add_banner')"
      @click="addItem"
      class="my-3"
    />

    <draggable
      v-model="section.data.images"
      group="people"
      @start="drag = true"
      @end="drag = false"
    >
      <div
        class="card mb-3"
        v-for="image in imagesList"
        v-bind:key="image.position"
      >
        <div class="row no-gutters">
          <div
            class="col-1 d-flex justify-content-center align-items-center"
            style="cursor: grab"
          >
            <SwitchVerticalIcon />
          </div>
          <div class="col-10">
            <div class="position-relative my-3">
              <img :src="image.src" class="card-img" alt="" />
              <div
                class="card-img-overlay d-flex align-items-center justify-content-center"
              >
                <file-upload
                  :id="image.position"
                  :input-id="image.position + '-file-html'"
                  v-model="internalFile[image.position]"
                  :data="{ position: image.position, type: uploadType }"
                  :accept="accept"
                  :extensions="extensions"
                  post-action="/backend/files/upload-files-customize"
                  :size="1024 * 1024 * 8"
                  @input-file="
                    (newFile, oldFile) =>
                      inputFile(newFile, oldFile, image.position)
                  "
                  @input-filter="inputFilter"
                  :drop="$refs.droppable"
                  class="btn-sm btn-dt-secondary border rounded-pill cursor-pointer mb-3"
                  ref="upload"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  v-bind="$attrs"
                >
                  {{
                    image.src && image.src.length > 0
                      ? "Trocar imagem"
                      : "Enviar imagem"
                  }}
                </file-upload>
              </div>
            </div>

            <b-collapse :id="`collapse-banner-${image.position}`">
              <form-field-input
                name="customize.banner.redirect_url"
                label-class="font-weight-semibold"
                v-model="image.redirect_url"
                rules="custom-url"
              />
            </b-collapse>
            <b-button
              v-if="image.src && image.src.length > 0"
              variant="link"
              v-b-toggle="`collapse-banner-${image.position}`"
              class="w-100 text-center pt-0"
            >
              <ChevronDownIcon />
            </b-button>
          </div>

          <div
            class="col-1 d-flex justify-content-center align-items-center cursor-pointer"
          >
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content><DotsVerticalIcon /></template>
              <b-dropdown-item @click="removeItem(image.position)">
                {{ $t("customize.banner.remove_banner") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

    </draggable>

    <div class="pt-4">
      <b-row cols="3">
        <b-col>
          <span>
          {{$t("customize.banner.banner_specifications.recommended_dimensions")}}
          </span>
        </b-col>
        <b-col>
          <span>
          {{$t("customize.banner.banner_specifications.maximum_file_size")}}
          </span>
        </b-col>
        <b-col>
          <span>
          {{$t("customize.banner.banner_specifications.supported_files")}}
          </span>
        </b-col>
        <b-col>
          <span class="text-medium">
          {{$t("customize.banner.banner_specifications.dimensions")}}
          </span>
        </b-col>
        <b-col>
          <span class="text-medium">
          {{$t("customize.banner.banner_specifications.size")}}
          </span>
        </b-col>
        <b-col>
          <span class="text-medium">
          {{$t("customize.banner.banner_specifications.formats")}}
          </span>
        </b-col>
      </b-row>
    </div>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";
import draggable from "vuedraggable";
import FormFieldBase from "../mixins/Base";
import FileUpload from "vue-upload-component";
import { ValidationProvider } from "vee-validate";
import { DotsVerticalIcon, SwitchVerticalIcon, ChevronDownIcon } from "@vue-hero-icons/outline";
import GigaButton from "@components/buttons/GigaButton.vue";
import FormFieldInput from "./Input.vue";

export default {
  name: "form-field-banners-upload",
  mixins: [FormFieldBase],
  components: {
    draggable,
    FileUpload,
    DotsVerticalIcon,
    SwitchVerticalIcon,
    ValidationProvider,
    GigaButton,
    FormFieldInput,
    ChevronDownIcon,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    uploadType: {
      type: String,
      required: false,
      default: "banner",
    },
  },
  data() {
    return {
      internalFile: [],
      files: [],
      accept: "image/png,image/jpeg,image/jpg",
      extensions: "jpg,jpeg,png",
      showPreview: [true, true, true],
      maxAllowedItems: 4,
      defaultItemProps: {
        src: null,
        alt: null,
        title: null,
        position: 0,
        index: 0,
        redirect_url: null,
      },
    };
  },
  computed: {
    imagesList() {
      let counter = 0;
      return _.map(this.section.data.images, (item) => {
        item.position = counter++;

        if (!item.hasOwnProperty("redirect_url")) {
          item.redirect_url = null;
        }

        return item;
      });
    },
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },

    inputFile(newFile, oldFile, position) {
      if (newFile && !oldFile) {
        // Add file
        //            this.$refs.upload[position].active = true;
      } else if (newFile && oldFile) {
        // Update file
        this.showPreview[position] = false;
        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // min size
          /*
                if (newFile.size >= 0 && newFile.size < 100 * 1024) {
                    newFile = this.$refs.upload.update(newFile, {error: 'size'})
                }
                */
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          console.log("error", newFile.error, newFile);
          this.uploadError = true;
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.uploadError = false;
          if (this.internalFile.length > 0) {
            //this.alreadyLoaded.splice(0, this.alreadyLoaded.length);
          }

          this.showPreview[position] = true;

          //this.dispatchEvent("change", newFile.response.value);

          this.section.data.images[position].src = newFile.response.path;
        } else {
          this.uploadError = true;
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload[position].active) {
          newFile.headers = {
            Authorization: `Bearer ${this.$auth.token()}`,
          };
          newFile.data;

          this.$refs.upload[position].active = true;
        }
      }
    },

    addItem() {
      if (Object.values(this.imagesList).length >= this.maxAllowedItems) {
        return;
      }
      this.section.data.images.push({ ...this.defaultItemProps });
    },

    removeItem(position) {
      const index = this.imagesList.findIndex(
        (item) => item.position == position
      );
      if (index != null) {
        this.section.data.images.splice(index, 1);
      }
    },
  },
};
</script>
