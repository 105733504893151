<template>
  <section id="create-account-button-bar" data-scroll-to="create-account-button-bar">
    <div class="d-flex justify-content-center">
      <div>
        <b-button block variant="primary" @click="$router.push({ name: 'vacancy-search' })" pill class="btn-lg">
            {{ $t(button_label) }}
          </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import * as FormComponents from "@components/forms";

export default Vue.extend({
  props: ["button_label"],
  components: {
    ...FormComponents,
  },
  data() {
    return {};
  },
});
</script>
