
const CookieConsentConfiguration = {
    current_lang: "pt-BR",
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    force_consent: false, // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {},

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
    },

    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },
    languages: {
        "pt-BR": {
            gui_options: {
                consent_modal: {
                    layout: 'bar',               // box/cloud/bar
                    position: 'bottom center',     // bottom/middle/top + left/right/center
                    transition: 'slide',           // zoom/slide
                    swap_buttons: true            // enable to invert buttons
                },
                settings_modal: {
                    layout: 'bar',                 // box/bar
                    // position: 'left',           // left/right
                    transition: 'slide'            // zoom/slide
                }
            },
            consent_modal: {
                title: "Cookies",
                description:
                    'Para que este site funcione corretamente, às vezes colocamos pequenos arquivos de dados chamados cookies no seu dispositivo. A maioria dos grandes sites também faz isso. <button type="button" data-cc="c-settings" class="cc-link">Mudar configurações</button>',
                primary_btn: {
                    text: "Aceitar todos",
                    role: "accept_all", // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: "Rejeitar todos",
                    role: "accept_necessary", // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: "Configurações de Cookies",
                save_settings_btn: "Salvar configurações",
                accept_all_btn: "Aceitar tudo",
                reject_all_btn: "Rejeitar tudo",
                close_btn_label: "Fechar",
                blocks: [
                    {
                        title: "Configurações de privacidade",
                        description:
                            'Decida quais cookies você deseja permitir. Você pode alterar essas configurações a qualquer momento. No entanto, isso pode resultar em algumas funções que não estão mais disponíveis. Para obter informações sobre como excluir os cookies, consulte a função de ajuda do seu navegador. Para mais detalhes sobre as informações que utilizamos, leia nossa <a href="/privacy-policy" class="cc-link">política de privacidade</a>.',
                    },
                    {
                        title: "Essencial",
                        description:
                            "Os cookies de sessão são utilizados para reunir informações inseridas em formulários de contato, boletins informativos, outros formulários em todas as páginas, autenticar quando estiver logado em sua conta de usuário e lembra-se da versão do idioma que você selecionou.",
                        toggle: {
                            value: "essential",
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    {
                        title: "Funcionalidade",
                        description:
                            "Estes cookies são utilizados para suas configurações de mídia social, região e país selecionados.",
                        toggle: {
                            value: "performance", // your cookie category
                            enabled: false,
                            readonly: false,
                        },
                    },
                    {
                        title: "Analytics",
                        description:
                            "Estes cookies são utilizados para acompanhar as páginas visitadas, interações realizadas, localização e região com base em seu IP, tempo gasto em cada página e aumentar a qualidade dos dados das funções estatísticas.",
                        toggle: {
                            value: "analytics",
                            enabled: false,
                            readonly: false,
                        },
                    },
                    {
                        title: "Propaganda",
                        description:
                            "Estes cookies são utilizados para publicidade personalizada de terceiros, conexão a sites sociais, identifica o dispositivo que você está usando e reunir informações pessoalmente identificáveis como nome e localização.",
                        toggle: {
                            value: "advertising",
                            enabled: false,
                            readonly: false,
                        },
                    },
                ],
            },
        },
    },
}

export default CookieConsentConfiguration;
