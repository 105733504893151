<template>
    <!-- O componente disc-profile-icon recebe uma string e retorna o ícone respectivo -->
    <div class="disc-profile-icon">
        <img :src="images[profile]" :width="width">
    </div>
</template>

<script>
import developerSvg from '@assets/images/disc/developer.svg'
import resultOrientedSvg from '@assets/images/disc/result-oriented.svg'
import inspirationalSvg from '@assets/images/disc/inspirational.svg'
import creativeSvg from '@assets/images/disc/creative.svg'
import promoterSvg from '@assets/images/disc/promoter.svg'
import persuaderSvg from '@assets/images/disc/persuader.svg'
import counselorSvg from '@assets/images/disc/counselor.svg'
import appraiserSvg from '@assets/images/disc/appraiser.svg'
import specialistSvg from '@assets/images/disc/specialist.svg'
import achieverSvg from '@assets/images/disc/achiever.svg'
import agentSvg from '@assets/images/disc/agent.svg'
import investigatorSvg from '@assets/images/disc/investigator.svg'
import objectiveThinkerSvg from '@assets/images/disc/objective-thinker.svg'
import perfectionistSvg from '@assets/images/disc/perfectionist.svg'
import practitionerSvg from '@assets/images/disc/practitioner.svg'

export default {
    props: {
        profile: {
            type: String,
            validator: function (value) {
                // The value must match one of these strings
                return [
                    'developer',
                    'result-oriented',
                    'inspirational',
                    'creative',
                    'promoter',
                    'persuader',
                    'counselor',
                    'appraiser',
                    'specialist',
                    'achiever',
                    'agent',
                    'investigator',
                    'objective',
                    'perfectionist',
                    'practitioner',
                ].indexOf(value) !== -1
            }
        },
        width: {
            type: Number,
            default: 60
        }
    },

    data() {
        return {
            images: {
                'developer': developerSvg,
                'result-oriented': resultOrientedSvg,
                'inspirational': inspirationalSvg,
                'creative': creativeSvg,
                'promoter': promoterSvg,
                'persuader': persuaderSvg,
                'counselor': counselorSvg,
                'appraiser': appraiserSvg,
                'specialist': specialistSvg,
                'achiever': achieverSvg,
                'agent': agentSvg,
                'investigator': investigatorSvg,
                'objective': objectiveThinkerSvg,
                'perfectionist': perfectionistSvg,
                'practitioner': practitionerSvg,
            }
        }
    },
}
</script>

<style lang="scss">
    
</style>