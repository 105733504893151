<template>
  <div
    class="d-flex w-full h-100 align-items-center justify-content-center p-4"
    id="page-login"
  >
    <div class="col-md-6 col-lg-6 col-xl-4 px-0">
      <b-card>
        <div class="justify-center items-center">
          <div class="login-container w-full p-2">
            <div class="mb-6" v-if="!socialMediaAccount">
              <div v-if="!requestSent">
                <h5>{{ $t("user.forgot.title") }}</h5>
                <p>{{ $t("user.forgot.info") }}</p>
              </div>
              <div v-if="requestSent">
                <h5 class="text-success">{{ $t("user.forgot.email_sent") }}</h5>
                <p>{{ $t("user.forgot.email_instructions", { email }) }}</p>
                <p>{{ $t("user.forgot.email_spam_instructions") }}</p>

                <b-button
                  class="btn-block border"
                  variant="link"
                  @click="$router.push({ name: 'confirm-account-info' })"
                  pill
                  size="sm"
                >
                  {{ $t("user.forgot.no_longer_email_access") }}
                </b-button>
              </div>
            </div>
            <div v-else>
              <div>
                <h5 class="text-danger">{{ $t("user.forgot.atention") }}</h5>
                <p>
                  {{ $t("user.forgot.social_media_account") }}
                </p>

                <b-button
                  class="btn-block border"
                  variant="link"
                  @click="$router.push({ name: 'confirm-account-info' })"
                  pill
                  size="sm"
                >
                  {{ $t("user.forgot.recover_account") }}
                </b-button>
              </div>
            </div>

            <form
              @submit.prevent
              v-if="!requestSent && !socialMediaAccount"
              class="mb-4"
            >
              <validation-observer ref="formRules" slim>
                <form-field-input
                  name="user.forgot.login"
                  v-model="passwords.cpf"
                  rules="required|cpf"
                  mask="###.###.###-##"
                  autocomplete="off"
                  inputmode="numeric"
                />

                <div>
                  <b-button
                    class="btn-block"
                    type="submit"
                    variant="primary"
                    @click="submit()"
                    data-cy="login-submit"
                    size="sm"
                    pill
                    >{{ $t("user.forgot.submit") }}</b-button
                  >

                  <b-button
                    class="btn-block border"
                    variant="link"
                    @click="$router.push({ name: 'login' })"
                    pill
                    size="sm"
                    >{{ $t("user.forgot.return_to") }}</b-button
                  >
                </div>
              </validation-observer>
            </form>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import companyMixin from "@state/company";
import * as FormComponents from "@components/forms";
import { usePasswords } from "@state/user/passwords";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    ...FormComponents,
  },
  data() {
    return {
      email: "",
      requestSent: false,
      socialMediaAccount: false,
    };
  },
  setup() {
    const passwords = usePasswords();

    return {
      passwords,
    };
  },
  created() {
    if (this.$auth.check()) {
      this.$router.push({ name: "profile" });
    }
  },
  methods: {
    submit() {
      this.$refs.formRules.validate().then((success) => {
        if (success) {
          this.$root.loading = true;
          // SEND THE FORGOT REQUEST
          this.passwords
            .forgot({ cpf: this.passwords.cpf })
            .then((response) => {
              if (response.data.success) {
                this.requestSent = false;
                this.$nextTick(() => {
                  this.requestSent = true;
                  this.email = response.data.email;
                  console.log("re-render start");
                  this.$nextTick(() => {
                    console.log("re-render end");
                  });
                });
              } else if (response.data.social_media_account) {
                this.socialMediaAccount = false;
                this.$nextTick(() => {
                  this.socialMediaAccount = true;
                  console.log("re-render start");
                  this.$nextTick(() => {
                    console.log("re-render end");
                  });
                });
              }
            })
            .finally(() => {
              this.$root.loading = false;
            });
        }
      });
    },
  },
};
</script>
