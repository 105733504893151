import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ptBrLang from '@lang/pt-BR'
import enUSLang from '@lang/en-US'
import esLang from '@lang/es'

import * as _ from 'lodash-es'

import { ptBR, enUS, es } from 'date-fns/locale'
//import { createI18n, useI18n } from 'vue-i18n-bridge'

// TRY TO DETECT DEFAULT USER LANGUAGE
const locales = {
    'pt-BR' : ptBR,
    'en-US' : enUS,
    'es' : es
}

Vue.use(VueI18n);

export const i18n = //castToVueI18n(
    new VueI18n({
        legacy: false,
        locale: 'pt-BR',
        fallbackLocale: 'en',
        silentTranslationWarn: true,
        missingWarn: false,
        messages: {
           'pt-BR' : ptBrLang,
           'en-US' : enUSLang,
           'es' : esLang
        },
        //messages
    //    messages: {
    //        'pt-BR' : ptBrLang,
    //        'en-US' : enUSLang,
    //        'es'    : esLang
    //    } // set locale messages
    })
//);



import { format as DateFnsFormat, parse as DateFnsParse } from 'date-fns'

export function format(date, formatStr = 'PP') {
	return DateFnsFormat(date, formatStr, {
	    locale: locales[i18n.locale] // or global.__localeId__
	})
}

export function parse(date, formatStr = 'PP') {
	return DateFnsParse(date, formatStr, new Date(), {
	    locale: locales[i18n.locale] // or global.__localeId__
	})
}

export function isoToLocal(date, formatStr = 'P') {
    if (!_.isEmpty(date)) {
        try {
            const dateObject = parse(date, 'yyyy-MM-dd');
            return format(dateObject, formatStr)
        } catch(E) {
            return date;
        }
    }
    return '';
}

export default { format, parse, isoToLocal, i18n };
