<template>
  <div
    class="home d-flex flex-column"
    :class="{ 'gap-10': isMobile(), 'gap-16': !isMobile() }"
    v-if="currentSections"
  >
    <component
      v-for="(section, index) in currentSections"
      :is="section.type"
      :key="index"
      v-bind="section.data"
      :class="index === 0 && section.type !== 'banner' ? 'pt-10' : null"
    />
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useTheme } from "@state/system/theme";

import List from "@blocks/editor/List.vue";
import Swiper from "@blocks/editor/Swiper.vue";
import Banner from "@blocks/editor/Banner.vue";
import RichText from "@blocks/editor/RichText.vue";
import Accordion from "@blocks/editor/Accordion.vue";
import BannerCta from "@blocks/editor/BannerCta.vue";
import BannerCtaButtons from "@blocks/editor/BannerCtaButtons.vue";
import Placeholder from "@blocks/editor/Placeholder.vue";
import BannerMosaic from "@blocks/editor/BannerMosaic.vue";
import ImageWithList from "@blocks/editor/ImageWithList.vue";
import CentralizedLogo from "@blocks/editor/CentralizedLogo.vue";
import AccordionWithImage from "@blocks/editor/AccordionWithImage.vue";
import CreateAccountButtonBar from "@blocks/editor/CreateAccountButtonBar.vue";

export default {
  mixins: [companyMixin],
  components: {
    List,
    Swiper,
    Banner,
    RichText,
    Accordion,
    BannerCta,
    BannerCtaButtons,
    Placeholder,
    BannerMosaic,
    ImageWithList,
    CentralizedLogo,
    AccordionWithImage,
    CreateAccountButtonBar,
  },
  created() {
    this.theme.load(
      this.company.config.themes.current,
      this.company.config.themes.list
    );
  },
  mounted() {
      // Redirect if hasnt page
      if (!this.companyHasPage()) {
        this.$router.push({ name: "home" });
      }
  },
  setup() {
    const theme = useTheme();
    return {
      theme,
      isMobile: theme.isMobile,
    };
  },
  computed: {
    currentSections() {
      let sections = [];

      // Get section based on current_page
      if (!_.isEmpty(this.company.config.sections[this.$route.meta.section])) {
        sections = this.company.config.sections[this.$route.meta.section].filter((section) => section.enabled);
      }

      return sections;
    },
  },
  methods: {
    companyHasPage() {
      return _.get(this.company.config.pages, this.$route.meta.section, false);
    }
  }
};
</script>
