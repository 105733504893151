export default (value, args) => {
    let total = 0;
    let resto = 0;
    let i;
    let ftap = "3298765432";
    let numPIS = value.replace(/[^\d]/g, "");
    let strResto = "";
    let resultado;

    if (numPIS == "" || numPIS == null) {
        return false;
    }

    for (i = 0; i <= 9; i++) {
        resultado = numPIS.slice(i, i + 1) * ftap.slice(i, i + 1);
        total = total + resultado;
    }

    resto = total % 11;

    if (resto != 0) {
        resto = 11 - resto;
    }

    if (resto == 10 || resto == 11) {
        strResto = resto + "";
        resto = strResto.slice(1, 2);
    }

    if (resto != numPIS.slice(10, 11)) {
        return false;
    }

    return true;
};
