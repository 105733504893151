<template>
  <div
    v-if="show_terms"
    class="d-flex justify-content-center"
  >
  <div class="w-50 term-box pt-4"   v-html="terms_conditions">
    
  </div>
  </div>
</template>
<script>
import { useConfiguration } from "@state/user/configuration";

export default {
  setup() {
    const configuration = useConfiguration();

    return {
      configuration,
    };
  },
  created() {
    this.configuration.getTermsAndConditions().then((response) => {
      this.terms_conditions = response.data;
      this.show_terms = true;
    });
  },

  data() {
    return {
      terms_conditions: "",
      show_terms: false,
    };
  },
};
</script>
<style lang="scss">
.term-box{
    max-height: 40.625rem;
    overflow: scroll;
}

</style>