let FactorCalculation = {
    props: {
        factor: Object,
    },
    computed: {
        internalFactor() {
            let result = {};
            for (var i in this.factor) {
                if (['d', 'i', 's', 'c'].indexOf(i) != -1) {
                    result[i] = this.factor[i];
                }
            }
            return result;
        },

        factors () {
            let result = []

            for (var i in this.internalFactor) {
                if (this.factor[i] > 4) {
                    // CRIA VETOR COM LETRA E NÚMERO
                    result.push([i.toUpperCase(), this.factor[i]])
                }
            }

            return result.sort(function(a, b) {
                return b[1] - a[1];
            });
        },
        percents () {
            let sum = Object.values(this.internalFactor).reduce((a, b) => {
                return a + b;
            });

            let result = {};

            for(var i in this.internalFactor) {
                result[i] =this.internalFactor[i] / sum * 100;
            }

            return result
        }
    },
    data () {
        return {
            highFactor: 30
        }
    }
}
export default FactorCalculation;