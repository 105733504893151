<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="internalRules"
    >
        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot
                    name="label"
                    :inputId="inputId"
                    :label="internalLabel"
                >
                    <label
                        :id="fieldsetId + '__BV_label_'"
                        :for="inputId"
                        class="d-block"
                        v-if="internalLabel"
                    >
                        {{ internalLabel }}
                        <span
                            v-if="required"
                            class="text-danger"
                        >*</span>
                    </label>
                </slot>
            </template>

            <b-input-group class="datepicker-container">

                <b-form-input
                    :id="inputId"
                    v-model="formatted"
                    v-bind="$attrs"
                    :data-cy="internalTestId"
                    :label="internalLabel"
                    :placeholder="internalPlaceholder"
                    @change="onInputChanged"
                    v-mask="'##/##/####'"
                    :class="{'is-invalid' : errors.length > 0}"
                    autocomplete="off"
                ></b-form-input>

                <b-input-group-append>
                    <b-form-datepicker
                        :id="inputId"
                        v-model="internalValue"
                        v-bind="$attrs"
                        :locale="company.config.locale"
                        :class="{'is-invalid' : errors.length > 0}"
                        @context="onContext"
                        button-only
                        right
                        show-decade-nav
                        autocomplete="off"
                        size="sm"
                        button-variant="default"
                    >
                        <template #button-content>
                            <CalendarIcon size="1.5x" />
                        </template>
                    </b-form-datepicker>
                </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>

    </validation-provider>
</template>

<script>
import * as _ from 'lodash-es'

import companyMixin from "@state/company";
import FormFieldBase from "../mixins/Base";
import { ValidationProvider } from "vee-validate";

import { CalendarIcon } from "@vue-hero-icons/outline";

import { format, parse } from "@/plugins/i18n";

/**
 * Componente que encapsula o b-form-datepicker.
 * @see https://bootstrap-vue.org/docs/components/form-datepicker
 */
export default {
    name: "form-field-date",
    mixins: [companyMixin, FormFieldBase],
    components: {
        ValidationProvider,
        CalendarIcon,
    },
    props: {
        /**
         * String ou Objeto contendo as regras de validação do campo
         */
        rules: {
            type: String | Object,
            required: false,
            default: () => {
                return { date: true };
            },
        },
    },
    data() {
        return {
            formatted: "",
        };
    },
    mounted() {
        if (_.isString(this.internalRules)) {
            this.internalRules = _.isEmpty(this.internalRules)
                ? "date"
                : this.internalRules + "|date";
        } else {
            this.internalRules.date = true;
        }
    },
    methods: {
        onInputChanged(value) {
            this.$refs.validator.validate(value).then(({ valid }) => {
                if (valid) {
                    let parsed = parse(value, "P");
                    // SET THE VALUE ON DATEPICKER
                    this.internalValue = format(parsed, "yyyy-MM-dd");
                }
            });
        },
        onContext(ctx) {
            // The date formatted in the locale, or the `label-no-date-selected` string
            //this.formatted = ctx.selectedFormatted
            // The following will be an empty string until a valid date is entered
            //this.selected = ctx.selectedYMD
            if (ctx.selectedDate) {
                this.formatted = format(ctx.selectedDate, "P");
                //this.$nextTick(() => {
                    this.dispatchEvent("change", this.internalValue);
                //});
            }
        },
    },
};
</script>
<!--
<docs>
```vue
  <template>
    <form-field-date v-model="inputValue" name="field-name" />
  </template>

  <script>
    export default {
      data() {
        return { inputValue: '' };
      }
    }
  </script>
```
</docs>
-->
