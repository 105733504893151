<template>
  <div
    class="flex w-full h-100 align-items-center justify-content-center p-4"
    id="page-login"
  >
    <div class="col-md-6 col-lg-6 col-xl-4 px-0">
      <b-card>
        <div class="justify-center items-center">
          <div class="login-container w-full p-2">
            <div class="vx-card__title" v-if="!requestSent">
              <h5>{{ $t("user.reset_email.title") }}</h5>
              <p>{{ $t("user.reset_email.info") }}</p>
            </div>
            <div v-if="requestSent">
              <div>
                <h5 class="text-success">{{ $t("user.forgot.email_sent") }}</h5>
                <p>
                  {{ $t("user.forgot.email_instructions", { new_email }) }}
                </p>
                <p>{{ $t("user.forgot.email_spam_instructions") }}</p>
              </div>
              <div class="mb-4">
                <b-button
                  size="sm"
                  class="btn-block border"
                  variant="link"
                  @click="$router.push({ name: 'login' })"
                  pill
                >
                  {{ $t("user.forgot.return_to") }}
                </b-button>
              </div>
            </div>

            <form v-if="!requestSent" class="mb-4">
              <validation-observer ref="formRules" slim>
                <div class="pb-4">
                  <form-field-input
                    label="Novo e-mail"
                    v-model="passwords.sensitive.new_email"
                    rules="required|email"
                    inputmode="email"
                    type="email"
                  />

                  <form-field-input
                    label="Confirme o novo e-mail"
                    v-model="passwords.sensitive.new_email_confirmation"
                    rules="required|email"
                    inputmode="email"
                    type="email"
                  />

                  <b-button
                    variant="primary"
                    class="btn w-full"
                    @click="submit()"
                    pill
                  >
                    {{ $t("user.reset_email.submit") }}
                  </b-button>
                </div>
              </validation-observer>
            </form>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import companyMixin from "@state/company";
import * as FormComponents from "@components/forms";
import { usePasswords } from "@state/user/passwords";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    ...FormComponents,
  },
  setup() {
    const passwords = usePasswords();

    return {
      passwords,
    };
  },
  created() {
    if (!this.passwords.cpf) {
      this.$router.push({ name: "forgot-account" });
    }

    if (this.$auth.check()) {
      this.$router.push({ name: "profile" });
    }
  },
  data() {
    return {
      requestSent: false,
    };
  },
  methods: {
    new_email() {
      return this.passwords.sensitive.new_email;
    },
    submit() {
      this.$refs.formRules.validate().then((success) => {
        this.$root.loading = true;

        if (success) {
          this.passwords
            .changeEmail({
              email: this.passwords.sensitive.new_email,
              cpf: this.passwords.cpf,
            })
            .then((response) => {
              setTimeout(() => {
                this.passwords
                  .forgot({ cpf: this.passwords.cpf })
                  .then((response) => {
                    if (response.data.success) {
                      this.requestSent = false;
                      this.$nextTick(() => {
                        this.requestSent = true;
                        console.log("re-render start");
                        this.$nextTick(() => {
                          console.log("re-render end");
                        });
                      });
                    }
                  })
                  .finally(() => {
                    this.$root.loading = false;
                  });
              }, 2000);
            })
            .catch((error) => {
              this.$root.loading = false;
              console.log(error);
              return;
            });
        }
      });
    },
  },
};
</script>