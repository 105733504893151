<template>
    <div>
        <form-set-generic
            v-if="section.additional.show"
            v-model="curriculum.additional"
            form="curriculum"
            section="additional"
            :fields="section.additional.fields"
            :title="$t('curriculum.sections.additional.title')"
            class="profile-subsection"
        >
            <template #abroad_experience="{ model, field, componentName, testId, component }">
                <span>
                    <component
                        v-if="model['has_abroad_experience']"
                        :is="component.is"
                        :name="componentName"
                        :test-id="testId"
                        v-model="model[field]"
                        v-bind="component"
                    />
                </span>
            </template>
        </form-set-generic>
    </div>
</template>

<script>
import { useCurriculum } from '@state/user/curriculum'
import * as FormComponents from '@components/forms'
import FormSetGeneric from '@components/forms/set/Generic.vue'

export default {
    components: {
        FormSetGeneric,
        ...FormComponents
    },
    setup() {
        const curriculum = useCurriculum()
        return {
            // you can return the whole store instance to use it in the template
            curriculum
        }
    },
    props : {
        section : {
            type : Object,
            required: true
        }
    }
};
</script>
