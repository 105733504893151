<template>
  <validation-provider
    ref="validator"
    #default="{ errors }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
  >
    <draggable
      v-model="section.data.images"
      group="people"
      @start="drag = true"
      @end="drag = false"
    >
      <div
        class="card mb-3"
        v-for="image in imagesList"
        v-bind:key="image.position"
      >
        <div class="row no-gutters">
          <div class="col-md-0 align-self-center p-2" style="cursor: grab">
            <DotsVerticalIcon />
          </div>
          <div class="col-md-11">
            <div>
              <img :src="image.src" class="card-img" alt="" />

              <div
                class="card-img-overlay d-flex align-items-center justify-content-center"
              >
                <file-upload
                  :id="image.position"
                  :input-id="image.position + '-file-html'"
                  v-model="internalFile[image.position]"
                  :data="{ position: image.position, type: uploadType }"
                  :accept="accept"
                  :extensions="extensions"
                  post-action="/backend/files/upload-files-customize"
                  :size="1024 * 1024 * 8"
                  @input-file="
                    (newFile, oldFile) =>
                      inputFile(newFile, oldFile, image.position)
                  "
                  @input-filter="inputFilter"
                  :drop="$refs.droppable"
                  class="btn-sm btn-dt-secondary border rounded-pill cursor-pointer"
                  ref="upload"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  v-bind="$attrs"
                >
                  {{
                    image.src && image.src.length > 0
                      ? $t("image.change_image")
                      : $t("image.send_image")
                  }}
                </file-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <div class="pt-4">
      <b-row cols="3">
        <b-col>
          <span>Dimensões recomendadas:</span>
        </b-col>
        <b-col>
          <span>Tamanho máximo do arquivo:</span>
        </b-col>
        <b-col>
          <span>Arquivos suportados:</span>
        </b-col>
        <b-col>
          <span class="text-medium">1920 x 560px</span>
        </b-col>
        <b-col>
          <span class="text-medium">10mb</span>
        </b-col>
        <b-col>
          <span class="text-medium">JPG ou PNG</span>
        </b-col>
      </b-row>
    </div>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";
import draggable from "vuedraggable";
import FormFieldBase from "../mixins/Base";
import FileUpload from "vue-upload-component";
import { ValidationProvider } from "vee-validate";
import { DotsVerticalIcon } from "@vue-hero-icons/outline";

export default {
  mixins: [FormFieldBase],
  components: {
    draggable,
    FileUpload,
    DotsVerticalIcon,
    ValidationProvider,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    uploadType: {
      type: String,
      required: false,
      default: "banner",
    },
  },
  data() {
    return {
      internalFile: [],
      files: [],
      accept: "image/png,image/jpeg,image/jpg",
      extensions: "jpg,jpeg,png",
      showPreview: [true, true, true],
    };
  },
  computed: {
    imagesList() {
      let counter = 0;
      return _.map(this.section.data.images, (item) => {
        item.position = counter++;
        return item;
      });
    },
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },

    inputFile(newFile, oldFile, position) {
      if (newFile && !oldFile) {
        // Add file
        //            this.$refs.upload[position].active = true;
      } else if (newFile && oldFile) {
        // Update file
        this.showPreview[position] = false;
        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // min size
          /*
                if (newFile.size >= 0 && newFile.size < 100 * 1024) {
                    newFile = this.$refs.upload.update(newFile, {error: 'size'})
                }
                */
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          console.log("error", newFile.error, newFile);
          this.uploadError = true;
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.uploadError = false;
          if (this.internalFile.length > 0) {
            //this.alreadyLoaded.splice(0, this.alreadyLoaded.length);
          }

          this.showPreview[position] = true;

          //this.dispatchEvent("change", newFile.response.value);

          this.section.data.images[position].src = newFile.response.path;
        } else {
          this.uploadError = true;
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload[position].active) {
          newFile.headers = {
            Authorization: `Bearer ${this.$auth.token()}`,
          };
          newFile.data;

          this.$refs.upload[position].active = true;
        }
      }
    },
  },
};
</script>
