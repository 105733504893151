<template>
  <validation-provider
    ref="validator"
    #default="{ errors, required }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
  >
    <b-form-group
      :name="internalVid"
      :id="fieldsetId"
      :label="internalLabel"
      :label-for="inputId"
      :state="errors.length == 0"
    >
      <template #label>
        <slot name="label" :inputId="inputId" :label="internalLabel">
          <label
            :id="fieldsetId + '__BV_label_'"
            :for="inputId"
            class="d-block"
            v-if="internalLabel"
          >
            {{ internalLabel }}
            <span v-if="required" class="text-danger">*</span>
          </label>
        </slot>
      </template>

      <div class="d-flex justify-content-start mb-2">
        <b-avatar :src="internalValue" size="5rem"></b-avatar>

        <div>
          <file-upload
            :input-id="inputId"
            v-model="internalFile"
            :data="{ position: position, type: uploadType }"
            :accept="accept"
            :extensions="extensions"
            post-action="/backend/files/upload-files-customize"
            @input-file="
              (newFile, oldFile) => inputFile(newFile, oldFile, position)
            "
            @input-filter="inputFilter"
            :drop="$refs.droppable"
            class="btn border m-2 btn-outline-primary btn-sm rounded-pill cursor-pointer"
            ref="upload"
            :class="{ 'is-invalid': errors.length > 0 }"
            v-bind="$attrs"
          >
            {{ internalValue > 0 ? "Trocar imagem" : "Enviar imagem" }}
          </file-upload>
        </div>
      </div>

      <div class="flex flex-column">
        <div class="text-info">
          {{ $t("forms.fields.allowed_extensions") }}:
          {{ extensions.split(",").join(", ") }}
        </div>
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import FileUpload from "vue-upload-component";
import FormFieldBase from "../mixins/Base";
import { ValidationProvider } from "vee-validate";
import LetterAvatar from "@components/avatar/LetterAvatar.vue";
import { BAvatar } from "bootstrap-vue";
/**
 * Componente de upload, permitindo visualização, upload de avatar e cropping
 *
 * @see https://lian-yue.github.io/vue-upload-component/#/en/
 */
export default {
  name: "form-field-upload-avatar",
  mixins: [FormFieldBase],
  components: {
    ValidationProvider,
    FileUpload,
    BAvatar,
    LetterAvatar,
  },
  props: {
    item:{
      type: Object,
      required: true,
    },
    uploadType: {
      type: String,
      required: false,
      default: "attest-avatar",
    },
  },
  data() {
    return {
      internalFile: [],
      alreadyLoaded: [],
    };
  },
  computed: {
    position() {
      return this.internalTestId.at(-1);
    },
    extensions() {
      return ["jpg", "jpeg", "gif", "png", "webp"].join(",");
    },
    accept() {
      return [
        "image/png",
        "image/gif",
        "image/jpeg",
        "image/jpg",
        "image/webp",
      ].join(",");
    },
  },
  watch: {},
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile, position) {
      if (newFile && !oldFile) {
        // Add file
        //            this.$refs.upload[position].active = true;
      } else if (newFile && oldFile) {
        // Update file

        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // min size
          /*
                if (newFile.size >= 0 && newFile.size < 100 * 1024) {
                    newFile = this.$refs.upload.update(newFile, {error: 'size'})
                }
                */
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          console.log("error", newFile.error, newFile);
          this.uploadError = true;
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.uploadError = false;

          this.item.image = (newFile.response.path +'?' + new Date().getTime());
        } else {
          this.uploadError = true;
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload.active) {
          newFile.headers = {
            Authorization: `Bearer ${this.$auth.token()}`,
          };
          newFile.data;

          this.$refs.upload.active = true;
        }
      }
    },
  },
};
</script>