<template>
    <div>
        <span v-if="step.status == 2">
            {{ getTranslatedContent(step) }}
        </span>
        <span v-else-if="step.status == 1">
            {{ getTranslatedContent(step) }}
            <router-link :to="{ name: step.link.name, params: step.link.params }"
                v-slot="{ navigate, href, isExactActive }">
                <a :href="href" @click="navigate" :class="{ 'active': isExactActive }">
                    <span>{{ $t(step.link.text) }}</span>
                </a>
            </router-link>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        step: {
            type: Object,
            required: true
        }
    },

    methods: {
        getTranslatedContent(item = {}) {
            const content = item.content ? item.content : '';
            const additional_params = item.content_params ? item.content_params : {};
            return this.$t(content, additional_params);
        }
    }
}
</script>