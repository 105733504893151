<template>
  <section id="sobre" data-scroll-to="rich-text">
    <h4 class="mb-4" v-if="title">{{ title }}</h4>

    <p>{{ text }}</p> 

    <div class="embed-container" v-if="getEmbedVideo">
      <iframe :src="getEmbedVideo" :alt="subtitle" frameborder="0" allowfullscreen></iframe>
    </div>
    <div v-else-if="imageUrl">
      <img :src="imagesList[imageUrl]" :alt="subtitle" style="height: 100%; width: 100%; object-fit: contain;"/>
    </div>
  </section>
</template>

<script>

import images from '@mixins/images';

export default {
  mixins: [images],
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      required: true,
    },
    videoUrl: {
      type: String,
      required: false,
    },
    imageUrl: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    videoEmbed: {
      type: String,
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    getEmbedVideo() {
      const prefixYoutube = 'https://www.youtube.com/embed/';
      const sufixYoutube = '&controls=1';

      let urlEmbed = '';

      let url = this.videoUrl ? this.videoUrl.split('/').pop() : null;

      if (url) {
          url = url.replace('watch?v=', '');
          url += url.indexOf('?') === -1 ? '?' : '';
          urlEmbed = prefixYoutube + url + sufixYoutube;
      }

      return urlEmbed;
    }
  }
};
</script>
