/*
export const saveQuestion = (interviewId, questionIndex, answer) => {
    return Http.post(
        '/evaluation/test/answers/' + this.interviewId + '/' + questionIndex, answer
    ).then(({ data }) => {
        this.answers[questionIndex].status = 'SAVED';
        this.$vs.loading.close()
    }).catch((error) => {
        this.$vs.loading.close()
    });
*/
import Http from '@/plugins/axios'; // não precisa de .js
import Service from '@services/Service'
import { promise } from '@services/Faker'


const ROOT = '/domains';

let INSTANCE = null;

/**
 * @type {Api}
 */
export default class Domain extends Service {
    /* ADDING A SINGLETON */
    static build (options) {
        if (INSTANCE) {
            return INSTANCE;
        }
        INSTANCE = new this(options)
        return INSTANCE;
    }    
    /**
    * @param {Object} options
    * @param {string} resource
    */
    constructor (options, resource) {
        super(options)
        this.resource = resource
        this.domains = {};
        this.promises = {};
    }

    read(path, cache) {
        if (!cache) {
            cache = true;
        }

        if (cache && this.promises[path]) {
            return this.promises[path];
        } else if (cache && this.domains[path]) {
            return Promise.resolve({
                data : this.domains[path]
            });
        } else {
            return this.promises[path] = Http.get(`${ROOT}/${path}`).then((response) => {
                this.domains[path] = response.data;

                return response;
            })
        }
    }

    post(path, data) {
        return Http.post(`${ROOT}/${path}`, data);
    }

}    