<template>
  <div
    class="vaga vacancy-full"
    :class="{
      'rounded-top': rounded,
      scrollable: !noScroll,
      'is-focused': forceFocus,
    }"
    v-if="show && item"
  >

    <script type="application/ld+json" v-if="item.google_jobs_posting">
      {{ item.google_jobs_posting }}
    </script>

    <div
      class="d-flex align-items-center justify-content-between sticky-top bg-white p-4"
      v-if="forceFocus"
    >
      <a class="mr-4 text-default cursor-pointer" @click="$router.back()">
        <ArrowLeftIcon size="1.5x" />
      </a>

      <div
        class="d-flex flex-column justify-content-between"
        v-if="!titleIsVisible"
      >
        <p class="text-medium m-0">
          {{ item.occupation }}
        </p>
        <p class="subtitle text-sm m-0">
          {{
            item.company_name
              ? item.company_name
              : item.publisher_company
              ? item.publisher_company
              : ""
          }}
        </p>
      </div>

      <a class="mr-4 text-default" v-if="!titleIsVisible">
        <vacancy-share-button :item="item" />
      </a>
    </div>
    <!-- MOBILE HEADER -->
    <div
      class="d-flex align-items-center justify-content-between sticky-top bg-white p-4"
      v-if="!forceFocus && isMobile()"
    >
      <a class="mr-4 text-default" @click="$emit('close')">
        <XIcon size="1.5x" />
      </a>

      <div
        class="d-flex flex-column justify-content-between"
        v-if="!titleIsVisible"
      >
        <p class="text-medium m-0">
          {{ item.occupation }}
        </p>
        <p class="subtitle text-sm m-0">
          {{
            item.company_name
              ? item.company_name
              : item.publisher_company
              ? item.publisher_company
              : ""
          }}
        </p>
      </div>

      <a class="mr-4 text-default">
        <!-- <ShareIcon size="1.5x" /> -->
        <vacancy-share-button :item="item" />
      </a>
    </div>
    <!-- END MOBILE HEADER -->
    <div class="banner border-bottom bg-white">
      <!-- :class="{ 'sticky-top': !isMobile() }" -->

      <vacancy-banner
        :item="item"
        v-if="options.header_image && !compact_mode"
        v-b-visible="bannerVisibleHandler"
        aspect="1280:366"
        :isLoading="isLoading"
      />

      <vacancy-title
        class="p-6"
        :item="item"
        :tags="options.tags"
        :compact="compact_mode"
        v-b-visible="titleVisibleHandler"
        :bookmarked="bookmarked"
        :isLoading="isLoading"
      />
    </div>

    <div class="dados-vaga p-6" :class="{ 'mb-20': !titleIsVisible }">
      <vacancy-details :item="item" :isLoading="isLoading" />
    </div>

    <div
      class="vacancy-full-footer p-4 border justify-content-between gap-2 w-100 text-nowrap"
      v-if="!titleIsVisible"
    >
      <!-- <b-button pill variant="outline-primary mr-4">Compartilhar</b-button> -->
      <b-button
        pill
        @click="enroll(item.id)"
        variant="primary m-0"
        :size="compact_mode ? 'sm' : 'md'"
        block
      >
        {{ $t("vacancy.actions.apply") }}
      </b-button>

      <span id="save-vacancy">
        <b-button
          pill
          variant="outline-primary m-0"
          @click="save(item.id)"
          :size="compact_mode ? 'sm' : 'md'"
          block
          no-scroll
          :disabled="!$auth.check()"
          >{{ $t("vacancy.actions.save") }}
        </b-button>
      </span>

      <b-tooltip target="save-vacancy">
        {{
          $auth.check()
            ? $t("vacancy.actions.save_vacancy")
            : $t("vacancy.actions.log_to_save")
        }}
      </b-tooltip>
    </div>
  </div>
</template>
<script>
import { useTheme } from "@state/system/theme";

import { ShareIcon, XIcon, ArrowLeftIcon } from "@vue-hero-icons/outline";

import VacancyBanner from "@blocks/vacancy/VacancyBanner.vue";
import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";
import VacancyShareButton from "@blocks/vacancy/VacancyShareButton.vue";

import VacancyTitle from "@blocks/vacancy/VacancyTitle.vue";
import VacancyDetails from "@blocks/vacancy/VacancyDetails.vue";

import companyMixin from "@state/company";

export default {
  components: {
    VacancyHeader,
    VacancyBanner,
    VacancyShareButton,
    VacancyTitle,
    VacancyDetails,
    XIcon,
    ShareIcon,
    ArrowLeftIcon,
  },
  mixins: [companyMixin],
  props: {
    item: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    // slim: {
    // 	type: Boolean,
    // 	default: false,
    // },
    // stacked: {
    // 	type: Boolean,
    // 	default: false,
    // },
    rounded: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    bookmarked: {
      type: Boolean,
      default: false,
    },
    forceFocus: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return { header_image: true, tags: true, header: false };
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      compact_mode: false,
      titleIsVisible: true,
      bannerIsVisible: true,
      func: null,
    };
  },
  setup() {
    const theme = useTheme();

    return {
      isMobile: theme.isMobile,
    };
  },
  mounted() {
    if (!this.noScroll && !this.isMobile()) {
      this.initScroll();
    }
  },
  destroyed() {
    this.destroyScroll();
  },
  computed: {
    compactMode() {
      return !this.bannerIsVisible && this.$el.scrollTop > 0;
    },
  },
  methods: {
    bannerVisibleHandler(isVisible) {
      this.bannerIsVisible = isVisible;
    },
    titleVisibleHandler(isVisible) {
      this.titleIsVisible = isVisible;
    },
    initScroll() {
      /*
            this.compact_mode = this.$el.scrollTop > 0 && this.$el.scrollTop + this.$el.clientHeight > this.$el.scrollHeight * 0.9;

            console.info('scroll', this.$el.scrollTop, this.$el.clientHeight, this.$el.scrollHeight, this.$el.scrollHeight * 0.9);


            this.func = _.debounce(
                () => {
                    console.info('scroll', this.$el.scrollTop, this.$el.clientHeight, this.$el.scrollHeight, this.$el.scrollHeight * 0.9);

                    this.compact_mode =
                        // this.$el.scrollTop > 0 &&
                        this.$el.scrollTop + this.$el.clientHeight >
                        this.$el.scrollHeight * 0.9;
                },
                2500,
                { maxWait: 1000 }
            );

            this.$el.addEventListener("scroll", this.func);
            */
    },
    destroyScroll() {
      //this.$el.removeEventListener("scroll", this.func);
    },
  },
};
</script>
