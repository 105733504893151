<template>
  <div class="vacancy-list" v-if="!isLoading">

    <!-- Page title -->
    <div class="d-flex justify-between align-items-center mb-8">
      <h2 class="text-3xl font-weight-bold">{{ $t("home.messages") }}</h2>
    </div>

    <!-- Page filters -->
    <div class="search-bar py-2">
      <b-input-group>
        <template #prepend>
          <b-input-group-text class="bg-white">
            <b-icon icon="search" class="text-sm" />
          </b-input-group-text>
        </template>
        <b-form-input
          :placeholder="$t('messages.search')"
          v-model.lazy="searchQuery"
        ></b-form-input>
        <template #append>
          <b-dropdown variant="default" no-caret right size="sm">
            <template #button-content>
              <AdjustmentsIcon /> <span class="sr-only">Filter</span>
            </template>
            <b-dropdown-item v-for="item in filterByOptions" :key="item.slug" @click="filterBy(item.slug)">
              <span class="mx-1">{{ $t(`messages.${item.slug}`) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>        
      </b-input-group>
    </div>  
    
    <!-- Page clear filters -->
    <div class="border px-4 py-3 rounded-top bg-white d-flex gap-2">
      <div class="flex-grow text-right">
        <b-button variant="link" size="xs" @click="clearConditions()" class="">
          {{ $t("vacancy.cleanFilters") }}
        </b-button>
      </div>
    </div>

    <!-- Page content not found -->
    <div
      v-if="!isLoading && mails.length <= 0"
      class="border rounded-bottom bg-white d-flex gap-4 flex-column align-items-center p-8"
    >
      <div class="rounded-pill p-5 bg-surface-informational mb-2">
        <SearchIcon />
      </div>
      <h4 class="text-lg font-weight-semibold">
        {{ $t("messages.noMessagesFound") }}
      </h4>
    </div>

    <!-- Page main content -->
    <div class="row no-gutters conteudo" v-if="!isLoading && allMails.length > 0">

        <!-- Message card (left slot)  -->
      <div class="d-flex flex-column col-lg-5 cards" v-if="!forceFullMessage || !isMobile()">
        <message-card
          v-for="(mail) in mails"
          :key="mail.application_message_id"
          @click="updateOpenMail(mail)"
          :class="{ selected: mail.application_message_id == openMailId }"
          :mail="mail"
          :isSelected="isMailSelected(mail.application_message_id)"
          @addToSelected="addToSelectedMails"
          @removeSelected="removeSelectedMail"
        />
      </div>

      <!-- Message content (right slot) -->
      <message-full
        v-if="openMailId"
        class="col-lg-7"
        :class="{ 'is-focused': true }"
        :item="openMail"
        @markUnread="updateSingleMarkUnread"
        @removeMail="removeOpenMail"
        @previousMail="previousMail"
        @nextMail="nextMail"
        @moveTo="moveCurrentTo"
        @reply="reply"
        @close="closeMailViewSidebar"
      />

      <!-- Empty Message content (right slot) -->
      <div v-if="!isMobile() && !openMailId" class="col-lg-7 bg-white min-h-100"></div>

    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useMessages } from "@state/user/messages";
import { useTheme } from "@state/system/theme";
import { AdjustmentsIcon } from "@vue-hero-icons/outline"
import MessageCard from "@blocks/message/MessageCard.vue";
import MessageFull from '@blocks/message/MessageFull.vue'

export default {
  mixins: [companyMixin],
  components: {
    MessageCard,
    AdjustmentsIcon,
    MessageFull
  },
  setup() {
    const messages = useMessages();
    const theme = useTheme();

    return {
      messages,
      isMobile: theme.isMobile,
    };
  },
  data() {
    return {
      isLoading : true,
      mails: [],
      allMails: [],
      openMail: null,
      openMailId: null,
      searchQuery: null,
      selectedMails: [],
      isSidebarActive: false,
      showThread: false,
      clickNotClose: true,
      isEmailSidebarActive: true,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
      forceFullMessage: false,
      filterByOptions: [
        { slug: 'all'},
        { slug: 'read'},
        { slug: 'unread'}
      ]
    };
  },
  watch: {
    isSidebarActive(value) {
      if (!value) this.showThread = false;
    },
    mailFilter() {
      this.selectedMails = [];

      const scroll_el = this.$refs.mailListPS.$el || this.$refs.mailListPS;
      scroll_el.scrollTop = 0;

      this.$store.commit("email/UPDATE_MAIL_FILTER", this.$route.params.filter);
      this.$store.dispatch("email/fetchEmails", {
        filter: this.$route.params.filter,
      });
      this.toggleEmailSidebar();
    },
    windowWidth() {
      this.setSidebarWidth();
    },
    searchQuery() {
      this.search();
    }
  },
  computed: {
    isSJ() {
      return this.company.config.sj;
    },
    mailFilter() {
      return this.$route.params.filter;
    },
    emailTags() {
      return this.$store.state.email.mailTags;
    },
    selectAllCheckBox: {
      get() {
        return this.selectedMails.length;
      },
      set(value) {
        value
          ? (this.selectedMails = this.mails.map((mail) => mail.id))
          : (this.selectedMails = []);
      },
    },
    selectAllIcon() {
      return this.selectedMails.length === this.mails.length
        ? "icon-check"
        : "icon-minus";
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    isMailSelected() {
      return (mailId) => this.selectedMails.indexOf(mailId) !== -1;
    },
    windowWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    updateOpenMail(mail) {
      this.openMail = mail;
      this.openMailId = mail.application_message_id;
      this.isSidebarActive = true;

      this.forceFullMessage = true;

      if (!this.openMail.read) {
        this.messages.markAsRead(this.openMailId).then(() => {
          this.openMail.read = new Date();
        });
      }
    },
    nextMail() {
      const currentMailIndex = this.mails.findIndex(
        (mail) => mail.application_message_id === this.openMailId
      );
      if (this.mails[currentMailIndex + 1]) {
        this.openMailId =
          this.mails[currentMailIndex + 1].application_message_id;
        this.openMail = this.mails[currentMailIndex + 1];

        if (!this.openMail.read) {
          this.messages.markAsRead(this.openMailId);
        }
      }
    },
    previousMail() {
      const currentMailIndex = this.mails.findIndex(
        (mail) => mail.application_message_id === this.openMailId
      );
      if (this.mails[currentMailIndex - 1]) {
        this.openMailId =
          this.mails[currentMailIndex - 1].application_message_id;
        this.openMail = this.mails[currentMailIndex - 1];

        if (!this.openMail.read) {
          this.messages.markAsRead(this.openMailId);
        }
      }
    },
    addToSelectedMails(mailId) {
      if (this.selectedMails.indexOf(mailId) === -1)
        this.selectedMails.push(mailId);
    },
    removeSelectedMail(mailId) {
      const mailIndex = this.selectedMails.indexOf(mailId);
      if (mailIndex !== -1) this.selectedMails.splice(mailIndex, 1);
    },
    moveTo(to) {
      const payload = { emailIds: this.selectedMails, to };
      this.$store.dispatch("email/moveMailsTo", payload).catch((error) => {});
      this.selectedMails = [];
    },
    moveCurrentTo(to) {
      this.selectedMails = [this.openMailId];
      this.moveTo(to);
    },
    updateMarkUnread() {
      const payload = { emailIds: this.selectedMails, unreadFlag: true };
      this.$store.dispatch("email/setUnread", payload);
      this.selectedMails = [];
    },
    removeOpenMail() {
      this.selectedMails = [this.openMailId];
      this.moveTo("trashed");
      this.isSidebarActive = false;
    },
    toggleIsStarred() {
      const payload = {
        mailId: this.currentMail.id,
        value: !this.currentMail.isStarred,
      };
      this.$store.dispatch("email/toggleIsMailStarred", payload);
    },
    updateSingleMarkUnread() {
      this.selectedMails = [this.openMailId];
      this.updateMarkUnread();
      this.isSidebarActive = false;
    },
    updateLabels(label) {
      this.$store.dispatch("email/updateLabels", {
        mails: this.selectedMails,
        label,
      });
      this.selectedMails = [];
    },
    closeMailViewSidebar() {
      this.isSidebarActive = false;
      this.openMailId = null
      this.forceFullMessage = false;
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isEmailSidebarActive = this.clickNotClose = false;
      } else {
        this.isEmailSidebarActive = this.clickNotClose = true;
      }
    },
    toggleEmailSidebar(value = false) {
      if (!value) {
        this.closeMailViewSidebar();
        if (this.clickNotClose) {
          return;
        }
      }
      this.isEmailSidebarActive = value;
    },
    fetchAllMails() {
      this.messages.list().then((response) => {
        this.allMails = response;
        this.mails = response;
        this.isLoading = false;
      });
    },
    search() {
      const term = this.searchQuery.toLowerCase();
      this.mails = _.filter(this.allMails, (item) => {
        if (
          item.from.toLowerCase().indexOf(term) > -1 ||
          item.subject.toLowerCase().indexOf(term) > -1
        ) {
          return item;
        }
      });
    },
    reply(payload) {
      this.messages.reply(payload).then((response) => {
        //rehidrate openMail
        const currentMail = response.data.message;
        const mails = [...this.mails];
        const currentMailIndex = mails.findIndex(
          (i) => i.application_message_id === this.openMailId
        );
        this.mails[currentMailIndex] = currentMail;
        this.openMail = currentMail;
      });
    },
    clearConditions() {
      this.searchQuery = null
      this.mails = this.allMails
    },
    filterBy(filter) {

      if (filter === 'all') {
        this.clearConditions()
        return;
      }

      this.mails = _.filter(this.allMails, (item) => {
        if (filter === 'read' && !_.isEmpty(item.read)) {
          return item 
        }

        else if (filter === 'unread' && _.isEmpty(item.read)) {
          return item
        }
      })
    }
  },

  created() {
    
    if (this.isSJ) {
      this.$router.replace({ name: "profile" });
    }

    this.setSidebarWidth();
    this.fetchAllMails();
  }
};
</script>