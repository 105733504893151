<template>
  <div class="wrapper">
	<!-- <img :src="require(`@assets/images/disc-grafico.svg`).default" class="img-fluid"> -->

    <canvas ref="background"  class="layer-1" :height="height" :width="internalWidth"></canvas>
    <canvas ref="line_canvas"  class="layer-2" :height="height" :width="internalWidth"></canvas>
    <canvas ref="point_canvas"  class="layer-3" :height="height" :width="internalWidth"></canvas>
    	
    
  </div>
</template>
<style scoped="true">
	.wrapper {
		/* height: 100%; */
		position: relative;
		display: block;
	}
	.wrapper canvas {
		/* width: 100%; */

		/* height: 100%; */
		position: absolute;
		top: 0;
		left: 0;
	}

	.wrapper canvas.layer-1 {
		/* width: 100%; */

		/* height: 100%; */
		position: relative;
	}
</style>
<script>
export default {
	data() {
    	return {
    		internalValue : this.value,
    		context: null,
    		internalWidth: this.width ? this.width : 400,
    		ratio: 0.648192771,

			HORIZONTAL_MARGIN : 0.039653036,
			VERTICAL_MARGIN : 0.024783147,

			TOP_LEFT : 0,
			BOTTOM_RIGHT: 0,
			SECTION_SIZE: 0,
			LINE_SIZE: 2,
			TITLE_BAR_SIZE : 0.101204819,
			Y_AXIS_POSITIONS: [],
			X_AXIS_POSITIONS: [],
			COLOR_OPTIONS : [
  				{
  					fillStyle : '#f73b3b'
  				},
  				{
  					fillStyle : '#f5b800'
  				},
  				{
  					fillStyle : '#52c01b'
  				},
  				{
  					fillStyle : '#5197e6'
  				},
  			]
		}
	},
	props : {
		width : {
			type: Number,
			default: 400
		},
        value : {
            type: Object,
            required: true
        },
        animated: {
        	type: Boolean,
        	default: true,
        }
	},
	computed: {
		height() {
			return this.internalWidth / this.ratio;
		},
		base_fator() {
			return this.internalWidth / 807;
		}
	},
	watch : {
		'internalValue': {
			handler () {
				this.drawSeries();
			},
			deep: true
		}
	},
	mounted () {
	    // We can't access the rendering context until the canvas is mounted to the DOM.
	    // Once we have it, provide it to all child components.
	    this.context = this.$refs['background'].getContext('2d')
	    this.lineContext = this.$refs['line_canvas'].getContext('2d')
	    this.pointContext = this.$refs['point_canvas'].getContext('2d')

	    
	    this.$nextTick(() => {
			//let f = new FontFace('Roboto', 'url(https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap)');

			//f.load().then(() => {
			  	// Ready to use the font in a canvas context
	   			this.drawGraph();
			//});	    

	    });
	},
  	methods : {
  		drawSection(NUMBER) {

			//var NUMBER = 5;

			// CALCULATE THE NUMBERS
			//var BASE_NUMBER = NUMBER;
			var STARTING = (NUMBER - 1) * 4 + 1;

			// CALCULATE THE SECTION POSITION
			var SECTION_POSITION = NUMBER;

			var LEFT_MARGIN = 10
			var TOP_MARGIN = 5

			var yAxisTop = 0
			var yAxisBottom = 0;

			var yAxisTop = this.BOTTOM_RIGHT[1] - (this.SECTION_SIZE * SECTION_POSITION);
			var yAxisBottom = yAxisTop + this.SECTION_SIZE;	

			var littleNumberSize = ((yAxisBottom - yAxisTop) - (TOP_MARGIN * 2 )) / 4;
			// DRAW BIG NUMBER NUMBERS ON RIGHT	
				//console.warn(j, SECTION_POSITION);
			//console.warn(yAxisTop, yAxisBottom, littleNumberSize);

			var counter = 1;

			this.context.fillStyle = "#435d6b";
			this.context.font = '10px Roboto';
			this.context.textAlign = 'left'
			this.context.textBaseline = 'left'
			this.context.lineWidth = 1;

			for(var j = STARTING; j < STARTING + 4; j++) {
				//this.drawPoint(this.BOTTOM_RIGHT[0], yAxisTop)
				//this.drawPoint(this.TOP_LEFT[0], yAxisBottom)
				// DRAW LITTLE NUMBERS ON LEFT
				this.Y_AXIS_POSITIONS[j] = yAxisBottom - (counter * littleNumberSize) + 3;

				this.context.fillText(
					j.toString(), 
					this.TOP_LEFT[0] + LEFT_MARGIN, 
					this.Y_AXIS_POSITIONS[j]
				);

				counter++;


			}

			this.context.font = 'bold 23px Roboto';
			this.context.textAlign = 'right'

			this.context.fillText(
				NUMBER.toString(), 
				this.BOTTOM_RIGHT[0] - LEFT_MARGIN, 
				yAxisTop + ((yAxisBottom - yAxisTop) / 2)
			);			


  		},
  		drawBackground() {
			// Set line width
			this.context.lineWidth = this.LINE_SIZE;
			this.context.lineJoin = 'round'
			this.context.strokeStyle = "#dedfe1";
			// Container
			this.context.strokeRect(
				this.TOP_LEFT[0],
				this.TOP_LEFT[1],
				this.BOTTOM_RIGHT[0] - (this.HORIZONTAL_MARGIN * this.internalWidth),
				this.BOTTOM_RIGHT[1] - (this.VERTICAL_MARGIN * this.height)
			);
			
			this.context.fillStyle = "#e9f5fb";


			// DRAW THE BLUE RECT ON SECTION 4
			// 
			this.context.fillRect(
				this.TOP_LEFT[0],
				this.BOTTOM_RIGHT[1] - (this.SECTION_SIZE * 4),
				this.BOTTOM_RIGHT[0] - (this.HORIZONTAL_MARGIN * this.internalWidth),
				this.SECTION_SIZE,
			);


			//this.context.beginPath();
			// DRAWING GRID
			var yAxis = 0;

			for(var i = 7; i >= 0; i--) {
				this.context.lineWidth = this.LINE_SIZE;
				this.context.lineJoin = 'round'
				this.context.strokeStyle = "#dedfe1";

				yAxis = 
					this.BOTTOM_RIGHT[1] - (this.SECTION_SIZE * i);


				this.context.moveTo(
					this.TOP_LEFT[0],
					yAxis
				);
				this.context.lineTo(
					this.BOTTOM_RIGHT[0], 
					yAxis
				);

				//this.drawPoint(this.TOP_LEFT[0], yAxis)
				//this.drawPoint(this.BOTTOM_RIGHT[0], yAxis)
			}

			//this.context.closePath();
			this.context.stroke();
  		},
  		drawTitle() {

  			var FONT_SIZE = 80 * this.base_fator;
			this.context.font = 'bold ' + FONT_SIZE + 'px Roboto';
			this.context.textAlign = 'center'
			this.context.textBaseline = 'middle'
			this.context.lineWidth = 1;

			//var VERTICAL_MARGIN = 0.1;

  			var HORIZONTAL_SIZE = this.BOTTOM_RIGHT[0] - this.TOP_LEFT[0];
  			var TITLE_MARGIN = 0.15;


  			// REMOVENDO MARGENS A ESQUERDA E A DIREITA
  			HORIZONTAL_SIZE = HORIZONTAL_SIZE - (TITLE_MARGIN * this.internalWidth * 2);
  			var UNIQUE_SIZE = HORIZONTAL_SIZE / 4

  			var FACTORS = ['D', 'I', 'S', 'C'];

  			for (var i in FACTORS) {
  				this.context.fillStyle = this.COLOR_OPTIONS[i].fillStyle;

  				this.X_AXIS_POSITIONS[i] = 
  					this.TOP_LEFT[0] + // PONTO X A ESQUERDA
					(TITLE_MARGIN * this.internalWidth) + // + A MARGEM
					(UNIQUE_SIZE * i) + // + A POSIÇÂO NA STRING 
					(UNIQUE_SIZE / 2);

				this.context.fillText(
					FACTORS[i], 
					this.X_AXIS_POSITIONS[i], // + CENTRALIZADO

					this.TOP_LEFT[1] + // PONTO Y NO TOPO
					(this.TITLE_BAR_SIZE * this.height / 2) + this.LINE_SIZE, // + CENTRALI NA VERTICAL
					UNIQUE_SIZE
				);
  			}
 		},
  		drawPoint(x, y, radius) {
			this.pointContext.beginPath();
			this.pointContext.arc(x, y, radius, 0, 2 * Math.PI, false);
			this.pointContext.closePath();
			this.pointContext.stroke();
			this.pointContext.fill();
  		},
 		drawLine(from, to) {
			this.lineContext.beginPath();
			this.lineContext.moveTo(
				from[0],
				from[1]
			);
			this.lineContext.lineTo(
				to[0],
				to[1]
			);
			this.lineContext.stroke();
 		},  		
  		drawPointProgress(x, y, ratio, context) {
 			var progress = 0;
 			return new Promise((resolve, reject) => {
				var requestAnimationFunction = () => {
					if (progress < 1) {
						this.pointContext.lineWidth = context.lineWidth;
						this.pointContext.strokeStyle = context.strokeStyle;
						this.pointContext.fillStyle = context.fillStyle;
						

						this.pointContext.clearRect(
							x - 4, 
							y - 4,
							x + 4,
							y + 4
						);

						this.drawPoint(x, y, 5 * progress);
						/*
						this.pointContext.beginPath();
						this.pointContext.arc(x, y, 5 * progress, 0, 2 * Math.PI, false);
						this.pointContext.closePath();
						this.pointContext.stroke();
						this.pointContext.fill();
						*/

						progress += ratio;
						window.requestAnimationFrame(requestAnimationFunction);
					} else {
						//window.clearInterval(interval);
						resolve(true)		
					}
				};

				window.requestAnimationFrame(requestAnimationFunction);
 			});
  		},
 		drawLineProgress(from, to, ratio, context) {
 			var progress = 0;
 			return new Promise((resolve, reject) => {
				var requestAnimationFunction = () => {
					if (progress < 1) {
						this.lineContext.lineWidth = context.lineWidth;
						this.lineContext.strokeStyle = context.strokeStyle;

						this.lineContext.clearRect(
							from[0], 
							from[1],
							to[0] - from[0],
							to[1] - from[1]
						);
						
						//this.lineContext.beginPath();
						this.lineContext.lineWidth = context.lineWidth;
						this.lineContext.strokeStyle = context.strokeStyle;

						this.drawLine(
							from,
							[
								from[0] + ((to[0] - from[0]) * progress),
								from[1] + ((to[1] - from[1]) * progress),
							]
						);

						progress += ratio;
						window.requestAnimationFrame(requestAnimationFunction);
					} else {
						//window.clearInterval(interval);
						resolve(true)		
					}
				};

				window.requestAnimationFrame(requestAnimationFunction);
 			});

 		},
 		async drawSeries() {
			this.pointContext.clearRect(
				0,0,this.internalWidth, this.height
			);
			this.lineContext.clearRect(
				0,0,this.internalWidth, this.height
			);

			var VALUES = [
				this.internalValue.di,
				this.internalValue.ii,
				this.internalValue.si,
				this.internalValue.ci,
			];

		
			var promises = [];
			var path = [];
			for(var i = 0; i < 3; i++) {
				path.push([
					[
						this.X_AXIS_POSITIONS[i],
						this.Y_AXIS_POSITIONS[VALUES[i]]					
					],
					[
						this.X_AXIS_POSITIONS[i+1], 
						this.Y_AXIS_POSITIONS[VALUES[i+1]]					
					],
				]);
			}

			var dots = [];
			for(var i = 0; i < 4; i++) {
				// DRAW THE POINT
				/*
				this.pointContext.lineWidth = 10;
				this.pointContext.strokeStyle = this.COLOR_OPTIONS[i].fillStyle;
				this.pointContext.fillStyle = "white";
				
				this.drawPoint(
					this.X_AXIS_POSITIONS[i],
					this.Y_AXIS_POSITIONS[VALUES[i]]
				);
				*/
				dots.push([
					this.X_AXIS_POSITIONS[i],
					this.Y_AXIS_POSITIONS[VALUES[i]]
				]);
				//this.drawPoint(this.X_AXIS_POSITIONS[i], VALUES[i]);

			}

			if (this.animated) {
				for(var i = 0; i < 4; i++) {
	 				await this.drawPointProgress(
	 					dots[i][0],
	 					dots[i][1],
						0.05,
						{
							lineWidth : 10,
							strokeStyle : this.COLOR_OPTIONS[i].fillStyle,
							fillStyle: "white"
						}
	 				)
	 				if (path[i]) {
		 				await this.drawLineProgress(
		 					path[i][0],
		 					path[i][1],
							0.02,
							{
								lineWidth : 3,
								strokeStyle : "#435D6B"
							}
		 				) 			
	 				}	
				}
			} else {
				this.lineContext.lineWidth = 3;
				this.lineContext.strokeStyle = "#435D6B"

				this.pointContext.lineWidth = 10;
				this.pointContext.fillStyle = "white";

				for(var i = 0; i < 4; i++) {
					//this.lineContext.beginPath();
					if (path[i]) {
						this.drawLine(
							path[i][0],
							path[i][1]
						);
					}

					this.pointContext.strokeStyle = this.COLOR_OPTIONS[i].fillStyle;

	 				this.drawPoint(
	 					dots[i][0],
	 					dots[i][1],
	 					5
	 				);			
	 			}

			}


			/*
			console.warn(path.push);
 			for(var i in path) {
 				await this.drawLineProgress(
 					path[i][0],
 					path[i][1],
					0.02,
					{
						lineWidth : 3,
						strokeStyle : "#435D6B"
					}
 				)
 			}

 			for(var i in dots) {
 				await this.drawPointProgress(
 					dots[i][0],
 					dots[i][1],
					0.02,
					{
						lineWidth : 10,
						strokeStyle : this.COLOR_OPTIONS[i].fillStyle,
						fillStyle: "white"
					}
 				)
 			}
			*/


 		},

  		drawGraph() {
			// BASE VALUES, IN PERCENT
			//var LINE_SIZE = 2;

			this.TOP_LEFT = [
				this.HORIZONTAL_MARGIN * this.internalWidth,
				this.VERTICAL_MARGIN * this.height
			];
			this.BOTTOM_RIGHT = [
				this.internalWidth - (this.HORIZONTAL_MARGIN * this.internalWidth), 

				this.height - (this.VERTICAL_MARGIN * this.height)
			];
			
			var BEGIN = this.TOP_LEFT[1] + (this.TITLE_BAR_SIZE * this.height);
			this.SECTION_SIZE = ((this.height - BEGIN) - (this.LINE_SIZE * 7)) / 7;

			this.drawBackground();

			this.drawTitle();

			// DRAW NUMBERS AND LETTERS (LEGEND)
			for(var i = 1; i <= 7; i++) {
				this.drawSection(i)
			}

			this.rendered = true;

			if (this.internalValue) {
				//this.$nextTick(() => {
					this.drawSeries();

				//})
			}
		}
  	}
}
</script>

