import { defineStore } from 'pinia'
import Http from "@/plugins/axios";

export const useCommunication = defineStore('communication', {
    state: () => {
        return {
        }
    },
    actions : {
        async askForHelp(data) {
            return Http.post(`/communication/ask-for-help`, data).then(({ data }) => {
                return data;
            });            
        },
        async provideFeedback(data) {
            return Http.post(`/communication/provide-feedback`, data).then(({ data }) => {
                return data;
            });            
        },
    }

});
