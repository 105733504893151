<template>
  <div>
    <form-set-generic
      v-if="section.personal.show"
      v-model="curriculum.personal"
      form="curriculum"
      section="personal"
      :fields="section.personal.fields"
      :title="$t('curriculum.sections.personal.title')"
      class="profile-subsection"
    />

    <form-set-generic
      v-if="section.address.show"
      v-model="curriculum.address"
      form="curriculum"
      section="address"
      :fields="section.address.fields"
      :title="$t('curriculum.sections.personal.address')"
      class="profile-subsection"
    />

    <form-set-generic
      v-if="section.contacts.show"
      v-model="curriculum.personal"
      form="curriculum"
      section="personal"
      :fields="section.contacts.fields"
      :title="$t('curriculum.sections.personal.contacts')"
      :subtitle="$t('curriculum.sections.personal.required_contacts')"
      class="profile-subsection"
    />

    <form-set-generic
      v-if="section.cnh.show"
      v-model="curriculum.cnh"
      form="curriculum"
      section="cnh"
      :fields="section.cnh.fields"
      :title="$t('curriculum.sections.personal.cnh')"
      class="profile-subsection"
    />

    <form-set-pcd
      v-if="section.pcd.show"
      v-model="curriculum.personal"
      :fields="section.pcd.fields"
      class="profile-subsection"
    />

    <form-set-generic
      v-if="section.diversity.show"
      v-model="curriculum.diversity"
      form="curriculum"
      section="diversity"
      :fields="section.diversity.fields"
      :title="$t('curriculum.sections.personal.diversity')"
      :subtitle="$t('curriculum.sections.personal.diversity_subtitle')"
      class="profile-subsection"
    />

    <!-- ** @TODO configurar o as ações dos campos extras **
    <form-set-extra-fields
      v-if="section.extra.show"
      v-model="curriculum.extra"
      :fields="section.diversity.fields"
      class="profile-subsection"
      :title="$t('curriculum.sections.personal.extra')"
    /> -->
    <form-set-generic
      v-if="section.extra.show"
      v-model="curriculum.extra"
      form="curriculum"
      section="extra"
      :fields="section.extra.fields"
      :title="$t('curriculum.sections.personal.extra')"
      class="profile-subsection"
    />
  </div>
</template>

<script>
import FormSetPcd from "@components/forms/set/Pcd.vue";
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import FormSetExtraFields from "@components/forms/set/ExtraFields.vue";

export default {
  components: {
    FormSetGeneric,
    FormSetPcd,
    FormSetExtraFields,
  },
  setup() {
    const curriculum = useCurriculum();
    return {
      // you can return the whole store instance to use it in the template
      curriculum,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        pcd: [
          "personal.special_needs",
          "personal.special_needs_item",
          "personal.special_needs_details",
        ],
      },
    };
  },

  methods: {},
  watch: {},
};
</script>
