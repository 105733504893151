<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <div>
        <div class="d-flex align-items-center">
          <b-skeleton width="50%" height="5%"></b-skeleton>
        </div>
        <div class="detalhes">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="90%"></b-skeleton>
          <b-skeleton width="65%"></b-skeleton>
          <b-skeleton width="75%"></b-skeleton>
        </div>      
        <div class="d-flex align-items-center">
          <b-skeleton width="50%" height="5%"></b-skeleton>
        </div>
        <div class="detalhes">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="90%"></b-skeleton>
          <b-skeleton width="65%"></b-skeleton>
          <b-skeleton width="75%"></b-skeleton>
        </div>   
        <div class="d-flex align-items-center">
          <b-skeleton width="50%" height="5%"></b-skeleton>
        </div>
        <div class="detalhes">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="90%"></b-skeleton>
          <b-skeleton width="65%"></b-skeleton>
          <b-skeleton width="75%"></b-skeleton>
        </div>                   
      </div>
    </template>

    <div class="vacancy-details">
      <div
        class="d-flex align-items-center"
        v-if="
          item.contract_type ||
          item.interest_area ||
          item.workload ||
          item.work_schedule ||
          item.salary ||
          item.is_special_needs_job
        "
      >
          <h6 class="subtitle">{{ $t("vacancy.vacancy_info") }}</h6>
      </div>
      <div class="detalhes">
        <ul class="list-unstyled">
          <li v-if="item.contract_type">
            {{ $t("vacancy.contract_type") + " " + item.contract_type }}
          </li>
          <li v-if="item.interest_area">
            {{ $t("vacancy.interest_area") + " " + item.interest_area }}
          </li>
          <li v-if="item.workload">
            {{ $t("vacancy.workload") + " " + item.workload }}
          </li>
          <li v-if="item.work_schedule">
            {{ $t("vacancy.work_schedule") + " " + item.work_schedule }}
          </li>
          <li v-if="item.salary">
            {{ $t("vacancy.salary") + " " + item.salary }}
          </li>
          <li v-if="item.is_special_needs_job || (item.info_special_needs != null && item.info_special_needs != '')">
            {{ $t("vacancy.pcd") + " " + $t("common.yes") }}
          </li>
          <li v-if="item.workplace_type">
            {{ $t("vacancy.workplace_type") + $t(getWorkplaceType(item.workplace_type)) }}
          </li>
        </ul>
      </div>

      <div class="d-flex align-items-center" v-if="item.activities">
        <!-- <b-icon
          class="rounded-circle bg-secondary p-2"
          font-scale="2"
          variant="primary"
          icon="person-check-fill"
        /> -->
        <h6 class="subtitle">{{ $t("vacancy.responsabilities") }}</h6>
      </div>
      <div class="detalhes" v-if="item.activities">
        <p>{{ item.activities }}</p>
      </div>
      <div class="d-flex align-items-center" v-if="item.requirements">
        <!-- <b-icon
          class="rounded-circle bg-secondary p-2"
          font-scale="2"
          variant="primary"
          icon="tools"
        /> -->
        <h6 class="subtitle">{{ $t("vacancy.requirements") }}</h6>
      </div>
      <div class="detalhes" v-if="item.requirements">
        <p>{{ item.requirements }}</p>
      </div>
      <div class="d-flex align-items-center" v-if="item.desirable">
        <!-- <b-icon
          class="rounded-circle bg-secondary p-2"
          font-scale="2"
          variant="primary"
          icon="hand-thumbs-up-fill"
        /> -->
        <h6 class="subtitle">{{ $t("vacancy.desirable") }}</h6>
      </div>
      <div class="detalhes" v-if="item.desirable">
        <ul>
          <li v-for="item in item.desirable">{{ item }}</li>
        </ul>
      </div>

      <div class="d-flex align-items-center" v-if="item.info_company">
        <!-- <b-icon
          class="rounded-circle bg-secondary p-2"
          font-scale="2"
          variant="primary"
          icon="briefcase-fill"
        /> -->
        <h6 class="subtitle">{{ $t("vacancy.company") }}</h6>
      </div>
      <div class="detalhes" v-if="item.info_company">
        <p>{{ item.info_company }}</p>
      </div>

      <div class="d-flex align-items-center" v-if="item.benefits">
        <!-- <b-icon
          class="rounded-circle bg-secondary p-2"
          font-scale="2"
          variant="primary"
          icon="star-fill"
        /> -->
        <h6 class="subtitle">{{ $t("vacancy.benefits") }}</h6>
      </div>
      <div class="detalhes" v-if="item.benefits">
        <p>{{ item.benefits }}</p>
      </div>

      <div class="d-flex align-items-center" v-if="item.additionalInfo">
        <!-- <b-icon
          class="rounded-circle bg-secondary p-2"
          font-scale="2"
          variant="primary"
          icon="three-dots"
        /> -->
        <h6 class="subtitle">{{ $t("vacancy.additional_info") }}</h6>
      </div>

      <div class="d-flex align-items-center" v-if="item.is_special_needs_job || (item.info_special_needs != null && item.info_special_needs != '')">
        <!-- <b-icon
          class="rounded-circle bg-secondary p-2"
          font-scale="2"
          variant="primary"
          icon="eyeglasses"
        /> -->
        <h6 class="subtitle">{{ $t("vacancy.pcdInfo") }}</h6>
      </div>

      <div class="detalhes" v-if="item.is_special_needs_job || (item.info_special_needs != null && item.info_special_needs != '')">
        <p>{{ item.info_special_needs }}</p>
      </div>

      <div class="detalhes" v-if="item.additionalInfo">
        <ul>
          <li v-for="item in item.additionalInfo">{{ item }}</li>
        </ul>
      </div>

      <h6 class="">
        <small>{{ $t("vacancy.codVacancy") + " " + item.id }} </small>
      </h6>

    </div>
  </b-skeleton-wrapper> 
</template>
<script>
import companyMixin from "@state/company";
import vacanciesMixin from "@mixins/vacancies";
export default {
  mixins: [companyMixin, vacanciesMixin],
  props: {
    item: {
      required: true,
      type: Object,
    },
    isLoading : {
      type : Boolean,
      default: false
    }
  },
};
</script>
