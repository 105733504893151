//import './openvidu-browser-2.20.0.min';

class SessionManager {
  //requestConfig
  //onSuccess
  //onError
  //axios

    constructor(axios, backend = 'openvidu') {

        this.$http = axios;

        this.backend = 'openvidu';

        this.openVidu = new OpenVidu();
        this.openVidu.setAdvancedConfiguration({
            forceMediaReconnectionAfterNetworkDrop: true,
        })



        this.urls = {
            'get-token' : '/media/video/get-token',
            'recording-start' : '/media/video/recording/start',
            'recording-stop' : '/media/video/recording/stop',
            'recording-list' : '/media/video/recording/list',
        };

        this.session = null;
        this.publisher = null;
        this.token = null;

        /*axios
        this.url = url

        this.requestConfig = options.requestConfig
        this.onSuccess = options.onSuccess
        this.onError = options.onError
        this.axios = axios

        //this.started = false
        this.loading = false
        this.response = {}
        */
    }

    checkSystemRequirements() {
            return this.openVidu.checkSystemRequirements() == 1;
    }

    request(method, url, body, errorMsg) {
        return this.$http.request({
            url : this.urls[url],
            method: method,
            //baseURL : "http://localhost:5000",
            headers: {'Content-type': 'application/json'},
            data: JSON.stringify(body),
            //data: body,
            withCredentials: false,
            responseType: 'json'
        });/*.then(function(response) {
            //if (response.status == 200) {
            //callback(response);
        }).catch((response) => {
            //callback(response);
        });*/
    }

    stopSession() {
        if (this.session) {
            this.session.disconnect();

            //this.session.unpublish();

            // REMOVE ACCESS TO CAMERFA AND MIC

        }
    }

    stopPublishing() {
        if (this.publisher) {

            this.publisher.stream.disposeWebRtcPeer();
            this.publisher.stream.disposeMediaStream();

            if (this.session) {
                //this.publisher.publishVideo(false)
                ///this.publisher.publishAudio(false)

                this.session.unpublish(this.publisher);
            }
        }
    }

    getLocalRecorder(stream) {
        return this.localRecorder = this.openVidu.initLocalRecorder(stream);
    }

    getPublisher(options, callback) {
        if (!this.publisher) {
            this.publisher = this.openVidu.initPublisher(undefined, options);

            this.publisher.once('streamDestroyed', (ev) => {
                ev.preventDefault()
            });
        }

        callback(this.session, this.publisher);
    }

    getSession(options) {
        if (this.backend == 'openvidu') {

        }

        return new Promise((resolve, reject) => {
            this.getToken(options).then((token) => {
                if (!this.session) {
                    this.session = this.openVidu.initSession();
                }

                if (!this.session.connection) {
                    this.session.connect(this.token)
                        .then(() => {
                            resolve()
                        })
                        .catch((error) => {
                            reject(error)
                        })
                } else {
                    resolve(true)
                }
            }).catch((error) => {
                reject(error)
            })
        });
    }

    getToken(options) {
        return new Promise((resolve, reject) => {
            if (!this.token) {
                //let sessionName = $("#sessionName").val(); // Video-call chosen by the user
                this.request(
                    'post',
                    'get-token',
                    options,
                    'Request of TOKEN gone WRONG:'
                ).then((res) => {
                    if (res.status == 200) {
                        this.token = res.data.token; // Get token from response

                        //callback(); // Continue the join operation
                        resolve(this.token)
                    } else {
                        reject(res);
                    }
                }).catch((response) => {
                    reject(response);
                })
            } else {
                resolve(this.token);
            }
        });
    }

    startRecording(recording_name) {
        return this.request(
            'post',
            'recording-start',
            {
                session: this.session.sessionId,
                outputMode: "INDIVIDUAL",
                name: recording_name,
                hasAudio: true,
                hasVideo: true
            },
            'Start recording WRONG'
        ).then((res) => {
            if (res.status == 200) {
                this.recordingData = res.data;
            } else {
                //this.getRecording();
            }
            return res;
        });
    }

    stopRecording() {
        var forceRecordingId = this.recordingData.id;

        return this.request(
            'post',
            'recording-stop',
            {
                recording: this.recordingData.id
            },
            'Start recording WRONG',
        ).then((res) => {
            if (res.status == 200) {
                this.recordingData = res.data;
                return this.recordingData;
            }
            return false;
        });
    }
}

export default SessionManager
