import * as _ from "lodash-es";

function cleaner(value) {
  const digits = value.replace(/[^\d]/g, '')
  return digits
}

function checkNormalCns(value) {
  /**
   * CNS NORMAL COM INICIO 1 ou 2
   */
  if (value.trim().length !== 15) {
    return false;
  }

  let soma = 0;
  let resto, dv;
  let pis = "";
  let resultado = "";
  pis = value.substring(0, 11);

  soma = ((parseInt(pis.substring(0, 1))) * 15) +
    ((parseInt(pis.substring(1, 2))) * 14) +
    ((parseInt(pis.substring(2, 3))) * 13) +
    ((parseInt(pis.substring(3, 4))) * 12) +
    ((parseInt(pis.substring(4, 5))) * 11) +
    ((parseInt(pis.substring(5, 6))) * 10) +
    ((parseInt(pis.substring(6, 7))) * 9) +
    ((parseInt(pis.substring(7, 8))) * 8) +
    ((parseInt(pis.substring(8, 9))) * 7) +
    ((parseInt(pis.substring(9, 10))) * 6) +
    ((parseInt(pis.substring(10, 11))) * 5);

  resto = soma % 11;
  dv = 11 - resto;

  if (dv === 11) {
    dv = 0;
  }

  if (dv === 10) {
    soma = ((parseInt(pis.substring(0, 1))) * 15) +
      ((parseInt(pis.substring(1, 2))) * 14) +
      ((parseInt(pis.substring(2, 3))) * 13) +
      ((parseInt(pis.substring(3, 4))) * 12) +
      ((parseInt(pis.substring(4, 5))) * 11) +
      ((parseInt(pis.substring(5, 6))) * 10) +
      ((parseInt(pis.substring(6, 7))) * 9) +
      ((parseInt(pis.substring(7, 8))) * 8) +
      ((parseInt(pis.substring(8, 9))) * 7) +
      ((parseInt(pis.substring(9, 10))) * 6) +
      ((parseInt(pis.substring(10, 11))) * 5) + 2;

    resto = soma % 11;
    dv = 11 - resto;
    resultado = pis + "001" + dv.toString();
  } else {
    resultado = pis + "000" + dv.toString();
  }

  return value === resultado;
}

function checkProvisionalCns(value) {
  /**
   * CNS PROVISÓRIO INICIO 7,8 ou 9
   */
  if (value.trim().length !== 15) {
    return false;
  }

  let soma = 0;
  for (let i = 0; i < 15; i++) {
    soma += parseInt(value.charAt(i)) * (15 - i);
  }

  const resto = soma % 11;
  return resto === 0;
}

function validate(value) {
  const cleanCns = cleaner(value)
  const firstDigit = cleanCns.substring(0, 1);

  if (_.indexOf(['1', '2'], firstDigit) >= 0) {

    return checkNormalCns(cleanCns);
  } else if (_.indexOf(['7', '8', '9'], firstDigit) >= 0) {

    return checkProvisionalCns(cleanCns);
  }

  return false;
}
export default validate