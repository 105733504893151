<template>
    <div class="d-flex align-items-center justify-content-center px-4 py-10 h-100">
        <div class="rounded card d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6 col-xl-3 col-lg-6 col-md-7 col-sm-9">
            <template>
                <component :is="icons[code]" :class="iconsClasses[code]" size="3x" />

                <h3 class="text-2xl font-weight-bolder m-0">
                    {{ code }}

                </h3>
                <p class="text-subdued m-0">
                    {{ errorDescription }}
                </p>

                <b-button round pill size="sm" variant="info" @click="$router.replace({name: 'home'})">Voltar ao início</b-button>

            </template>
        </div>
    </div>
</template>

<script>
import * as _ from 'lodash-es'

import { useCurriculum } from "@state/user/curriculum";


import { ShieldExclamationIcon } from "@vue-hero-icons/outline"
import { ExclamationCircleIcon } from "@vue-hero-icons/solid";

export default {
    mixins: [],
    components : {
        ExclamationCircleIcon,
        ShieldExclamationIcon
    },
    props : {
        code : {
            type : Number,
            default: 404
        }
    },
    setup() {
        const icons = {
            403 : 'ShieldExclamationIcon',
            404 : 'ExclamationCircleIcon'
        }

        const iconsClasses = {
            403 : 'text-danger',
            404 : 'text-warning'
        };

        return {
            icons,
            iconsClasses
        }
    },
    computed : {
        errorDescription() {
            return this.$t('errors.' + this.code);
        },
        errorIcon() {
            ShieldExclamationIcon
        }
    }
};
</script>
