<template>
  <div class="container mt-3 mt-lg-20">
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-xs="12" vs-lg="7">
        <div class="disq-wrap text-center mb-3">
          <img
            :src="profileInvalidSvg"
            style="max-width: 300px"
            alt="DISC profile invalid"
          />
          <p>{{ $t("disc.form.disc_invalid_profile_message") }}</p>
          <p>{{ $t("disc.form.remake_evaluation") }}</p>
          <b-button
            variant="primary"
            size="sm"
            pill
            @click.prevent="remakeDiscEvaluation()"
            >{{ $t("disc.form.start") }}
          </b-button>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import profileInvalidSvg from "@assets/images/disc/disc-profile-invalid.svg";
export default {
  setup() {
    return {
      profileInvalidSvg,
    };
  },
  methods: {
    remakeDiscEvaluation() {
      this.$emit("remakeDiscEvaluation");
    },
  },
};
</script>