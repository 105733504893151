<template>
  <div class="d-flex gap-3 align-items-center">
    <template v-if="!noIcon">
      <b-avatar
        v-if="!item.company"
        rounded="sm"
        variant="neutral-subdued"
        size="2.5rem"
      >
        <template #default="{}">
          <OfficeBuildingIcon class="icon-subdued" />
        </template>
      </b-avatar>
      <b-avatar
        v-else
        rounded="sm"
        size="2.5rem"
        variant="neutral-subdued"
        :src="'https://cdn.selecty.com/config/' + item.company + '/squared.png'"
      ></b-avatar>
    </template>

    <div class="flex-grow">
      <div class="d-flex flex-column justify-content-between">
        <p class="subtitle text-sm m-0">
          {{
            item.company_name
              ? item.company_name
              : item.publisher_company
              ? item.publisher_company
              : ""
          }}
        </p>
        <p class="title text-medium m-0">
          {{ item.occupation }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { BAvatar } from "bootstrap-vue";
import { OfficeBuildingIcon } from "@vue-hero-icons/solid";

export default {
  name: "VacancyHeader",
  components: {
    BAvatar,
    OfficeBuildingIcon,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    noIcon: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
<!--
<docs>
```vue
<template>
    <VacancyHeader :item="item" />
</template>

<script>
    export default {
        data() {
            return {
                item : {
                    company_name : "{Nome da empresa}",
                    occupation : "{Nome do Cargo}",
                    //company : "selectycorp4"
                    // ... OUTROS DADOS DA VAGA AQUI
                }
            };
        }
    }
</script>
```
</docs>
-->
