<template>
  <div>
    <span v-if="step.status == 2">
      {{ step.content }}
    </span>
    <span v-else-if="step.status == 1">
      <router-link
        :to="{ name: step.link.name, params: step.link.params }"
        v-slot="{ navigate, href, isExactActive }"
      >
        <a :href="href" @click="navigate" :class="{ active: isExactActive }">
          <span>{{ step.link.text }}</span>
        </a>
      </router-link>
    </span>
    <span v-else-if="step.status == 0">
      {{ step.content }}
    </span>
  </div>
</template>
<script>
import DiscCard from "@components/disc/DiscCard.vue";

export default {
  components: {
    DiscCard,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
};
</script>
