<template>
  <b-card
    no-body
    :data-cy="form + '.' + section"
    v-bind="$attrs"
    v-if="hasFields"
    :class="{ 'border-none': noCard }"
    :header-class="{ 'bg-transparent px-0': noCard }"
  >
    <template #header="{ label }" v-if="title">
      <slot name="header" :label="label">
        <!-- <b-card-header header-tag="header" class="d-flex w-100 justify-content-between align-items-center"> -->
        <h6 class="mb-1">{{ title }}</h6>
        <!-- </b-card-header> -->
      </slot>
    </template>

    <b-list-group flush>
      <span class="pt-2" v-if="subtitle"
        >{{ subtitle }}<span style="color: red">*</span></span
      >
      <b-list-group-item
        v-for="(component, name) in internalFields"
        :key="form + '.' + section + '.' + name"
        class="flex-column align-items-start"
        :class="{ 'p-0 pt-1 border-none': noCard }"
        autocomplete="off"
      >
        <slot
          :name="name"
          :model="internalValue"
          :field="name"
          :component-name="(label ? label : form) + '.' + section + '.' + name"
          :test-id="form + '.' + section + '.' + name"
          :component="
            componentAttrs(
              component,
              (label ? label : form) + '.' + section + '.' + name
            )
          "
        >
          <div v-if="!component.show_dependency">
            <component
              v-if="component.is && component.show"
              :key="form + '.' + section + '.' + name"
              :is="component.is"
              :name="(label ? label : form) + '.' + section + '.' + name"
              :test-id="form + '.' + section + '.' + name"
              v-model="internalValue[name]"
              v-bind="
                componentAttrs(
                  component,
                  (label ? label : form) + '.' + section + '.' + name
                )
              "
              autocomplete="off"
            />
          </div>
          <div v-else>
            <component
              v-if="
                component.is &&
                component.show &&
                internalValue[component.show_dependency] == true
              "
              :key="form + '.' + section + '.' + name"
              :is="component.is"
              :name="(label ? label : form) + '.' + section + '.' + name"
              :test-id="form + '.' + section + '.' + name"
              v-model="internalValue[name]"
              v-bind="
                componentAttrs(
                  component,
                  (label ? label : form) + '.' + section + '.' + name
                )
              "
              autocomplete="off"
            />
          </div>
          <div v-if="component.help" class="alert alert-primary d-flex">
            <InformationCircleIcon />
            <div v-html="getTranslatedHelp(component.help)"></div>
          </div>
        </slot>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import * as _ from "lodash-es";
import { InformationCircleIcon } from "@vue-hero-icons/solid";
import FormFieldSet from "../mixins/Set";

export default {
  components: {
    InformationCircleIcon,
  },
  mixins: [FormFieldSet],
  props: {
    label: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    noCard: {
      type: Boolean,
      required: false,
      default: true,
    },
    fieldProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    componentAttrs() {
      return (component, name) => {
        return _.merge(component, this.fieldProps);
      };
    },
  },
  methods: {
    getTranslatedHelp(content = '') {

      if (content.startsWith("$t:")) {
        return this.$t(content.replace('$t:', ''));
      }

      return content
    }
  },
};
</script>
