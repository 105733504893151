<template>
  <div
    class="d-flex align-items-center justify-content-center px-4 py-10 h-100"
  >
    <div
      class="rounded card d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6 col-xl-3 col-lg-6 col-md-7 col-sm-9"
    >
      <template>
        <b-spinner class="text-black" style="opacity: 0.5" />

        <h3 class="text-2xl font-weight-bolder m-0">
          {{ $t("redirecting.redirecting") }}
        </h3>
        <p class="text-subdued m-0">
          {{ $t("redirecting.please_wait") }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useCurriculum } from "@state/user/curriculum";

import { ExclamationCircleIcon } from "@vue-hero-icons/solid";

export default {
  mixins: [companyMixin],
  components: {
    ExclamationCircleIcon,
  },
  setup() {
    const curriculum = useCurriculum();

    return {
      curriculum,
    };
  },
  computed: {
    isSJ() {
      return this.company.config.sj;
    },
    defaultProvider() {
      return this.isSJ ? "sj" : "candidate";
    },
  },
  mounted() {
    let provider = this.defaultProvider;
    if (!_.isEmpty(this.$route.params.auth_type)) {
      provider = this.$route.params.auth_type;
    }

    //this.$auth.token(null, this.$route.params.hash, false);

    this.$auth
      .login({
        data: {
          provider: provider,
          driver: "jwt",
          credentials: {
            hash: this.$route.params.hash,
          },
        },
        redirect: false,
        fetchUser: true,
        remember: this.checkbox_remember_me,
        staySignedIn: true,
      })
      .then(() => {
        const user = this.$auth.user();
        if (user) {
          let route = { name: "register2" };

          if (!_.isEmpty(this.$route.params.module)) {
            if (this.$route.params.module == "express") {
              localStorage.setItem(
                "changeable-express-form",
                JSON.stringify({
                  name: user.name,
                  cpf: user.cpf,
                  primary_email: user.email,
                })
              );

              //Seta um parâmetro local storage para identificar que a conta é de midias sociais
              //utilizado para validar no step2 se é necessário cadastrar a senha ou não
              localStorage.setItem(
                "social-media-account",
                JSON.stringify({
                  social_media_account: true,
                })
              );

              route = { name: "register2" };
            } else if (this.$route.params.module == "admissional") {
              localStorage.setItem(
                "bank_account_selective_process",
                JSON.stringify({
                  selective_process_id: this.$route.query.selectiveProcess,
                })
              );

              route = {
                name: "profile-admissional",
                params: {
                  candidate_id: this.$route.query.candidate,
                },
              };
            } else if (this.$route.params.module == "profile") {
              route = { name: "profile" };
            } else if (this.$route.params.module == "profile-edit") {
              route = { name: "profile-edit" };
            } else if (this.$route.params.module == "customize") {
              route = { name: "customize" };
            } else if (this.$route.params.module == "complete-registration") {
              route = { name: "complete-registration" };
            } else if (this.$route.params.module == "esocial") {
              route = { name: "profile-esocial" };
            } else if (this.$route.params.module == "disc") {
              route = {
                name: "evaluation-disc-form",
              };
            } else if (this.$route.params.module == "preferences") {
              route = {
                name: "profile-configurations",
              };
            } else if (this.$route.params.module == "survey") {
              route = {
                name: "evaluation-survey-execute",
                params: { evaluationId: this.$route.query.additional_param },
              };
            } else {
              route = { name: "home" };
            }

            this.$router.replace(route);
          }
        }
      })
      .catch((error) => {
        if (this.$route.params.module == "admissional") {
          sessionStorage.setItem("redirectPath", "/profile/admissional");
        } else if (this.$route.params.module == "esocial") {
          sessionStorage.setItem("redirectPath", "/profile/esocial");
        }

        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      })
      .finally(() => (this.$root.loading = false));
  },
};
</script>
