<template>
    <b-dropdown
        right
        id="dropdown-1"
        variant="default"
        class="i18n-dropdown"
        size="sm"
        toggle-class="p-0"
        no-caret
    >
        <template #button-content>
            <span class="cursor-pointer flex items-center i18n-locale">
                <img
                    class="h-4 w-5"
                    :src="locale.current.flag"
                    :alt="$i18n.locale"
                />
                <span class="hidden sm:block ml-2">{{ locale.current.lang }}</span>
            </span>
        </template>

        <b-dropdown-item
            v-for="(lang, index) in locale.available"
            :key="index"
            @click="changeLocale(index)"
        >
            <img
                class="h-4 w-5 mr-1"
                :src="lang.flag"
                :alt="index"
            /> &nbsp;{{ lang.lang }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { useLocale } from "@state/system/locale";

export default {
    setup() {
        const locale = useLocale();
        return {
            locale,
        };
    },
    methods : {
        changeLocale(index) {
            this.locale.change(index).then(() => {
                // GAMBIARRA PARA FAZER O RELOAD DA PÁGINA!!
                this.$router.go(0)
            })

        }
    }
};
</script>
