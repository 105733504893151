<template>
  <section
    id="vagas"
    data-scroll-to="vacancies"
    v-if="(searchable && !isMobile()) || viewable"
  >
    <div class="mb-6" v-if="searchable && !isMobile()">
      <vacancy-search-bar @search="search" />
    </div>

    <div class="d-flex justify-content-between align-items-center mt-6 pb-3" v-if="viewable">
      <h4 v-if="title" class="m-0">
        {{ title }}
      </h4>
      <a :class="{'text-sm': isMobile()}" href="/search">
        {{ $t("vacancy.actions.seeAllVacancies")}}
      </a>
    </div>
    <div
      class="d-flex flex-column flex-wrap justify-content-between no-gutters"
      :class="{ 'flex-md-row': recentList.length > 0 }"
      v-if="viewable && !list && !swiper"
    >
      <div
        class="col-md-4 py-2 card-group"
        v-for="(vacancy, index) in recentList"
        :key="vacancy.id"
        :class="{
          'pr-md-2': index % 3 == 0,
          'px-md-1': index % 3 == 1,
          'pl-md-2': index % 3 == 2,
        }"
      >
        <vacancy-card
          :item="vacancy"
          :config="company.config"
          v-if="recentList.length > 0"
          @click="openVacancy(vacancy)"
          type="featured"
        />
      </div>
      <div v-if="recentList <= 0">
        <div
          class="vacancy-card card d-flex align-items-center justify-content-center"
        >
          <div class="card-body">
            <h6 class="text-subdued">{{ $t("vacancy.no-vacancy") }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column flex-md-row flex-wrap justify-content-between no-gutters"
      v-else-if="viewable && list"
    >
      <div class="vacancies-list w-100">
        <vacancy-list :vacancies-list="recentList" />
      </div>
    </div>
    <div
      v-else-if="viewable && swiper"
      class="flex-column flex-wrap justify-content-between no-gutters"
      :class="{ 'flex-md-row': recentList.length > 0 }"
    >
      <swiper-container
        init="false"
        class="swiper-container"
        ref="swiper_container"
      >
        <swiper-slide
          v-for="(vacancy, index) in recentList"
          :key="index"
          class="col-md-4 py-2 card-group"
        >
          <vacancy-card
            :item="vacancy"
            :config="company.config"
            v-if="recentList.length > 0"
            @click="openVacancy(vacancy)"
            type="featured"
          />
        </swiper-slide>
      </swiper-container>
    </div>
  </section>
</template>

<script>
import companyMixin from "@state/company";
import { useTheme } from "@state/system/theme";
import { register } from "swiper/element/bundle";
import { Navigation, Pagination } from "swiper/modules";
import { useVacancies } from "@state/vacancy/vacancies";
import VacancyCard from "@blocks/vacancy/VacancyCard.vue";
import VacancyList from "@blocks/vacancy/VacancyList.vue";
import VacancySearchBar from "@blocks/vacancy/VacancySearchBar.vue";

register();

export default {
  mixins: [companyMixin],
  components: {
    VacancyCard,
    VacancyList,
    VacancySearchBar,
  },
  setup() {
    const vacancies = useVacancies();
    const theme = useTheme();

    return {
      vacancies,
      isMobile: theme.isMobile,
    };
  },
  mounted() {
    const swiperOption = {
      modules: [Navigation, Pagination],
      injectStylesUrls: [],
      navigation: !this.isMobile(),
      pagination: this.isMobile(),
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: 1,
      spaceBetween: 2,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 2,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 2,
        },
      },
    };
    // swiper element
    //const swiperEl = document.querySelector('swiper-container');
    const swiperEl = this.$refs.swiper_container;

    if (swiperEl) {
      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEl, swiperOption);

      // and now initialize it
      swiperEl.initialize();
    }
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    viewable: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
    },
    highlighting: {
      type: Boolean,
    },
    list: {
      type: Boolean,
      default: false,
    },
    swiper: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      jobFilter: "",
      placeFilter: "",
      recentList: [],
      home_search: true,
    };
  },
  computed: {
    conditions() {
      return {
        limit: this.limit,
        highlighting: this.highlighting,
        list: this.list,
      };
    },
  },
  created() {
    this.vacancies
      .search({
        limit: this.limit,
        highlighting: this.highlighting,
        list: this.list,
        home_search: this.home_search,
      })
      .then((response) => {
        if (response.length >= 1) {
          this.recentList = response;
        }
      });
  },
  watch: {
    limit: {
      deep: true,
      handler: "updateList",
    },
    list: {
      deep: true,
      handler: "updateList",
    },
  },
  methods: {
    updateList() {
      this.vacancies
        .search({
          limit: this.limit,
          highlighting: this.highlighting,
          list: this.list,
          home_search: this.home_search,
        })
        .then((response) => {
          if (response.length >= 1) {
            this.recentList = response;
          }
        });
    },
    openVacancy(item) {
      this.$router.push({
        name: "vacancy-show",
        params: {
          vacancy_id: item.id,
          slug: item.slug
        },
      });
    },
    search() {
      this.$router.push({
        name: "vacancy-search",
        query: {
          text: this.vacancies.conditions.text.trim(),
          location: this.vacancies.conditions.location.trim(),
        },
      });
    },
  },
};
</script>
