<i18n>
{
    "pt_BR": {
        "achiever": "Realizador",
        "agent": "Representante",
        "appraiser": "Avaliador",
        "counselor": "Conselheiro",
        "creative": "Criador",
        "developer": "Desbravador",
        "inspirational": "Inspirador",
        "investigator": "Investigador",
        "objective thinker": "Pensador Objetivo",
        "overshift": "Expandido",
        "perfectionist": "Perfeccionista",
        "persuader": "Convincente",
        "pratitioner": "Praticante",
        "promoter": "Promotor",
        "result-oriented": "Orientado a Resultados",
        "specialist": "Especialista",
        "tight": "tight",
        "undershift": "Comprimido"
    },
    "en" : {
        "achiever": "Achiever",
        "agent": "Agent",
        "appraiser": "Appraiser",
        "counselor": "Counselor",
        "creative": "Creator",
        "developer": "Developer",
        "inspirational": "Inspirational",
        "investigator": "Investigator",
        "objective thinker": "Objective Thinker",
        "overshift": "Overshift",
        "perfectionist": "Perfectionist",
        "persuader": "Persuader",
        "pratitioner": "Pratitioner",
        "promoter": "Promoter",
        "result-oriented": "Result Oriented",
        "specialist": "Specialist",
        "tight": "Tight",
        "undershift": "Undershift"
    }
}
</i18n>

<template>
    <div>
        <!-- O card recebe a prop direction (horizontal ou vertical) e aplica a classe respectiva - disc-card--horizontal ou disc-card--vertical -->
        <!--
            O card deve se adaptar de acordo com o perfil disc recebido no teste, isso altera ícone, título do perfil e cor da borda.
            O ícone é o componente disc-profile-icon. A borda é uma classe css disc-card--[nome-do-perfil].
        -->
        <div :class="['flex-container w-100 disc-card disc-card--' + orientation, 'disc-card--' + profile]">
            <div class="flex-panel">
                <div class="flex-element disc-card--profile-content">
                    <!-- Ícone de perfil disc -->
                    <disc-profile-icon :profile="profile" />
                    <div>
                        <!-- Título de perfil disc -->
                        <h3 class="mb-0">{{$t("common.profile")}} {{ $t('disc.profile.' + profile) }}</h3>
                    </div>
                </div>
                <div class="flex-element disc-content-factor">
                    <!-- O componente disc-factors-bar recebe na prop factor os valores de cada fator obtidos no teste e se comporta de acordo. -->
                    <disc-factors-bar :factor="factor"></disc-factors-bar>
                </div>
            </div>
        </div>
        <!-- MODAL QUANDO CLICA NO CARD -->
        <b-modal id="modal-disc-details" size="xl" scrollable body-class="modal-gray-bg p-0" :title="modalTitle">
            <b-tabs class="nav-tabs-primary-wrapper nav-tabs-primary" fill content-class="p-3 bg-lightgray" nav-class="nav-tabs-primary">
                <b-tab title="Perfil" active>
                    <disc-profile-header :pattern="pattern" />
                    <div class="mb-3"></div>
                    <disc-profile-description :chart="factor" :pattern="pattern" />
                </b-tab>
                <b-tab title="Fatores">
                    <div class="d-flex align-items-center text-sm mb-1"><i class="fas fa-info-circle fa-xs mr-1"></i>{{ $t('disc.disc_card.click_on_bars_to_read_description') }}</div>
                    <disc-factors-bar :factor="factor" :ShowFactorIcon="false" :UseCollapse="true"></disc-factors-bar>

                    <b-collapse id="collapse-d" accordion="my-accordion" visible>
                        <div v-if="percents.d >= highFactor">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <disc-factor-icon factor="d" class="mr-2" />
                                    <span class="">
                                        {{ $t('disc.disc_card.high_dominance') }}
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.assertive') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.competitive') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.high_self_esteem') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.direct') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.objective') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.positive_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.adventurous') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.takes_initiative') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.bold') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.persistent') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.focuses_on_results') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.determined') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.limiting_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.takes_too_many_risks') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.arrogant') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.impulsive') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.insensitive') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.difficulty_in_delegating') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.takes_on_many_responsibilities') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.brings_value_to_the_team') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.coordinating') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.innovating') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.with_proactivity') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.with_motivation_in_the_face_of_challenges') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.the_ideal_environment_provides') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.debates_to_express_viewpoints') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.freedom_to_make_decisions') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.challenges_and_opportunities') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.little_routine') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.under_pressure_can_be') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.very_demanding') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.aggressive') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.selfish') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="d" class="mr-2" />
                                <span class="disc-color-d">
                                    {{ $t('disc.disc_card.low_dominance') }}
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>{{ $t('disc.disc_card.conservative') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.moderate') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.diplomatic') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.unpretentious') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.careful') }}</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>

                    <b-collapse id="collapse-i" accordion="my-accordion">
                        <div v-if="percents.i >= 30">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <disc-factor-icon factor="i" class="mr-2" />
                                    <span class="">
                                        {{ $t('disc.disc_card.high_influence') }}
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.confident') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.optimistic') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.persuasive') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.sociable') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.communicative') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.positive_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.attentive') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.charming') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.convincing') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.warm') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.inspiring') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.good-humored') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.limiting_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.inattentive_to_details') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.loses_focus_easily') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.disorganized') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.difficulty_completing_tasks') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.difficulty_managing_time') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.speaks_without_thinking') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.brings_value_to_the_team') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.resolving_conflicts_creatively') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.motivating_others') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.being_optimistic') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.collaborating') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.the_ideal_environment_provides') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.constant_contact_with_people') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.space_to_hear_ideas') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.freedom_to_communicate') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.democratic_managers') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.under_pressure_can_be') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.very_optimistic') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.not_very_realistic') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.talks_too_much') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="i" class="mr-2" />
                                <span class="disc-color-i">
                                    {{ $t('disc.disc_card.low_influence') }}
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>{{ $t('disc.disc_card.serious') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.skeptical') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.reserved') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.reflective') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.rational') }}</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>

                   <b-collapse id="collapse-s" accordion="my-accordion" visible>
                       <div v-if="percents.s >= 30">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <disc-factor-icon factor="s" class="mr-2" />
                                    <span class="">
                                        {{ $t('disc.disc_card.high_stability') }}
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.good_listener') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.self_controlled') }}</b-list-group-item>
                                    <b-list-group-item> {{ $t('disc.disc_card.patient') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.kind') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.thoughtful') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.positive_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.friendly') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.peacemaker') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.calm') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.understanding') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.loyal') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.sincere') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.limiting_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.avoids_conflicts') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.lacks_initiative') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.dislikes_change') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.inflexible') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.difficulty_setting_priorities') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.low_ambition') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.brings_value_to_the_team') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.listening') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.serving') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.conveying_calm') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.bringing_consistency') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.the_ideal_environment_provides') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.stability') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.few_conflicts') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.lasting_work_relationships') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.predictability') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.under_pressure_can_be') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.indecisive') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.inflexible') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.closed') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="s" class="mr-2" />
                                <span class="disc-color-s">
                                    {{ $t('disc.disc_card.low_stability') }}
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>{{ $t('disc.disc_card.active') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.restless') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.impetuous') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.energetic') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.flexible') }}</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>

                    <b-collapse id="collapse-c" accordion="my-accordion" visible>
                        <div v-if="percents.c >= highFactor">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <disc-factor-icon factor="c" class="mr-2" />
                                    <span class="">
                                        {{ $t('disc.disc_card.high_conformity') }}
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.self_discipline') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.precise') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.detail_oriented') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.prudent') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.perfectionist') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.positive_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.analytical') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.diplomatic') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.seeks_achievements') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.careful') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.asks_good_questions') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.thinks_objectively') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            {{ $t('disc.disc_card.limiting_behaviors') }}
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>{{ $t('disc.disc_card.arrogant_when_challenged') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.defensive_to_criticism') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.inflexible') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.very_critical') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.slow_to_act') }}</b-list-group-item>
                                        <b-list-group-item>{{ $t('disc.disc_card.seems_distant_and_cold') }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.brings_value_to_the_team') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.solves_problems_using_understanding') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.aware_and_consistent') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.defines_and_clarifies') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.gathers_information_and_tests_it') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.the_ideal_environment_provides') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.openness_to_critical_thinking') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.close_relationships_with_small_groups') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.privacy') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.freedom_to_question') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        {{ $t('disc.disc_card.under_pressure_can_be') }}
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>{{ $t('disc.disc_card.hard_to_please') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.pessimistic') }}</b-list-group-item>
                                    <b-list-group-item>{{ $t('disc.disc_card.excessively_meticulous') }}</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="c" class="mr-2" />
                                <span class="disc-color-c">
                                    {{ $t('disc.disc_card.low_conformity') }}
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>{{ $t('disc.disc_card.firm') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.bold') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.impulsive') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.persistent') }}</b-list-group-item>
                                <b-list-group-item>{{ $t('disc.disc_card.independent') }}</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>
                </b-tab>
            </b-tabs>
            <template #modal-footer="{ ok }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button variant="outline-blue" @click="ok()">
                {{ $t('common.close') }}
            </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import DiscProfileIcon from './DiscProfileIcon.vue';
import DiscFactorsBar from './DiscFactorsBar.vue';
import DiscFactorIcon from './DiscFactorIcon.vue';
import FactorCalculation from './mixins/FactorCalculation.js';
import DiscProfileHeader from './DiscProfileHeader.vue';
import DiscProfileDescription from './DiscProfileDescription.vue';

export default {
    components: {
        DiscProfileIcon,
        DiscFactorsBar,
        DiscFactorIcon,
        DiscProfileHeader,
        DiscProfileDescription
    },
    mixins: [
        FactorCalculation
    ],
    props: {
        candidateName : {
            type: String,
            required: false,
        },
        orientation : {
            type: String,
            default: 'horizontal'
        },
        pattern: {
            type: Object
        },
        chart: {
            type: Object
        },
    },
    computed: {
        profile() {
            return this.pattern.name.toLowerCase();
        },
        modalTitle() {
            if (this.candidateName) {
                return 'DISC - ' + this.candidateName;
            } else {
                return 'DISC - Resultado';
            }
        }
    }
}
</script>

<style lang="scss">

    .flex-container{
        display: flex;
    }

    .flex-column, .flex-panel{
        flex-grow: 1;
    }

    .flex-panel{
        display: flex;
        flex-wrap: wrap;
        $breakpoint: 550px;
        --multiplier: calc( #{$breakpoint} - 100%);

        .flex-element{
            min-width: 33%;
            max-width: 100%;
            flex-grow: 1;
            flex-basis: calc( var(--multiplier) * 999 );
            justify-content: center;
            padding: 16px;

            &:first-child {
                flex-grow: unset;
                flex-shrink: 1;
            }
        }
    }

    .disc-card {
        display: flex;
        align-items: center;
        // max-width: 750px;
        border-radius: 8px;
        padding: 8px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        background-color: #fff;
        border-width: 2px;
        border-style: solid;
        border-color: #000;
        cursor: pointer;

        .disc-card--profile-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .disc-card--horizontal {
        flex-direction: row;

        .disc-card--profile-content {
            flex-direction: row;
            // border-right: 1px solid #D7D9DB;
            // margin-right: 32px;
            // padding-right: 16px;
        }

        .disc-profile-icon {
            margin-right: 8px;
        }
    }
    .disc-card--vertical {
        flex-direction: column;
        justify-content: center;
        padding: 16px 16px;
        cursor: unset;

        .disc-card--profile-content {
            flex-direction: column;
            // border-bottom: 1px solid #D7D9DB;
            // margin-bottom: 32px;
            // padding-bottom: 16px;
        }

        .disc-profile-icon {
            margin-bottom: 8px;
        }

        .disc-content-factor {
            border-top: 1px solid #D7D9DB;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .disc-popover--header .fas, .far {
        opacity: .5;
    }


    // PROFILE COLORS //

    .disc-card--developer {
        border-color: #F73B3B;
    }
    .disc-card--result-oriented {
        border-color: #F45434;
    }
    .disc-card--inspirational {
        border-color: #F96C15;
    }
    .disc-card--creative {
        border-color: #F53D7A;
    }
    .disc-card--promoter {
        border-color: #DB9200;
    }
    .disc-card--persuader {
        border-color: #FA9200;
    }
    .disc-card--counselor {
        border-color: #AA980E;
    }
    .disc-card--appraiser {
        border-color: #6DA046;
    }
    .disc-card--specialist {
        border-color: #51BA1C;
    }
    .disc-card--achiever {
        border-color: #9CA51D;
    }
    .disc-card--agent {
        border-color: #81A81F;
    }
    .disc-card--investigator {
        border-color: #1AB74E;
    }
    .disc-card--objective {
        border-color: #4794EB;
    }
    .disc-card--perfectionist {
        border-color: #16A2DA;
    }
    .disc-card--practitioner {
        border-color: #13ACAE;
    }

    .nav-tabs-primary-wrapper > div:first-child {
        position: sticky;
        top: 0;
        z-index: 3;
    }

    .nav-tabs-primary {
        border-bottom: 1px solid #d2d2d2;
        background: hsl(0deg 0% 100% / 80%);
        backdrop-filter: blur(10px);

    .nav-item {
        // flex: 1;
        text-align: center;

        .nav-link {
            color: #648090;
            font-size: 1.2rem;
            font-weight: bold;
            background: transparent !important;
            background-color: transparent;
            border: 0;
            border-bottom: 4px solid transparent;
            border-radius: 0;
            transition: all 0.2s ease;

                &:hover, &:focus {
                    border-bottom-color: #d2d2d2;
                }

                &.active {
                    color: #00A5F7;
                    border-width: 0 0 4px 0;
                    border-bottom-style: solid;
                    border-bottom-color: lighten(#F9B147, 10);
                    background: transparent;
                }
            }
        }
    }
</style>
