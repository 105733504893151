<template>
    <div>
        <b-navbar type="dark" variant="primary" v-if="selectiveProcessId && finished">
            <b-navbar-brand href="#">{{ $t("disc.results.selection_process") }} #{{ selectiveProcessId }}</b-navbar-brand>

            
            <b-button variant="light" class="my-2 my-sm-0" @click="returnToCaller" type="button">{{ $t("disc.questions.return") }}</b-button>
        </b-navbar>
        <vs-row vs-type="flex" vs-justify="center" class="video-interview" style="margin:0 auto">
            <vs-col vs-xs="12" vs-lg="5">
                <div class="rounded-xl text-center d-flex align-items-center justify-content-center vh-90">

                    <div class="text-center text-lg">
                        <img :src="illustCheckSvg" alt="check" class="mb-5 w-40">
                        <!-- <img src="/images/illust-check.svg" class="mb-5 w-40" alt=""> -->
                        <div class="font-bold text-dark mb-2">
                            {{ $t('evaluation.form.video-interview.congratulations', [activeUser.name]) }}
                        </div>
                        <div class="mb-4">
                            <div>{{ $t('evaluation.form.video-interview.you-finished') }}</div>
                        </div>
                    </div>
            </div>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import Vue from "vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import illustCheckSvg from '@assets/images/survey/illust-check.svg';

export default {
    components: {
        FormWizard,
        TabContent,
    },
    setup() {
            return {
                illustCheckSvg,
            }
        },
    computed: {
        activeUser() {
            return this.$auth.user();
        },
        selectiveProcessId() {
            return this.$route.params.selectiveProcessId
        },        
    }
};
</script>

