
/**
 * O suporte a url na v3 foi removido e adicionado posteriormente a v4.
 * Para evitar conflitos criei esta validação customizada.
 */
export default { 
    validate: value => {
        const pattern = new RegExp(
            '^(https?:\\/\\/)' + // protocolo (apenas http ou https)
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domínio
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // endereço IP (v4) 
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // porta e caminho
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // string de consulta
            '(\\#[-a-zA-Z\\d_]*)?$' // fragmento
        );
        return pattern.test(value);
      },
      message: 'O campo {_field_} deve ser uma URL válida'
};

