<template>
  <section id="ambiente-de-trabalho">
    <h1>{{ $t('home.environmentTitle') }}</h1>

    <div class="imagens-ambiente row">
      <div v-for="(image, index) in config.list" :key="index" :class="image.class">
        <img :src="image.url" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    'config',
  ],
  data() {
    return {};
  },
  methods: {},
};
</script>