<template>
  <div>
    <form-set-generic
      v-if="section.benefits.show"
      v-model="curriculum.benefits"
      label="curriculum"
      form="admissional"
      section="benefits"
      :fields="benefitsFields"
      class="profile-subsection"
    />

    <div v-if="section.transports.show">
      <form-field-radio
        name="curriculum.benefits.has_transports"
        v-model="curriculum.benefits.has_transports"
        v-bind="section.benefits.fields.has_transports"
        :options="domain.domains.has_letter_attribute"
        autocomplete="off"
        :rules="transportsRules"
        immediate
      />

      <form-set-transports
        v-if="curriculum.benefits.has_transports == 'y'"
        name="curriculum.transports"
        v-model="curriculum.transports"
        :fields="section.transports.fields"
        section="transports"
        autocomplete="off"
        :title="$t('curriculum.sections.benefits.transports')"
        class="profile-subsection"
      />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import FormSetTransports from "@components/forms/set/Transports.vue";

export default {
  components: {
    FormSetGeneric,
    FormSetTransports,
    ...FormComponents,
  },
  setup() {
    const curriculum = useCurriculum();
    const domain = useDomain();

    return {
      // you can return the whole store instance to use it in the template
      domain,
      curriculum,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    transportsRules() {
      return {
        must_exists_if: ["y", this.curriculum.transports.length],
      };
    },
    benefitsFields() {
      return _.filter(
        this.section.benefits.fields,
        (item) => item != "benefits.has_transports"
      );
    },
  },
};
</script>
