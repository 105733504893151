<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <!-- <div id="app" :class="'theme-' + $root.theme.theme"> -->
    <div id="app">
        <div
            class="loader"
            v-if="$root.loading || $root.initialLoading"
        >
            <b-spinner
                variant="primary"
                class="mr-1"
            />
        </div>
        <router-view @setAppClasses="setAppClasses" v-if="!$root.initialLoading" :class="{'is-sj': company.config.sj}" />
        <!-- <font-awesome-icon icon="user-clock" /> -->
    </div>
</template>

<script>
import companyMixin from "@state/company";
import { useCompany } from '@state/company/config'
import { useTheme } from '@state/system/theme'

import { BSpinner } from "bootstrap-vue";

//import CompanyService from '@services/company/Company'

export default {
    mixins: [companyMixin],
    components: {
        BSpinner,
    },
    setup() {
        const theme = useTheme();

        return {
            theme
        };
    },
    data() {
        return {
            vueAppClasses: [],
        }
    },

    // watch: {
    //     /*
    //     "$store.state.theme"(val) {
    //         this.toggleClassInBody(val);
    //     },
    //     */
    //     // '$vs.rtl'(val) {
    //     //   document.documentElement.setAttribute("dir", val ? "rtl" : "ltr")
    //     // }
    // },
    methods: {
        toggleClassInBody(className) {
            /*
            if (className == "dark") {
                if (document.body.className.match("theme-semi-dark"))
                    document.body.classList.remove("theme-semi-dark");
                document.body.classList.add("theme-dark");
            } else if (className == "semi-dark") {
                if (document.body.className.match("theme-dark"))
                    document.body.classList.remove("theme-dark");
                document.body.classList.add("theme-semi-dark");
            } else {
                if (document.body.className.match("theme-dark"))
                    document.body.classList.remove("theme-dark");
                if (document.body.className.match("theme-semi-dark"))
                    document.body.classList.remove("theme-semi-dark");
            }
            */
        },
        setAppClasses(classesStr) {
            this.vueAppClasses.push(classesStr);
        },
        handleWindowResize() {
            //this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

            // // Set --vh property
            // document.documentElement.style.setProperty(
            //     "--vh",
            //     `${window.innerHeight * 0.01}px`
            // );

            this.theme.windowSize = window.innerWidth;
        },
        handleScroll() {

            //this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
        },
    },
    // mounted() {
    //     //this.toggleClassInBody(themeConfig.theme)
    //     //this.toggleClassInBody(this.$root.theme.theme)

    //     //this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    //     let vh = window.innerHeight * 0.01;
    //     // Then we set the value in the --vh custom property to the root of the document
    //     document.documentElement.style.setProperty("--vh", `${vh}px`);
    // },
    created() {
        let dir = "ltr";
        document.documentElement.setAttribute("dir", dir);

        window.addEventListener("resize", this.handleWindowResize);
        window.addEventListener("scroll", this.handleScroll);

        this.handleWindowResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleWindowResize);
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>
