<template>
  <div>
    <form-set-generic
      v-if="section.show"
      v-model="curriculum.professional"
      form="curriculum"
      section="professional"
      :fields="section.fields"
      :title="$t('curriculum.sections.professional.objectives')"
      autocomplete="off"
    >
    </form-set-generic>

    <form-set-professional-experience
      form-id="curriculum"
      name="curriculum.experiences"
      v-model="curriculum.experiences"
      :fields="section.experiences.fields"
      section="experiences"
      v-if="curriculum.professional.has_professional_history == 'y'"
      :title="$t('curriculum.sections.professional.experiences')"
      class="profile-subsection"
    />
  </div>
</template>

<script>
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import FormSetProfessionalExperience from "@components/forms/set/ProfessionalExperience.vue";

export default {
  components: {
    FormSetGeneric,
    FormSetProfessionalExperience,
    ...FormComponents,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  created() {},
  setup() {
    const curriculum = useCurriculum();
    const domain = useDomain();

    return {
      // you can return the whole store instance to use it in the template
      curriculum,
      domain,
    };
  },
  methods: {},
};
</script>
