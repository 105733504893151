<template>
    <div>
        <!-- <div>CURRENT REC ID #{{ recordingId }}</div> -->

        <div v-if="!testMode" class="position-relative">
            <video :id="inputId" width="100%" height="auto" v-show="isVideoVisible" ref="video" muted></video>
            <div class="overlay-audio-indicator" style="height:40px; position: absolute; left: 8px; top: 88%;">
                <av-media
                    :media="mediaObject"
                    type="wform"
                    :canv-width="20"
                    :canv-height="40"
                    :line-color="'#64ffda'"
                      />

            </div>
        </div>
        <!-- <b-embed type="video" aspect="4by3" v-if="testMode && video">
            <div id="vr-test-image" @click="testSession()" v-show="canStartVideo">
                <div class="vr-image vr-test-image"></div>
                <p>Iniciar teste</p>
            </div>
            <video :id="inputId" width="100%" height="auto" v-show="isVideoVisible"></video>
        </b-embed> -->
        <b-progress v-if="testMode && maxTime" :value="timer.diff / timer.total * 100" :max="timer.total" variant="warning">warning</b-progress>



        <b-aspect :aspect="'4:3'" class="p-1 vr-container align-items-center bg-black text-white" v-if="testMode && video">
            <div class="cursor-pointer" id="vr-test-image" @click="testSession()" v-show="canStartVideo">
                <!-- <div class="vr-image vr-test-image"></div> -->
                <i class="fas fa-play-circle fa-4x"></i>
                <div>{{ $t('test-alerts.test-cam-mic') }}</div>
            </div>
            <video class="recorder-video" :id="inputId" width="100%" height="auto" v-show="isVideoVisible" muted></video>
            <video class="playback-video" :id="inputId2" width="100%" height="auto" v-show="isVideoPlaybackVisible"  autoplay></video>
            <audio v-show="isVideoPlaybackVisible" ref="audio" id="audio-preview" autoplay></audio>
        </b-aspect>

        <template v-if="testMode && audio && !video" id="loading-sound">
            <vs-button class="mx-auto mt-4" color="primary" type="border" icon-pack="feather" icon="icon-play" @click="testSession()" v-if="status == 'STOPPED'">
                {{ $t('test-alerts.test-mic') }}
            </vs-button>

            <audio ref="audio" id="audio-preview" autoplay></audio>
            <av-line ref-link="audio" />

        </template>

        <div class="log-output" v-if="debug">
            <ul>
                <li v-for="(evt, index) in logs" :key="index">
                    Event: #{{ index + 1 }} - {{ evt.type }}
                </li>
            </ul>
        </div>
	</div>
</template>

<script>
import Vue from 'vue'
import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

import { BAspect, BModal } from 'bootstrap-vue'
//Vue.component('b-aspect', BAspect)



import SessionManager from './SessionManager';

//import Microphone from './mic';
//
//import SoundMeter from './soundmeter'

const STOPPED = 'STOPPED';
const STARTING = 'STARTING';
const PLAYING = 'PLAYING';
const TESTING = 'TESTING';
const RECORDING = 'RECORDING';
const COMPLETED = 'COMPLETED';


const ERROR_START_RECORDING = 'ERROR_START_RECORDING';
const ERROR_START_PUBLISHING = 'ERROR_START_PUBLISHING';

export default {
    components : {
        BAspect,
        BModal
    },
    props: {
        // THE V-MODEL TO RECEIVED THE INFORMATION BACK
        value : {
            type: Object,
            required: false,
        },
        sessionManager : {
            type: Object,
            required: false
        },
        record: {
            type: Boolean,
            default: false
        },
        audio: {
            type: Boolean,
            default: true
        },
        video: {
            type: Boolean,
            default: true
        },
        autoStart: {
            type: Boolean,
            default: false
        },
        testMode:  {
            type: Boolean,
            default: false
        },
        testType:  {
            type: String,
            default: 'local'
        },
        debug: {
            type: Boolean,
            default: false
        },
        maxTime: {
            type: Number,
            default: null
        }
        /*
        'time',
        'file',
        'relatedId',
        'saveUrl',
        'contextType'
        */
    },
    data() {
        return {
            internalValue : this.value,
            internalSessionManager : this.sessionManager,
            inputId : null,
            videoElement : null,
            videoElement2 : null,

            recordingId : null,

            mediaObject : null,

            localRecorder : null,

            session: null,
            publisher: null,

            status : 'STOPPED',
            streamStatus : 'STOPPED',
            accessAllowed : false,
            streamStarted : false,
            audioStopped : false,

            recordingName : null,
            recordingData : null,

            logs : [],

            options : {
                timer: this.maxTime // IN SECONDS
            },
            timer: {
                started : null,
                finished : null,
                diff: null,
                interval: null,
                remaining : null,
                percent : null
            }
            //Mic : new Microphone()
        }
    },
    created() {
        this.inputId = 'input-video-' + this._uid;
        this.inputId2 = 'playback-video-' + this._uid;
        if (!this.internalSessionManager) {
            this.internalSessionManager = new SessionManager(this.$http);
        }
    },
    mounted() {
        this.videoElement = document.getElementById(this.inputId);
        this.videoElement2 = document.getElementById(this.inputId2);


        if (this.autoStart) {
            if (this.testMode) {

                this.testSession()

            } else {
                this.recordSession()
            }
        }
    },
    computed : {
        canStartVideo() {
            return (this.status != STARTING && this.status != TESTING && this.status != RECORDING && this.status != PLAYING);
        },
        isRecording() {
            return this.status == RECORDING;
        },
        isVideoVisible() {
            return (this.status == TESTING || this.status == RECORDING) && this.video && this.accessAllowed;
        },
        isVideoPlaybackVisible() {
            return (this.status == PLAYING) && this.video && this.accessAllowed;
        },
        isAudioVisible() {
            return (this.status == TESTING || this.status == RECORDING) && this.audio && this.accessAllowed && this.streamStarted;
        },
        isComplete() {
            return this.status == COMPLETED;
        },
        activeUser() {
            return this.$auth.user();
        }
    },
    methods: {
        startTimer() {
            this.timer.total = this.options.timer;
            this.timer.started = new Date().getTime();

            this.timer.interval = setInterval(() => {
                let diff = new Date().getTime() - this.timer.started;

                if (this.timer.total) {

                    this.timer.remaining = Math.round(this.options.timer - (diff / 1000));

                    if (this.timer.remaining <= 0) {
                        this.$emit('expired', this.timer);
                        this.stopTimer();
                        //this.finishSession();
                    }
                }

                this.timer.diff = Math.round(diff / 1000);

                this.$emit('timer', this.timer);

            }, 1000);
        },
        stopTimer() {
            this.timer.finished = new Date().getTime();
            clearInterval(this.timer.interval);
        },
        getStatus() {
            return this.status;
        },
        setStatus(status) {
            this.status = status;
        },
        testSession(activeUser) {
            this.setStatus(STARTING);

            this.$nextTick(() => {
                this.videoElement = document.getElementById(this.inputId);
                this.audioElement = document.getElementById('audio-preview');

                this.joinSession(false, 'test-mode');
            });
        },
        recordSession(name) {
            return new Promise((resolve, reject) => {
                this.recordingName = name;
                //this.setStatus(RECORDING);

                this.$nextTick(() => {
                    this.videoElement = document.getElementById(this.inputId);
                    this.audioElement = document.getElementById('audio-preview');

                    this.joinSession(true, name).then(() => {
                        resolve(true)
                    }).catch((reason) => {
                        reject('Não foi possível registrar esta gravação. Por favor tente novamente');
                    })
                });
            });
        },
        joinSession(recording, recording_name) {
            return new Promise((resolve, reject) => {

                let requestId = null;
                if(this.$root._route && this.$root._route.params && this.$root._route.params.requestId){
                    requestId = this.$root._route.params.requestId;
                }

                this.internalSessionManager.getSession({
                    videoElement : this.videoElement,
                    audioElement : this.audioElement,

                    recording : recording,
                    //type: "individual",
                    request_candidate_uuid: requestId,
                    onrecording : (recording_id) => {

                    },
                    oncleanup : () => {
                        this.setStatus(STOPPED);
                    }
                }).then(() => {
                    const recording_token = requestId + ':' + recording_name + ':' + this.activeUser.company_id + ':' + this.activeUser.id;

                    if (!recording) {
                        this.internalSessionManager.testRecording(recording_token, {
                            video: this.video,
                            audio: this.audio,
                            remote: this.testType == 'remote',
                            data: false
                        }, {
                            onstart : (data) => {
                                console.warn('recording-start', data)
                                this.recordingId = data.id;
                            },
                            onlocalstream : (stream) => {
                                this.videoElement.srcObject = stream;
                                this.videoElement.play();
                            }
                        }).then(() => {
                            this.accessAllowed = true;
                            this.setStatus(TESTING)

                            this.startTimer();
                            this.streamStatus = 'CREATED';
                        })

                    } else {
                        this.internalSessionManager.testRecording(recording_token, {
                            video: this.video,
                            audio: this.audio,
                            data: false
                        }, {
                            onstart : (data) => {
                                console.warn('recording-start', data)
                            },
                            onlocalstream : (stream) => {
                                this.videoElement.srcObject = stream;
                                this.videoElement.play();
                            }
                        }).then(() => {
                            this.accessAllowed = true;
                            this.setStatus(RECORDING);

                            this.startTimer();
                            this.$emit('started');
                            resolve(true);
                        });



                        //this.session = session;
                        this.startPublishing().then(() => {
                            resolve(true)
                        }).catch((error) => {
                            // JUST BY PASS THE ERROR
                            reject(error);
                        })
                    }
                }).catch(() => {
                    reject('no session');

                })


            })
        },
        finishSession(forced) {
            return new Promise((resolve, reject) => {
                if (this.status != STOPPED) {
                    if (this.record) {
                        this.internalSessionManager.stopRecording().then((recording_id) => {

                            //alert('stopped')

                            //verifica se será necessário regravar a pergunta, pois pode não ter salvo os dados necessários
                            //let recordAgain = (!recording_data || !recording_data.sessionId) ? true : null;
                            let recordAgain = false;


                            //this.internalValue.session_id = session_id;
                            this.internalValue.recording_info = {
                                id : recording_id,
                                backend: 'janus'
                            };

                            console.warn('stop', this.internalValue);


                            this.stopTimer();
                            this.streamStatus = 'DESTROYED';

                            this.setStatus(STOPPED)

                            this.$emit('stopped', recordAgain);

                            resolve(this.internalValue, recordAgain)
                        });
                    } else {
                        this.internalSessionManager.stopRecording().then((recordingId) => {
                            console.warn('recordingId', recordingId);
                            //this.recordingId = recordingId;
                            //
                            if (forced !== true) {

                                this.$root.loading = true;

                                setTimeout(() => {
                                    this.setStatus(PLAYING);
                                    
                                    if (this.testType == 'remote') {
                                        if (this.audio && !this.audio) {
                                            this.audioElement = document.getElementById('audio-preview');
                                            this.internalSessionManager.startPlayout(recordingId, this.audioElement);
                                        } else {
                                            this.internalSessionManager.startPlayout(recordingId, this.videoElement2, this.audioElement);
                                        }
                                    }
                                    this.$root.loading = false;
                                }, 3500);
                            }

                        });

                        resolve(this.internalValue, false)

                    }
                } else {
                    resolve(this.internalValue, false)
                }
            });
        },
    }
}

</script>