import Vue from "vue";
import { defineStore } from "pinia";
import Http from "@/plugins/axios"; // não precisa de .js
import * as _ from "lodash-es";
const READ = "/company/config";

const LOGIN = "/auth/login"; //, method: 'POST', fetchUser: true },
const LOGOUT = "/auth/logout"; //, method: 'POST', redirect: '/login', makeRequest: true },
const FETCH = "/auth/user"; //, method: 'GET', enabled: true },

const CHANGE_PASSWORD = import.meta.env.VITE_SSO_URL + "/auth/change-password";

export const useAuth = defineStore("auth", {
    // other options...
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            user_data: null,
        };
    },
    // getters: {
    // },
    actions: {
        installRoutesHooks() {
            Vue.router.beforeEach((to, from, next) => {
                const authRequired = to.matched.some((x) => {
                    return x.meta.auth;
                });

                const isAuth = this.check();

                if (authRequired && !isAuth) {
                    sessionStorage.setItem("redirectPath", to.path);
                    //next({ name: 'login' });
                    next();
                } else if (authRequired && isAuth) {
                    next();
                } else {
                    next();
                }
            });
        },
        changePassword(data) {
            return Http.post(CHANGE_PASSWORD, data);
        },

        user() {
            if (this.check()) {
                return this.user_data;
            }
            return false;
        },
        check() {
            return !_.isEmpty(this.user_data);
        },
        async load() {
            return Http.get(FETCH)
                .then(({ data }) => {
                    this.user_data = data;
                    return data;
                })
                .catch(() => {
                    this.user_data = null;
                });
        },
        async login(data) {
            return new Promise((resolve, reject) => {
                Http.post(LOGIN, data.data)
                    .then(({ data }) => {
                        if (data.status == "success") {
                            this.load().then((data) => {
                                resolve(data);
                            });
                        } else {
                            reject("nao-atenticado");
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async logout() {
            return Http.post(LOGOUT).then(({ data }) => {
                this.user_data = null;
            });
        },
    },
});
