<template>
    <span v-observe-visibility="visibilityChanged">
        <slot v-bind:timer="timer">{{ timer.remaining }}</slot>
    </span>
</template>
<script>

import { ObserveVisibility } from 'vue-observe-visibility'



export default {
    directives: {
        'observe-visibility': ObserveVisibility
    },
    props: {
        counter: {
          type: Number,
          required: true
        },
        paused: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            internalCounter : this.counter,
            timer: {
                total : this.counter,
                started : null,
                finished : null,
                diff: 0,
                interval: null,
                remaining : this.counter
            },
            started: false,
            internalPaused: this.paused
        }
    },
    methods : {
        visibilityChanged (isVisible, entry) {
            if (isVisible && !this.internalPaused) {
                this.startTimer();
            } else {
                //this.stopTimer();
            }
        },
        startTimer() {
            if (!this.started) {
                this.$emit('started');
                this.started = true;
                this.timer.total = this.internalCounter;
                this.timer.started = new Date().getTime();

                this.createInterval();
            }
        },
        pauseTimer() {
            if (this.started) {
                this.internalPaused = true;
                clearInterval(this.timer.interval);
                this.timer.total = this.timer.remaining;
            }
        },
        resumeTimer() {
            this.internalPaused = false;
            this.timer.started = new Date().getTime();

            this.createInterval();
        },
        stopTimer() {
            if (this.started) {
                this.timer.finished = new Date().getTime();
                clearInterval(this.timer.interval);
                this.started = false;

                this.$emit('stopped');
            }
        },
        restartTimer(){
            this.stopTimer();
            this.started = false;
            this.startTimer()
        },
        createInterval() {
            this.timer.interval = setInterval(() => {
                let diff = new Date().getTime() - this.timer.started;

                if (this.timer.total) {

                    this.timer.remaining = Math.round(this.timer.total - (diff / 1000));

                    if (this.timer.remaining <= 0) {
                        this.stopTimer();
                    }
                }
                this.timer.diff = Math.round(diff / 1000);

                this.$emit('timer', this.timer);
            }, 1000);   
        }
    }
}
</script>