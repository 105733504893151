<template>
  <div v-if="section.voter.show">
    <form-field-switch
      name="curriculum.voter.has_voter_registration"
      test-id="admissional.voter.has_voter_registration"
      v-model="curriculum.voter.has_voter_registration"
      v-bind="section.voter.fields.has_voter_registration"
      autocomplete="off"
    />

    <form-set-generic
      v-if="curriculum.voter.has_voter_registration"
      v-model="curriculum.voter"
      label="curriculum"
      form="admissional"
      section="voter"
      :fields="voterFields"
      class="profile-subsection"
    />

  </div>
</template>

<script>
import * as _ from 'lodash-es'
import { useCurriculum } from '@state/user/curriculum'
import FormSetGeneric from '@components/forms/set/Generic.vue'
import { FormFieldSwitch } from '@components/forms'

export default {
  components: {
    FormSetGeneric,
    FormFieldSwitch
  },
  setup() {
    const curriculum = useCurriculum()
    return {
      // you can return the whole store instance to use it in the template
      curriculum
    }
  },
  props : {
    section : {
      type : Object,
      required: true
    }
  },
  computed : {
    voterFields() {
      return _.filter(this.section.voter.fields, (item) => item != 'voter.has_voter_registration')
    }
  }
};
</script>
