<template>
  <section id="colaboradores">
    <div v-if="!only_logo">
      <h4 class="mb-4" v-if="title">{{ title }}</h4>

      <div class="card-colaboradores" v-if="show">
        <swiper-container
          init="false"
          class="swiper-container"
          ref="swiper_container"
        >
          <swiper-slide
            class="d-flex align-items-start flex-column"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="colaborador">
              <div class="colaborador-foto">
                <img
                  :src="item.image"
                  @error="renderPlaceholder($event)"
                  :alt="subtitle"
                />
              </div>
              <div>
                <span class="text-sm text-center">“{{ item.text }}”</span>
              </div>
              <div>
                <span class="colaborador-nome">{{ item.name }}</span>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper-container>
      </div>
    </div>
  </section>
</template>

<script>
// import "swiper/dist/css/swiper.min.css";
//import { Swiper, SwiperSlide } from "swiper/vue";
import { useTheme } from "@state/system/theme";
import { register } from "swiper/element/bundle";
import { Navigation, Pagination } from "swiper/modules";
register();

import swiperPlaceholder from "./assets/swiper-placeholder.png";

export default {
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    only_logo: {
      type: Boolean,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  setup() {
    const theme = useTheme();

    return {
      isMobile: theme.isMobile,
    };
  },
  created() {
    this.$watch(
      "items",
      function (value) {
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
          console.log("re-render start");
          this.$nextTick(() => {
            console.log("re-render end");
          });
        });
      },
      {
        deep: true,
      }
    );
  },
  mounted() {
    const swiperOption = {
      modules: [Navigation, Pagination],
      navigation: this.isMobile(),
      pagination: !this.isMobile(),
      loop: true,
      //effect: 'fade',
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    };

    // swiper element
    const swiperEl = this.$refs.swiper_container;

    // now we need to assign all parameters to Swiper element
    Object.assign(swiperEl, swiperOption);

    // and now initialize it
    swiperEl.initialize();
  },
  data() {
    return {
      show: true,
      placeholderImage: swiperPlaceholder,
    };
  },
  methods: {
    renderPlaceholder(ev) {
      ev.target.src = this.placeholderImage;
    },
  },
};
</script>
