<template>
  <div v-if="section.armed_forces.show">
    <form-field-switch
      name="curriculum.armed_forces.has_certificate_armed_forces"
      test-id="admissional.armed_forces.has_certificate_armed_forces"
      v-model="curriculum.armed_forces.has_certificate_armed_forces"
      v-bind="section.armed_forces.fields.has_certificate_armed_forces"
      autocomplete="off"
    />

    <form-set-generic
      v-if="curriculum.armed_forces.has_certificate_armed_forces"
      v-model="curriculum.armed_forces"
      label="curriculum"
      form="admissional"
      section="armed_forces"
      :fields="armedForcesFields"
      class="profile-subsection"
    />

  </div>
</template>

<script>
import * as _ from 'lodash-es'
import { useCurriculum } from '@state/user/curriculum'
import { useDomain } from '@state/domain/domain'
import { useCompanyForm } from '@state/company/form'
import FormSetGeneric from '@components/forms/set/Generic.vue'
import { FormFieldSwitch, FormFieldRadio } from '@components/forms'

export default {
  components: {
    FormSetGeneric,
    FormFieldSwitch,
    FormFieldRadio
  },
  setup() {
    const curriculum = useCurriculum()
    const domain = useDomain()
    const forms = useCompanyForm()
    return {
      // you can return the whole store instance to use it in the template
      curriculum,
      domain,
      forms
    }
  },
    props : {
        section : {
            type : Object,
            required: true
        }
    },
    // data() {
    //     return {
    //         internalValue: this.value,
    //         internalFields : {},
    //     }
    // },
    computed : {
        armedForcesFields() {
            return _.filter(this.section.armed_forces.fields, (item) => item != 'armed_forces.has_certificate_armed_forces')
        }
    }
};
</script>
