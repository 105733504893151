<template>
    <div
      v-if="show_privacy"
      class="d-flex justify-content-center"
    >
    <div class="w-50 term-box pt-4"   v-html="privacy_policy">
      
    </div>
    </div>
  </template>
  <script>
  import { useConfiguration } from "@state/user/configuration";
  
  export default {
    setup() {
      const configuration = useConfiguration();
  
      return {
        configuration,
      };
    },
    created() {
      this.configuration.getPrivacyPolicy().then((response) => {
        this.privacy_policy = response.data;
        this.show_privacy = true;
      });
    },
  
    data() {
      return {
        privacy_policy: "",
        show_privacy: false,
      };
    },
  };
  </script>
  <style lang="scss">
  .term-box{
      max-height: 40.625rem;
      overflow: scroll;
  }
  
  </style>