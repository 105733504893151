<template>
    <!-- O componente disc-factor-icon recebe uma string e retorna o ícone respectivo -->
    <div>
        <img :src="images[internalFactor]" :width="width">
    </div>
</template>

<script>
import DSvg from './svg/D.svg'
import ISvg from './svg/I.svg'
import SSvg from './svg/S.svg'
import CSvg from './svg/C.svg'

export default {
    props: {
        factor: {
            type: String,
            validator: function (value) {
                // The value must match one of these strings
                return ['d', 'i', 's', 'c', 'D', 'I', 'S', 'C'].indexOf(value) !== -1
            }
        },
        width: {
            type: Number,
            default: 20
        }
    },

    data() {
        return {
            images: {
                'D': DSvg,
                'I': ISvg,
                'S': SSvg,
                'C': CSvg
            },

            internalFactor: this.factor.toUpperCase(),
        }
    },
}
</script>

<style lang="scss">
    .disc-tag--wrap {
        display: inline-block;
        position: relative;
    }
    .disc-tag {
        display: inline-block;
        /*
        // img {
        //     width: 100%;
        //     height: auto;
        // }
        */
    }

    /* fator secundário */
    .factor-1 {
        // width: 60px;
    }
</style>