/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
//import VxTooltip from './../layouts/components/vx-tooltip/VxTooltip.vue'
//import VxCard  from './../components/vx-card/VxCard.vue'
//import VxList  from './../components/vx-list/VxList.vue'
//import VxBreadcrumb  from './../layouts/components/VxBreadcrumb.vue'
//import FeatherIcon  from './../components/FeatherIcon.vue'
//import VxInputGroup  from './../components/vx-input-group/VxInputGroup.vue'

//Vue.component(VxTooltip.name, VxTooltip)
//Vue.component(VxCard.name, VxCard)
//Vue.component(VxList.name, VxList)
//Vue.component(VxBreadcrumb.name, VxBreadcrumb)
//Vue.component(FeatherIcon.name, FeatherIcon)
//Vue.component(VxInputGroup.name, VxInputGroup)

//import SelectyInput from './../views/custom-components/SelectyInput.vue';
//import SelectyDropdown from './../views/custom-components/SelectyDropdown.vue';
//import SelectyTextarea from './../views/custom-components/SelectyTextarea.vue';
//import SelectyRadio from './../views/custom-components/SelectyRadio.vue';
//import SelectyCalendar from './../views/custom-components/SelectyCalendar.vue';
//import SelectyUpload from './../views/custom-components/SelectyUpload.vue';
//import SelectyCheckbox from './../views/custom-components/SelectyCheckbox.vue';
//import SelectyDatepicker from './../views/custom-components/SelectyDatepicker.vue';
//import SelectyField from './../views/custom-components/SelectyField.vue';
////import SelectyBarpicker from './../views/custom-components/SelectyBarpicker.vue';



//Vue.component(SelectyInput.name, SelectyInput);
//Vue.component(SelectyDropdown.name, SelectyDropdown);
//Vue.component(SelectyTextarea.name, SelectyTextarea);
//Vue.component(SelectyRadio.name, SelectyRadio);
//Vue.component(SelectyCalendar.name, SelectyCalendar);
//Vue.component(SelectyUpload.name, SelectyUpload);
//Vue.component(SelectyCheckbox.name, SelectyCheckbox);
//Vue.component(SelectyDatepicker.name, SelectyDatepicker);
//Vue.component(SelectyField.name, SelectyField);
//Vue.component(SelectyBarpicker.name, SelectyBarpicker);


// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('b-icon', {
      props: {
        icon: 'x',
        svgClasses: 'w-4 h-4 mt-1'
      }
    }),
  },
  OpenIndicator: {
    render: createElement => createElement('b-icon', {
      props: {
        icon: 'chevron-down',
        svgClasses: 'w-5 h-5'
      }
    }),
  },
});

Vue.component('v-select', vSelect)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
