<template>
  <div id="banner-cta-buttons" data-scroll-to="banner-cta-buttons" class="w-100 h-100 py-10" :style="{ background: getBackgroundColor() }">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-center mb-3" v-for="(item, index) in items" :key="index">
          <div v-if="item.title" class="mb-3 font-weight-bold text-white" :style="{'font-size': '20px'}">
            {{ item.title }}
          </div>
          <b-button v-if="item.button_link" pill variant="primary" :href="item.button_link" target="_blank" size="sm" :style="{'min-width': '260px', 'font-size': '16px'}">
            {{ item.button_label }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background_color: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getBackgroundColor() {
      const default_color = '#2563eb';
      return this.background_color ? this.background_color : default_color
    }
  }
};
</script>
