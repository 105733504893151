<template>
  <section id="centralized-logo" data-scroll-to="centralized-logo">
    <div class="d-flex justify-content-center">
      <b-avatar
        rounded="sm"
        variant="neutral-subdued"
        class="centralized-logo-div"
      >
        <template #default="{}">
          <img
            v-if="!logoError"
            :src="company.config.menu.square_logo"
            alt="avatar"
            @error="renderLogo"
            class="centralized-logo"
          />

          <OfficeBuildingIcon class="icon-subdued centralized-logo" v-else />
        </template>
      </b-avatar>
    </div>
  </section>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import companyMixin from "@state/company";
import { OfficeBuildingIcon } from "@vue-hero-icons/solid";

export default {
  mixins: [companyMixin],
  components: {
    BAvatar,
    OfficeBuildingIcon,
  },
  computed: {},
  data() {
    return {
      logoError: false,
    };
  },
  methods: {
    renderLogo() {
      this.logoError = true;
    },
  },
};
</script>
