import { i18n } from '@/plugins/i18n'

export function notify(notifiable) {
    let title = notifiable.title

    if (title.startsWith("$t:")) {
        title = i18n.t(title.replace('$t:', ''));
    }

    let message = notifiable.message

    if (message.startsWith("$t:")) {
        message = i18n.t(message.replace('$t:', ''));
    }

    app.$bvToast.toast(message, {
        title: title,
        variant: notifiable.variant,
        solid: true
    })
}