import { useCompany } from '@state/company/config'

export default {
  data() {
    return {
      company: useCompany()
    };
  }
}

export { useCompany }
