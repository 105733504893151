import { defineStore } from 'pinia'
import Vue from 'vue'
import Http from '@/plugins/axios';

const LIST = "/evaluation/survey/list";


export const useSurvey = defineStore('evaluation.survey', {
    // other options...
    state: () => {
        return {
            list : [],
            survey_uuid : null,
            selective_process_id: null,
        }
    },
    actions: {
        read (sp_id) {
            return Http.get('/evaluation/test/questions/' + this.survey_uuid + '/' + this.selective_process_id)
        },
        doTimer(type, info) {
            // SEND THE REQUEST TO START THE EVALUATION TIMER
            return Http.put('/evaluation/test/timer/' + this.survey_uuid + '/' + this.selective_process_id, {type: type, info: info});
        },
        /**
        * [startQuestion description]
        * @param  {[type]} questionIndex [description]
        * @return {[type]}               [description]
        */
        startQuestion(questionIndex) {
            // SEND THE REQUEST TO START THE QUESTION TIMER
            return this.doTimer('evaluation.question.started', {question_id : questionIndex});
        },

        saveQuestion(questionIndex, answer) {
        // SEND THE REQUEST TO STOP THE QUESTION TIMER, AND RETURN THE PROMISSE.ALL
            return Http.post('/evaluation/test/answers/' + this.survey_uuid + '/' + questionIndex + '/' + this.selective_process_id, answer);
        },

        finishQuestion(questionIndex, answer) {
            // SEND THE REQUEST TO STOP THE QUESTION TIMER, AND RETURN THE PROMISSE.ALL
            return Http.post('/evaluation/test/answers/' + this.survey_uuid + '/' + questionIndex + '/' + this.selective_process_id, answer);
        },

        finish(info = {reason : 'normal'}) {
            return Http.post('/evaluation/test/finish/' + this.survey_uuid + '/' + this.selective_process_id, info).then((response) => {
                return response;
                //return this.doTimer('evaluation.finished');
            });
        },

        load(conditions) {
            return Http.post(LIST, conditions).then((response) => {
                this.$patch((state) => {
                    state.list.splice(response.data.length)

                    for (let i in response.data) {
                        Vue.set(state.list, i, response.data[i]);
                    }
                });
                return this.list;
            })
        }
    }
});
