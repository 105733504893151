<template>
    <div class="card-disq card-disq-single p-3 mx-auto">
        <div class="card-disq-header d-flex align-items-center justify-content-between mb-3">
            <div>{{ $t('disc.question.I_am') }}</div>
            <div>
                <span class="mr-2 mr-lg-4 pr-0 text-darkgreen">{{ $t('disc.question.More') }}</span>
                <span class="text-darkred">{{ $t('disc.question.Less') }}</span>
            </div>
        </div>
        <div :key="index" v-for="(term,index) in terms" vs-w="12" class="d-flex align-items-center mb-3 pb-3 card-disq-content justify-content-between">
            <!-- Popover com a descrição de cada termo -->
            <div v-b-popover.hover.top="term.description" variant="primary" class="d-flex align-items-center">
                {{ $t('disc.question.' + term.term) }} <i class="far fa-question-circle fa-sm text-grey ml-1"></i>
            </div>
            <!-- Popover com a descrição de cada termo -->
            <div class="d-inline-flex ml-3">
                    <!--  -->
                    <!-- :vs-name="question + 'most'" -->
                    <!-- v-model="response[question].most.value" -->
                    <!-- :option-index="index" -->
                    <!-- :option-term="term" -->

                <!-- <b-form-radio
                    :color="'success ' + (internalValue.most.value ? 'checked' : '')"
                    class="mb-0 vs-radio-left vs-radio"
                    :vs-value="term.most"
                    vs-name="most"
                    v-model="internalValue.most.value"
                    @change="validate"
                    @input="validate"
                    option-type="most"
                    :option-term="term.term"
                /> -->

                <vs-radio
                    :color="'success ' + (internalValue.most.value ? 'checked' : '')"
                    class="mb-0 vs-radio-left"
                    :vs-value="term.most"
                    vs-name="most"
                    v-model="internalValue.most.value"
                    @change="validate($event)"
                    option-type="most"
                    :option-term="term.term"
                />

                <vs-radio
                    :color="'danger ' + (internalValue.least.value ? 'checked' : '')"
                    class="mb-0"
                    vs-name="least"
                    :vs-value="term.least"
                    v-model="internalValue.least.value"
                    @change="validate($event)"
                    option-type="least"
                    :option-term="term.term"
                />

                <!-- <b-form-radio
                    :color="'success ' + (internalValue.most.value ? 'checked' : '')"
                    class="mb-0 vs-radio-left vs-radio"
                    :vs-value="term.most"
                    vs-name="most"
                    v-model="internalValue.most.value"
                    @change="validate"
                    option-type="most"
                    :option-term="term.term"
                /> -->
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { VBTooltip, VBPopover } from 'bootstrap-vue'
Vue.directive('b-tooltip', VBTooltip)
Vue.directive('b-popover', VBPopover)
import * as _ from 'lodash-es'
export default {
    props: {
        // THE V-MODEL TO RECEIVED THE INFORMATION BACK
        terms : {
            type: Array,
            required: true,
        },
        value : {
            type: Object,
            required: false,
        },
        questionIndex : {
            type: [Number, String],
            required: true,
            default: null
        },
        maxTime: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            internalValue : this.value,
            inputId : null,
            options : {
                timer: this.maxTime // IN SECONDS
            },
            timer: {
                started : null,
                finished : null,
                diff: null,
                interval: null,
                remaining : null,
                percent : null
            }
        }
    },

    created() {
        // RANDOMIZE TERMS?
    },

    mounted() {
        this.$emit('answer:start');
    },

    computed : {
    },

    methods: {
        validate(ev) {

            let type = ev.target.getAttribute('option-type');
            let term = ev.target.getAttribute('option-term');

            this.internalValue[type].option = term;

            console.warn(type, this.internalValue);
            /*
            //let index = ev.target.getAttribute('option-index');
            let response = this.response['question' + (parseInt(index) + 1)];


            */
            console.warn(this.internalValue.most.option, this.internalValue.least.option);

            if (
                !_.isNull(this.internalValue.most.value) &&
                !_.isNull(this.internalValue.least.value)
            ) {
                if (this.internalValue.most.option == this.internalValue.least.option) {
                    if (type == "most") {
                        this.internalValue.least.value = null;
                        this.internalValue.least.option = null;
                        //this.internalValue.least = null;
                    } else {
                        //this.internalValue.most = null;
                        this.internalValue.most.option = null;
                        this.internalValue.most.value = null;
                    }
                    //this.form_errors[index + 1] = true;
                    this.$emit('answer:change', false, this.questionIndex, this.internalValue);
                    return false;
                }
                this.$emit('input', this.internalValue);
                this.$emit('answer:change', true, this.questionIndex, this.internalValue);
                //this.form_errors[index + 1] = false;
                return true;
            }
            this.$emit('answer:change', false, this.questionIndex, this.internalValue);
            return false;
        },

        recordSession() {
            this.startTimer();
            this.$emit('started');
        },

        startTimer() {
            this.timer.total = this.options.timer;
            this.timer.started = new Date().getTime();

            this.timer.interval = setInterval(() => {
                let diff = new Date().getTime() - this.timer.started;

                if (this.timer.total) {

                    this.timer.remaining = Math.round(this.options.timer - (diff / 1000));

                    if (this.timer.remaining <= 0) {
                        this.finishSession();
                    }
                }

                this.timer.diff = Math.round(diff / 1000);

                this.$emit('timer', this.timer);

            }, 1000);
        },

        stopTimer() {
            this.timer.finished = new Date().getTime();
            clearInterval(this.timer.interval);
        },

        pushEvent(event) {
            this.logs.push(event);
        },

        finishSession() {
            //this.internalValue = this.$refs.input.value;
            this.stopTimer();
            this.$emit('stopped');
        }
    }
}

</script>