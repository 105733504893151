import * as FormComponents from "@components/forms";
import { useDomain } from "@state/domain/domain";
import { useCompanyForm } from "@state/company/form";
import { useChangeable } from "@state/behaviours/changeable";
import { useCurriculum } from "@state/user/curriculum";
import * as _ from "lodash-es";

export default {
    components: {
        ...FormComponents,
    },
    props: {
        label: {
            type: String,
            required: false,
        },
        form: {
            type: String,
            required: false,
            default: "curriculum",
        },
        value: {
            type: Object | Array,
            required: true,
        },
        fields: {
            type: Array | Object,
            required: true,
        },
        section: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        noCard: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            forms: null,
            domain: null,
            curriculum: null,
            new_entity: {},
            is_loaded: null,
            changeable: null,
            getDomainLabel: null,

            internalValue: this.value,
            internalFields: {},
        };
    },
    computed: {
        hasFields() {
            return !_.isEmpty(this.internalFields);
        },
        computedValue() {
            return _.filter(this.internalValue, (item, index) => {
                return !_.has(item, "_action") || item._action != "remove";
            });
        },
    },
    created() {
        this.forms = useCompanyForm();
        this.domain = useDomain();
        this.curriculum = useCurriculum();
        this.new_entity = {};
        this.is_loaded = false;
        this.changeable = useChangeable();
        this.getDomainLabel = this.domain.getLabel;

        if (_.isArray(this.fields) || _.isString(this.fields)) {
            this.forms
                .fields(this.form, true, ...this.fields)
                .then((fields) => {
                    for (let i in fields[this.section]) {
                        this.$set(
                            this.internalFields,
                            i,
                            fields[this.section][i]
                        );
                    }
                    this.is_loaded = true;
                });
        } else {
            for (let i in this.fields) {
                this.$set(this.internalFields, i, this.fields[i]);
            }
            this.is_loaded = true;
        }
    },
    methods: {
        add() {
            //this.clear();
            this.editing = false;
            this.$nextTick(() => this.$refs.modal.show());
        },
        edit(entity, index) {
            for (let i in entity) {
                this.$set(this.new_entity, i, entity[i]);
            }
            this.editing = true;
            this.editingIndex = index ?? 0;
            this.$nextTick(() => this.$refs.modal.show());
        },
        append(bvModalEvent) {
            // PREVENT CLOSING UNTIL IT IS VALIDATED
            bvModalEvent.preventDefault();

            this.$refs.entityRules.validate().then((success) => {
                if (success) {
                    const entity = _.clone(this.new_entity);
                    if (this.editing) {
                        /**
                         * @todo Move to curriculum state
                         */
                        const changeIndex = `${this.name}[${this.editingIndex}]`;
                        let changes = {};
                        // _.set(changes, changeIndex, entity);
                        _.set(changes, this.name, [entity]);

                        // PLACE THE EDIT
                        this.$bvModal.show("modal-saving");

                        if (this.$auth.check()) {
                            this.curriculum
                                .persist(_.get(changes, this.form, {}))
                                .then(({ data }) => {
                                    if (_.has(data, "merge")) {
                                        const data_to_set = _.merge(
                                            entity,
                                            _.get(
                                                data.merge,
                                                `${this.section}[${this.editingIndex}]`
                                            )
                                        );
                                        this.$set(
                                            this.internalValue,
                                            this.editingIndex,
                                            data_to_set
                                        );
                                    } else {
                                        this.$set(
                                            this.internalValue,
                                            this.editingIndex,
                                            entity
                                        );
                                    }

                                    this.$bvModal.hide("modal-saving");
                                })
                                .catch(() => {
                                    this.$bvModal.hide("modal-saving");
                                });

                        // step 2
                        } else {
                            this.$set( this.internalValue, this.editingIndex, entity);
                            this.$bvModal.hide("modal-saving");
                        }

                        /*
                        this.changeable.placeChange(this.name + '[' + this.editingIndex +']', entity).then((response) => {
                        })
                        */
                    } else {
                        this.editingIndex = this.internalValue.length;
                        const changeIndex = `${this.name}[${this.editingIndex}]`;
                        let changes = {};
                        // _.set(changes, changeIndex, entity);
                        _.set(changes, this.name, [entity]);

                        if (this.$auth.check()) {
                            // PLACE THE ADD

                            this.$bvModal.show("modal-saving");
                            this.curriculum
                                .persist(_.get(changes, this.form, {}))
                                .then(({ data }) => {
                                    if (_.has(data, "merge")) {
                                        const data_to_set = _.merge(
                                            entity,
                                            _.get(
                                                data.merge,
                                                `${this.section}[${this.editingIndex}]`
                                            )
                                        );
                                        //this.$set(this.internalValue, this.editingIndex, data_to_set);
                                        this.internalValue.push(data_to_set);
                                    } else {
                                        this.internalValue.push(entity);
                                    }

                                    this.$bvModal.hide("modal-saving");
                                })
                                .catch(() => {
                                    this.$bvModal.hide("modal-saving");
                                });
                        } else {
                            this.internalValue.push(entity);
                        }

                        //this.changeable.placeChange(this.name + '[' + (this.internalValue.length - 1) +']', entity)
                    }

                    // @todo PLACE THE CHANGE TO SAVE THE ENTRY

                    this.clear();

                    this.$refs.modal.hide();
                } else {
                    //this.scrollToError();
                }
            });
        },
        remove(entity, index) {

            if (this.$auth.check()) {
                entity._action = "remove";
                this.changeable.placeChange(this.name + "[" + index + "]", entity).then((name, value) => {
                    this.internalValue.splice(index, 1);
                });
            } else {
                this.internalValue.splice(index, 1);
            }
        },
        clear() {
            for (let i in this.new_entity) {
                this.$set(this.new_entity, i, null);
            }
        },
    },
};
