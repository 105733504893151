import { isAfter, isDate, isValid } from "date-fns";

export default (value, args) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    //Valida se a data está no formato brasileiro
    if (value.match(regex) === null) {
        return false;
    }

    //Separa o dia, mês e ano
    const [day, month, year] = value.split("/");

    //Cria a data
    const date = new Date(year, month, day);

    // Verifica se é uma data válida
    return (
        isDate(date) && isAfter(date, new Date("1910-01-01")) && isValid(date)
    );
};
