import { defineStore } from "pinia";
import Http from "@/plugins/axios";

const READ = "/profile";
const UPDATE_NOTIFICATIONS = "/profile/update-notifications";
const CHANGE_EMAIL = "/profile/change-email";
const CHANGE_PASSWORD = "/profile/change-password";
const CHANGE_AVATAR = "/profile/change-avatar";
const SEND_LGPD_MESSAGE = "/profile/send-lgpd-message";
const CREATE_LGPD = "/profile/create-lgpd";
const CANCEL_ACCOUNT = "/profile/cancel-account";
const PRIVACY_POLICY = "/privacy-policy";
const TERMS_AND_CONDITIONS = "/terms-and-conditions";
const CHECK_SOCIAL_MEDIA_REGISTRATION = '/auth/check-social-media-registration';
const CHECK_COMPLEMENTARY_HIRING_FORM = 'auth/check-complementary-hiring-form'

export const useConfiguration = defineStore("configuration", {
    // other options...
    state: () => {
        return {
            data: {},
        };
    },
    actions: {
        async read() {
            return Http.get(READ);
        },
        async updateNotifications(data) {
            return Http.post(UPDATE_NOTIFICATIONS, data);
        },

        async createLgpd(data) {
            return Http.post(CREATE_LGPD, data);
        },

        async changeEmail(data) {
            return Http.post(CHANGE_EMAIL, data);
        },

        async getPrivacyPolicy() {
            return Http.post(PRIVACY_POLICY);
        },

        async getTermsAndConditions() {
            return Http.post(TERMS_AND_CONDITIONS);
        },

        async changePassword(data) {
            return Http.post(CHANGE_PASSWORD, data);
        },

        async changeAvatar(data) {
            return Http.post(CHANGE_AVATAR, data);
        },

        async sendLgpdMessage(data) {
            return Http.post(SEND_LGPD_MESSAGE, data);
        },

        async checkSocialMediaRegistration(data){
            return Http.post(CHECK_SOCIAL_MEDIA_REGISTRATION, data);
        },

        async cancelAccount() {
            return Http.post(CANCEL_ACCOUNT);
        },

        async checkComplementaryHiringForm() {
            return Http.post(CHECK_COMPLEMENTARY_HIRING_FORM);
        },
    },
});
