<template>
  <div v-if="section.contacts.show">
    <form-set-generic
      v-model="curriculum.contacts"
      label="curriculum"
      form="admissional"
      section="contacts"
      :fields="fields"
      class="profile-subsection"
    />

  </div>
</template>

<script>
import { useCurriculum } from '@state/user/curriculum'
import FormSetGeneric from '@components/forms/set/Generic.vue'

export default {
  components: {
    FormSetGeneric
  },
  setup() {
    const curriculum = useCurriculum()
    return {
      // you can return the whole store instance to use it in the template
      curriculum
    }
  },
  props : {
    section : {
      type : Object,
      required: true
    }
  },
  computed : {
    fields() {
      return this.section.contacts.fields;
    }
  }
};
</script>
