<template>
  <form @submit.prevent>
    <div class="search-bar flex-column flex-md-row gap-2 align-items-center">
      <form-field-input-dropdown
        class="search-bar-item w-full"
        :placeholder="$t('vacancy.filters.text.placeholder')"
        v-model="vacancies.conditions.text"
        :search="vacancies.getSearchTextSuggestions"
        :options="vacancies.suggestions.text"
        :grouped="true"
        untracked
      >
        <template #footer="{ close }">
          <b-button
            type="button"
            @click="
              $emit('search');
              close();
            "
            variant="primary"
            pill
            block
          >
            {{ $t("vacancy.search") }}
          </b-button>
        </template>
      </form-field-input-dropdown>
      <form-field-input-dropdown
        class="search-bar-item w-full"
        :placeholder="$t('vacancy.filters.location.placeholder')"
        v-model="vacancies.conditions.location"
        :search="vacancies.getSearchLocationSuggestions"
        :options="vacancies.suggestions.location"
        :grouped="true"
        :group_selectable="true"
        untracked
      >
        <template #footer>
          <b-button
            type="button"
            @click="$emit('search')"
            variant="primary"
            pill
            block
          >
            {{ $t("vacancy.search") }}
          </b-button>
        </template>
      </form-field-input-dropdown>

      <b-button
        type="submit"
        @click="$emit('search')"
        variant="outline-primary"
        class="search"
        size="sm"
        pill
        :class="{ 'w-full': isMobile() }"
      >
        {{ $t("vacancy.search") }}
      </b-button>
    </div>
  </form>
</template>

<script>
import * as _ from "lodash-es";

import Vue from "vue";
import * as FormComponents from "@components/forms";
import { useVacancies } from "@state/vacancy/vacancies";
import { useTheme } from "@state/system/theme";

// interface Conditions {
//   title: string,
//   okMessage: string,
//   cancelMessage: string
// }

export default Vue.extend({
  components: {
    ...FormComponents,
  },
  props: {
    conditions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      forceHidden: true,
      loading: false,
      timeout: null,
      cities: [],
    };
  },
  setup(props) {
    const vacancies = useVacancies();
    const theme = useTheme();

    let internalConditions = {};

    if (!_.isUndefined(props.conditions)) {
      internalConditions = props.conditions;
    } else {
      internalConditions = vacancies.conditions;
    }

    return {
      internalConditions,
      vacancies,
      isMobile: theme.isMobile,
    };
  },
});
</script>
