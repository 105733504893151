<template>
  <div>
    <form-set-generic
      v-if="section.personal.show"
      v-model="curriculum.personal"
      label="curriculum"
      form="esocial"
      section="personal"
      :fields="section.personal.fields"
      autocomplete="off"
    />
  </div>
</template>

<script>
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";

export default {
  components: {
    FormSetGeneric,
  },
  setup() {
    const curriculum = useCurriculum();
    return {
      // you can return the whole store instance to use it in the template
      curriculum,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },

  methods: {},
  watch: {},
};
</script>
