import Vue from 'vue'
import { defineStore } from 'pinia'
import Http from '@/plugins/axios';

const LOAD = '/evaluation/test/questions';
const TIMER = '/evaluation/test/timer';

const LIST = "/evaluation/survey/list";


export const useDisc = defineStore('evaluation.disc', {
    // other options...
    state: () => {
        return {
            id: null,
            test: {},
            selective_process_id: null,
        }
    },
    actions: {
        load(id) {
            let disc_route = `${LOAD}/${id}`;
            if (this.selective_process_id) {
                disc_route += '/' + this.selective_process_id;
            }

            return Http.get(disc_route).then((response) => {
                this.$patch((state) => {
                    for (let i in response.data) {
                        Vue.set(state.test, i, response.data[i]);
                    }

                    state.id = state.test.request_candidate_uuid;
                });
                return this.test;
            })
        },
        doTimer(type, info) {
            // SEND THE REQUEST TO START THE EVALUATION TIMER
            return Http.put(`${TIMER}/${this.id}/${this.selective_process_id}`, { type: type, info: info });
        },

        list(conditions) {
            return Http.post(LIST, conditions);
        },
        /**
        * [startQuestion description]
        * @param  {[type]} questionIndex [description]
        * @return {[type]}               [description]
        */
        startQuestion(questionIndex) {
            // SEND THE REQUEST TO START THE QUESTION TIMER
            return this.doTimer('evaluation.question.started', { question_id: questionIndex });
        },

        saveQuestion(questionIndex, answer) {
            // SEND THE REQUEST TO STOP THE QUESTION TIMER, AND RETURN THE PROMISSE.ALL
            return Http.post(`/evaluation/test/answers/${this.id}/${questionIndex}/${this.selective_process_id}`, answer);
        },

        finishQuestion(questionIndex) {
            // SEND THE REQUEST TO STOP THE QUESTION TIMER, AND RETURN THE PROMISSE.ALL
            return this.doTimer('evaluation.question.finished', { question_id: questionIndex });
        },

        finishTest(answers) {

            return Promise.all([
                this.doTimer('evaluation.finished'),
                //Http.post('/evaluation/test/answers/' +this.options.id + '/' + questionIndex, answer)
                Http.post(`/evaluation/disc/finish/${this.id}/${this.selective_process_id}`, answers)
            ]);
        },

        results(id) {
            return Http.get(`/evaluation/disc/results/${id}/${this.selective_process_id}`)
        },

        getLastDiscDone() {
            return Http.get(`/evaluation/disc/get-last-disc-done`)
        }
    }
});