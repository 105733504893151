<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :description="description"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <label :id="fieldsetId + '__BV_label_'" :for="inputId" class="d-block" v-if="internalLabel">
                        {{ internalLabel }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </slot>
            </template>


                <!-- @input="$emit('input', internalValue)"
                @change="$emit('input', internalValue)" -->
                <!--  -->

            <currency-input
                ref="input"
                :id="inputId"
                :options="options"
                class="w-full"
                :class="{'is-invalid' : errors.length > 0}"
                :label="internalLabel"
                :value="internalValue"
                :placeholder="internalPlaceholder"
                :data-cy="internalTestId"
                v-bind="$attrs"
                @change="dispatchEvent('change', internalValue = $event)"
                autocomplete="off"
            />
                <!-- "
                lazy -->
            <small class="text-danger">{{ errors[0] }}</small>

        </b-form-group>

    </validation-provider>
</template>

<script>
import * as _ from 'lodash-es'

import { ValidationProvider } from 'vee-validate'
import FormFieldBase from '../mixins/Base'
import companyMixin from '@state/company'
import masks from '@lang/config/masks';
import CurrencyInput from './_internal/CurrencyInput.vue'

/**
 * Componente que encapsula o componse vue-curreny-input, e aplica máscaras para valores monetários, de acordo com o país atual.
 *
 * @see https://dm4t2.github.io/vue-currency-input/
 */
export default {
    name: 'form-field-currency',
    mixins : [
        FormFieldBase,
        companyMixin
    ],
    components: {
        ValidationProvider,
        CurrencyInput
    },
    data() {
        return {
            masks,
            defaultMasks : masks.BR
        };
    },
    computed : {
        options() {
            // GET THE MASK BASED ON LOCALE AND phoneType. OR BASES ON COUNTRY CODE IF IT'S
            if (_.has(this.masks, this.company.currentCountry + '.' + 'currency')) {
                return _.get(this.masks, this.company.currentCountry + '.' + 'currency');
            }
            return _.get(this.defaultMasks, 'currency');
        }
    }
}
</script>
<!--
<docs>
```vue
<template>
    <div class="container">
        <form-field-currency v-model="inputValue" name="field-name" />
        <span>v-model: {{ inputValue }}</span>
    </div>
</template>

  <script>
    export default {
      data() {
        return { inputValue: 0 };
      }
    }
  </script>
```
</docs>
-->
