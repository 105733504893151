<template>
    <div class="bullet-level">
        <span class="mr-auto">
        {{this.currentLabel}}
        </span>
        <ul :class="'list-level-' + this.currentLevel">
            <li 
                v-for="( item, index ) in this.max"
                :key="index"
                @click="emitValue( index )"
                :class="calculateClass( index )">
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                default: 1,
                required: false
            },
            label: {
                type: String,
                required: false
            },
            min: {
                required: false
            },
            max: {
                required: false
            },
            uiTheme: {
                type: String,
                required: false,
                default: ''
            },
            editable: {
                required: false,
                default: true
            }
        },
        data () {
            return {
                currentLevel: '',
                currentLabel: '',
                internalValue: this.value
            }
        },
        methods: {
            converterIntToLevel: function() {
                if ( this.uiTheme == 'stars' ) {
                    this.currentLevel = 'modular';
                } else {
                    if(this.max == 3){
                        switch ( true ) {
                            case (this.percent <= 0.34):
                                this.currentLevel = 'basic';
                                break;
                            case (this.percent > 0.34 && this.percent <= 0.67):
                                this.currentLevel = 'intermediate';
                                break;
                            case (this.percent > 0.67):
                                this.currentLevel = 'advanced';
                                break;
                        }
                    } else if(this.max == 4) {
                        switch ( true ) {
                            case (this.percent <= 0.25):
                                this.currentLevel = 'basic';
                                break;
                            case (this.percent > 0.25 && this.percent <= 0.5):
                                this.currentLevel = 'intermediate';
                                break;
                            case (this.percent > 0.5 && this.percent <= 0.75):
                                this.currentLevel = 'advanced';
                                break;
                            case (this.percent > 0.75):
                                this.currentLevel = 'fluent';
                                break;
                        }
                    }
                }
            },
            calculateClass: function( index ) {
                if (index < this.value) {
                    return 'checked'
                }
                return 'unchecked';

            },
            emitValue( index ) {
                if(this.editable) {
                    this.internalValue = (index + 1);
                    this.$emit('input', this.internalValue);
                }
            }
        },
        watch: {
            value() {
                this.converterIntToLevel();
            },
            percent: function() {
                this.converterIntToLevel();
            }
        },
        computed: {
            percent: {
                get: function() {
                    return parseInt(this.value)/this.max;
                }
            }
        },
        mounted: function () {
            //this.maxLevel = (this.max) ? this.max : 40;

            this.converterIntToLevel();
            this.currentLabel = (this.label) ? this.label : '';
        }
    }

</script>
