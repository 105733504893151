<template>
  <validation-provider
    ref="validator"
    #default="{ errors, required }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
  >
    <b-form-group
      :name="internalVid"
      :id="fieldsetId"
      :label="internalLabel"
      :label-for="inputId"
      :state="errors.length == 0"
    >
      <template #label>
        <slot name="label" :inputId="inputId" :label="internalLabel">
          <label
            :id="fieldsetId + '__BV_label_'"
            :for="inputId"
            class="d-block"
            v-if="internalLabel"
          >
            {{ internalLabel }}
            <span v-if="required" class="text-danger">*</span>
          </label>
        </slot>
      </template>

      <div
        ref="droppable"
        class="d-flex justify-content-start"
        :class="{ 'bg-black': isDragging }"
        @drop="isDragging = false"
        @dragleave="isDragging = false"
        @dragover="isDragging = true"
      >
        <div v-if="preview && type == 'avatar'" class="my-1">
          <letter-avatar
            v-if="showAvatar"
            :avatar-url="internalValue"
            :name="avatarName"
            :size="avatar_size"
          />
          <div class="progress mt-2" v-if="internalFile && internalFile[0]">
            <div
              :class="{
                'progress-bar': true,
                'progress-bar-striped': true,
                'bg-danger': internalFile[0].error,
                'progress-bar-animated': internalFile[0].active,
              }"
              role="progressbar"
              :style="{ width: internalFile[0].progress + '%' }"
            >
              {{ internalFile[0].progress }}%
            </div>
          </div>
        </div>
        <p v-if="preview && type == 'document'" class="my-1">
          <!-- <b-badge v-for="file in internalFile" :key="file.id">{{ file.name }}</b-badge> -->
          <b-badge v-for="file in alreadyLoaded" :key="file.name">{{
            file.name
          }}</b-badge>
        </p>
      </div>
      <div class="d-flex justify-content-start gap-2">
        <file-upload
          :id="inputId"
          :input-id="inputId + '-file-html'"
          :data-cy="internalTestId"
          v-model="internalFile"
          :accept="accept"
          :extensions="extensions"
          post-action="/backend/files/upload"
          :size="1024 * 1024 * 8"
          :multiple="multiple"
          @input-file="inputFile"
          @input-filter="inputFilter"
          :drop="$refs.droppable"
          class="btn-sm btn-outline-primary border rounded-pill"
          ref="upload"
          :class="{ 'is-invalid': errors.length > 0 }"
          v-bind="$attrs"
          v-if="!multiple || type == 'avatar'"
        >
          {{ uploadText ? uploadText : $t("forms.fields.upload") }}
        </file-upload>

        <b-button
          href="#"
          @click="removeFile"
          variant="danger"
          size="sm"
          v-if="canRemoveFile"
          pill
        >
          {{ $t("forms.fields.remove_file") }}
        </b-button>
      </div>

      <div class="flex flex-column">
        <div>{{ internalDescription }}</div>
        <div class="text-danger" v-if="uploadError">
          {{ $t("forms.fields.default_error") }}
        </div>
        <div class="text-info text-wrap">
          {{ $t("forms.fields.allowed_extensions") }}:
          {{ extensions.split(",").join(", ") }}
        </div>
      </div>

      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";
import FileUpload from "vue-upload-component";
import FormFieldBase from "../mixins/Base";
import { ValidationProvider } from "vee-validate";
import LetterAvatar from "@components/avatar/LetterAvatar.vue";

import Http from "@/plugins/axios"; // não precisa de .js

/**
 * Componente de upload, permitindo visualização, upload de avatar e cropping
 *
 * @see https://lian-yue.github.io/vue-upload-component/#/en/
 */
export default {
  name: "form-field-upload",
  mixins: [FormFieldBase],
  components: {
    ValidationProvider,
    //Cropper,
    FileUpload,
    LetterAvatar,
  },
  props: {
    /**
     * Define o tipo de arquivo a ser enviado, avatar (ou imagem), ou documento
     */
    type: {
      type: String,
      required: false,
      default: "document",
      validator: (value) => ["document", "avatar"].indexOf(value) != -1,
    },
    /**
     * Define se o preview do documento enviado está ativado
     */
    preview: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Define se o envio de multiplos arquivos é permitido
     */
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Define o texto usado para ao botão de upload
     */
    uploadText: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * Define as iniciais para o avatar
     */
    avatarName: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * Define o texto usado para ao botão de upload
     */
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },

    immediate: {
      type: Boolean,
      default: true,
      required: false,
    },

    /**
     * @todo Incluir atributos para [drag and drop support, envio de diretórios, arquivos zip]
     * @todo Incluir atributos para [rodar reconhecimento de arquivo, rodar OCR, rodar parsing]
     */
  },
  data() {
    return {
      internalFile: [],
      alreadyLoaded: [],
      isDragging: false,
      avatar_size: "84px",
      imageError: false,
      uploadError: false,
      showAvatar: true,
    };
  },
  computed: {
    canRemoveFile() {
      return (
        this.clearable && this.internalValue && this.internalValue !== "REMOVED"
      );
    },
    extensions() {
      if (this.type == "avatar") {
        return ["jpg", "jpeg", "gif", "png", "webp"].join(",");
      } else if (this.type == "document") {
        return [
          "jpg",
          "jpeg",
          "gif",
          "png",
          "webp",
          "pdf",
          "docx",
          "doc",
          "odt",
        ].join(",");
      }
    },
    accept() {
      if (this.type == "avatar") {
        return ["image/png", "image/gif", "image/jpeg", "image/webp"].join(",");
      } else if (this.type == "document") {
        return [
          "image/png",
          "image/gif",
          "image/jpeg",
          "image/webp",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.oasis.opendocument.text",
        ].join(",");
      }
    },
  },
  watch: {
    internalValue() {
      if (this.internalValue && this.internalValue !== "REMOVED") {
        if (
          !this.internalValue.toString().startsWith("http") &&
          !this.internalValue.toString().startsWith("blob")
        ) {
          if (this.preview && this.type == "avatar") {
            // LOAD FILE HERE
            Http.get("/files/download/" + this.internalValue, {
              responseType: "blob",
            }).then((blob) => {
              this.imageError = false;
              this.$nextTick(() => {
                this.showAvatar = false;
                this.internalValue = URL.createObjectURL(blob.data);
                console.log("re-render start");
                this.$nextTick(() => {
                  this.showAvatar = true;
                  console.log("re-render end");
                });
              });
            });
          } else if (this.preview && this.type == "document") {
            // LOAD FILE HERE

            Http.get("/files/metadata/" + this.internalValue)
              .then((response) => {
                this.uploadError = false;
                this.alreadyLoaded.push(response.data);
                this.internalFile.push(this.internalValue);
              })
              .catch(() => {
                this.uploadError = true;
              });
          }
        }
      } else {
        this.alreadyLoaded.length = 0;
      }
    },
  },
  methods: {
    removeFile() {
      if (!this.internalValue.toString().startsWith("http")) {
        Http.post("/files/remove/" + this.internalValue)
          .then((response) => {
            for (let i in this.internalFile) {
              this.$delete(this.internalFile, 0);
              // this.internalFile.splice(0, -1);
            }

            this.internalFile = this.internalFile.splice(0, -1);

            this.dispatchEvent("change", (this.internalValue = "REMOVED"));
          })
          .catch((error) => {
            this.imageError = true;
          });
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file
      }

      if (newFile && oldFile) {
        // Update file

        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // min size
          /*
                    if (newFile.size >= 0 && newFile.size < 100 * 1024) {
                        newFile = this.$refs.upload.update(newFile, {error: 'size'})
                    }
                    */
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error.length > 0 && newFile.error !== oldFile.error) {
          // console.log("error", newFile.error, newFile);
          this.uploadError = true;
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.uploadError = false;
          if (this.internalFile.length > 0) {
            this.alreadyLoaded.splice(0, this.alreadyLoaded.length);
          }

          this.dispatchEvent("change", newFile.response.value);

          this.$nextTick(() => {
            this.showAvatar = false;
            this.internalValue = newFile.response.value;

            console.log("re-render start");

            this.$nextTick(() => {
              this.showAvatar = true;
              console.log("re-render end");
            });
          });
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (!_.isUndefined(newFile)) {
        if (
          Boolean(newFile) !== Boolean(oldFile) ||
          oldFile.error !== newFile.error
        ) {
          if (!this.$refs.upload.active) {
            newFile.headers = {
              Authorization: `Bearer ${this.$auth.token()}`,
            };
            this.$refs.upload.active = true;
          }
        }
      }
    },
  },
};
</script>

<!--
<docs>
```vue
  <template>
    <form-field-upload
        name="field-name"
        v-model="inputValue"
        type="document"
        :preview="preview"
        :multiple="multiple"
        uploadText="Enviar Arquivos"
    />
  </template>

  <script>
    export default {
      data() {
        return {
            inputValue: ''
            type: 'document',
            preview: true,
            multiple: false
        };
      }
    }
  </script>
```
</docs>
-->
