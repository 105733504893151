<template>
  <div
    class="home d-flex flex-column"
    :class="{ 'gap-10': isMobile(), 'gap-16': !isMobile() }"
    v-if="currentSections"
  >
    <!-- {{ company.config.colors['botao-ativo'] }} -->
    <!-- <TransitionGroup name="fade" tag="div"> -->
    <component
      v-for="(section, index) in currentSections"
      :is="section.type"
      :key="index"
      v-bind="section.data"
      :class="index === 0 && section.type !== 'banner' ? 'pt-10' : null"
    />
    <!-- </TransitionGroup> -->

    <!-- <PortalNaoEncontrouVaga v-bind:config="theme" /> -->

    <!-- <PortalBanner2 v-bind:config="theme" /> -->

    <!-- <PortalValores v-bind:config="theme" /> -->

    <!-- <PortalColaboradores v-bind:config="theme" /> -->
  </div>
</template>

<script>
import companyMixin from "@state/company";
import List from "@blocks/editor/List.vue";
import Swiper from "@blocks/editor/Swiper.vue";
import Banner from "@blocks/editor/Banner.vue";
import { useTheme } from "@state/system/theme";
import RichText from "@blocks/editor/RichText.vue";
import Accordion from "@blocks/editor/Accordion.vue";
import Placeholder from "@blocks/editor/Placeholder.vue";
import BannerMosaic from "@blocks/editor/BannerMosaic.vue";
import Ambiente from "./sections/PortalAmbienteTrabalho.vue";
import ImageWithList from "@blocks/editor/ImageWithList.vue";
import CentralizedLogo from "@blocks/editor/CentralizedLogo.vue";
//import HighlightedVacancies from "@blocks/editor/HighlightedVacancies.vue";
//import RecentVacancies from "@blocks/editor/RecentVacancies.vue";
import Vacancies from "@blocks/editor/Vacancies.vue";

import GroupedVacancies from "@blocks/editor/GroupedVacancies.vue";
import AccordionWithImage from "@blocks/editor/AccordionWithImage.vue";
import CreateAccountButtonBar from "@blocks/editor/CreateAccountButtonBar.vue";

import { notify } from "@/plugins/notification";

export default {
  mixins: [companyMixin],
  components: {
    Placeholder,
    BannerMosaic,
    Vacancies,
    CentralizedLogo,
    //RecentVacancies,
    //HighlightedVacancies,
    RichText,
    ImageWithList,
    Accordion,
    Swiper,
    GroupedVacancies,
    List,
    Banner,
    Ambiente,
    AccordionWithImage,
    CreateAccountButtonBar,
  },
  created() {
    this.theme.load(
      this.company.config.themes.current,
      this.company.config.themes.list
    );
  },
  setup() {
    const theme = useTheme();
    return {
      theme,
      isMobile: theme.isMobile,
    };
  },
  computed: {
    currentSections() {
      return this.company.config.sections.home.filter(
        (section) => section.enabled
      );
    },
  },
};
</script>
