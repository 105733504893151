import * as _ from "lodash-es";

export default {
    methods: {
        getWorkplaceType(workplaceType) {
            const map = {
                Presencial: "vacancy.filters.workplace_type.tokens.presential",
                Híbrido: "vacancy.filters.workplace_type.tokens.hybrid",
                Remoto: "vacancy.filters.workplace_type.tokens.homeOffice",
                Hibrido: "vacancy.filters.workplace_type.tokens.hybrid",
                Externo: "vacancy.filters.workplace_type.tokens.extern",
            };

            if (_.has(map, workplaceType)) {
                return map[workplaceType];
            } else {
                return "vacancy.filters.workplace_type.tokens." + workplaceType;
            }
        },
        getStatus(candidate_status, application_status, grade) {
            const map = {
                n: "application.status.STATUS_NOT_ACCEPTED",
                c: "application.status.STATUS_CALLED",
                h: "application.status.STATUS_HIRED",
                j: "application.status.STATUS_HIRING",
                i: "application.status.STATUS_ACCEPTING",
                e: "application.status.STATUS_EXCLUDED",
                l: "application.status.STATUS_LEAVE",
                a: "application.status.STATUS_ACCEPTED",
                w: "application.status.STATUS_WAITING",
                g: "application.status.STATUS_HIRED_CANCELED",
                m: "application.status.STATUS_HIRED_REPLACEMENT",
                d: "application.status.STATUS_DISAPPROVED",
                o: "application.status.STATUS_NOT_CONTACTED",
                t: "application.status.STATUS_THINKING",
                x: "application.status.STATUS_NOT_ATTEND",
                f: "application.status.STATUS_NO_PROFILE",
                p: "application.status.STATUS_APPROVED",
            };

            if (_.indexOf(["s", "w"], application_status) >= 0) {
                if (_.has(map, candidate_status)) {
                    return map[candidate_status];
                } else {
                    if (grade == -2) {
                        return "application.status.STATUS_NO_PROFILE";
                    } else {
                        return "application.status.STATUS_ACCEPTING";
                    }
                }
            } else if (_.indexOf(["c", "f", "a"], application_status) >= 0) {
                if (_.indexOf(["h", "j"], candidate_status) >= 0) {
                    return map["h"];
                } else {
                    return map["d"];
                }
            }

            // // Status de convocação
            // const STATUS_WAITING = 'w';
            // const STATUS_NOT_ACCEPTED = 'n';
            // const STATUS_CALLED = 'c';
            // const STATUS_NOT_CONTACTED = 'o';
            // const STATUS_THINKING = 't';
            // const STATUS_NOT_ATTEND = 'x';
            // const STATUS_SHOW_UP = 's';
            // const STATUS_NO_PROFILE = 'f';
            // const STATUS_MASS_CONVOCATION = 'v';

            // //status de participação do processo
            // const STATUS_HIRED = 'h';
            // const STATUS_HIRING = 'j';
            // const STATUS_DISAPPROVED = 'd';
            // const STATUS_APPROVED = 'p';
            // const STATUS_ACCEPTING = 'i';
            // const STATUS_ACCEPTED = 'a';
            // const STATUS_HIRED_CANCELED = 'g';
            // const STATUS_HIRED_REPLACEMENT = 'm';
            // const STATUS_RECOMMENDED = 'r';
            // const STATUS_INVITED = 'q';
            // //status geral do candidato no PS
            // const STATUS_EXCLUDED = 'e';
            // const STATUS_LEAVE = 'l';
            // const STATUS_STAND_BY = 'y';
        },
        getStatusVariant(status, application_status) {
            const map = {
                n: "surface-critical",
                c: "surface-informational",
                i: "surface-informational",
                h: "surface-success",
                j: "surface-success",
                e: "surface-critical",
                l: "surface-critical",
                a: "surface-success",
                w: "surface-warning",
                g: "surface-critical",
                m: "surface-critical",
                d: "surface-critical",
                o: "surface-informational",
                t: "surface-informational",
                x: "surface-critical",
                f: "surface-critical",
                p: "surface-informational",
            };

            if (_.indexOf(["s", "w"], application_status) >= 0) {
                if (_.has(map, status)) {
                    return map[status];
                } else {
                    return "surface-neutral";
                }
            } else if (_.indexOf(["c", "f", "a"], application_status) >= 0) {
                if (_.indexOf(["h", "j"], status) >= 0) {
                    return map["h"];
                } else {
                    return map["d"];
                }
            }

            // OPTIONS
            //"surface-neutral"
            //"surface-informational"
            //"surface-success"
            //"surface-warning"
            //"surface-critical"
        },
    },
};
