<template>
    <div>
        <span v-if="step.status == 2">
            {{ getTranslatedContent(step) }}
        </span>
        <span v-else-if="step.status == 1">
            <CompanyForm v-bind="step.form" />
        </span>
    </div>
</template>

<script>
import CompanyForm from "@blocks/company/Form.vue";

export default {
    components: {
        CompanyForm,
    },
    props: {
        step: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getTranslatedContent(item = {}) {
            const content = item.content ? item.content : "";
            const additional_params = item.content_params ? item.content_params : {};
            return this.$t(content, additional_params);
        },
    },
};
</script>
