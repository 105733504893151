<template>
  <div v-if="section.dependents.show">
    <form-set-dependents
      v-if="section.dependents.show"
      name="curriculum.dependents"
      v-model="curriculum.dependents"
      :fields="section.dependents.fields"
      section="dependents"
      :title="$t('curriculum.sections.dependents.title')"
      class="profile-subsection"
    >
      <template #header="{ label }">
        <h4 v-if="label">{{ label }}</h4>
      </template>
    </form-set-dependents>
  </div>
</template>

<script>
import { useCurriculum } from "@state/user/curriculum";
import FormSetDependents from "@components/forms/set/Dependents.vue";
//import { FormFieldSwitch } from '@components/forms'

export default {
  components: {
    FormSetDependents,
  },
  setup() {
    const curriculum = useCurriculum();
    return {
      // you can return the whole store instance to use it in the template
      curriculum,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
};
</script>
