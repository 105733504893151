import * as _ from "lodash-es";

export default (value, args) => {
    var cleanZipCode = value.replace(/\D/g, "");

    var zipCodeValidate = _.indexOf(
        [
            "00000000",
            "11111111",
            "22222222",
            "33333333",
            "44444444",
            "55555555",
            "66666666",
            "77777777",
            "88888888",
            "99999999",
        ],
        cleanZipCode
    );

    var objER = /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/;
    return objER.test(value) && zipCodeValidate === -1;
};
