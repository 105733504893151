<template>
  <div>
    <span>{{internalFields}}</span>

        <!-- <form-set-generic
      v-model="internalValue"
      form="curriculum"
      section="extra"
      :fields="internalFields"
      :title="$t('curriculum.sections.personal.extra')"
      class="profile-subsection"
    />



        <component
          v-if="component.is && component.show"
          :key="form + '.' + section + '.' + name"
          :is="component.is"
          :name="form + '.' + section + '.' + name"
          v-model="internalValue[name]"
          v-bind="component"
        >

        </component> -->


  </div>
</template>
<script>
import FormFieldSet from '../mixins/Set'

export default {
    mixins : [
        FormFieldSet
    ],
    props : {
        'section' : {
            type : String,
            required: false,
            default: 'personal'
        },   
    },
    computed:  {
        hasExtraFields() {
            

        }
    }
};
</script>
