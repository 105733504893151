<template>
  <div v-if="loaded">
    <b-navbar type="dark" variant="primary" v-if="selectiveProcessId" class="p-3">
      <b-navbar-brand href="#"
        >{{ $t("disc.results.selection_process") }} #{{ selectiveProcessId }}
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-button variant="light" class="my-2 my-sm-0" @click="returnToCaller" type="button" pill>{{$t("disc.questions.return")}}</b-button>
      </b-navbar-nav>
    </b-navbar>

    <div v-if="isInvalidProfile">
      <disc-profile-invalid @remakeDiscEvaluation="remakeDiscEvaluation" />
    </div>
    <div v-else>
      <disc-profile-header :pattern="pattern"></disc-profile-header>
      <div class="mb-3"></div>
      <div class="container">
        <disc-profile-description
          :chart="chart"
          :pattern="pattern"
        ></disc-profile-description>
      </div>
    </div>
  </div>
</template>
<script>
import companyMixin from "@state/company";
import EvaluationMixin from "@mixins/evaluation";
import DiscProfileHeader from "@components/disc/DiscProfileHeader.vue";
import DiscProfileDescription from "@components/disc/DiscProfileDescription.vue";
import DiscProfileInvalid from "@components/disc/DiscProfileInvalid.vue";

import { useDisc } from "@state/evaluation/disc";

//http://selectycorp4.selecty.local:4002/evaluation/disc/results/9dd54c67-8f57-44f4-9591-c43a06b7cd41

/**
 * DISC patterns, retrieve from disc_patterns table on survey database
 */
const DISC_PATTERN_OVERSHIFT = 10;
const DISC_TIGHT_PROFILE = 17;
const DISC_PATTERN_UNDERSHIFT = 18;

const DISC_INVALID_PATTERNS = [DISC_TIGHT_PROFILE];

export default {
  mixins: [EvaluationMixin, companyMixin],
  components: {
    "disc-profile-header": DiscProfileHeader,
    "disc-profile-description": DiscProfileDescription,
    "disc-profile-invalid": DiscProfileInvalid,
  },
  setup() {
    const discState = useDisc();
    //const vacancies = useVacancies();

    return {
      // you can return the whole store instance to use it in the template
      discState,
    };
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      chart: {},
      pattern: {},
      loaded: false,
      isInvalidProfile: false,
    };
  },
  computed: {
    selectiveProcessId() {
      return this.$route.params.selectiveProcessId;
    },
  },
  methods: {
    fetchData() {
      this.$root.loading = true;
      // this.$vs.loading({ scale: 0.6 });

      this.discState.results(this.requestId).then(({ data }) => {
        this.pattern = data.pattern;
        this.chart = data.change;
        this.isInvalidProfile = DISC_INVALID_PATTERNS.includes(data.pattern.id);
        this.loaded = true;
        this.$root.loading = false;
        // this.$vs.loading.close();
      });

      /*
            this.$service.results().then(({ data }) => {
                this.pattern = data.pattern;
                this.chart = data.change;
                this.isInvalidProfile = DISC_INVALID_PATTERNS.includes(data.pattern.id)
                this.loaded = true;
                this.$vs.loading.close();
            });
            */
    },

    remakeDiscEvaluation() {
      this.$root.loading = true;
      // this.$vs.loading({ scale: 0.6 })
      this.$service
        .remakeDiscEvaluation({ request_candidate_uuid: this.requestId })
        .then(() => {
          if (this.selectiveProcessId) {
            this.$router.push({
              name: "evaluation-disc-form",
              params: { selectiveProcessId: this.selectiveProcessId },
            });
          } else {
            this.$router.push({ name: "evaluation-disc-form" });
          }
        })
        .finally(() => (this.$root.loading = false));
    },
    returnToCaller() {
      if (this.selectiveProcessId) {
        if (this.company.config.sj) {
          this.$router.push({
            name: "application-progress",
            params: {
              application_id: this.selectiveProcessId,
              sj_id: this.selectiveProcessId,
            },
          });
        } else {
          this.$router.push({
            name: "application-progress",
            params: { application_id: this.selectiveProcessId },
          });
        }
      }
    },
  },
};
</script>
