<template>
  <div
    class="d-flex gap-xs overflow-hidden"
    v-if="availableTags.length > 0 || type == 'workplace_type'"
  >
    <div v-for="(tag, index) in availableTags" :key="index">
      <h4>
        <b-badge variant="surface-neutral" class="tag tag-sm">{{
          tag
        }}</b-badge>
        <!-- <span class="badge badge-pill badge-secondary text-primary"></span> -->
      </h4>
    </div>
    <div v-if="item.workplace_type">
      <h4>
        <b-badge variant="surface-neutral" class="tag tag-sm">{{
          $t(getWorkplaceType(item.workplace_type))
        }}</b-badge>
        <!-- <span class="badge badge-pill badge-secondary text-primary"></span> -->
      </h4>
    </div>
  </div>
</template>

<script>
import vacanciesMixin from "@mixins/vacancies";

export default {
  mixins: [vacanciesMixin],
  props: {
    item: {
      required: true,
      type: Object,
    },
    size: {
      required: false,
      type: Number,
      default: 4,
    },
    type: {
      required: false,
      type: String,
      default: "portal4_tags",
    },
  },
  computed: {
    availableTags() {
      return this.item.portal4_tags.filter((item, index) => index < this.size);
    },
  },
};
</script>
