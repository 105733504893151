<template>
    <div>
        <ShareIcon size="1.5x" @click="openDropdown" :id="'vacancy-share-button' + _uid" class="cursor-pointer" />
        <div v-if="!isMobile()">
            <b-popover :target="'vacancy-share-button' + _uid" placement="bottom" triggers="blur click">
                <div triggers="focus">
                    <LinkIcon
                        v-b-popover.click.left="'Link copiado com sucesso!'"
                        class="icon-default cursor-pointer"
                        id="popover-social-media"
                        @click="copyVacancyLink"
                    />
                </div>
                <div
                    v-for="(link, name) in item.social_links"
                    :key="name"
                    class="mt-2"
                    triggers="focus"
                >
                    <a :href="link" target="_blank">
                        <img
                            :src="imagesList[`/resources/assets/images/social/${name}.png`]"
                            :alt="name"
                        />
                    </a>
                </div>
            </b-popover>        
        </div>
        <Teleport to="body" v-if="isMobile()">
            <div class="popover-dropdown flex-column gap-2 px-2" :class="{'d-flex' : showDropdown, 'd-none': !showDropdown}">

                <div
                    class="d-flex align-items-center justify-content-between mx-2"
                >
                    <span class="text-xl text-semibold">{{ $t('vacancy.share.title') }}</span>
                    <a @click="closeDropdown" class="text-default">
                        <XIcon size="1.25x" />
                    </a>
                </div>

                <div class="d-flex flex-row flex-wrap align-items-center justify-content-between mx-2">
                    <div
                        v-for="(link, name) in item.social_links"
                        :key="name"
                        
                    >
                        <a :href="link" target="_blank">
                            <img
                                :src="imagesList[`/resources/assets/images/social/${name}.png`]"
                                :alt="name"
                            />
                            {{ $t(`vacancy.share.${name}.label`) }}
                        </a>
                    </div>        
                </div>
            </div>
        </Teleport>
    </div>    
</template>
<script>

import images from '@mixins/images'
import { ShareIcon, XIcon, LinkIcon } from "@vue-hero-icons/outline";
import Teleport from 'vue2-teleport';

import { useTheme } from '@state/system/theme'

export default {
    mixins: [images],
    components : {
        XIcon,
        Teleport,
        ShareIcon,
        LinkIcon
    },
    props: {
        item: {
        required: true,
        type: Object,
        },    
    },
    setup() {
        const theme = useTheme();

        return {
            isMobile : theme.isMobile
        };
    },    
    data() {
        return {
            showDropdown : false
        }
    },
    methods : {
        openDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        closeDropdown() {
            this.showDropdown = false;
        },
        copyVacancyLink() {
            const vacancyUrl = `${window.location.origin}/vaga/${this.item.id}/${this.item.slug}`
            this.copyToClipboard(vacancyUrl)
        },
        copyToClipboard(textToCopy = '') {
            var el = document.createElement('textarea');
            el.value = textToCopy;
            el.setAttribute('readonly', '');
            el.style = {position: 'absolute', left: '-9999px'};
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
    }
}
</script>