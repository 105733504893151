<template>
  <section id="nossos-valores" data-scroll-to="accordion">
    <h4 class="mb-4" v-if="title">{{ title }}</h4>

    <div class="accordion" id="accordionExample">
      <div class="accordion-item" v-for="(item, index) in items" :key="index">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            type="button"
            aria-expanded="true"
            aria-controls="collapseOne"
            v-b-toggle="collapaseBaseId + '-' + index"
          >
            {{ item.title }}
          </button>
        </h2>
        <b-collapse
          :id="collapaseBaseId + '-' + index"
          role="tabpanel"
          v-bind="collapseBindings"
        >
          <div class="accordion-body">
            {{ item.text }}
          </div>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        items: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            required: false,
            default: "",
        },
    },
    computed: {
        collapaseBaseId() {
            return "accordion-" + this._uid;
        },
        collapseBindings() {
            if (this.mode == "accordion") {
                return {
                    accordion: "accordion-" + this._uid,
                };
            }
            return {};
        },
    }
};
</script>
