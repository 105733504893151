// Janus = import('./janus-gateway.js');
import Janus from './janus-gateway.js';
import Rollbar from 'rollbar';

// Helper to parse query string
function getQueryStringValue(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}


var RECORDPLAY_PLUGIN = null;
var RECORDING_ID = null;
var MEDIA_ELEMENT = null;
var AUDIO_ELEMENT = null;
var JANUS = null;
var CALLBACKS = {};

var ON_RECORD_START = null;
var ON_STREAM_RECEIVED = null;

class JanusSessionManager {
  //requestConfig
  //onSuccess
  //onError
  //axios

    constructor(axios, backend = 'janus', callbacks = {}) {
        this.$http = axios;
        this.backend = 'janus';
        this.bandwidth = 1024 * 1024;
        this.doSimulcast = false;
        this.acodec = null;
        this.vcodec = null;
        this.vprofile = null;
    }

    once(event, callback) {
        CALLBACKS[event] = callback;
    }

    checkSystemRequirements() {
        return Janus.isWebrtcSupported();
    }

    testRecording(recordingId, config = {audio: true, video: true, data: false}, callbacks = {}) {
        console.warn('config', config);

        // @todo CHECKS FOR RECORDPLAY_PLUGIN STATUS

        return new Promise((resolve, reject) => {
            // bitrate and keyframe interval can be set at any time:
            // before, after, during recording
            RECORDPLAY_PLUGIN.send({
                message: {
                    request: 'configure',
                    'video-bitrate-max': this.bandwidth,    // a quarter megabit
                    'video-keyframe-interval': 15000        // 15 seconds
                }
            });

            ON_RECORD_START = callbacks['onstart']
            ON_STREAM_RECEIVED = callbacks['onlocalstream']

            RECORDPLAY_PLUGIN.createOffer({
                // By default, it's sendrecv for audio and video... no datachannels
                // If you want to test simulcasting (Chrome and Firefox only), then
                // pass a ?simulcast=true when opening this demo page: it will turn
                // the following 'simulcast' property to pass to janus.js to true
                media: {
                    audio: config.audio,
                    /*
                    audio: {
                        deviceId: {
                            exact: audioDeviceId
                        }
                    },
                    replaceAudio: replaceAudio,    // This is only needed in case of a renegotiation
                    */
                    video : config.video,
                    /*
                    video: {
                        deviceId: {
                            exact: videoDeviceId
                        }
                    },
                    replaceVideo: replaceVideo,    // This is only needed in case of a renegotiation
                    */
                    data: config.data    // Let's negotiate data channels as well
                },
                simulcast: this.doSimulcast,
                success: function(jsep) {
                    Janus.debug("Got SDP!", jsep);
                    var body = { request: "record",  name: recordingId };

                    // We can try and force a specific codec, by telling the plugin what we'd prefer
                    // For simplicity, you can set it via a query string (e.g., ?vcodec=vp9)
                    if(this.acodec)
                        body["audiocodec"] = this.acodec;
                    if(this.vcodec)
                        body["videocodec"] = this.vcodec;
                    // For the codecs that support them (VP9 and H.264) you can specify a codec
                    // profile as well (e.g., ?vprofile=2 for VP9, or ?vprofile=42e01f for H.264)
                    if(this.vprofile)
                        body["videoprofile"] = this.vprofile;

                    RECORDPLAY_PLUGIN.send({ message: body, jsep: jsep });

                    resolve(true)
                },
                error: function(error) {
                    app.$rollbar.critical("Webrtc error", {error});


                    Janus.error("WebRTC error...", error);
                    //alert("WebRTC error... " + error.message);
                    RECORDPLAY_PLUGIN.hangup();

                    reject()
                }
            });
        });
    }

    stopRecording() {
        return new Promise((resolve, reject) => {
            // Stop a recording/playout
            var stop = { request: "stop", id: RECORDING_ID };
            RECORDPLAY_PLUGIN.send({ message: stop });
            //RECORDPLAY_PLUGIN.hangup();

            resolve(RECORDING_ID)
        });
    }

    startPlayout(recordingId, mediaElement, audioElement) {
        var play = { request: "play", id: parseInt(recordingId) };
        RECORDPLAY_PLUGIN.send({ message: play });

        MEDIA_ELEMENT = mediaElement;
        AUDIO_ELEMENT = audioElement;
    }
    getSession(options) {
        this.options = options;
        var self = this;

        return new Promise((resolve, reject) => {
            Janus.init({debug: "all", callback: function() {
                // Use a button to start the demo
                // Make sure the browser supports WebRTC
                if(!Janus.isWebrtcSupported()) {
                    alert("No WebRTC support... ");
                    return;
                }

                var server = null;
                if(window.location.protocol === 'http:') {
                    server = [
                        "ws://janus.selecty4.local:7500/ws",
                        "http://janus.selecty4.local:7500/janus"
                    ];
                } else {
                    // server = [
                    //     "wss://janus.selectybeta.app/ws",
                    //     "https://janus.selectybeta.app/janus"
                    // ];

                    if (BACKEND.env == "develop") {
                        server = [
                            "wss://janus-develop.selectybeta.app/ws",
                            "https://janus-develop.selectybeta.app/janus"
                        ];
                    } else if (BACKEND.env == "staging") {
                        server = [
                            "wss://janus-staging.selectybeta.app/ws",
                            "https://janus-staging.selectybeta.app/janus"
                        ];
                    } else if (BACKEND.env == "master") {
                        server = [
                            "wss://janus.selecty.app/ws",
                            "https://janus.selecty.app/janus"
                        ];
                    } else if (BACKEND.env == "local") {
                        server = [
                            "ws://janus.selecty4.local:7500/ws",
                            "http://janus.selecty4.local:7500/janus"
                        ];
                    }

                    //server = "https://" + window.location.hostname + ":8089/janus";
                }

                var recording = false;
                var playing = false;
                var recordingId = null;

                // Create session
                if (!JANUS || !JANUS.isConnected()) {
                    JANUS = new Janus(
                    {
                        server: server,
                        success: () => {
                            self.attachPlugin().then((pluginHandle) => {
                                resolve(true)
                            })
                        },
                        error: function(error) {
                            app.$rollbar.critical("Janus Error", {error});
                        },
                        destroyed: function() {
                            //window.location.reload();
                        }
                    });

                    window.JANUS = JANUS;
                } else {
                    window.JANUS = JANUS;
                    resolve(true)
                }
            }});
        });



    }

    attachPlugin() {
        var self = this;
        return new Promise((resolve, reject) => {
            var opaqueId = "recordplaytest-"+Janus.randomString(12);

            // Attach to Record&Play plugin
            JANUS.attach({
                plugin: "janus.plugin.recordplay",
                opaqueId: opaqueId,
                success: (pluginHandle) => {
                    //$('#details').remove();
                    RECORDPLAY_PLUGIN = pluginHandle;
                    window.RECORDPLAY_PLUGIN = RECORDPLAY_PLUGIN
                    Janus.log("Plugin attached! (" + RECORDPLAY_PLUGIN.getPlugin() + ", id=" + RECORDPLAY_PLUGIN.getId() + ")");

                    resolve(pluginHandle)
                },
                error: function(error) {
                    Janus.error("  -- Error attaching plugin...", error);

                    reject(error)
                },
                consentDialog: function(on) {
                    Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                    if(on) {
                        // Darken screen and show hint
                        /*
                        $.blockUI({
                            message: '<div><img src="up_arrow.png"/></div>',
                            css: {
                                border: 'none',
                                padding: '15px',
                                backgroundColor: 'transparent',
                                color: '#aaa',
                                top: '10px',
                                left: (navigator.mozGetUserMedia ? '-100px' : '300px')
                            } });
                        */
                    } else {
                        // Restore screen
                        //$.unblockUI();
                    }
                },
                iceState: function(state) {
                    Janus.log("ICE state changed to " + state);
                },
                mediaState: function(medium, on) {
                    Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium);
                },
                webrtcState: function(on) {
                    Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                    //$("#videobox").parent().unblock();
                },
                onmessage: function(msg, jsep) {
                    Janus.log(" ::: Got a message :::", msg, jsep);
                    var result = msg["result"];
                    if(result) {
                        if(result["status"]) {
                            var event = result["status"];
                            if(event === 'preparing' || event === 'refreshing') {
                                Janus.log("Preparing the recording playout");
                                RECORDPLAY_PLUGIN.createAnswer(
                                    {
                                        jsep: jsep,
                                        media: { audioSend: false, videoSend: false },    // We want recvonly audio/video
                                        success: function(jsep) {
                                            Janus.debug("Got SDP!", jsep);
                                            var body = { request: "start" };
                                            RECORDPLAY_PLUGIN.send({ message: body, jsep: jsep });
                                        },
                                        error: function(error) {
                                            Janus.error("WebRTC error:", error);
                                            alert("WebRTC error... " + error.message);
                                        }
                                    });
                                //if(result["warning"])
                                    //bootbox.alert(result["warning"]);
                            } else if(event === 'recording') {
                                // Got an ANSWER to our recording OFFER
                                //
                                //call the onstarted callback
                                ON_RECORD_START(result)

                                if(jsep)
                                    RECORDPLAY_PLUGIN.handleRemoteJsep({ jsep: jsep });
                                var id = result["id"];
                                if(id) {
                                    Janus.log("The ID of the current recording is " + id);
                                    RECORDING_ID = id;

                                    if (self.options.onrecording) {
                                        self.options.onrecording(RECORDING_ID)
                                    }

                                }
                            } else if(event === 'slow_link') {
                                var uplink = result["uplink"];
                                if(uplink !== 0) {
                                    // Janus detected issues when receiving our media, let's slow down
                                    this.bandwidth = parseInt(this.bandwidth / 1.5);
                                    recordplay.send({
                                        message: {
                                            request: 'configure',
                                            'video-bitrate-max': this.bandwidth,        // Reduce the bitrate
                                            'video-keyframe-interval': 15000    // Keep the 15 seconds key frame interval
                                        }
                                    });
                                }
                            } else if(event === 'playing') {
                                Janus.log("Playout has started!");

                                console.warn('PLAYOUT', msg);
                                //options.audioElement.srcObject = RECORDPLAY_PLUGIN.webrtcStuff.myStream;0
                                //options.audioElement.play()

                            } else if(event === 'stopped') {
                                Janus.log("Session has stopped!");
                                var id = result["id"];
                                if(RECORDING_ID) {
                                    if(RECORDING_ID !== id) {
                                        Janus.warn("Not a stop to our recording?", RECORDING_ID, result);
                                        return;
                                    }
                                    //alert("Recording completed! Check the list of recordings to replay it.");
                                }
                                //if(selectedRecording) {
                                //    if(selectedRecording !== id) {
                                //        Janus.warn("Not a stop to our playout?");
                                //        return;
                                //    }
                                //}
                                // FIXME Reset status
                                /*
                                $('#videobox').empty();
                                $('#video').hide();
                                recordingId = null;
                                recording = false;
                                playing = false;
                                recordplay.hangup();
                                $('#record').removeAttr('disabled').click(startRecording);
                                $('#play').removeAttr('disabled').click(startPlayout);
                                $('#list').removeAttr('disabled').click(updateRecsList);
                                $('#recset').removeAttr('disabled');
                                $('#recslist').removeAttr('disabled');
                                updateRecsList();
                                */
                            } else if(event === 'done') {
                            }
                        }
                    } else {
                        // FIXME Error?
                        var error = msg["error"];
                        //alert(error);
                        // FIXME Reset status
                        //$('#videobox').empty();
                        //$('#video').hide();
                        /*
                        recording = false;
                        playing = false;
                        recordplay.hangup();
                        $('#record').removeAttr('disabled').click(startRecording);
                        $('#play').removeAttr('disabled').click(startPlayout);
                        $('#list').removeAttr('disabled').click(updateRecsList);
                        $('#recset').removeAttr('disabled');
                        $('#recslist').removeAttr('disabled');
                        updateRecsList();
                        */
                    }
                },
                onlocaltrack: function(track, added) {
                    if (track.kind == 'video') {
                        console.warn('TRACK', track, added, self.options.videoElement);

                        ON_STREAM_RECEIVED(RECORDPLAY_PLUGIN.webrtcStuff.myStream)
                    } else if (track.kind == 'audio') {
                    }
                },
                onremotetrack: function(track, mid, added, metadata) {
                    if (track.kind == 'video' && metadata.reason == 'created') {
                        let stream = new MediaStream();
                        stream.addTrack(track.clone());
                        Janus.attachMediaStream(MEDIA_ELEMENT, stream);
                    }
                    
                    if (track.kind == 'audio' && metadata.reason == 'created') {
                        let stream = new MediaStream();
                        stream.addTrack(track.clone());
                        Janus.attachMediaStream(AUDIO_ELEMENT, stream);
                    }

                },
                oncleanup: function() {
                    Janus.log(" ::: Got a cleanup notification :::");

                    if (self.options.oncleanup) {
                        self.options.oncleanup()
                    }

                    // FIXME Reset status
                    /**
                    $('#waitingvideo').remove();
                    if(spinner)
                        spinner.stop();
                    spinner = null;
                    $('#videobox').empty();
                    $("#videobox").parent().unblock();
                    $('#video').hide();
                    recording = false;
                    playing = false;
                    $('#record').removeAttr('disabled').click(startRecording);
                    $('#play').removeAttr('disabled').click(startPlayout);
                    $('#list').removeAttr('disabled').click(updateRecsList);
                    $('#recset').removeAttr('disabled');
                    $('#recslist').removeAttr('disabled');
                    updateRecsList();
                    */
                }
            });
        });
    }

    stopPublishing() {
        console.warn(RECORDPLAY_PLUGIN)

        if (RECORDPLAY_PLUGIN) {
            RECORDPLAY_PLUGIN.detach();
        }
    }

    stopSession() {
        if (JANUS && JANUS.isConnected()) {
            JANUS.destroy();
        }
    }

    /*
    getToken(options) {
        return new Promise((resolve, reject) => {
            if (!this.token) {
                //let sessionName = $("#sessionName").val(); // Video-call chosen by the user
                this.request(
                    'post',
                    'get-token',
                    options,
                    'Request of TOKEN gone WRONG:'
                ).then((res) => {
                    if (res.status == 200) {
                        this.token = res.data.token; // Get token from response

                        //callback(); // Continue the join operation
                        resolve(this.token)
                    } else {
                        reject(res);
                    }
                }).catch((response) => {
                    reject(response);
                })
            } else {
                resolve(this.token);
            }
        });
    }

    startRecording(recording_name) {
        return this.request(
            'post',
            'recording-start',
            {
                session: this.session.sessionId,
                outputMode: "INDIVIDUAL",
                name: recording_name,
                hasAudio: true,
                hasVideo: true
            },
            'Start recording WRONG'
        ).then((res) => {
            if (res.status == 200) {
                this.recordingData = res.data;
            } else {
                //this.getRecording();
            }
            return res;
        });
    }
    */
    /*
    stopRecording() {
        var forceRecordingId = this.recordingData.id;

        return this.request(
            'post',
            'recording-stop',
            {
                recording: this.recordingData.id
            },
            'Start recording WRONG',
        ).then((res) => {
            if (res.status == 200) {
                this.recordingData = res.data;
                return this.recordingData;
            }
            return false;
        });
    }
    */
}

export default JanusSessionManager
