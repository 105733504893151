<template>

  <b-dropdown right id="dropdown-1" text="Dropdown Button" variant="default" class="i18n-dropdown" no-caret>
    <template #button-content>
      <letter-avatar :avatar-url="activeUserInfo.avatar" :name="activeUserInfo.name" />
    </template>
    <b-dropdown-item  @click="navigate({name: 'profile-edit'})">
      Curriculo
    </b-dropdown-item>
    <b-dropdown-item  @click="navigate({name: 'profile-admissional'})">
      Admissional
    </b-dropdown-item>

    <div class="dropdown-divider"></div>

    <b-dropdown-item @click="logout">
      Logout
    </b-dropdown-item>

  </b-dropdown>
</template>

<script>
//import firebase from 'firebase/app'
//import 'firebase/auth'

import LetterAvatar from '@components/avatar/LetterAvatar.vue'

export default {
  components : {
    LetterAvatar
  },
  computed: {
    activeUserInfo() {
      return this.$auth.user();
    }
  },
  methods: {
    navigate(route) {
      this.$router.push(route).catch(() => {})
    },
    logout() {
        // This is just for demo Purpose. If user clicks on logout -> redirect
        this.$auth.logout({ redirect: "/" });
    },
  }
}
</script>
