<template>
  <b-sidebar
    id="customize-sidebar"
    aria-labelledby="sidebar-no-header-title"
    no-close-on-route-change
    no-close-on-esc
    no-header-close
    visible
    width="320"
    shadow
    bg-variant="white"
    v-model="theme.customize"
    v-on="$listeners"
    no-header
  >
    <template>
      <validation-provider slim>
        <div class="gap-6 d-flex flex-sm-wrap">
          <div>
            <h4 class="text-xl font-weight-semibold">
              {{ $t("customize.main_title") }}
            </h4>
            <p class="text-sm text-subdued font-weight-normal">
              {{ $t("customize.subtitle") }}
            </p>
          </div>
          <div>
            <h4 class="text-lg font-weight-semibold">
              {{ $t("customize.logo_position") }}
            </h4>
            <p class="text-sm text-subdued font-weight-normal">
              {{ $t("customize.logo_position_description") }}
            </p>

            <form-field-radio
              name="company.config.logo_position"
              v-model="company.config.logo_position"
              :options="domain.domains.logo_position"
              autocomplete="off"
              immediate
            />
          </div>
          <div>
            <h4 class="text-lg font-weight-semibold">
              {{ $t("customize.color_theme") }}
            </h4>
            <p class="text-sm text-subdued font-weight-normal">
              {{ $t("customize.color_theme_description") }}
            </p>
            <ul
              class="color-changer d-flex justify-content-start flex-wrap gap-2 list-unstyled"
            >
              <li
                v-for="(color, name) in theme.themes"
                v-bind:key="name"
                class="rounded-pill w-8 h-8 d-inline-flex justify-content-center align-items-center"
                :style="{ 'background-color': color.value }"
                :class="{ active: theme.selectedTheme == name }"
                @click="changeTheme(name)"
              >
                <CheckIcon size="1x" v-show="theme.selectedTheme == name" />
              </li>
            </ul>
          </div>
          <div>
            <h4 class="text-lg font-weight-semibold">
              {{ $t("customize.sections") }}
            </h4>
            <p class="text-sm text-subdued">
              {{ $t("customize.sections_description") }}
            </p>
            <b-list-group flush>
              <draggable
                v-model="company.config.sections.home"
                group="people"
                @start="drag = true"
                @end="drag = false"
              >
                <b-list-group-item
                  v-for="(section, index) in pageSections"
                  v-if="section.type !== 'centralized-logo'"
                  :key="index"
                  class="d-flex justify-content-between"
                  style="min-width: 16.563rem; cursor: grab"
                >
                  <span class="mr-auto text-md font-weight-medium">{{
                    section.name
                  }}</span>
                  <PencilAltIcon
                    class="text-primary cursor-pointer"
                    v-on:click="edit(section)"
                  />
                  <b-form-checkbox
                    :checked="section.enabled"
                    name="check-button"
                    switch
                    v-model="section.enabled"
                  />
                </b-list-group-item>
              </draggable>
            </b-list-group>
          </div>

          <div class="d-flex justify-content-between w-100">
            <b-button variant="outline-primary" pill size="sm">
              {{ $t("common.cancel") }}
            </b-button>
            <b-button variant="primary" pill size="sm" @click="save()">
              {{ $t("common.save") }}
            </b-button>
          </div>
        </div>
        <b-modal
          ref="modal"
          id="customize-theme-modal"
          size="lg"
          centered
          :title="sectionEdited.name"
          @ok="append($event)"
          @cancel="clear"
          scrollable
          no-close-on-backdrop
          hide-header-close
          no-close-on-esc
        >
          <b-card-body class="p-0">
            <validation-observer ref="formRules">
              <template>
                <component
                  v-for="(component, name) in internalFields"
                  :is="component.is"
                  :key="sectionEdited.type + '-' + name"
                  :vid="sectionEdited.type + '-' + name"
                  :test-id="sectionEdited.type + '-' + name"
                  v-model="sectionEdited.data[name]"
                  :section="sectionEdited"
                  v-bind="component"
                />
              </template>
            </validation-observer>
          </b-card-body>

          <template #modal-footer="{ ok, cancel }">
            <div class="d-flex justify-content-end">
              <div>
                <b-button
                  class="border m-2"
                  variant="outline-primary"
                  pill
                  size="sm"
                  @click="cancel()"
                >
                  {{ $t("common.cancel") }}
                </b-button>
              </div>
              <div>
                <b-button
                  variant="primary"
                  pill
                  size="sm"
                  class="m-2"
                  @click="ok()"
                >
                  {{ $t("common.apply") }}
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>
      </validation-provider>
    </template>
  </b-sidebar>
</template>

<script>
import * as _ from "lodash-es";
import draggable from "vuedraggable";
import companyMixin from "@state/company";
import { useTheme } from "@state/system/theme";
import { useDomain } from "@state/domain/domain";
import { CheckIcon } from "@vue-hero-icons/outline";
import * as FormComponents from "@components/forms";
import { PencilAltIcon, TrashIcon } from "@vue-hero-icons/outline";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    draggable,
    ValidationProvider,
    ValidationObserver,
    ...FormComponents,
    TrashIcon,
    PencilAltIcon,
    CheckIcon,
  },
  setup() {
    const theme = useTheme();
    const domain = useDomain();

    return {
      theme,
      domain,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: "",
      form: {},
      internalFields: {},
      sectionEdited: {},
      internalVisible: null,
      customSidebarOpen: false,
    };
  },
  computed: {
    hasTitle() {
      return (section) =>
        !_.has(this.pageBlocks, section.type + ".config.has_title") ||
        this.pageBlocks[section.type].config.has_title;
    },
    hasSections() {
      return this.pageSections && Object.keys(this.pageSections).length > 0;
    },
    hasFields(section) {
      return (section) => _.has(this.pageBlocks, section.type);
    },
    pageSections() {
      return this.company.config.sections[this.$route.meta.section];
    },
    pageBlocks() {
      return this.company.config.blocks;
    },
  },
  created() {
    this.theme.load(
      this.company.config.themes.current,
      this.company.config.themes.list
    );
    this.form = this.company.config.theme;

    // this.theme.$subscribe((mutation, state) => {
    //   this.theme.update();
    // });
  },
  methods: {
    changeTheme(theme) {
      this.$root.loading = true;

      this.theme.selectedTheme = theme;

      this.theme.change();

      this.company.saveTheme(theme).then(() => {
        this.$root.loading = false;
      });
    },
    edit(section) {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();
        e.returnValue = "";
      });

      this.internalFields = this.pageBlocks[section.type].config.fields;
      this.sectionEdited = section;
      this.$nextTick(() => this.$refs.modal.show());
    },
    append(bvModalEvent) {
      // PREVENT CLOSING UNTIL IT IS VALIDATED
      bvModalEvent.preventDefault();

      _.union(this.pageSections, this.sectionEdited);

      this.$nextTick(() => this.$refs.modal.hide());
    },
    clear() {
      this.sectionEdited = "";
      this.$nextTick(() => this.$refs.modal.hide());
    },
    save() {
      this.$root.loading = true;
      window.removeEventListener("beforeunload", function (e) {
        e.preventDefault();
        e.returnValue = "";
      });

      this.company.saveHomeSections(this.pageSections).then((response) => {
        this.$root.loading = false;
      });
    },
    onSidebarClose(e) {
      this.internalVisible = false;
    },
    onSidebarOpen(e) {
      his.internalVisible = true;
    },
  },
  watch: {
    "company.config.logo_position": function (logo_position) {
      this.$root.loading = true;

      this.company.saveLogoPosition(logo_position).then(() => {
        const arrHomeSections = this.company.config.sections.home;

        const centralizedLogoSection = _.find(arrHomeSections, {
          type: "centralized-logo",
        });

        if (centralizedLogoSection) {
          centralizedLogoSection.enabled = logo_position === "centralized";

          _.union(this.pageSections, centralizedLogoSection);
        } else {
          arrHomeSections.splice(1, 0, {
            data: [],
            enabled: logo_position === "centralized",
            name: "Logo centralizada",
            type: "centralized-logo",
          });
        }

        this.$root.loading = false;
      });
    },
  },
};
</script>
