<template>
  <div
    class="d-flex align-items-center justify-content-center p-4"
    v-if="!isLoading"
  >
    <b-card class="col-md-6 col-lg-6 col-xl-4 px-0" variant="primary">
      <form @submit.prevent autocomplete="off">
        <validation-observer ref="curriculumStep2Rules">
          <div class="d-flex flex-column gap-4">
            <vacancy-header v-if="internalVacancy" :item="internalVacancy" />
            <div>
              <h3 class="text-xl font-weight-semibold">
                <span v-if="vacancy_id">
                  {{ $t("registration.lets_go", [firstName]) }}
                </span>
                <span v-else-if="firstName">
                  {{ $t("registration.hello", [firstName]) }}
                </span>
                <span v-else>
                  {{ $t("registration.finish_your_account_creation") }}
                </span>
              </h3>
              <p class="text-subdued">
                {{ $t("registration.create_mini_curriculum") }}
              </p>
            </div>

            <div v-if="showParsing">
              <h4 class="py-4">
                {{ $t("curriculum.sections.parsing.title") }}
              </h4>

              <ParsingWidget />
            </div>

            <div class="divider">
              <hr class="w-100" />
            </div>

            <div>
              <h4 class="py-4">
                {{ $t("curriculum.sections.personal.title") }}
              </h4>

              <div class="form-group text-nowrap">
                <form-field-upload
                  name="curriculum.personal.avatar"
                  v-model="curriculum.personal.avatar"
                  v-bind="curriculum.personal.avatar"
                  :type="'avatar'"
                  :preview="true"
                  :clearable="false"
                  :uploadText="$t('common.upload_photo')"
                  :avatarName="curriculum.personal.name"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-field-input
                  name="curriculum.personal.name"
                  v-model="curriculum.personal.name"
                  rules="required|full-name|max:100"
                  autocomplete="off"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-field-input
                  name="curriculum.personal.cpf"
                  v-model="curriculum.personal.cpf"
                  rules="required|cpf"
                  mask="###.###.###-##"
                  autocomplete="off"
                  inputmode="decimal"
                  disabled
                  v-if="!curriculum.personal.is_foreigner"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-field-input
                  name="curriculum.personal.primary_email"
                  v-model="curriculum.personal.primary_email"
                  rules="required|email|max:300"
                  autocomplete="off"
                  inputmode="email"
                  type="email"
                  :disabled="curriculum.personal.is_foreigner"
                />
              </div>
              <div class="form-group text-nowrap">
                <form-field-input
                  name="curriculum.personal.primary_email_confirmation"
                  v-model="curriculum.personal.primary_email_confirmation"
                  rules="required|email|confirmed:curriculum.personal.primary_email|max:300"
                  autocomplete="off"
                  inputmode="email"
                  type="email"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-field-select
                  name="curriculum.personal.origin_country"
                  v-model="curriculum.personal.origin_country"
                  rules="required"
                  :options="countryOptions"
                  autocomplete="off"
                  v-if="curriculum.personal.is_foreigner"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-field-select
                  name="curriculum.personal.document_type_id"
                  v-model="curriculum.personal.document_type_id"
                  rules="required"
                  :options="domain.document_type"
                  autocomplete="off"
                  :reduce="(option) => option.value || option"
                  v-if="curriculum.personal.is_foreigner"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-field-input
                  name="curriculum.personal.document_number"
                  v-model="curriculum.personal.cpf"
                  rules="required"
                  autocomplete="off"
                  maxlength="12"
                  v-if="curriculum.personal.is_foreigner"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-field-radio
                  name="curriculum.personal.gender"
                  v-model="curriculum.personal.gender"
                  rules="required"
                  :options="domain.gender"
                  buttons
                  autocomplete="off"
                  size="sm"
                />
              </div>

              <div class="form-group">
                <form-field-date
                  name="curriculum.personal.birth_date"
                  v-model="curriculum.personal.birth_date"
                  rules="required|date|minority-date"
                  autocomplete="off"
                  inputmode="decimal"
                />
              </div>

              <div class="form-group">
                <form-field-phone
                  name="curriculum.personal.cellphone"
                  v-model="curriculum.personal.cellphone"
                  rules="required"
                  phone-type="cellphone"
                  mask="(##) #####-####"
                  autocomplete="off"
                  inputmode="tel"
                />
              </div>

              <div class="form-group">
                <form-field-input
                  name="curriculum.address.zip_code"
                  v-model="curriculum.address.zip_code"
                  rules="required|zip-code|max:10"
                  mask="##.###-###"
                  autocomplete="off"
                  inputmode="decimal"
                />
              </div>

              <div class="form-group">
                <form-field-select
                  name="curriculum.address.state"
                  v-model="curriculum.address.state"
                  rules="required"
                  :options="domain.states"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <form-field-select
                  name="curriculum.address.city"
                  v-model="curriculum.address.city"
                  rules="required"
                  :options="domain.cities"
                  autocomplete="off"
                />
              </div>

              <div class="form-group text-nowrap">
                <form-set-pcd
                  v-if="sections.personal.pcd.show"
                  v-model="curriculum.personal"
                  :fields="sections.personal.pcd.fields"
                />
              </div>

              <form-set-generic
                v-if="sections.personal.diversity.show"
                v-model="curriculum.diversity"
                label="curriculum"
                form="express"
                section="diversity"
                :fields="sections.personal.diversity.fields"
                :title="$t('curriculum.sections.personal.diversity')"
                :subtitle="$t('curriculum.sections.personal.diversity_subtitle')"
                autocomplete="off"
              />
            </div>

            <div class="divider">
              <hr class="w-100" />
            </div>

            <div>
              <h4 class="pb-4">
                {{ $t("curriculum.sections.professional.objectives") }}
              </h4>

              <form-field-select
                name="curriculum.professional.intended_occupation"
                v-model="curriculum.professional.intended_occupation"
                rules="required"
                :options="domain.occupations"
                :reduce="(option) => option.label || option"
                :multiple="true"
                :selectable="
                  (option) =>
                    curriculum.professional.intended_occupation.length < 3
                "
                autocomplete="off"
              >
                <template #no-options="{ search, searching }">
                  <span v-if="searching">
                    {{ `${$t("common.institution_not_found")} "${search}"` }}.
                    <a href="#" @click.prevent="addOccupation(search)">{{
                      $t("common.click")
                    }}</a>
                    {{ $t("common.to_add") }}
                    <!-- @click.prevent="" -->
                  </span>
                  <span v-else>
                    {{ $t("common.no-results-found") }}
                  </span>
                </template>
              </form-field-select>

              <form-field-select
                name="curriculum.professional.interest_levels"
                v-model="curriculum.professional.interest_levels"
                rules="required"
                :options="domain.interest_levels"
                :multiple="true"
                :selectable="
                  (option) => curriculum.professional.interest_levels.length < 3
                "
                autocomplete="off"
              />

              <form-field-select
                name="curriculum.professional.interest_areas"
                v-model="curriculum.professional.interest_areas"
                rules="required"
                :options="domain.interest_areas"
                :multiple="true"
                :selectable="
                  (option) => curriculum.professional.interest_areas.length < 3
                "
                autocomplete="off"
              />
            </div>

            <div class="divider">
              <hr class="w-100" />
            </div>

            <div class="d-flex flex-column gap-2">
              <h4>{{ $t("curriculum.sections.professional.experiences") }}</h4>

              <div class="d-flex justify-content-start gap-2">
                <form-field-radio
                  name="curriculum.professional.has_professional_history"
                  v-model="curriculum.professional.has_professional_history"
                  :options="domain.domains.has_letter_attribute"
                  autocomplete="off"
                  :rules="profesionalRules"
                  immediate
                />
              </div>
              <form-set-professional-experience
                v-if="
                  sections.professional.experiences.show &&
                  curriculum.professional.has_professional_history == 'y'
                "
                form-id="express"
                name="curriculum.experiences"
                v-model="curriculum.experiences"
                :fields="sections.professional.experiences.fields"
                section="experiences"
              />
            </div>

            <div class="divider">
              <hr class="w-100" />
            </div>

            <div class="d-flex flex-column gap-2">
              <h4>{{ $t("curriculum.sections.education.academic") }}</h4>

              <div class="d-flex justify-content-start gap-2">
                <form-field-radio
                  name="curriculum.education.has_schooling_level"
                  v-model="curriculum.education.has_schooling_level"
                  :options="domain.domains.has_letter_attribute"
                  autocomplete="off"
                  :rules="academicRules"
                  immediate
                />
              </div>
              <form-set-academic
                v-if="
                  sections.education.academic.show &&
                  curriculum.education.has_schooling_level == 'y'
                "
                name="curriculum.academic"
                v-model="curriculum.academic"
                :fields="sections.education.academic.fields"
                section="academic"
              />
            </div>

            <div class="divider">
              <hr class="w-100" />
            </div>

            <div class="d-flex flex-column gap-2">
              <h4>{{ $t("curriculum.sections.education.computing") }}</h4>
              <div class="d-flex justify-content-start gap-2">
                <form-field-radio
                  name="curriculum.education.has_computer_knowledge"
                  v-model="curriculum.education.has_computer_knowledge"
                  :options="domain.domains.has_letter_attribute"
                  autocomplete="off"
                  rules="required"
                />
              </div>

              <form-set-computing
                v-if="
                  sections.education.computing.show &&
                  curriculum.education.has_computer_knowledge == 'y'
                "
                name="curriculum.computing"
                v-model="curriculum.computing"
                :fields="sections.education.computing.fields"
                section="computing"
              />
            </div>

            <div class="divider">
              <hr class="w-100" />
            </div>

            <div class="d-flex flex-column gap-2">
              <h4>{{ $t("curriculum.sections.education.languages") }}</h4>
              <div class="d-flex justify-content-start gap-2">
                <form-field-radio
                  name="curriculum.education.has_languages"
                  v-model="curriculum.education.has_languages"
                  :options="domain.domains.has_letter_attribute"
                  autocomplete="off"
                  :rules="languageRules"
                  immediate
                />
              </div>

              <form-set-languages
                v-if="
                  sections.education.languages.show &&
                  curriculum.education.has_languages == 'y'
                "
                name="curriculum.languages"
                v-model="curriculum.languages"
                :fields="sections.education.languages.fields"
                section="languages"
              />
            </div>

            <b-button variant="primary" block pill @click="submitForm">
              {{ $t("curriculum.sections.personal.bnt_ready") }}
            </b-button>
          </div>
        </validation-observer>
      </form>
    </b-card>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useDomain } from "@state/domain/domain";
import FormSetPcd from "@components/forms/set/Pcd.vue";
import * as FormComponents from "@components/forms";
import { useCompanyForm } from "@state/company/form";
import { useCurriculum } from "@state/user/curriculum";
import ParsingWidget from "@components/parsing/Widget.vue";
import { useVacancies } from "@state/vacancy/vacancies";
import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";
import FormSetAcademic from "@components/forms/set/Academic.vue";
import { useChangeable } from "@state/behaviours/changeable";
import FormSetLanguages from "@components/forms/set/Languages.vue";
import FormSetComputing from "@components/forms/set/Computing.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormSetProfessionalExperience from "@components/forms/set/ProfessionalExperience.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";

export default {
  mixins: [companyMixin],
  components: {
    FormSetPcd,
    VacancyHeader,
    ParsingWidget,
    FormSetAcademic,
    FormSetLanguages,
    FormSetComputing,
    ...FormComponents,
    ValidationObserver,
    ValidationProvider,
    FormSetProfessionalExperience,
    FormSetGeneric
  },
  props: {
    vacancy_id: {
      type: Number | String,
      required: false,
    },
    create_curriculum: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      disableCities: true,
      domain: null,
      forms: null,
      curriculum: null,
      changeable: null,
      vacancies: null,
      social_media_account: false,
      isLoading: true,
      stateSubscribe: null,
      showPassword: false,
      sections: {},
      emittedByZipCodeField: false,
      curriculumFile: "",
      internalVacancy: this.vacancy,
      canSubmit: false,
      showParsing: false,
    };
  },
  created() {
    this.domain = useDomain();
    this.forms = useCompanyForm();
    this.curriculum = useCurriculum();
    this.changeable = useChangeable();
    this.vacancies = useVacancies();

    this.isLoading = true;
    this.$root.loading = true;

    if (this.create_curriculum && this.$auth.check()) {
      this.curriculum.personal.primary_email = this.$auth.user().email;
    }

    this.curriculum.showParsing().then((response) => {
      this.showParsing = response.data;
    });

    const payload = localStorage.getItem("social-media-account");

    this.social_media_account = payload
      ? JSON.parse(payload).social_media_account
      : false;

    if (this.vacancy_id) {
      this.vacancies.read(this.vacancy_id).then(({ data }) => {
        this.internalVacancy = data;
      });
    }

    this.forms.fields("express", true).then((fields) => {
      for (let i in this.forms.express.sections) {
        this.$set(this.sections, i, this.forms.express.sections[i]);
      }
      //this.$nextTick(() => {
      this.isLoading = false;
      this.$root.loading = false;
      //});
    });

    if (this.changeable.loadChanges("changeable-express-form")) {
      const poll_changes = this.changeable.poolChanges("curriculum");
      this.curriculum.applyChanges(poll_changes.changes);
    }

    this.stateSubscribe = this.changeable.$subscribe((mutation, state) => {
      //const poll_changes = this.changeable.poolChanges('curriculum')
      const poll_changes = this.changeable.poolChanges("curriculum");
      this.changeCounter = poll_changes.counter;

      if (poll_changes.counter >= 1) {
        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;
        this.changeable.localPersist("changeable-express-form");
        this.changeCounter = 0;
      }
    });
  },
  destroyed() {
    this.stateSubscribe();
  },

  /**
   * @todo Move to a getter inside the state
   */
  computed: {
    countryOptions() {
      return _.filter(this.domain.countries, function (country) {
        return country.value !== "BRA";
      });
    },
    firstName() {
      const name = this.curriculum.personal.name.trim();
      return name.split(" ")[0] || "";
    },
    profesionalRules() {

      const activeExperiences = _.filter(this.curriculum.experiences, (item) => {
        return item && item._action !== 'remove';
      });

      return {
        must_exists_if: ["y", activeExperiences.length],
        required: true,
      };
    },
    academicRules() {
      return {
        must_exists_if: ["y", this.curriculum.academic.length],
        required: true,
      };
    },
    languageRules() {
      return {
        must_exists_if: ["y", this.curriculum.languages.length],
        required: true,
      };
    },
    // computingRules() {
    //   return {
    //     must_exists_if: ["y", _.size(this.curriculum.computing)],
    //     required: true,
    //   };
    // },
  },
  methods: {
    addDocumentType(name, country) {
      this.domain
        .create_record("document_type", {
          document_type: name,
          country: country,
        })
        .then((record) => {
          this.curriculum.personal.document_type = record.label;
        });
    },
    addOccupation(name) {
      this.domain
        .create_record("occupations", { label: name })
        .then((record) => {
          this.curriculum.professional.intended_occupation.push(record);
        });
    },
    submitForm() {
      return this.social_media_account || this.create_curriculum
        ? this.save(this.create_curriculum)
        : this.createAccount();
    },
    createAccount() {
      this.$refs.curriculumStep2Rules.validate().then((success) => {
        this.curriculum
          .setAddressBasedOnZipcode(this.curriculum.address.zip_code)
          .then((response) => {
            if (!response) {
              this.$refs.curriculumStep2Rules.setErrors({
                "curriculum.address.zip_code": [
                  this.$tc("validations.messages.zip-code")
                ],
              });

              this.curriculum.address.street_type = null;
              this.curriculum.address.street = null;
              this.curriculum.address.district = null;
              this.curriculum.address.state = null;
              // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
              this.$nextTick(() => {
                this.curriculum.address.city = null;
              });

              this.canSubmit = false;
            } else {
              this.canSubmit = true;
            }
          })
          .finally(() => (this.emittedByZipCodeField = false));

        if (!success || !this.canSubmit) {
          this.inputFocus();
          this.$root.loading = false;
          return;
        }

        const extra_fields = _.filter(
          this.forms.express.extra,
          function (fields) {
            return fields.show;
          }
        );

        if (_.isEmpty(extra_fields)) {
          this.$router.push({
            name: "register4",
            params: { vacancy_id: this.vacancy_id },
          });
        } else {
          this.$router.push({
            name: "register3",
            params: { vacancy_id: this.vacancy_id },
          });
        }
      });
    },
    save() {
      this.$refs.curriculumStep2Rules.validate().then((success) => {
        if (!success) {
          this.inputFocus();
          this.isSaving = false;
          return;
        }

        const poll_changes = this.changeable.poolChanges("curriculum");
        this.changeCounter = poll_changes.counter;

        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;

        this.curriculum.persist(poll_changes.changes).then((data) => {
          if (data.data.success) {
            this.changeable.cleanUpAfterSave();
            this.changeCounter = 0;

            this.curriculum.createVwCandidates().then((response) => {
              if (response.data.success || response.data.alwready_exists) {
                if (this.$auth.check()) {
                  this.$auth.fetch();
                }

                this.$router.push({ name: "profile" });
                this.isSaving = false;
              }
            });
          }
        });
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },
  },

  watch: {
    "curriculum.address.zip_code": {
      handler(zipcode, oldValue) {
        this.emittedByZipCodeField = true;

        this.curriculum
          .setAddressBasedOnZipcode(zipcode)
          .then((response) => {
            if (response) {
              this.curriculum.address.street_type = response.street_type;
              this.curriculum.address.street = response.street;
              this.curriculum.address.district = response.district;
              this.curriculum.address.state = response.state;
              this.curriculum.address.latitude = response.latitude;
              this.curriculum.address.longitude = response.longitude;
              // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
              this.$nextTick(() => {
                this.curriculum.address.city = response.city;
              });

              this.canSubmit = true;
            } else {
              if (this.$refs.curriculumStep2Rules) {
                this.$refs.curriculumStep2Rules.setErrors({
                  "curriculum.address.zip_code": [
                    this.$tc("validations.messages.zip-code") 
                  ],
                });
              }

              this.curriculum.address.street_type = null;
              this.curriculum.address.street = null;
              this.curriculum.address.district = null;
              this.curriculum.address.state = null;
              // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
              this.$nextTick(() => {
                this.curriculum.address.city = null;
              });

              this.canSubmit = false;
            }
          })
          .finally(() => (this.emittedByZipCodeField = false));
      },
      deep: true,
    },
    "curriculum.address.state": function (uf) {
      // if (_.isEmpty(uf)) {
      //   this.disableCities = true;
      // } else {
      //   this.disableCities = false;
      // }

      if (!this.emittedByZipCodeField) {
        this.curriculum.address.city = "";
      }
      this.domain.load(`cities/${uf}`, "cities");
    },
    beforeDestroy() {
      localStorage.removeItem("social-media-account");
      // this.curriculum.resetState();
      // this.changeable.cleanUpAfterSave();
      // this.changeable.clearLocalStorage();
    },
  },
};
</script>
