<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <label :id="fieldsetId + '__BV_label_'" :for="inputId" class="d-block" v-if="internalLabel">
                        {{ internalLabel }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </slot>
            </template>


            <input :data-cy="internalTestId + '-proxy'" type="hidden" v-model="internalValue" />
                <!-- @input="$emit('input', internalValue)"
                @change="$emit('input', internalValue)" -->
            <b-form-checkbox-group
                :id="inputId"
                v-model="internalValue"
                @change="dispatchEvent('change', internalValue)"
                :class="{'is-invalid' : errors.length > 0}"
                :options="options"
                :data-cy="internalTestId"
                :label="internalLabel"
                :placeholder="internalPlaceholder"
                text-field="label"
                button-variant="outline-primary"
                v-bind="$attrs"
                autocomplete="off"
            ></b-form-checkbox-group>

            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>

    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import FormFieldBase from '../mixins/Base'

/**
 * Componente que encapsula o b-form-checkbox-group.
 * @see https://bootstrap-vue.org/docs/components/form-checkbox
 */
export default {
    name : 'form-field-checkbox',
    mixins : [
        FormFieldBase
    ],
    components: {
        ValidationProvider
    },
    props : {
        /**
         * Vetor com as opções de escolha disponíveis
         * @see https://bootstrap-vue.org/docs/components/form-checkbox#checkbox-group-options-array
         */
        options : {
            type: Array,
            required: true
        }
    },
    data() {
        return {
        };
    }
}
</script>
<!--
<docs>
```vue
<template>
    <div class="container">
        <form-field-checkbox v-model="inputValue" name="field-name" :options="options" stacked />
        <span>Selecionado: {{ inputValue }}</span>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            inputValue: [],
            options : [
                {
                    label: 'Opção 1',
                    value: 'Value1'
                },
                {
                    label: 'Opção 2',
                    value: 'Value2'
                },
            ]
        };
      }
    }
</script>
```
</docs>
-->
