<template>
  <div
    class="d-flex w-full h-100 align-items-center justify-content-center p-4"
    id="page-login"
  >
    <div class="col-md-6 col-lg-6 col-xl-4 px-0">
      <b-card>
        <div class="justify-center items-center">
          <div class="login-container w-full p-2">
            <div v-if="invalidationMessage">
              <div class="alert alert-danger" role="alert">
                <h5>{{ $t("user.forgot.recover_account_failure") }}</h5>
                <p v-html="$t('user.forgot.contact_to', { company_name })"></p>
              </div>
              <div class="mb-4">
                <b-button
                  class="btn w-full border"
                  variant="link"
                  pill
                  @click="$router.push({ name: 'login' })"
                >
                  {{ $t("user.forgot.return_to") }}
                </b-button>
              </div>
            </div>

            <div class="mb-6" v-if="!invalidationMessage">
              <div>
                <h5>{{ $t("user.forgotAccount.title") }}</h5>
                <p>{{ $t("user.forgotAccount.info") }}</p>
              </div>
            </div>

            <div class="mb-6" v-if="showCaptchaAlert">
              <div class="alert alert-danger" role="alert">
                {{ $t("common.captcha_error") }}
              </div>
            </div>

            <validation-observer ref="confirmAccountInfoRules">
              <div class="pb-4" v-if="!invalidationMessage">
                <form-field-input
                  name="passwords.cpf"
                  v-model="passwords.cpf"
                  rules="required|cpf"
                  mask="###.###.###-##"
                  autocomplete="off"
                  inputmode="numeric"
                  v-if="step === 0"
                />

                <form-field-phone
                  name="passwords.cellphone"
                  v-model="passwords.cellphone"
                  rules="required"
                  phone-type="cellphone"
                  mask="(##) #####-####"
                  inputmode="tel"
                  v-if="step === 1"
                />

                <form-field-date
                  name="passwords.birth_date"
                  v-model="passwords.birth_date"
                  rules="date|required|minority-date"
                  inputmode="numeric"
                  v-if="step === 2"
                />

                <form-field-input
                  name="passwords.primary_email"
                  v-model="passwords.primary_email"
                  rules="required|email"
                  inputmode="email"
                  type="email"
                  v-if="step === 3"
                />

                <!-- <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6LeRqQclAAAAAJMw7LmjmHK98_zdn9aB4ECMGJ-p"
                  @verify="captchaVerified"
                  @expired="captchaExpired"
                  @error="captchaError"
                /> -->

                <b-button
                  variant="primary"
                  class="btn w-full"
                  @click="step === 3 ? submitForm() : nextStep()"
                  pill
                >
                  {{
                    step === 3
                      ? $t("user.forgotAccount.validate_data")
                      : $t("user.forgotAccount.next")
                  }}
                </b-button>
              </div>
            </validation-observer>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { VueRecaptcha } from "vue-recaptcha";
import { notify } from "@/plugins/notification";
import * as FormComponents from "@components/forms";
import { usePasswords } from "@state/user/passwords";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    VueRecaptcha,
    ValidationObserver,
    ValidationProvider,
    ...FormComponents,
  },
  setup() {
    const passwords = usePasswords();

    return {
      passwords,
    };
  },
  created() {
    this.company_name = this.company.config.company_name;

    if (this.$auth.check()) {
      this.$router.push({ name: "profile" });
    }
  },
  data() {
    return {
      step: 0,
      captchaToken: "",
      showCaptchaAlert: false,
      robot: true,
      invalidationMessage: false,
      company_name: "",
    };
  },
  methods: {
    captchaVerified(response) {
      if (response) {
        this.captchaToken = response;
        this.robot = false;
        this.showCaptchaAlert = false;
      }
    },
    captchaExpired() {
      this.showCaptchaAlert = true;
      this.recaptchaToken = "";
      this.$refs.recaptcha.reset();
    },
    captchaError() {
      this.showCaptchaAlert = true;
      this.captchaToken = false;
      this.robot = true;
      this.$refs.recaptcha.reset();
    },
    nextStep() {
      // if (this.robot || !this.captchaToken) {
      //   this.showCaptchaAlert = true;
      //   return;
      // }

      if (this.step === 3) {
        return;
      }

      this.$refs.confirmAccountInfoRules.validate().then((success) => {
        if (success) {
          this.step++;
        }
      });
    },
    submitForm() {
      // if (this.robot || !this.captchaToken) {
      //   this.showCaptchaAlert = true;
      //   return;
      // }

      this.$refs.confirmAccountInfoRules.validate().then((success) => {
        if (success) {
          this.$root.loading = true;

          this.passwords
            .validateInfo()
            .then((response) => {
              if (response.data.success) {
                this.$router.push({
                  name: "reset-email",
                  params: { cpf: this.passwords.cpf },
                });

                return;
              }

              notify({
                title: "$t:errors.title.danger",
                message: "$t:errors.account_recover.not-found",
                variant: "danger",
              });

              this.invalidationMessage = true;
            })
            .catch((error) => {
              notify({
                title: "$t:errors.title.danger",
                message: "$t:errors.account_recover.not-found",
                variant: "danger",
              });

              this.invalidationMessage = true;
              this.$root.loading = false;
            })
            .finally(() => {
              this.$root.loading = false;
            });
        }
      });
    },
  },
};
</script>