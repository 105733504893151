<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <label :id="fieldsetId + '__BV_label_'" :for="inputId" class="d-block" v-if="internalLabel">
                        {{ internalLabel }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </slot>
            </template>
                <!-- @input="$emit('input', internalValue)"
                @change="$emit('input', internalValue)" -->
            <b-form-textarea
                id="inputId"
                class="w-full"
                :class="{'is-invalid' : errors.length > 0}"
                :label="internalLabel"
                v-model="internalValue"
                @change="dispatchEvent('change', internalValue)"
                :placeholder="placeholder"
                :data-cy="internalTestId"
                v-bind="$attrs"
                autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>

        </b-form-group>

    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import FormFieldBase from '../mixins/Base'

/**
 * Componente que encapsula o b-form-textarea.
 * @see https://bootstrap-vue.org/docs/components/form-textarea
 */
export default {
    name: 'form-field-textarea',
    mixins : [
        FormFieldBase
    ],
    components: {
        ValidationProvider
    }
}
</script>
<!--
<docs>
```vue
  <template>
    <form-field-textarea v-model="inputValue" name="field-name"></form-field-textarea>
  </template>

  <script>
    export default {
      data() {
        return { inputValue: 'Texto' };
      }
    }
  </script>
```
</docs>
-->
