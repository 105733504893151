<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-skeleton-img :aspect="aspect" animation="wave"></b-skeleton-img>
    </template>
    <b-aspect
      :aspect="aspect"
      class="imagem-vaga"
      v-if="item.banner_url != ''"
      ref="aspect"
    >
      <img
        ref="image"
        class="vacancy-banner-img"
        :class="{ 'rounded-top': rounded }"
        :src="'https://assets.selecty.app/' + item.banner_url"
        alt=""
        @error="renderPlaceholder($event)"
      />
    </b-aspect>
  </b-skeleton-wrapper>
</template>

<script>
import * as _ from "lodash-es";
import vacancyPlaceholder from "../editor/assets/vacancy-placeholder.jpg";

import images from "@mixins/images";
export default {
  mixins: [images],
  props: {
    item: {
      required: true,
      type: Object,
    },
    aspect: {
      type: String,
      default: "652:88",
      required: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      placeholderImage: vacancyPlaceholder,
      // placeholderImage: [
      //   "vacancy_01",
      //   "vacancy_02",
      //   "vacancy_03",
      //   "vacancy_04",
      //   "vacancy_05",
      //   "vacancy_06",
      //   "vacancy_07",
      //   "vacancy_08",
      //   "vacancy_09",
      //   "vacancy_10",
      // ],
    };
  },
  created() {
    window.addEventListener("resize", this.updateAspectRatio);
  },
  mounted() {
    // window.acs = this.$refs.aspect;
    this.updateAspectRatio();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateAspectRatio);
  },
  methods: {
    renderPlaceholder(ev) {
      ev.target.src = this.placeholderImage;
    },
    getSrc(src) {
      if (!src) {
        src = this.placeholderImage[Math.floor(Math.random() * 10) + 1];
      }

      return this.imagesList[`/resources/assets/images/vacancies/${src}.jpg`];
    },
    updateAspectRatio() {
      // window.acs = this.$refs.aspect;
      this.$refs.image.height = this.$refs.aspect.$el.clientHeight;
      this.$refs.image.width = this.$refs.aspect.$el.clientWidth;

      const sizes = _.map(this.aspect.split(":"), (item) => {
        return parseInt(item);
      });
      this.$refs.image.height = (this.$refs.image.width * sizes[1]) / sizes[0];
    },
  },
  watch: {
    isLoading: function (value) {
      if (!value) {
        this.$nextTick(() => {
          this.updateAspectRatio();
        });
      }
    },
  },
};
</script>

