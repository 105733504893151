<template>
    <!-- ESTE COMPONENTE RECEBE O CONTEÚDO DO RESULTADO DO TESTE, É O MESMO QUE O CANDIDATO VISUALIZA -->
    <div class="disc-result">
        <div class="card p-3">
            <p>{{ $t('disc.results.behavioral_profile_result') }}</p>
            <p>{{ $t('disc.results.unique_individual_statement') }}</p>
            <p class="mb-0">{{ $t('disc.results.information_utility_message') }}</p>
        </div>

        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-target.svg']" alt="Ícone de um alvo">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.goals') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.goal">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
             <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-heart.svg']" alt="Ícone de um coração">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.emotions') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.emotions">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-eye.svg']" alt="Ícone de um olho">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.judges_others_based_on') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.judges_others">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-users.svg']" alt="Ícone de duas pessoas">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.methods_of_influencing_others') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.influences_others">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-barchart.svg']" alt="Ícone de um gráfico em barra">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.value_to_the_organization') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.organization_value">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-warning.svg']" alt="Ícone de alerta">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.uses_excessively') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.overuses">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-heartbeat.svg']" alt="Ícone de batimentos cardíacos">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.under_pressure') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.under_pressure">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-thumbsdown.svg']" alt="Ícone de reprovação">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.avoids') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.fear">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="card p-3">
            <div>
                <div class="mb-3 d-flex align-items-center">
                    <img class="disc-profile-description--icon mr-3" :src="imagesList['/resources/assets/images/disc/disc-icon-chartlineup.svg']" alt="Ícone de um gráfico em linha">
                    <h2 class="text-blue mb-0">{{ $t('disc.results.needs_to_develop') }}</h2>
                </div>
                <ul class="text-lg disc-profile-description--ul">
                    <li v-for="item in pattern.effectiveness">{{ item }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import images from '@mixins/images';
import DiscGraphRenderer from './GraphRenderer.vue';
import { VueShowdown } from 'vue-showdown';

export default {
    mixins: [images],
    components: {
        DiscGraphRenderer,
        VueShowdown
    },
    props: {
        pattern: {
            type: Object
        },
        chart: {
            type: Object
        },
    }
}
</script>

<style lang="scss">
    .disc-result {
        .card {
            margin-bottom: 1rem;
            background-color: #FFFFFF;
            border: none;
            box-shadow: 0px 5px 5px rgba(135, 166, 222, 0.1);
            border-radius: 1.2rem;

            h2 {
                color: #00A5F7 !important;
                font-size: 1.4rem;
            }
        }

        .disc-grid {
            display: grid;
            grid-template-columns: 68px 1fr;
            grid-column-gap: 10px;

            @media (max-width: 767.98px) {
                grid-template-columns: 1fr;
                grid-row-gap: 0;
            }
        }

        .disc-description-grid {
            display: grid;
            grid-template-columns: 225px 1fr;
            grid-column-gap: 32px;

            @media (max-width: 767.98px) {
                grid-template-columns: 1fr;
                grid-row-gap: 0;
            }
        }

        .disc-profile-description--icon {
            @media (max-width: 767.98px) {
                width: 40px;
                height: 40px;
            }
        }

        .disc-profile-description--ul {
            line-height: 32px;
            padding-left: 110px;
            text-indent: -1em;
            list-style: none;
            margin-bottom: 1em;

            @media (max-width: 767.98px) {
                padding-left: 40px;
            }

            li {
                position: relative;

                &::before {
                    content: '•';
                    position: absolute;
                    top: 3px;
                    color: #F9B147;
                    font-size: 25px;
                    display: inline;
                }
            }
        }
    }
</style>
