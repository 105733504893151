<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >

        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <input :data-cy="internalTestId + '-proxy'" type="hidden" v-model="internalValue" />
            <b-form-checkbox
                :id="inputId"
                v-model="internalValue"
                @change="dispatchEvent('change', internalValue)"
                :class="{'is-invalid' : errors.length > 0}"
                :data-cy="internalTestId"
                :label="internalLabel"
                :placeholder="internalPlaceholder"
                text-field="label"
                button-variant="outline-primary"
                v-bind="$attrs"
                autocomplete="off"
                :value="$attrs['checked-value'] ?? true"
                :unchecked-value="$attrs['unchecked-value'] ?? false"
            >
            {{ $t(internalLabel) }}
            </b-form-checkbox>

            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>

    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import FormFieldBase from '../mixins/Base'

/**
 * Componente que encapsula o b-form-checkbox-group.
 * @see https://bootstrap-vue.org/docs/components/form-checkbox
 */
export default {
    name : 'form-field-simple-checkbox',
    mixins : [
        FormFieldBase
    ],
    components: {
        ValidationProvider
    },
    data() {
        return {
        };
    }
}
</script>
<!--
<docs>
```vue
<template>
    <div class="container">
        <form-field-checkbox v-model="inputValue" name="field-name" :options="options" stacked />
        <span>Selecionado: {{ inputValue }}</span>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            inputValue: [],
            options : [
                {
                    label: 'Opção 1',
                    value: 'Value1'
                },
                {
                    label: 'Opção 2',
                    value: 'Value2'
                },
            ]
        };
      }
    }
</script>
```
</docs>
-->
