<template>
  <div class="nps-widget">
    <validation-observer ref="npsRules" #default="{ invalid }" class="">
      <b-modal
        id="nps-widget-modal"
        @hide="clearUserData"
        v-model="isOpened"
        size="md"
        hide-header-close
        scrollable
      >
        <template #modal-title>
          {{ $t("feedback.modal.title") }}
        </template>
        <template #modal-footer="">
          <b-button
            variant="primary"
            pill
            @click="submit()"
            :disabled="isLoading || invalid"
          >
            <b-spinner small v-if="isLoading">
              {{ $t("common.loading") }}
            </b-spinner>
            {{ $t("common.send") }}
          </b-button>
          <b-button
            variant="secondary"
            pill
            @click="$bvModal.hide('nps-widget-modal')"
          >
            {{ $t("common.cancel") }}
          </b-button>
        </template>
        <template #default>
          <b-form>
            <!-- <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true, 'full-name': true }"
              :name="$t('curriculum.personal.name.label')"
              slim
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
              >
                <template #label>
                  <label for="name" class="d-block">
                    {{ $t("curriculum.personal.name.label") }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template> -->
            <!-- description="Prometemos não utilizar suas informações de contato para enviar qualquer tipo de SPAM." -->
            <!-- <b-form-input
                  id="name"
                  v-model="name"
                  :placeholder="$t('curriculum.personal.name.label')"
                  :disabled="$auth.check()"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
            </validation-provider> -->

            <!-- <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true, email: true }"
              :name="$t('curriculum.personal.primary_email.label')"
              slim
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
              >
                <template #label>
                  <label for="email" class="d-block">
                    {{ $t("curriculum.personal.primary_email.label") }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  id="email"
                  v-model="email"
                  :placeholder="$t('curriculum.personal.primary_email.label')"
                  :disabled="$auth.check()"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
            </validation-provider> -->

            <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true, min_value: 1 }"
              :name="$t('feedback.rating_0.name')"
              slim
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
              >
                <template #label>
                  <label for="rating_0" class="d-block">
                    {{ $t('feedback.modal.question_1') }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template>

                <b-form-rating
                  id="rating_0"
                  v-model="rating[0]"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  stars="5"
                  size="md"
                  show-value
                  show-value-max
                  variant="warning"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true, min_value: 1 }"
              :name="$t('feedback.rating_1.name')"
              slim
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
              >
                <template #label>
                  <label for="rating_1" class="d-block">
                    {{ $t('feedback.modal.question_2') }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template>

                <b-form-rating
                  id="rating_1"
                  v-model="rating[1]"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  stars="5"
                  size="md"
                  show-value
                  show-value-max
                  variant="warning"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true, min_value: 1 }"
              :name="$t('feedback.rating_2.name')"
              slim
              v-if="enrolling"
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
              >
                <template #label>
                  <label for="rating_2" class="d-block">
                    {{ $t('feedback.modal.question_3') }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template>

                <b-form-rating
                  id="rating_2"
                  v-model="rating[2]"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  stars="5"
                  size="md"
                  show-value
                  show-value-max
                  variant="warning"
                />
              </b-form-group>
            </validation-provider>
          </b-form>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>
<script>
import { useTheme } from "@state/system/theme";
import { StarIcon } from "@vue-hero-icons/solid";
import { useCommunication } from "@state/system/communication";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    StarIcon,
  },
  props: {
    enrolling: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      rating: [0, 0, 0],
      isOpened: false,
      isLoading: false,
    };
  },
  setup() {
    const theme = useTheme();
    const communication = useCommunication();

    return {
      communication,
      isMobile: theme.isMobile,
    };
  },
  methods: {
    clearUserData() {
      this.rating = [0, 0, 0];
    },
    submit() {
      this.isLoading = true;
      const currentCursor = document.body.style.cursor;
      document.body.style.cursor = "progress";
      return new Promise((resolve, reject) => {
        this.$refs.npsRules.validate().then((success) => {
          if (success) {
            // GET THE STATE AND PUSH TO SERVER
            this.communication
              .provideFeedback({
                rating: this.rating,
              })
              .then((data) => {
                if (data.success) {
                  this.$bvModal.hide("nps-widget-modal");
                  resolve(data.success);
                } else {
                  reject(false);
                }
              })
              .catch((err) => {
                console.info("err", err);
              })
              .finally(() => {
                this.isLoading = false;
                document.body.style.cursor = currentCursor;
              });
          } else {
            //this.scrollToError();
          }
        });
      });
      return false;
    },
  },
  computed: {
    showNps() {
      if (this.$route.query.showNps) {
        this.isOpened = true;
      }

      return this.$route.query.showNps;
    },
  },
  watch: {
    showNps() {
      this.isOpened = true;
    },
  },
};
</script>