<template>
    <div id="password-strength-checker">
        <div class="vx-row">
            <div
                class="password-strength vx-col w-full"
                :class="{
                    'very-week-password': passwordLevel == 1,
                    'week-password': passwordLevel == 2,
                    'regular-password': passwordLevel == 3,
                    'strong-password': passwordLevel >= 4,
                }"
            >
                <div :class="{ active: passwordLevel >= 1 }"></div>
                <div :class="{ active: passwordLevel >= 2 }"></div>
                <div :class="{ active: passwordLevel >= 3 }"></div>
                <div :class="{ active: passwordLevel >= 4 }"></div>
                <div :class="{ active: passwordLevel >= 5 }"></div>
                <p v-if="passwordLevel > 0">
                    {{ $t(passwordLevelOptions[passwordLevel]) }}
                </p>
            </div>
        </div>

        <div class="password-instructions-container w-full">
            <div v-for="(item, index) in passwordChecks" :key="index" class="d-flex align-items-center w-100">
                <b-icon
                    class="rounded-circle password-instructions-icon mr-2"
                    :class="{'bg-danger': !item.status, 'bg-success': item.status }"
                    font-scale="1"
                    variant="light"
                    icon="circle"
                />

                <span>{{ $t(item.description) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import * as _ from 'lodash-es';
export default {
    props: {
        password: {
            type: String,
            default: "",
        },
        password_confirmation: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            passwordLevel: 0,
            passwordLevelOptions: [
                '',
                'password_level.very_week',
                'password_level.week',
                'password_level.regular',
                'password_level.strong',
            ],
            passwordChecks: {
                hasLowercase: {
                    status: false,
                    description: "registration.lowerLetter",
                },
                hasUppercase: {
                    status: false,
                    description: "registration.upperLetter",
                },
                hasNumbers: {
                    status: false,
                    description: "registration.number",
                },
                hasSpecialCharacters: {
                    status: false,
                    description: "registration.specialChar",
                },
                hasLength: {
                    status: false,
                    description: "registration.eightChars",
                },
            }
        };
    },

    methods: {
        checkPasswordStrength(val) {
            if (val == null || val.length == 0) {
                this.passwordLevel = 0;
                this.passwordChecks.hasNumbers.status = false;
                this.passwordChecks.hasLength.status = false;
                this.passwordChecks.hasSpecialCharacters.status = false;
                this.passwordChecks.hasLowercase.status = false;
                this.passwordChecks.hasUppercase.status = false;
            } else {
                this.passwordChecks.hasNumbers.status = /\d/.test(val);
                this.passwordChecks.hasLength.status = val.length >= 8;
                this.passwordChecks.hasSpecialCharacters.status = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val);
                this.passwordChecks.hasLowercase.status = /[a-z]/.test(val);
                this.passwordChecks.hasUppercase.status = /[A-Z]/.test(val);
                this.passwordLevel = this.getPasswordLevel()
            }
        },

        getPasswordLevel() {
            return _.reduce(this.passwordChecks, function (sum, item) {
                return sum + (item.status ? 1 : 0);
            }, 0);
        }
    },

    watch: {
        password: function (val) {
            this.checkPasswordStrength(val)
        },

        password_confirmation: function (val) {
            this.checkPasswordStrength(val)
        },
    },
};
</script>
