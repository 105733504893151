// axios
import Vue from 'vue'
import axios from 'axios'

import { notify } from '@/plugins/notification';

//axios.defaults.baseURL = 'http://localhost:8000/api';
//axios.defaults.baseURL = window.location.href + 'api';
axios.defaults.withCredentials = true;

const httpClient = axios.create({
    baseURL: window.location.origin + '/backend',
    withCredentials: true,
    /* timeout: 20000, */
    headers: {
        'Content-Type': 'application/json',
    }
});


// Set selected language on request
httpClient.interceptors.request.use(function (config) {

    // Default language and available languages
    const default_locale = 'pt-BR'
    const available_languages = ['pt-BR', 'en-US', 'es']

    // Get language from local storage
    let current_language = window.localStorage.getItem('language')

    // Validate language
    const locale = available_languages.includes(current_language) ? current_language : default_locale;

    // Apply language
    config.headers['Accept-Language'] = locale;

    return config;
})

httpClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.data.notifiable) {
        notify(response.data.notifiable);
    }

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response?.data?.notifiable) {
        notify(error.response.data.notifiable);
    }
    //}


    return Promise.reject(error);
});

export default httpClient;
